<template>
  <base-layout
    page-title="Share"
    page-default-back-link="/posts"
    showBackButton="true"
  >
    <ion-row class="ion-no-padding ion-no-margin" v-if="!group">
      <ion-col
        class="ion-text-end ion-no-padding ion-no-margin ion-text-wrap"
        :size="getColSize()"
        :offset="getOffsetSize()"
      >
        <ion-item no-lines lines="none" class="ion-horizontal-margin">
          <ion-label style="margin-left: 50px">Share With</ion-label>
          <ion-select
            multiple="false"
            value="Follower"
            interface="popover"
            v-model="enteredVisibility"
            @ionChange="updateVisibility"
            style="margin-right: 50px"
          >
            <ion-select-option
              class="ion-padding-horizontal ion-no-margin"
              value="Public"
              >Public</ion-select-option
            >
            <ion-select-option
              class="ion-padding-horizontal ion-no-margin"
              value="Friend"
              >Friends</ion-select-option
            >
            <ion-select-option
              class="ion-padding-horizontal ion-no-margin"
              value="Family"
              >Family</ion-select-option
            >
          </ion-select>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row class="ion-no-padding ion-no-margin" v-if="group">
      <ion-col
        class="ion-text-end ion-no-padding ion-no-margin ion-text-wrap"
        :size="getColSize()"
        :offset="getOffsetSize()"
      >
        <ion-item no-lines lines="none" class="ion-horizontal-margin">
          <ion-label
            >Share With Group: <b>{{ group.group_name }}</b></ion-label
          >
        </ion-item>
      </ion-col>
    </ion-row>

    <ion-row class="ion-no-padding ion-no-margin" v-if="!group" style="margin-left: 5px; margin-right: 5px;">
      <ion-col
        class="ion-text-end ion-no-padding ion-no-margin ion-text-wrap"
        :size="getColSize()"
        :offset="getOffsetSize()"
      >
        <create-post-form
          @save-post="savePost"
          :externalContent="enteredDescription"
          :visibilityMode="enteredVisibility"
          :topics="createPostTopics"
          :cancelCallback="cancelShare"
          ref="createPostFormVar"
        ></create-post-form>
      </ion-col>
    </ion-row>

    <ion-row class="ion-no-padding ion-no-margin" v-if="group" style="margin-left: 5px; margin-right: 5px;">
      <ion-col
        class="ion-text-end ion-no-padding ion-no-margin ion-text-wrap"
        :size="getColSize()"
        :offset="getOffsetSize()"
      >
        <create-post-form
          @save-post="savePost"
          :externalContent="enteredDescription"
          visibilityMode="Group"
          :topics="createPostTopics"
          :cancelCallback="cancelShare"
          :group="group"
          ref="createPostFormVar"
        ></create-post-form>
      </ion-col>
    </ion-row>

    <ion-row class="ion-no-padding ion-no-margin">
      <ion-col
        class="ion-text-end ion-no-padding ion-no-margin ion-text-wrap"
        :size="getColSize()"
        :offset="getOffsetSize()"
      >
        <br /><br />
        <ion-button color="warning" @click="cancelShare">Cancel</ion-button>
      </ion-col>
    </ion-row>
  </base-layout>
</template>

<script>
import {
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonButton,
  IonRow,
  IonCol,
  alertController,
  loadingController,
  toastController,
} from "@ionic/vue";

import { Plugins } from "@capacitor/core";

import { useRouter } from "vue-router";

import { presignedURL, uploadImage, fetchGroup } from "../services/apiCall";

import {
  getColSize,
  getOffsetSize,
  b64toBlob,
  maxUploadSize,
  logOffApp,
} from "../services/utils";
import { getStrings } from "../services/lang";
import { readFromDB } from "../services/db";

import CreatePostForm from "../components/posts/CreatePostForm.vue";

const { Filesystem } = Plugins;

export default {
  components: {
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonButton,
    IonRow,
    IonCol,
    CreatePostForm,
  },
  setup() {
    return {
      getColSize,
      getOffsetSize,
      b64toBlob,
      maxUploadSize,
      getStrings,
    };
  },
  data() {
    const router = useRouter();
    return {
      router,
      paramData: this.$route.params.data ? this.$route.params.data : "",
      paramDataType: this.$route.params.type ? this.$route.params.type : "text",
      createPostTopics: [],
      enteredVisibility: "Public",
      photoPost: {
        is_photo_post: false,
        is_video_post: false,
        photo_url: "",
        photo_object_name: "",
      },
      prepareImageShareCalled: false,
      prepareImageShareCompleted: false,
      enteredDescription: "",
      group: undefined,
      fetchGroupCalled: false,
    };
  },
  ionViewDidEnter() {
    console.log("ExternalShare page did enter");
  },
  ionViewDidLeave() {
    console.log("ExternalShare page did leave");
  },
  ionViewWillEnter() {
    console.log("ExternalShare page will enter");
    this.readDBAndInit();
  },
  ionViewWillLeave() {
    console.log("ExternalShare page will leave");
    this.clearData();
  },
  watch: {
    "$route.params.data": function (data) {
      console.log("changed data", data);
      try {
        this.init();
      } catch (err) {
        alert("Error in updating Intent. " + err);
      }
    },
    "$route.params.type": function (type) {
      console.log("changed type", type);
      try {
        this.init();
      } catch (err) {
        alert("Error in updating Intent. " + err);
      }
    },
    "$route.params.tokenizedgroup": function (tokenizedgroup) {
      console.log("changed tokenizedgroup", tokenizedgroup);
      try {
        this.init();
      } catch (err) {
        alert("Error in updating Intent. " + err);
      }
    },
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    async openToast(msg) {
      const toast = await toastController.create({
        message: msg,
        duration: 1500,
      });
      return toast.present();
    },
    async showAlert(title, msg) {
      const anAlert = await alertController.create({
        header: title,
        message: msg,
        buttons: ["OK"],
      });
      await anAlert.present();
    },
    init() {
      this.group = undefined;

      let tokenizedGroup = this.$route.params.tokenizedgroup;
      if (typeof tokenizedGroup !== "undefined") {
        this.fetchGroupInfo(tokenizedGroup);
      }

      (this.paramData = this.$route.params.data ? this.$route.params.data : ""),
        (this.paramDataType = this.$route.params.type
          ? this.$route.params.type
          : "text"),
        (this.createPostTopics = JSON.parse(
          JSON.stringify(this.$store.getters.topicList)
        ));
      this.decodeParamData();
    },
    fetchGroupInfo(tokenizedgroup) {
      if (this.fetchGroupCalled) return;

      this.fetchGroupCalled = true;
      try {
        console.log("group id", tokenizedgroup);
        fetchGroup({ group_id: tokenizedgroup }).then((res) => {
          console.log("group info", res.data);
          if (res.data.status === 0) {
            let grp = res.data.result.group;
            this.group = {
              group_id: grp.id,
              group_name: grp.group_name,
              tokenized_group: grp.tokenized_group,
            };
            this.fetchGroupCalled = false;
            this.enteredVisibility = "Group";
          } else {
            this.group = undefined;
            this.fetchGroupCalled = false;
          }
        });
      } catch (err) {
        alert("Error fetching group info: " + err);
        this.group = undefined;
        this.fetchGroupCalled = false;
      }
    },
    readDBAndInit(callbackObject = this) {
      try {
        readFromDB(this.$store, true)()
          .then(async () => {
            // read from DB done
            // console.log("read from db, user", callbackObject.user);

            if (
              typeof callbackObject.user === "undefined" ||
              typeof callbackObject.user.user_id === "undefined"
            ) {
              this.logOff(callbackObject);
            } else {
              this.init();
            }
          })
          .catch((err) => {
            console.log("indexdb not available", err);
            this.logOff(callbackObject);
          });
      } catch (err) {
        console.log("indexdb not available", err);
        this.logOff(callbackObject);
      }
    },
    decodeParamData() {
      console.log('decodeParamData', this.paramDataType, this.paramData);
      if (this.paramDataType === "text") {
        this.enteredDescription = window.atob(this.paramData);

        this.refereshParams();
      } else if (this.paramDataType === "image") {
        let imageURI = window.atob(this.paramData);

        this.prepareImageShare(imageURI);
      } else if (this.paramDataType === "video") {
        let imageURI = window.atob(this.paramData);

        this.prepareVideoShare(imageURI);
      } else {
        this.enteredDescription = window.atob(this.paramData);

        this.refereshParams();
      }
    },
    refereshParams() {
      try {
        this.$refs.createPostFormVar.updateDescription(
          this.enteredDescription,
          undefined
        );
      } catch (ignored) {
        console.log(ignored);
      }
    },
    async prepareImageShare(imageURI) {
      if (this.prepareImageShareCalled) return;
      this.prepareImageShareCalled = true;

      const loading = await loadingController.create({
        message: "Making image preview...",
      });
      await loading.present();

      try {
        // form a unique file name
        const user = this.$store.getters.user;
        const fileName =
          user.user_id +
          "_" +
          user.auth.token +
          "_" +
          user.auth.session +
          "_" +
          Date.now() +
          ".jpg";

        this.photoPost.photo_object_name = fileName;

        const imgData = await Filesystem.readFile({
          path: imageURI,
        });

        // create a image blob
        const imageObject = new File([b64toBlob(imgData.data)], fileName);

        const sizeInBytes = imageObject.size;

        this.uploadMediaFile(
          fileName,
          imageObject,
          sizeInBytes,
          loading,
          false
        );
      } catch (gerr) {
        loading.dismiss();
        this.showAlert(
          "Image upload error",
          "We were unable to upload your image, please try again. Error is: " +
            gerr.toString()
        );
      }
    },
    async prepareVideoShare(imageURI) {
      if (this.prepareImageShareCalled) return;
      this.prepareImageShareCalled = true;

      const loading = await loadingController.create({
        message: "Making video preview...",
      });
      await loading.present();

      try {
        // form a unique file name
        const user = this.$store.getters.user;
        const fileName =
          user.user_id +
          "_" +
          user.auth.token +
          "_" +
          user.auth.session +
          "_" +
          Date.now() +
          ".mp4";

        this.photoPost.photo_object_name = fileName;

        const imgData = await Filesystem.readFile({
          path: imageURI,
        });

        // create a image blob
        const imageObject = new File([b64toBlob(imgData.data)], fileName);

        const sizeInBytes = imageObject.size;

        this.uploadMediaFile(fileName, imageObject, sizeInBytes, loading, true);
      } catch (gerr) {
        loading.dismiss();
        this.showAlert(
          "Video upload error",
          "We were unable to upload your video, please try again"
        );
      }
    },
    async uploadMediaFile(
      fileName,
      fileBlob,
      sizeInBytes,
      loader,
      isVideo = false
    ) {
      if (sizeInBytes > maxUploadSize()) {
        this.showAlert(
          "Media upload error",
          "Maximum upload size exeeded. Max: " +
            maxUploadSize() / 1024 / 1024 +
            "mb."
        );
        loader.dismiss();
        return;
      }

      // convert to file object
      const fileObject = new File([fileBlob], fileName);

      var photoPost = this.photoPost;

      // start the upload process
      presignedURL({ name: fileName })
        .then((res) => {
          console.log("presignedURL", res);
          if (res.data.status === 0) {
            uploadImage(res.data.result.put_url, fileObject)
              .then((res2) => {
                console.log("uploadImage", res2);
                // this.enteredDescription = res.data.result.get_url;
                photoPost.is_photo_post = !isVideo;
                photoPost.is_video_post = isVideo;
                photoPost.photo_url = res.data.result.get_url;
                photoPost.photo_object_name = fileName;

                this.enteredDescription = res.data.result.get_url;

                this.prepareImageShareCompleted = true;

                this.$refs.createPostFormVar.updateDescription(
                  this.enteredDescription,
                  photoPost
                );

                loader.dismiss();
              })
              .catch((err2) => {
                console.log("uploadImage - err", err2);
                this.showAlert(
                  "Media upload error",
                  "We were unable to upload your media, please try again"
                );
                loader.dismiss();
              });
          }
        })
        .catch((err) => {
          console.log("presignedURL - err", err);
          this.showAlert(
            "Media upload error",
            "We were unable to upload your media, please try again"
          );
          loader.dismiss();
        });
    },
    savePost(postData) {
      this.$store.dispatch("addpost", postData);
      console.log("savePost", postData);

      if (this.enteredVisibility === "Group") {
        this.router.replace("/groups/" + this.group.tokenized_group + "/show");
      } else {
        let theTab = "friend";
        if (this.enteredVisibility === "Public") theTab = "public";
        else if (this.enteredVisibility === "Family") theTab = "family";
        this.router.replace("/posts/tabs/" + theTab);
      }
    },
    async logOff(callbackObject = this) {
      this.openToast(
        this.getStrings(this.strings, "LoggingOffUserInvalidSession")
      );

      await logOffApp();

      callbackObject.$store.dispatch("adduser", {});

      callbackObject.router.replace("/login");
    },
    clearData() {
      this.paramData = "";
      this.paramDataType = "";
      this.createPostTopics = [];
      this.enteredVisibility = "Friend";
      this.photoPost.is_photo_post = false;
      this.photoPost.is_video_post = false;
      this.photoPost.photo_url = "";
      this.photoPost.photo_object_name = "";
      this.prepareImageShareCalled = false;
      this.prepareImageShareCompleted = false;
      this.enteredDescription = "";
    },
    cancelShare() {
      // clear all data
      this.clearData();

      this.router.replace("/posts");
    },
    updateVisibility() {
      this.$refs.createPostFormVar.updateVisibility(this.enteredVisibility);
    },
  },
};
</script>