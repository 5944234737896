<template>
  <base-layout page-title="Share" page-default-back-link="/posts" showBackButton="true">
    <ion-row class="ion-no-padding ion-no-margin">
      <ion-col
        class="ion-text-end ion-no-padding ion-no-margin ion-text-wrap"
        :size="getColSize()"
        :offset="getOffsetSize()"
      >
        <br/>
        <ion-item no-lines lines="none" class="left-margin-15px right-margin-15px">
          <ion-label style="text-align: center;">Select where to share</ion-label>
        </ion-item>
        <br/>
        <ion-button
          shape="round"
          expand="full"
          class="left-margin-15px right-margin-15px"
          @click="shareInPost"
        >
          Post
        </ion-button>
        <br/>
        <ion-button
          shape="round"
          expand="full"
          class="left-margin-15px right-margin-15px"
          @click="shareInGroup"
        >
          Group
        </ion-button>
        <br/>
        <ion-button
          shape="round"
          expand="full"
          class="left-margin-15px right-margin-15px"
          @click="shareInChat"
        > 
          Chat
        </ion-button>
        <br/>
        <ion-button
          fill="clear"
          size="small"
          class="left-margin-15px right-margin-15px"
          @click="cancelShare"
        > 
          Cancel
        </ion-button>
      </ion-col>
    </ion-row>
  </base-layout>
</template>

<script>
import {
  IonItem,
  IonLabel,
  IonButton,
  IonRow,
  IonCol,
  toastController,

} from "@ionic/vue";

import { useRouter } from "vue-router";

import { getColSize, getOffsetSize, logOffApp } from "../services/utils";
import { getStrings } from "../services/lang";
import { readFromDB } from "../services/db";

export default {
  components: {
    IonItem,
    IonLabel,
    IonRow,
    IonCol,
    IonButton,
  },
  ionViewWillEnter() {
    this.readDBAndInit();
  },
  ionViewWillLeave() {
  },
  setup() {
    return {
      getColSize,
      getOffsetSize,
      getStrings,
    };
  },
  data() {
    const router = useRouter();
    return {
      router,
      paramData: this.$route.params.data ? this.$route.params.data : "",
      paramDataType: this.$route.params.type ? this.$route.params.type : "text",
    };
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    async openToast(msg) {
      const toast = await toastController.create({
        message: msg,
        duration: 1500,
      });
      return toast.present();
    },
    readDBAndInit(callbackObject = this) {
      try {
        readFromDB(this.$store, true)()
          .then(async () => {
            // read from DB done
            // console.log("read from db, user", callbackObject.user);

            if (
              typeof callbackObject.user === "undefined" ||
              typeof callbackObject.user.user_id === "undefined"
            ) {
              this.logOff(callbackObject);
            } else {
              // this.init();
            }
          })
          .catch((err) => {
            console.log("indexdb not available", err);
            this.logOff(callbackObject);
          });
      } catch (err) {
        console.log("indexdb not available", err);
        this.logOff(callbackObject);
      }
    },
    async logOff() {
      this.openToast(this.getStrings(this.strings, "LoggingOffUserInvalidSession"));
      await logOffApp();

    },
    setParams() {
      this.paramData = this.$route.params.data ? this.$route.params.data : "";
      this.paramDataType = this.$route.params.type ? this.$route.params.type : "text";
    },
    async shareInPost() {
      this.setParams();
      await this.router.replace("/share/" + this.paramDataType + "/" + this.paramData);
    },
    async shareInGroup() {
      this.setParams();
      await this.router.replace("/sharegroup/" + this.paramDataType + "/" + this.paramData);
    },
    async shareInChat() {
      this.setParams();
      await this.router.replace("/chats/" + this.paramDataType + "/" + this.paramData);
    },
    async cancelShare() {
      await this.router.replace("/posts");
    },
  },
};
</script>