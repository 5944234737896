<template>
  <ion-content class="ion-padding ion-margin">
    <ion-button size="small" slot="end" shape="round">
      <ion-icon slot="icon-only" :icon="closeCircle"></ion-icon>
    </ion-button>
    <ion-label>Choose a Relation</ion-label>
    <ion-select
      v-model="familyRelation"
      class="ion-padding-horizontal ion-no-margin"
    >
      <ion-select-option
        class="ion-padding-horizontal ion-no-margin"
        value="Father"
        >Father</ion-select-option
      >
      <ion-select-option
        class="ion-padding-horizontal ion-no-margin"
        value="Mother"
        >Mother</ion-select-option
      >
      <ion-select-option
        class="ion-padding-horizontal ion-no-margin"
        value="Wife"
        >Wife</ion-select-option
      >
      <ion-select-option
        class="ion-padding-horizontal ion-no-margin"
        value="Husband"
        >Husband</ion-select-option
      >
      <ion-select-option
        class="ion-padding-horizontal ion-no-margin"
        value="Son"
        >Son</ion-select-option
      >
      <ion-select-option
        class="ion-padding-horizontal ion-no-margin"
        value="Daughter"
        >Daughter</ion-select-option
      >
      <ion-select-option
        class="ion-padding-horizontal ion-no-margin"
        value="Brother"
        >Brother</ion-select-option
      >
      <ion-select-option
        class="ion-padding-horizontal ion-no-margin"
        value="Sister"
        >Sister</ion-select-option
      >
      <ion-select-option
        class="ion-padding-horizontal ion-no-margin"
        value="GrandMother"
        >Grandmother</ion-select-option
      >
      <ion-select-option
        class="ion-padding-horizontal ion-no-margin"
        value="GrandFather"
        >Grandfather</ion-select-option
      >
      <ion-select-option
        class="ion-padding-horizontal ion-no-margin"
        value="Uncle"
        >Uncle</ion-select-option
      >
      <ion-select-option
        class="ion-padding-horizontal ion-no-margin"
        value="Aunt"
        >Aunt</ion-select-option
      >
      <ion-select-option
        class="ion-padding-horizontal ion-no-margin"
        value="Cousin"
        >Cousin</ion-select-option
      >
      <ion-select-option
        class="ion-padding-horizontal ion-no-margin"
        value="Nephew"
        >Nephew</ion-select-option
      >
      <ion-select-option
        class="ion-padding-horizontal ion-no-margin"
        value="Niece"
        >Niece</ion-select-option
      >
      <ion-select-option
        class="ion-padding-horizontal ion-no-margin"
        value="Other"
        >Other</ion-select-option
      >
    </ion-select>
    <ion-button size="small" shape="round" @click="sendFamilyRequest"
      >Send Request</ion-button
    >
  </ion-content>
</template>
<script>
import {
  IonContent,
  IonSelect,
  IonSelectOption,
  IonLabel,
  IonButton,
  IonIcon,
} from "@ionic/vue";
import { defineComponent } from "vue";

import { closeCircle } from "ionicons/icons";

import { familyRequest } from "../../services/apiCall.js";

export default defineComponent({
  name: "FamilyRequestOptions",
  props: ["userId", "parent", "familyRequestCallback"],
  components: {
    IonContent,
    IonSelect,
    IonSelectOption,
    IonLabel,
    IonButton,
    IonIcon,
  },
  data() {
    return { familyRelation: "", closeCircle };
  },
  methods: {
    sendFamilyRequest() {
      familyRequest({ request_to: this.userId, relation: this.familyRelation })
        .then((res) => {
          console.log("familyRequest", res);
          this.parent.dismiss();
        })
        .catch((err) => {
          console.log("familyRequest - err", err);
          this.parent.dismiss();
        });
    },
  },
});
</script>
