
<template>
  <ion-toolbar>
    <ion-row v-if="showAttachOptions" class="animate-bottom">
      <ion-col class="center_image_col">
        <ion-fab-button @click="takePhoto()" size="small" color="warning">
          <ion-icon size="small" :icon="icon.cameraOutline"></ion-icon>
        </ion-fab-button>
      </ion-col>
      <ion-col class="center_image_col">
        <input type="file" id="image_files" accept="image/png, image/gif, image/jpeg" style="display: none;" @change="uploadImages" multiple>
        <ion-fab-button @click="uploadPhoto()" size="small" color="tertiary">
          <ion-icon size="small" :icon="icon.imagesOutline"></ion-icon>
        </ion-fab-button>
      </ion-col>
      <ion-col class="center_image_col">
        <ion-fab-button @click="uploadVideo()" size="small" color="success">
          <ion-icon size="small" :icon="icon.videocamOutline"></ion-icon>
        </ion-fab-button>
      </ion-col>
      <ion-col class="center_image_col">
        <ion-fab-button @click="uploadDocument()" size="small" color="secondary">
          <ion-icon size="small" :icon="icon.documentOutline"></ion-icon>
        </ion-fab-button>
      </ion-col>
    </ion-row>
    <!-- Image preview -->
    <ion-row v-if="attachedImages.length" class="animate-bottom">
      <ion-col class="center_image_col" v-for="image in attachedImages" :key="image">
        <ion-card
          no-lines
          lines="none"
          class="ion-no-padding ion-no-pargin all-margin-0px"
          style="border-radius: 0px 0px 10px 10px;"
        >
          <ion-img :src="image.url" style="min-width: 100px; max-width: 100px;"></ion-img>
          <ion-button
            color="light"
            size="small"
            shape="round"
            class="ion-no-padding ion-no-pargin"
            style="position: absolute; bottom: 0px; right: 0px;"
            @click="onRemoveImage(image.id)"
            :disabled="removeImageClicked"
          >
            <ion-icon
              slot="icon-only"
              color="dark"
              :icon="icon.closeCircle"
            ></ion-icon>
          </ion-button>
        </ion-card>
      </ion-col>
    </ion-row>
    <!-- Message text box -->
    <ion-item no-lines lines="none">
      <ion-textarea
        auto-grow="true"
        autocomplete="on"
        autocapitalize="on"
        spellcheck="true"
        rows="1"
        v-model="messagPayload.message"
        id="_messageBox"
        placeholder="New Message"
        class="conversation_textarea_outline"
      >
        <ion-button
          fill="clear"
          shape="round"
          color="dark"
          @click="toggleAttachmentOptions"
          style="position: absolute; right: 10px; bottom: 7px;"
          class="ion-no-padding ion-no-margin"
        >
          <ion-icon v-if="!showAttachOptions" slot="icon-only" :icon="icon.attachOutline"></ion-icon>
          <ion-icon v-if="showAttachOptions" slot="icon-only" :icon="icon.closeOutline"></ion-icon>
        </ion-button>
      </ion-textarea>
      <ion-fab style="position: absolute; right: 0px; bottom: 0px">
        <ion-fab-button
          @click="sendMessage"
          size="small"
          vertical="bottom"
          horizontal="end"
          style="margin-bottom: 5px"
          :disabled="messagPayload.message.length === 0 && attachedImages.length === 0"
        >
          <ion-icon size="small" :icon="icon.sendSharp"></ion-icon>
        </ion-fab-button>
      </ion-fab>
    </ion-item>  
  </ion-toolbar>
</template>

<script>
import {
  IonTextarea,
  IonItem,
  IonButton,
  IonIcon,
  IonFab,
  IonFabButton,
  IonToolbar,
  IonRow,
  IonCol,
  IonCard,
  IonImg,
} from "@ionic/vue";
import { useRouter } from "vue-router";

import {
  sendSharp,
  attachOutline,
  cameraOutline,
  videocamOutline,
  imagesOutline,
  documentOutline,
  closeOutline,
  closeCircle,
} from "ionicons/icons";

export default {
  name: "ConversationBox",
  emits: ["send-message"],

  components: {
    IonTextarea,
    IonItem,
    IonButton,
    IonIcon,
    IonFab,
    IonFabButton,
    IonToolbar,
    IonRow,
    IonCol,
    IonCard,
    IonImg,
  },

  setup() {
    const router = useRouter();
    return {
      router,
    };
  },

  data() {
    return {
      showAttachOptions: false,
      attachedImages: [],
      removeImageClicked: false,
      icon: {
        sendSharp,
        attachOutline,
        cameraOutline,
        videocamOutline,
        imagesOutline,
        documentOutline,
        closeOutline,
        closeCircle,
      },

      messagPayload: {
        message: "",
      },
    };
  },

  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      return this.$store.getters.user;
    },
    currentConversationSocket() {
      return this.$store.getters.currentConversationSocket;
    },
  },

  methods: {
    init() {
      this.messagPayload.message = "";
      this.setFocusToMessageBox();
    },
   
    setFocusToMessageBox() {
      document.getElementById("_messageBox").setFocus();
      this.scrollMessageListToBottom();
    },
    
    scrollMessageListToBottom() {
      document.getElementById("_conversationThreadContent").scrollToBottom();
    },
    
    sendMessage() {
      this.showAttachOptions = false;
      this.messagPayload.message = this.messagPayload.message.trim();
      
      if (this.messagPayload.message === "" && this.attachedImages.length === 0) return;

      let msgCopy = JSON.parse(JSON.stringify(this.messagPayload));
      this.messagPayload.message = "";
      this.setFocusToMessageBox();

      msgCopy.message_type = "Text";
      if (this.attachedImages.length) {
        msgCopy.message_type = "Image";
        for (var idx=0; idx<this.attachedImages.length; idx++) {
          msgCopy.attached_image = this.attachedImages[idx];
          this.$emit("send-message", msgCopy);  // within the cache
          msgCopy.message = ""; // message is attached to the first image only
        }
      } else {
        this.$emit("send-message", msgCopy);  // within the cache
      }

      this.attachedImages = [];
    },

    takePhoto() {
      this.showAttachOptions = false;
    },
    
    async uploadPhoto() {
      var inputFileElement = document.getElementById('image_files');
      inputFileElement.click();
    },
    
    async uploadImages() {
      this.showAttachOptions = false;
      var inputFileElement = document.getElementById('image_files');
      //var readImages = inputFileElement.files.map((x) => URL.createObjectURL(x) );
      //this.attachedImages.concat(readImages);
      for (var idx=0; idx<inputFileElement.files.length; idx++) {
        console.log("IMAGEFILE", inputFileElement.files[idx]);
        this.attachedImages.push({
          'url': URL.createObjectURL(inputFileElement.files[idx]),
          'id': inputFileElement.files[idx].name + new Date(),
          'image_file': inputFileElement.files[idx],
        });
      }
    },
    
    uploadVideo() {
      this.showAttachOptions = false;
    },
    
    uploadDocument() {
      this.showAttachOptions = false;
    },
    
    toggleAttachmentOptions() {
      this.showAttachOptions = !this.showAttachOptions;
      this.scrollMessageListToBottom();
    },
    
    onRemoveImage(id) {
      var filterIndex = this.attachedImages.findIndex(
        (x) => x.id === id
      );
      this.attachedImages.splice(filterIndex, 1);
    },
  },
};
</script>