
<template>
  <ion-avatar
    @click="navigateGroup(group)"
    style="
      cursor: pointer;
      min-width: 50px;
      min-height: 50px;
      margin-top: 0px;
      margin-left: 15px;
      padding-top: 0px;
    "
    class="comment-avatar"
  >
    <ion-img
      style="
        min-width: 50px;
        min-height: 50px;
        margin-top: 8px;
        padding-top: 0px;
      "
      v-if="!group.photo_thumbnail"
      src="assets/icon/misc/group.svg"
    ></ion-img>
    <ion-img
      style="
        min-width: 50px;
        min-height: 50px;
        margin-top: 8px;
        padding-top: 0px;
      "
      v-if="group.photo_thumbnail"
      :src="group.photo_thumbnail"
    ></ion-img>
  </ion-avatar>
  <ion-label style="margin-left: 80px; min-height: 65px;">
    <ion-text
      @click="navigateGroup(group)"
      :disabled="!canViewGroup(group)"
      style="cursor: pointer;"
    >
      <ion-icon :icon="group.visibility_type === 'Closed' ? icons.lockClosedOutline : icons.lockOpenOutline"> </ion-icon>
        <b>&nbsp;{{ group.group_name }}</b>
      <p><span class="ion-text-wrap">{{ group.description }}</span></p>
    </ion-text>
    <ion-label
      color="success"
      v-if="
        ((group.joining_by !== 'Invitation' && group.isRequestSent) ||
          group.has_active_join_request) &&
        !group.is_group_member
      "
      class="top-margin-10px"
    >
      <p>{{ getStrings(strings, "JoinGroupRequestSent") }}</p>
    </ion-label>  
    <ion-buttons xv-if="!group.is_group_member" class="right-margin-15px top-margin-10px">
      <ion-button
        size="small"
        fill="outline"
        shape="round"
        color="dark"
        v-if="
          group.joining_by !== 'Invitation' &&
          !group.isRequestSent &&
          !group.is_group_member &&
          !(group.has_active_invite || group.has_active_join_request)
        "
        @click="joinGroup(group)"
        :disabled="joinGroupClicked"
      >
        <small>{{ getStrings(strings, "JoinGroup") }}</small>
      </ion-button>
      <ion-button
        size="small"
        fill="outline"
        shape="round"
        color="dark"
        @click="acceptInvite(group)"
        v-if="group.has_active_invite"
      >
        <small>{{ getStrings(strings, "Accept") }}</small>
      </ion-button>
      <ion-button
        size="small"
        fill="outline"
        shape="round"
        color="dark"
        @click="rejectInvite(group)"
        v-if="group.has_active_invite"
      >
        <small>{{ getStrings(strings, "Reject") }}</small>
      </ion-button>
    </ion-buttons>
  </ion-label>
  <br/>
</template>

<script>
import {
  IonLabel,
  IonButton,
  IonAvatar,
  IonImg,
  IonText,
  IonButtons,
  IonIcon,
  toastController,
} from "@ionic/vue";
import { lockClosedOutline, lockOpenOutline } from "ionicons/icons";
import { useRouter } from "vue-router";

import {
  isMobileScreen,
  getColSize,
  getOffsetSize,
} from "../../services/utils";

import { getStrings } from "../../services/lang";

import { requestJoinGroup, acceptGroupInvite, ignoreGroupInvite } from "../../services/apiCall";

export default {
  name: "GroupItem",
  props: ["group", "shareData", "shareDataType"],
  components: {
    IonLabel,
    IonButton,
    IonAvatar,
    IonImg,
    IonText,
    IonButtons,
    IonIcon,
  },
  setup() {
    const router = useRouter();
    return {
      router,
      getColSize,
      getOffsetSize,
      isMobileScreen,
      requestJoinGroup,
      acceptGroupInvite,
      ignoreGroupInvite,
      getStrings,
    };
  },
  data() {
    return {
      joinGroupClicked: false,

      icons: {
        lockClosedOutline,
        lockOpenOutline
      },
    };
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      return this.$store.getters.user;
    },
    currentGroupMember() {
      return this.$store.getters.currentGroupMember;
    },
    isAdmin() {
      return this.$store.getters.currentGroupMember.member_type === "Admin";
    },
  },
  methods: {
    async openToast(msg) {
      const toast = await toastController.create({
        message: msg,
        duration: 1500,
      });
      return toast.present();
    },
    joinGroup(group) {
      this.joinGroupClicked = true;
      console.log("joinGroup", group);

      requestJoinGroup({
        group_id: group.id,
        user_id: this.$store.getters.user.user_id,
      })
        .then((res) => {
          console.log("requestJoinGroup", res);
          if (res.data.status === 0) {
            group.isRequestSent = true;
          } else {
            this.openToast(res.data.error.error_message);
          }
          this.joinGroupClicked = false;
        })
        .catch((err) => {
          console.log("requestJoinGroup - err", err);
          this.joinGroupClicked = false;
          this.openToast(this.getStrings(this.strings, "UnableToJoinRequestTryAgain"));
        });
    },
    acceptInvite(group) {
      acceptGroupInvite({ group_invite_id: group.group_invite_id, group_id: group.id })
      .then((res) => {
        console.log("acceptInvite", res);
        if (res.data.status === 0) {
          group.has_active_invite = false;
          group.is_group_member = true;
          group.group_member_type = 'Member';
        } else {
          this.openToast(res.data.error.error_message);
        }

      })
      .catch((err) => {
          console.log("acceptInvite - err", err);
          this.joinGroupClicked = false;
          this.openToast(this.getStrings(this.strings, "UnableAcceptInviteTryAgain"));
      });
    },
    rejectInvite(group) {
      ignoreGroupInvite({ group_invite_id: group.group_invite_id})
      .then((res) => {
        console.log("rejectInvite", res);
        if (res.data.status === 0) {
          group.has_active_invite = false;
          group.is_group_member = false;
        } else {
          this.openToast(res.data.error.error_message);
        }

      })
      .catch((err) => {
          console.log("rejectInvite - err", err);
          this.joinGroupClicked = false;
          this.openToast(this.getStrings(this.strings, "UnableRejectInviteTryAgain"));
      });
    },
    canViewGroup(group) {
      return group.is_group_member || group.visibility_type === "Public" || group.visibility_type === "Professional_Page";
    },
    async navigateGroup(group) {
      if (this.canViewGroup(group)) {
        if (group.visibility_type !== "Professional_Page" && this.shareData) {
          await this.router.replace("/share/" + this.shareDataType + "/" + this.shareData + "/" + group.tokenized_group);
        } else if (group.visibility_type === "Professional_Page") {
          await this.router.push("/page/" + group.tokenized_group);
        } else {
          await this.router.push("/groups/" + group.tokenized_group + "/show");
        }
      }
    },
  },
};
</script>