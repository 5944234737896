
<template>
  <ion-page>
    <ion-header class="ion-no-border ion-no-padding ion-no-margin">
      <ion-toolbar mode="md" class="ion-no-border ion-no-padding ion-no-margin">
        <ion-buttons :collapse="true" slot="end">
          <language-selection></language-selection>
        </ion-buttons>
      </ion-toolbar>
      <hr 
        style="border-top: 1px dotted grey"
        class="
            ion-no-border
            ion-no-padding
            ion-no-margin
            left-margin-15px 
            right-margin-15px
          "
      />
    </ion-header>
    <ion-content>
      <ion-row class="animate-fading">
        <ion-col
          :size="getColSize(true)"
          :offset="getOffsetSize(true)"
          :class="isMobileScreen() ? 'ion-no-padding ion-no-margin' : ''"
        >
          <ion-row>
            <ion-col
              :size="getFullColSize()"
              :style="getMargin(true)"
              :class="
                isMobileScreen() ? 'ion-padding-horizontal ion-no-margin' : ''
              "
            >
              <p>
                <ion-text color="primary" style="text-align: center" padding>
                  <b
                    style="
                      font-family: 'ShadowsIntoLight';
                      font-size: 40px;
                      font-weight: 500;
                    "
                    >{{ appName().toUpperCase() }}</b
                  > <sup style="font-family: 'ShadowsIntoLight'; font-size: 40px;"><small><small><small>βeta</small></small></small></sup>
                </ion-text>
                <br />
                <ion-text color="dark">
                  <br />
                  <span :style="getTitleFont()">
                    A network for life itself. Connect with real people in your
                    life.
                  </span>
                </ion-text>
              </p>
            </ion-col>
            <ion-col
              :size="getFullColSize()"
              :style="getMargin(false)"
              v-if="!loadingUI"
              :class="
                isMobileScreen()
                  ? 'ion-no-padding ion-no-margin animate-fading-2'
                  : 'animate-fading-2'
              "
            >
              <form @submit.prevent="handleLogin">
                <ion-row
                  :class="
                    isMobileScreen() ? 'ion-no-padding ion-no-margin' : ''
                  "
                >
                  <ion-col
                    :class="
                      isMobileScreen() ? 'ion-no-padding ion-no-margin' : ''
                    "
                  >
                    <ion-card
                      style="box-shadow: none;"
                      :class="
                        isMobileScreen() ? 'ion-no-padding ion-no-margin' : ''
                      "
                    >
                      <ion-card-content
                        :class="
                          isMobileScreen()
                            ? 'ion-padding-horizontal ion-no-margin'
                            : ''
                        "
                      >
                        <ion-item no-lines lines="none" class="login-input-item">
                          <ion-label
                            style="--padding-top: 0px; margin-bottom: 5px;"
                            class="line_hight_one ion-no-padding ion-no-margin"
                            position="floating">
                              {{ getStrings(strings, "Email") }} / {{ getStrings(strings, "Phone") }}
                          </ion-label>
                          <ion-input
                            v-model="form.username"
                            id="username"
                            type="text"
                            inputmode="text"
                            required
                            @keyup.enter="keyHit"
                            style="--padding-top: 0px; margin-top: 0px;"
                            class="line_hight_one"
                          ></ion-input>
                        </ion-item>
                        <ion-item no-lines lines="none" class="login-input-item" style="margin-bottom: 20px;">
                          <ion-label style="--padding-top: 0px; margin-bottom: 5px;" class="line_hight_one ion-no-padding ion-no-margin" position="floating">{{ getStrings(strings, "Password") }}</ion-label>
                          <ion-input
                            type="password"
                            v-model="form.password"
                            id="password"
                            required
                            @keyup.enter="keyHit"
                            style="--padding-top: 0px; margin-top: 0px;"
                            class="line_hight_one"
                          ></ion-input>
                        </ion-item>
                        <ion-button
                          expand="block"
                          shape="round"
                          color="primary"
                          type="submit"
                          slot="start"
                          size="normal"
                          :disabled="submitDisabled"
                        >
                          {{ getStrings(strings, "SignIn") }}
                          <ion-icon slot="end" :icon="logIn"></ion-icon>
                          <ion-spinner
                            v-if="submitDisabled"
                            slot="end"
                            name="dots"
                          ></ion-spinner>
                        </ion-button>
                        <ion-button
                          expand="block"
                          slot="start"
                          fill="clear"
                          router-link="/user/password/reset"
                          >{{ getStrings(strings, "ForgotPassword") }}?</ion-button
                        >
                        <hr style="border-top: 1px dotted grey" />
                        <ion-button
                          expand="block"
                          color="success"
                          router-link="/signup"
                          shape="round"
                          style="margin-top: 22px;"
                        >
                          {{ getStrings(strings, "CreateNewAccount") }}
                          <ion-icon slot="end" :icon="personAdd"></ion-icon>
                        </ion-button>
                        <br />
                      </ion-card-content>
                    </ion-card>
                  </ion-col>
                </ion-row>
              </form>
            </ion-col>
          </ion-row>
          <br />
          <ion-label style="text-align: center">
            <p>
              <a
                style="text-decoration: none"
                href="https://www.nextapp.co.in/docs/about_us.html"
                >About us -
              </a>
              <a
                style="text-decoration: none"
                href="https://www.nextapp.co.in/docs/privacy_policy.html"
                >Privacy -
              </a>
              <a
                style="text-decoration: none"
                href="https://www.nextapp.co.in/docs/terms_of_service.html"
                >Terms -
              </a>
              <a
                style="text-decoration: none"
                href="https://www.nextapp.co.in/docs/community_guidelines.html"
                >Guidelines -
              </a>
              <span v-if="isMobileScreen()"><br /></span>
              <a
                style="text-decoration: none"
                href="https://www.nextapp.co.in/docs/compliance.html"
                >Compliance -
              </a>
              <a
                style="text-decoration: none"
                href="https://www.nextapp.co.in/docs/contact_us.html"
                >Contact us</a
              >
            </p>
            <br />
            <ion-label color="medium" style="text-align: center"
              ><p>&#169;2021, Conversant Algorithms Pvt Ltd</p></ion-label>
          </ion-label>
          <br />
        </ion-col>
      </ion-row>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonItem,
  IonLabel,
  IonButton,
  IonInput,
  IonIcon,
  IonRow,
  IonCol,
  IonSpinner,
  IonText,
  IonCard,
  IonCardContent,
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  loadingController,
  toastController,
  alertController,
} from "@ionic/vue";
import { logIn, personAdd } from "ionicons/icons";
import { useRouter } from "vue-router";

import { Plugins } from "@capacitor/core";

import { loadLang, getStrings } from "../services/lang";

import { login, registerDevice, getPreferredLang } from "../services/apiCall";
import { readFromDB, putUser } from "../services/db";
import { isMobileScreen, isAndroid, isIOS } from "../services/utils";
import {
  getColSize,
  getOffsetSize,
  getFullColSize,
  getMargin,
  appName,
  getTitleFont,
} from "../services/utils";

import LanguageSelection from "../components/settings/LanguageSelection.vue";

const { PushNotifications, LocalNotifications } = Plugins;

export default {
  name: "SignIn",
  emits: ["save-user"],
  ionViewDidEnter() {
    console.log("Login page did enter", this.user);
  },
  ionViewDidLeave() {
    console.log("Login page did leave");
  },
  ionViewWillEnter() {
    console.log("Login page will enter", this.user, this.$store.getters.user);
    this.init();
  },
  ionViewWillLeave() {
    console.log("Login page will leave");
  },
  components: {
    IonItem,
    IonLabel,
    IonButton,
    IonInput,
    IonIcon,
    IonCol,
    IonRow,
    IonSpinner,
    IonText,
    IonCard,
    IonCardContent,
    IonPage,
    IonContent,
    IonHeader,
    IonToolbar,
    IonButtons,
    LanguageSelection
  },
  setup() {
    const router = useRouter();
    return {
      router,
      logIn,
      personAdd,
      getColSize,
      getOffsetSize,
      getFullColSize,
      getMargin,
      appName,
      getTitleFont,
      isMobileScreen,
      isAndroid,
      isIOS,
      registerDevice,
      getStrings,
      getPreferredLang,
    };
  },
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      login,
      submitDisabled: false,
      loadingUI: true,
    };
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    async init() {
      console.log("[login-init]");

      const callbackObject = this;

      var loadUI = async () => {
        const loading = await loadingController.create({
          message: "Please wait...",
        });

        loading.present();

        try {
          // already logged in? re-direct
          readFromDB(this.$store)()
            .then(() => {
              // read from DB done
              console.log("read from db, user", callbackObject.user);

              loadLang(this.$store);

              if (
                typeof callbackObject.user !== "undefined" &&
                typeof callbackObject.user.user_id !== "undefined"
              ) {
                if (callbackObject.user.key_verified) {
                  localStorage.setItem("defaultRoute", "/posts");
                  callbackObject.router.replace("/tabs/posts");
                  loading.dismiss();
                } else {
                  callbackObject.router.replace("/verify");
                  loading.dismiss();
                }
              } else {
                // invalid login redirect to login
                loading.dismiss();
                this.loadingUI = false;
                callbackObject.router.replace("/login");
              }
            })
            .catch(() => {
              loading.dismiss();
              loadLang(this.$store);
              this.loadingUI = false;
              callbackObject.router.replace("/login");
            });
        } catch (err) {
          loading.dismiss();
          loadLang(this.$store);
          this.loadingUI = false;
          callbackObject.router.replace("/login");
        }
      };

      // load the UI
      loadUI();
    },
    keyHit(ev) {
      console.log("keyHit", ev);
      // this.$dispatch("submit");
      this.handleLogin();
    },
    async showAlert(title, msg) {
      const anAlert = await alertController.create({
        header: title,
        message: msg,
        buttons: ["OK"],
      });
      await anAlert.present();
    },
    async openToast(msg) {
      const toast = await toastController.create({
        message: msg,
        duration: 1500,
      });
      return toast.present();
    },
    async handleLogin() {
      if (this.submitDisabled) return ;
      
      // console.log('handleLogin....', this.login);
      if (this.form.username.trim() === "") {
        this.showAlert('No user name provided', 'Please provide your email / phone as your username');
        return;
      }
      if (this.form.password.trim() === "") {
        this.showAlert('No password provided', 'Please provide your password');
        return;
      }

      this.submitDisabled = true;
      this.login({
        user_email: this.form.username,
        user_name: this.form.username,
        password: this.form.password,
      })
        .then(async (res) => {
          if (res.data.status === 0) {
            console.log('login ', res);
            let loggedInUser = res.data.result;
            
            await this.$store.dispatch("adduser", loggedInUser);
            await putUser("currentUser", loggedInUser);

            // fetch language preferences
            getPreferredLang().then((res) => {
              console.log('lang pref', res);

              if (res.data.status === 0) {
                if (res.data.result.preferred_language.language_preference !== null) {
                  loadLang(this.$store, res.data.result.preferred_language.language_preference.value);
                }
              }
            }).catch((err) => {
              console.log('lang preference fetch err', err);
            });

            // register for push notification, and navigate to next page
            this.registerPushNotifications(res.data.result.key_verified);
          } else {
            console.log("login - err", res);
            this.submitDisabled = false;
            await this.showAlert("Sign in Failed", "Failed to sign in, error is: " + res.data.error.error_message);
          }
        })
        .catch(async (err) => {
          // console.log('loging - err', err);
          this.submitDisabled = false;
          await this.showAlert("Sign in Failed", "Failed to sign in, error is: " + err.toString());
          window.location.reload();
        });
    },
    navigateNextPage(isVerified) {
      // if the user is verified direct to /posts else to /verify
      if (isVerified) {
        this.submitDisabled = false;
        localStorage.setItem("defaultRoute", "/posts");
        this.router.replace("/posts");
      } else {
        this.submitDisabled = false;
        this.router.replace("/verify");
      }
    },
    async registerPushNotifications(isVerified) {
      if (isAndroid() || isIOS()) {
        try {
          await PushNotifications.requestPermission();
          await LocalNotifications.requestPermission();
          await PushNotifications.register();
          PushNotifications.addListener("registration", (token) => {
            // save this device token to user profile
            registerDevice({
              device_id: token.value,
              device_type: isAndroid() ? "android" : isIOS() ? "ios" : "na",
            })
              .then((res) => {
                console.log("registerDevice", res);
                this.navigateNextPage(isVerified);
              })
              .catch((err) => {
                console.log("registerDevice - err", err);
                alert(err);
                this.navigateNextPage(isVerified);
              });
          });
          PushNotifications.addListener("registrationError", (err) => {
            console.log("notification registration failed", err);
            // alert(err.toString());
            this.navigateNextPage(isVerified);
          });
        } catch (ignored) {
          console.log("Push notification reg error", ignored);
          // alert(ignored);
          this.navigateNextPage(isVerified);
        }
      } else {
        this.navigateNextPage(isVerified);
      }
    },
  },
};
</script>