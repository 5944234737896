<template>
  <ion-button
    size="small"
    fill="clear"
    slot="end"
    shape="round"
    @click="openMoreOptionsPopover"
    v-if="post.is_active"
    class="post_dropdown_button"
  >
    <ion-icon
      size="small"
      color="dark"
      slot="icon-only"
      :icon="ellipsisHorizontal"
    ></ion-icon>
  </ion-button>
</template>

<script>
import { ellipsisHorizontal } from "ionicons/icons";

import { IonButton, IonIcon, popoverController } from "@ionic/vue";

import { defineComponent } from "vue";

import MoreActionOptions from "./MoreActionOptions.vue";
import { getPostUserSettings } from "../../services/apiCall.js";
import { getStrings } from "../../services/lang";

export default defineComponent({
  name: "MoreActionsButton",
  props: ["post"],
  components: {
    IonButton,
    IonIcon,
  },
  setup() {
    return {
      getStrings,
    };
  },
  data() {
    return {
      ellipsisHorizontal,
      popoverController,
      getPostUserSettings,
      postSharedCount: 0,
    };
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    async openMoreOptionsPopover(ev) {
      const popover = await popoverController.create({
        component: MoreActionOptions,
        event: ev,
        translucent: true,
        cssClass: "userlist_popover",
      });

      var options = [];
      var mutePostId = "MUTE_POST";
      var mutePostName = this.getStrings(this.strings, "NotificationOff");
      if (this.post.mute_notifications) {
        mutePostId = "UNMUTE_POST";
        mutePostName = this.getStrings(this.strings, "NotificationOn");
      }

      var hidePostId = "HIDE_POST";
      var hidePostName = this.getStrings(this.strings, "HidePost");
      if (this.post.hide_post) {
        hidePostId = "UNHIDE_POST";
        hidePostName = this.getStrings(this.strings, "UnHidePost");
      }

      var blockUser = "BLOCK_USER";
      var blockUserName = this.getStrings(this.strings, "Block User");

      if (this.post.post_by === this.user.user_id) {
        options = [
          { id: "EDIT", name: this.getStrings(this.strings, "Edit") },
          { id: "DELETE", name: this.getStrings(this.strings, "Delete") },
        ];

        options.push({ id: mutePostId, name: mutePostName });
        options.push({ id: hidePostId, name: hidePostName });

        if (
          !this.post.previewData.is_sold &&
          this.post.previewData.sale_specifics &&
          this.post.previewData.sale_specifics !== ""
        ) {
          options.push({
            id: "MARK_SOLD",
            name: this.getStrings(this.strings, "MarkSold"),
          });
        }
      } else {
        var followPostId = "FOLLOW_POST";
        var followPostName = this.getStrings(this.strings, "Follow");
        if (this.post.following_user) {
          followPostId = "UNFOLLOW_POST";
          followPostName = this.getStrings(this.strings, "UnFollow");
        }

        options.push({ id: followPostId, name: followPostName });
        options.push({ id: mutePostId, name: mutePostName });
        options.push({ id: hidePostId, name: hidePostName });
        options.push({ id: blockUser, name: blockUserName });
        options.push({ id: "REPORT", name: this.getStrings(this.strings, "Report") });
      }

      popover.componentProps = {
        moreActions: options,
        post: this.post,
        parent: popover,
      };
      return popover.present();
    },
  },
});
</script>
