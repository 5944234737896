<template>
  <span v-if="langList.length <= maxLanguagesToShow">
    <ion-row>
      <ion-col
        style="cursor: pointer;"
        v-for="lang in langList"
        :key="lang.id"
        @click="changeLanguage(lang.id)"
      >
        <ion-text v-if="lang.id !== currentLang">{{ lang.value }}</ion-text>
        <ion-text v-if="lang.id === currentLang"><b>{{ lang.value }}</b></ion-text>
      </ion-col>
    </ion-row>
  </span>
  <ion-button
    v-if="langList.length > maxLanguagesToShow"
    fill="clear"
    size="small"
    @click="openLanguageListPopover"
  >
    <ion-icon slot="start" :icon="icon.languageOutline"></ion-icon> {{ currentLangName }}
  </ion-button>
</template>

<script>
import { languageOutline } from "ionicons/icons";
import { IonButton, IonIcon, IonRow, IonCol, IonText, popoverController } from "@ionic/vue";
import { defineComponent } from "vue";

import LanguageList from "./LanguageList.vue";

import { loadLang } from "../../services/lang";

export default defineComponent({
  name: "LanguageSelection",
  props: ["changeLangCallback", "callbackRef"],
  components: { IonButton, IonIcon, IonRow, IonCol, IonText },
  data() {
    return {
      maxLanguagesToShow: 3,
      icon: { languageOutline },
      popoverController,
    };
  },
  setup() {
    return {
      loadLang
    };
  },
  computed: {
    langList() {
      return this.$store.getters.langList.map((x) => {
        return {
          id: x.code,
          value: x.language,
        };
      });
    },
    currentLang() {
      return this.$store.getters.currentLang;
    },
    currentLangName() {
      var langName = '';
      var curLang = this.$store.getters.langList.filter((y) => y.code === this.$store.getters.currentLang);
      if (curLang.length > 0) {
        langName = curLang[0].language;
      }
      return langName;
    },

  },
  methods: {
    async openLanguageListPopover(ev) {
      const popover = await popoverController.create({
        component: LanguageList,
        event: ev,
        translucent: true,
      });
      popover.componentProps = {
        langList: this.langList,
        changeLangCallback: this.changeLanguage,
        parent: popover,
      };
      return popover.present();
    },
    async changeLanguage(langCode) {
      console.log("changeLanguage", langCode);

      await loadLang(this.$store, langCode);

      if (this.changeLangCallback) this.changeLangCallback(this.callbackRef, langCode);
    },
  },
});
</script>
