<template>
  <base-layout
    page-title="Settings"
    page-default-back-link="/posts"
    showBackButton="true"
  >
    <template v-slot:actions-end>
      <language-selection></language-selection>
      <!--
      <ion-button @click="this.$router.replace('/posts');">
        <ion-icon slot="icon-only" :icon="icons.homeOutline"></ion-icon>
      </ion-button>
      -->
    </template>

    <ion-row class="ion-no-padding ion-no-margin">
      <ion-col :size="getColSize()" :offset="getOffsetSize()">
        <ion-item lines="none" no-lines class="ion-no-padding ion-no-margin">
          <ion-button
            color="primary"
            router-link="/user/password/change"
            shape="round"
            size="small"
            slot="end"
            >Change Password
          </ion-button>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col :size="getColSize()" :offset="getOffsetSize()">
        <ion-item lines="none" no-lines no-padding no-margin>
          <ion-searchbar
            type="text"
            color="light"
            placeholder="Search Settings"
            v-model="searchText"
            autofocus="true"
            @keyup.enter="searchSettings"
            clearInput
          >
          </ion-searchbar>
        </ion-item>
        <ion-list class="ion-padding-horizontal ion-no-margin">
          <span
            v-for="section in currentProfileStructure"
            v-bind:key="section.section_name"
          >
            <ion-item
              lines="none"
              no-lines
              no-padding
              no-margin
              @click="expandSection(section)"
            >
              <ion-label v-if="!section.searchFound || searchText == ''">{{
                section.display_text
              }}</ion-label>
              <ion-label v-if="section.searchFound && searchText != ''"
                ><b>{{ section.display_text }}</b></ion-label
              >
              <ion-text size="small"
                ><small>{{ section.section_description }}</small></ion-text
              >
              <ion-button slot="end" fill="clear">
                <ion-icon
                  slot="icon-only"
                  :icon="icons.caretForwardOutline"
                  v-if="!section.is_expanded"
                ></ion-icon>
                <ion-icon
                  slot="icon-only"
                  :icon="icons.caretDownOutline"
                  v-if="section.is_expanded"
                ></ion-icon>
              </ion-button>
            </ion-item>
            <profile-photo-section
              v-if="
                section.is_expanded &&
                section.section_name === 'PROFILE_PHOTO' &&
                section.section_type === 'custom'
              "
            ></profile-photo-section>
            <friends-and-family-section
              v-if="
                section.is_expanded &&
                section.section_name === 'FRIENDS' &&
                section.section_type === 'custom'
              "
            >
            </friends-and-family-section>
            <invite-section
              v-if="
                section.is_expanded &&
                section.section_name === 'FRIENDS' &&
                section.section_type === 'custom'
              "
            >
            </invite-section>
            <notification-settings
              v-if="
                section.is_expanded &&
                section.section_name === 'NOTIFICATION_SETTINGS' &&
                section.section_type === 'custom'
              "
            >
            </notification-settings>
            <privacy-setting-section
              v-if="section.is_expanded && section.section_name === 'PRIVACY_SETTINGS'"
              :field="section.fields"
              :section="section"
            >
            </privacy-setting-section>
            <generic-profile-section
              v-if="section.is_expanded && section.section_type !== 'custom' && section.section_name !== 'PRIVACY_SETTINGS'"
              :fields="section.fields"
              :section="section"
            >
            </generic-profile-section>

            <hr style="border-top: 1px dotted grey" />
          </span>
        </ion-list>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col :size="getColSize()" :offset="getOffsetSize()">
        <ion-item no-lines lines="none">
          <small class="ion-padding-horizontal ion-no-margin"
            ><sub
              >App Version: &nbsp;v{{ appVersion() }}
            </sub></small
          >
          <br /><br />
        </ion-item>
      </ion-col>
    </ion-row>
  </base-layout>
</template>

<script>
import {
  IonButton,
  IonIcon,
  IonRow,
  IonCol,
  IonList,
  IonLabel,
  IonItem,
  IonText,
  IonSearchbar,
  alertController,
} from "@ionic/vue";

import {
  caretForwardOutline,
  caretDownOutline,
  homeOutline,
  arrowBackOutline,
} from "ionicons/icons";

import { useRouter } from "vue-router";

import LanguageSelection from "../components/settings/LanguageSelection.vue";
import ProfilePhotoSection from "../components/settings/ProfilePhotoSection.vue";
import FriendsAndFamilySection from "../components/settings/FriendsAndFamilySection.vue";
import NotificationSettings from "../components/settings/NotificationSettings.vue";
import InviteSection from "../components/settings/InviteSection.vue";
import GenericProfileSection from "../components/settings/GenericProfileSection.vue";
import PrivacySettingSection from "../components/settings/PrivacySettingSection.vue";

import {  profileStructure,  fetchDetailedUserProfile } from "../services/apiCall.js";

import {
  getColSize,
  getOffsetSize,
  appVersion,
  isMobileScreen,
  logOffApp,
} from "../services/utils.js";

import { loadLang } from "../services/lang";
import { readFromDB } from "../services/db";

export default {
  components: {
    IonButton,
    IonIcon,
    IonRow,
    IonCol,
    IonList,
    IonLabel,
    IonItem,
    IonText,
    IonSearchbar,
    LanguageSelection,
    ProfilePhotoSection,
    FriendsAndFamilySection,
    InviteSection,
    PrivacySettingSection,
    GenericProfileSection,
    NotificationSettings,
  },
  ionViewWillEnter() {
    this.readDBAndInit();
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  setup() {
    const router = useRouter();
    return {
      getColSize,
      getOffsetSize,
      router,
      appVersion,
      isMobileScreen,
      loadLang,
    };
  },
  data() {
    return {
      searchText: "",
      icons: {
        homeOutline,
        caretForwardOutline,
        caretDownOutline,
        arrowBackOutline,
      },
      currentProfileStructure: [],
    };
  },
  methods: {
    async showAlert(title, message) {
      (
        await alertController.create({
          header: title,
          message: message,
          buttons: ["OK"],
        })
      ).present();
    },
    readDBAndInit(callbackObject = this) {
      // this.$store.dispatch("setposts", []);

      try {
        readFromDB(this.$store, true)()
          .then(async () => {
            if (
              typeof callbackObject.user === "undefined" ||
              typeof callbackObject.user.user_id === "undefined"
            ) {
              this.logOff(callbackObject);
            } else {
              this.init();
            }
          })
          .catch((err) => {
            console.log("indexdb not available", err);
            this.init();
          });
      } catch (err) {
        console.log("indexdb not available", err);
        this.init();
      }
    },
    init() {
      loadLang(this.$store, this.$store.getters.currentLang);

      profileStructure()
        .then((res) => {
          if (res.data.status === 0) {
            this.currentProfileStructure = res.data.result.profile_structure;
          }
        })
        .catch((err) => {
          console.log("profileStructure - err", err);
        });
    },
    searchSettings() {
      try {
        this.currentProfileStructure.map((x) => {
          x.searchFound = false;
        });

        var searchFor = this.searchText.toLowerCase();

        for (var sidx = 0; sidx < this.currentProfileStructure.length; sidx++) {
          var section = this.currentProfileStructure[sidx];

          if (
            section.display_text.toLowerCase().indexOf(searchFor) >= 0 ||
            section.section_description.toLowerCase().indexOf(searchFor) >= 0
          ) {
            section.searchFound = true;
            continue;
          }

          if (section.section_type !== "custom") {
            var fields = section.fields;

            for (var fidx = 0; fidx < fields.length; fidx++) {
              var field = fields[fidx];

              if (
                field.display_text.toLowerCase().indexOf(searchFor) >= 0 ||
                field.field_description.toLowerCase().indexOf(searchFor) >= 0
              ) {
                section.searchFound = true;
                break;
              }
            }
          }
        }
      } catch (err) {
        console.log("searchSetting - err", err);
      }
    },
    expandSection(section) {
      section.is_expanded = !section.is_expanded;

      if (section.is_expanded && section.section_type !== "custom") {
        try {
          fetchDetailedUserProfile({ section_name: section.section_name })
            .then((res) => {
              if (res.data.status === 0) {
                // section.fields = res.data.result.section_fields;
                var fieldData = res.data.result.section_fields;
                for (var fld in fieldData) {
                  var field = fieldData[fld];
                  var currentField = section.fields.filter(
                    (x) => x.field_name == field.field_name
                  );
                  if (currentField.length === 1) {
                    currentField[0].value = field.value;
                    if (
                      field.type === "json" &&
                      typeof field.value === "string"
                    ) {
                      currentField[0].value = JSON.parse(field.value);
                    }
                    currentField[0].visibility = field.visibility;
                  }
                }
              }
            })
            .catch((err) => {
              console.log("fetchDetailedUserProfile - err", err);
            });
        } catch (err) {
          console.log("expandSection - err", err);
        }
      }
    },
    async logOff(callbackObject = this) {
      await logOffApp();

      callbackObject.$store.dispatch("adduser", {});

      callbackObject.router.replace("/login");
    },
    async navigateMenu() {
      await this.router.push("/menu");
    },
  },
};
</script>