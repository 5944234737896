<template>
  <ion-content>
    <ion-list lines="none">
      <ion-item
        v-for="action in moreActions"
        :key="action"
        :value="action"
        class="ion-padding-horizontal popover_item_style"
      >
        <ion-content>
          <ion-item class="popover_item_style">
            <ion-icon
              size="small"
              color="dark"
              :icon="optionIcons[action.id]"
            ></ion-icon>
            <ion-button
              color="dark"
              fill="clear"
              style="text-transform: none"
              @click="handleAction(post, action.id)"
              :disabled="action === 'Report'"
            >
              {{ action.name }}
            </ion-button>
          </ion-item>
        </ion-content>
      </ion-item>
    </ion-list>
  </ion-content>
</template>

<script>
import {
  IonContent,
  IonItem,
  IonList,
  IonButton,
  IonIcon,
  alertController,
  toastController,
} from "@ionic/vue";

import {
  notificationsOutline,
  notificationsOffOutline,
  createOutline,
  trashOutline,
  eyeOffOutline,
  eyeOutline,
  flagOutline,
  personAddOutline,
  personRemoveOutline,
  walletOutline,
  closeCircleOutline,
} from "ionicons/icons";

import { defineComponent } from "vue";
import {
  fetchPostContent,
  friendRequest,
  unFriend,
  followUser,
  deletePost,
  addPostUserSettings,
  deletePostUserSettings,
  markPostSold,
  blockUser,
} from "../../services/apiCall.js";

import { getStrings } from "../../services/lang";

export default defineComponent({
  name: "UserList",
  props: ["moreActions", "post", "parent"],
  components: {
    IonContent,
    IonItem,
    IonList,
    IonButton,
    IonIcon,
  },
  setup() {
    return {
      friendRequest,
      unFriend,
      followUser,
      deletePost,
      fetchPostContent,
      addPostUserSettings,
      deletePostUserSettings,
      getStrings,
    };
  },
  data() {
    return {
      notificationsOutline,
      notificationsOffOutline,
      createOutline,
      trashOutline,
      eyeOffOutline,
      eyeOutline,
      flagOutline,
      personAddOutline,
      personRemoveOutline,
      closeCircleOutline,
      createPostList: this.postTopicList(this.post),
      optionIcons: {
        MUTE_POST: notificationsOffOutline,
        UNMUTE_POST: notificationsOutline,
        HIDE_POST: eyeOffOutline,
        UNHIDE_POST: eyeOutline,
        EDIT: createOutline,
        DELETE: trashOutline,
        FOLLOW_POST: personAddOutline,
        UNFOLLOW_POST: personRemoveOutline,
        REPORT: flagOutline,
        MARK_SOLD: walletOutline,
        BLOCK_USER: closeCircleOutline,
      },
    };
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    handleAction(post, actionId) {
      if (actionId === "EDIT") {
        this.editThePost(post);
      } else if (actionId === "FRIEND") {
        this.friendRequestToUser(post);
      } else if (actionId === "UNFRIEND") {
        this.unFriendThePerson(post);
      } else if (actionId === "FOLLOW" || actionId === "UNFOLLOW") {
        this.followThePerson(post);
      } else if (actionId === "DELETE") {
        this.deleteThePost(post);
      } else if (actionId === "MUTE_POST") {
        this.addSetting(post, "mute_notifications");
      } else if (actionId === "UNMUTE_POST") {
        this.deleteSettings(post, "mute_notifications");
      } else if (actionId === "HIDE_POST") {
        this.addSetting(post, "hide_post");
      } else if (actionId === "UNHIDE_POST") {
        this.deleteSettings(post, "hide_post");
      } else if (actionId === "MARK_SOLD") {
        this.markSold(post);
      } else if (actionId === "BLOCK_USER") {
        this.blockPostByUser(post);
      }
    },
    async openToast(msg) {
      const toast = await toastController.create({
        message: msg,
        duration: 1500,
      });
      return toast.present();
    },
    editThePost(post) {
      if (post.edit_mode || post.edit_mode === true) {
        post.edit_mode = false;
      } else {
        fetchPostContent(post.post_id)
          .then(async (res) => {
            if (res.data.status === 0) {
              post.description = res.data.result.post_content;
              post.content_type = res.data.result.content_type;
              post.edit_mode = true;
            } else {
              post.edit_mode = false;
              console.log("post content fetch err - 1", res.data.error);
            }
          })
          .catch((err) => {
            post.edit_mode = false;
            console.log("post content fetch err - 2", err);
            this.parent.dismiss();
          });
      }
      this.parent.dismiss();
    },
    friendRequestToUser(post) {
      friendRequest({ request_to: post.post_by })
        .then((res) => {
          if (res.data.status === 0) {
            this.openToast(this.getStrings(this.strings, "FriendRequestSent"));
          } else {
            this.openToast(
              this.getStrings(this.strings, "FriendRequestAlreadySent")
            );
          }
        })
        .catch((err) => {
          console.log("friend the person - err2", err);
          this.parent.dismiss();
        });
      this.parent.dismiss();
    },
    unFriendThePerson(post) {
      post.is_friend = false;
      unFriend({ unfriend_user_id: post.post_by })
        .then((res) => {
          if (res.data.status === 0) {
            this.openToast(
              this.getStrings(this.strings, "YouHaveUnFriendTheUser")
            );
          } else {
            console.log("un-friend the person - err1");
          }
        })
        .catch((err) => {
          console.log("un-friend the person - err2", err);
          this.parent.dismiss();
        });
      this.parent.dismiss();
    },
    followThePerson(post) {
      post.following_user =
        typeof post.following_user === "undefined"
          ? true
          : !post.following_user;
      followUser({ follow_to: post.post_by })
        .then((res) => {
          if (res.data.status === 0) {
            if (res.data.result.is_following)
              this.openToast(
                this.getStrings(this.strings, "YouAreFollowingNow")
              );
            else
              this.openToast(
                this.getStrings(this.strings, "YouHaveUnFollowedTheUser")
              );
          } else {
            console.log("follow the person - err1");
          }
        })
        .catch((err) => {
          console.log("follow  the person - err2", err);
          this.parent.dismiss();
        });
      this.parent.dismiss();
    },
    deleteThePost(post) {
      this.confirmDelete(post);
      this.parent.dismiss();
    },
    async confirmDelete(post) {
      const anAlert = await alertController.create({
        title: "Confirm Delete Post",
        message:
          '<span style="text-align: justify; text-justify: inter-word;">Deleting a post cannot be undone.<br/><small>For regulatory requirement, your post content may still be archived for sometime as per local laws.</small><br/><br/><b>Do you really want to delete?</b></span>',
        buttons: [
          {
            text: "Cancel",
            role: "cancel",
            handler: () => {
              console.log("Cancel clicked");
            },
          },
          {
            text: "Confirm",
            handler: () => {
              this.deletePost(post)
                .then((res) => {
                  if (res.data.status === 0) {
                    post.is_active = false;
                    post.preview = "You have deleted this Post.";
                    if (post.description) {
                      post.description = "You have deleted this Post.";
                    }
                  } else {
                    console.log("deletePost - err1");
                  }
                })
                .catch((err) => {
                  console.log("deletePost - err2", err);
                });
            }, // end handler
          },
        ],
      });
      await anAlert.present();
    },
    postTopicList(post) {
      var selectedTopics = post.topics;
      var topicList = JSON.parse(JSON.stringify(this.$store.getters.topicList));

      if (typeof selectedTopics !== "undefined") {
        topicList = topicList.map((x) => {
          var isSlectedTopic =
            selectedTopics.filter((y) => {
              return y.topic_name == x.topic_name;
            }).length > 0;
          x.isSelected = isSlectedTopic;
          return x;
        });
      }
      return topicList;
    },
    addSetting(post, settingName) {
      this.addPostUserSettings({
        post_id: post.post_id,
        setting_name: settingName,
      })
        .then(async (res) => {
          if (res.data.status === 0) {
            if (settingName === "mute_notifications") {
              this.openToast(
                this.getStrings(
                  this.strings,
                  "YouWillNotReceivePostNotification"
                )
              );
              post.mute_notifications = true;
            }
            if (settingName === "hide_post") {
              this.openToast(
                this.getStrings(this.strings, "YouWillNotSeePost")
              );
              post.hide_post = true;
            }
          } else {
            console.log("addPostUserSettings err - 1", res.data.error);
          }
        })
        .catch((err) => {
          console.log("addPostUserSettings err - 2", err);
          this.parent.dismiss();
        });
      this.parent.dismiss();
    },
    deleteSettings(post, settingName) {
      this.deletePostUserSettings({
        post_id: post.post_id,
        setting_name: settingName,
      })
        .then(async (res) => {
          if (res.data.status === 0) {
            if (settingName === "mute_notifications") {
              this.openToast(
                this.getStrings(this.strings, "YouWillReceivePostNotifications")
              );
              post.mute_notifications = false;
            }
            if (settingName === "hide_post") {
              this.openToast(this.getStrings(this.strings, "YouWillSeePost"));
              post.hide_post = false;
            }
          } else {
            console.log("deletePostUserSettings err - 1", res.data.error);
          }
        })
        .catch((err) => {
          console.log("deletePostUserSettings err - 2", err);
          this.parent.dismiss();
        });
      this.parent.dismiss();
    },
    markSold(post) {
      markPostSold({ post_id: post.post_id })
        .then((res) => {
          post.previewData.is_sold = res.data.status === 0;
          this.parent.dismiss();
        })
        .catch((err) => {
          console.log("markPostSold - err", err);
          this.parent.dismiss();
        });
    },
    async blockPostByUser(post) {
      const anAlert = await alertController.create({
        title: "Confirm Block User",
        message:
          '<span style="text-align: justify; text-justify: inter-word;">Once you block a user you will be you will not be able to interact with the user.<br/><br/><b>Do you really want to block the user?</b></span>',
        buttons: [
          {
            text: "Cancel",
            role: "cancel",
            handler: () => {
              console.log("Cancel clicked");
            },
          },
          {
            text: "Confirm",
            handler: () => {
              blockUser({ user_id: post.post_by })
                .then((res) => {
                  if (res.data.status === 0) {
                    this.openToast("You have blocked the user");
                  } else {
                    console.log("blockUserRequestClickHandler - err1");
                  }
                })
                .catch((err) => {
                  console.log("blockUserRequestClickHandler - err2", err);
                });
            }
          },
        ],
      });
      await anAlert.present();
      this.parent.dismiss();
    },
  },
});
</script>
