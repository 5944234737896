<template>
  <post-comment-box v-if="post.is_active && showCommentBox" :post="post"></post-comment-box>
  <ion-row v-if="!post.is_active" class="left-margin-10px">
    <ion-col>
      <ion-label size="small" color="medium"
        >(New Comments are disabled on this post.)</ion-label
      >
    </ion-col>
  </ion-row>
  <ion-row no-lines lines="none" class="left-margin-10px ion-no-margin ion-no-padding">
    <ion-col class="ion-no-margin">
      <ion-list class="ion-no-margin ion-no-padding" v-for="comment in comments" v-bind:key="comment.id" no-lines lines="none">
        <single-comment :comment="comment" :post="post"></single-comment>
      </ion-list>
    </ion-col>
  </ion-row>
</template>

<script>
import { IonList, IonLabel, IonRow, IonCol } from "@ionic/vue";
import { chatbubbles } from "ionicons/icons";

import { postComment } from "../../services/apiCall.js";

import PostCommentBox from "./PostCommentBox.vue";
import SingleComment  from "./SingleComment.vue";

import { formatDate } from "../../services/utils";

export default {
  props: ["post", "comments", "showCommentBox"],
  components: {
    IonList,
    IonLabel,
    IonRow,
    IonCol,
    SingleComment,
    PostCommentBox,
  },
  data() {
    return {
      chatbubbles,
      commentDescription: "",
      formatDate,
      disableComment: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    sendComment() {
      this.disableComment = true;

      // console.log("sendComment", this.post.post_id, this.commentDescription);

      postComment(this.post.post_id, this.commentDescription)
        .then(async (res) => {
          // console.log("post comment", res);
          if (res.data.status === 0) {
            const postData = {
              content: this.commentDescription,
              post_id: this.post.post_id,
              comment_id: res.data.result.comment_id,
              comment_by_name: "me",
              reply_to_comment: res.data.result.reply_to_comment,
              createdAt: new Date().toISOString(),
            };

            this.$store.dispatch("addcomment", postData);

            this.commentDescription = "";
            this.disableComment = false;
          } else {
            console.log("post comment err - 1", res.data.error);
          }
        })
        .catch(async (err) => {
          console.log("post comment err - 2", err);
        });
    },
  },
};
</script>