<template>
  <base-layout page-title="Feedback" showBackButton="true">
    <br/>
    <ion-row class="ion-no-padding ion-no-pargin">
      <ion-col
        :size="getColSize()"
        :offset="getOffsetSize()"
        class="ion-no-padding ion-no-pargin"
      >
        <ion-item no-lines lines="none">
          <ion-button
            shape="round"
            fill="outline"
            :disabled="showFeedbackClicked"
            type="submit"
            class="right-margin-15px"
            @click="showUserFeedback"
          >
            Show Feedbacks
          </ion-button>
          <ion-spinner v-if="showFeedbackClicked" name="dots"></ion-spinner>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row
      class="ion-no-padding ion-no-pargin"
      v-for="feedback in userFeedbacks"
      :key="feedback.id"
      :value="feedback.user_name"
    >
      <ion-col
        :size="getColSize()"
        :offset="getOffsetSize()"
        class="ion-no-padding ion-no-pargin"
      >
        <ion-item>
          <ion-label color='medium' class="left-margin-15px right-margin-15px">
            {{ feedback.id }}. <b>{{ feedback.user_name }}</b> <small>{{ formatShortDate(feedback.createdAt) }}</small>
            <p class="ion-text-wrap">{{ feedback.feedback_text }}</p>
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row class="ion-no-padding ion-no-pargin" v-if="feedbackSubmitted">
      <ion-col
        :size="getColSize()"
        :offset="getOffsetSize()"
        class="ion-no-padding ion-no-pargin"
      >
        <ion-item no-lines lines="none">
          <ion-label color="success" style="text-align: center;" class="left-margin-15px right-margin-15px">
            {{ getStrings(strings, 'ThanksForFeedback') }}
            <br/>
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
  </base-layout>
</template>

<script>
import {
  IonButton,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonSpinner,
} from "@ionic/vue";

import { homeOutline, settingsOutline, logOutOutline, pencilOutline } from "ionicons/icons";
import { useRouter } from "vue-router";
import { getColSize, getOffsetSize, logOffApp, formatShortDate } from "../services/utils";
import { readFromDB } from "../services/db";
import { showFeedbacks } from "../services/apiCall";
import { getStrings } from "../services/lang";

export default {
  name: "ViewFeedbackPage",
  ionViewWillEnter() {
    this.init();
  },
  components: {
    IonButton,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonSpinner,
  },
  setup() {
    const router = useRouter();
    return {
      router,
      getColSize,
      getOffsetSize,
      showFeedbacks,
      getStrings,
      formatShortDate,
    };
  },
  data() {
    return {
      homeOutline,
      settingsOutline,
      logOutOutline,
      pencilOutline,
      logOutDisabled: false,
      showFeedbackClicked: false,
      feedbackSubmitted: false,
      form: {
        feedbackDescription: "",
        feedbackRating: "Excellent",
      },
      userFeedbacks: [],
    };
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    init() {
      console.log("USER:", this.user);
      this.readDB();
    },
    readDB(callbackObject = this) {
      try {
        readFromDB(this.$store, true)()
          .then(async () => {
            // read from DB done
            if (
              typeof callbackObject.user === "undefined" ||
              typeof callbackObject.user.user_id === "undefined"
            ) {
              this.logOff(callbackObject);
            }
            console.log("current user:", callbackObject.currentUser);
          })
          .catch((err) => {
            console.log("indexdb not available", err);
          });
      } catch (err) {
        console.log("indexdb not available", err);
      }
    },
    async logOff(callbackObject = this) {
      callbackObject.logOutDisabled = true;

      await logOffApp();

      callbackObject.$store.dispatch("adduser", {});

      callbackObject.router.replace("/login");
    },

    showUserFeedback() {
      console.log('Send feedback', this.form);
      this.showFeedbackClicked = true;
      showFeedbacks()
        .then((res) => {
          console.log("showUserFeedback", res);
          this.showFeedbackClicked = false;
          if (res.data.status === 0) {
            this.userFeedbacks = res.data.result.feedback_list;
          }
        })
        .catch((err) => {
          console.log("showUserFeedback - err", err);
          this.showFeedbackClicked = false;
        });
    }
  },
};
</script>