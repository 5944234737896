/** wrapper for all microservice api calls */

import axios from "axios";

import { getUser } from "./db";
import { getAPIKey } from "./apiCall";

const CONVERSATION_BASE_URL = 'https://conversation-api.vlifesciences.com';
const VIDEO_CONVERSATION_BASE_URL = 'https://video-call-api.vlifesciences.com';

export async function getConversationList() {
    const user = await getUser('currentUser');
    const getURL = CONVERSATION_BASE_URL + '/v1/conversation-list' + "/" + getAPIKey() + "/" + user.tokenized_user + "/" + user.auth.token + "/" + user.auth.session;

    console.log('getConversationList', getURL);

    return (await axios.get(getURL)).data;
}

export async function getConversationThreadList(otherTokenizedUser) {
    const user = await getUser('currentUser');
    const getURL = CONVERSATION_BASE_URL + '/v1/conversation-thread' + "/" + getAPIKey() + "/" + user.tokenized_user + "/" + otherTokenizedUser + "/" + user.auth.token + "/" + user.auth.session;

    console.log('getConversationThreadList', getURL);

    return (await axios.get(getURL)).data;
}

export async function getConversationRoomID(otherTokenizedUser, entityType = "user") {
    const user = await getUser('currentUser');
    const getURL = CONVERSATION_BASE_URL + '/v1/get-chatroom' + "/" + getAPIKey() + "/" + user.tokenized_user + "/" + otherTokenizedUser + "/" + entityType + "/" + user.auth.token + "/" + user.auth.session;

    console.log('getConversationRoomID', getURL);

    return (await axios.get(getURL)).data;
}

export async function getChatRoomID(joinInfo) {
    console.log('getChatRoomID', joinInfo);

    let createRoomURI =
        CONVERSATION_BASE_URL +
        "/v1/get-chatroom/" +
        getAPIKey() +
        "/" +
        joinInfo.tokenized_user +
        "/" +
        joinInfo.tokenized_entity_id +
        "/" +
        joinInfo.entity_type +
        "/" +
        joinInfo.user_auth.auth.token +
        "/" +
        joinInfo.user_auth.auth.session;
    console.log("getChatRoomID", createRoomURI);

    let res = (await axios.get(createRoomURI)).data;
    console.log("getChatRoomID", res);

    if (res.status === 0) {
        return res.result.room_id;
    } else {
        return undefined;
    }
}

export async function getVideoChatRoomID(joinInfo) {
    console.log('getVideoChatRoomID', joinInfo);
    
    let createRoomURI =
        VIDEO_CONVERSATION_BASE_URL +
        "/v1/get-videoroom/" +
        getAPIKey() +
        "/" +
        joinInfo.tokenized_user +
        "/" +
        joinInfo.tokenized_entity_id +
        "/" +
        joinInfo.entity_type +
        "/" +
        joinInfo.user_auth.auth.token +
        "/" +
        joinInfo.user_auth.auth.session;
    console.log("getVideoChatRoomID", createRoomURI);

    let res = (await axios.get(createRoomURI)).data;
    console.log("getVideoChatRoomID", res);

    if (res.status === 0) {
        return res.result.room_id;
    } else {
        return undefined;
    }
}

export async function getDeliveryStatus(options) {
    console.log('getDeliveryStatus', options);

    var theHeaders = {
        api_key: getAPIKey(),
        tokenized_user: options.tokenized_user, 
        auth_token: options.auth_token,
    };
    return axios.post(CONVERSATION_BASE_URL + '/v1/get-delivery-status', options, { headers: theHeaders });
}
