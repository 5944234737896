<template>
  <ion-button
    fill="clear"
    slat="end"
    color="primary"
    class="button_nopadding"
    size="small"
    style="text-transform: none"
    @click="openUserListPopover"
    v-if="shareCount"
  >
    {{ shareCount }} {{ getStrings(strings, "Share") }} {{ shareCount === 1 ? "" : "s" }}
  </ion-button>
</template>

<script>
import { arrowRedoOutline, arrowRedoSharp } from "ionicons/icons";
import { IonButton, popoverController } from "@ionic/vue";
import { defineComponent } from "vue";

import UserList from "./UserList.vue";
import { sharedBy } from "../../services/apiCall.js";
import { getStrings } from "../../services/lang";

export default defineComponent({
  name: "SharedByButton",
  props: ["postId", "shareCount"],
  components: {
    IonButton,
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
  },  
  setup() {
    return {
      getStrings,
    };
  },
  data() {
    return {
      arrowRedoOutline,
      arrowRedoSharp,
      popoverController,
      sharedByUsers: [],
      postSharedCount: 0,
    };
  },
  methods: {
    async getSharedByUsers() {
      await sharedBy({ post_id: this.postId })
        .then(async (res) => {
          if (res.data.status == 0) {
            this.sharedByUsers = res.data.result.share_by_list;
            this.postSharedCount = this.sharedByUsers.length;
          }
        })
        .catch(async (err) => {
          console.log("share by api - err", err);
        });
    },
    async openUserListPopover(ev) {
      const popover = await popoverController.create({
        component: UserList,
        event: ev,
        translucent: true,
        cssClass: "userlist_popover",
      });

      await this.getSharedByUsers();

      popover.componentProps = {
        users: this.sharedByUsers,
        parent: popover,
      };
      return popover.present();
    },
  },
});
</script>
