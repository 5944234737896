import { createStore } from 'vuex';
import { defaultMaxPosts } from '../services/utils'; "../service/db";
import { putPost, putStrings } from "../services/db";
import { setMessageLocalList, getMessageLocalList, setConversationLocalList } from "../services/conversationdb";

const store = createStore({
  state() {
    return {
      posts: [],
      friends: [],
      followers: [],
      following: [],
      friendRequestList: [],
      user: {},
      userProfile: {},
      userEncryptionKeys: {},
      strings: {},
      topicList: [],
      postDrafts: [],
      notifications: [],
      currentProfilePhoto: {},
      currentGroup: {},
      currentGroupMember: {},
      langList: [],
      chatConnections: [],
      chatMessages: {},
      currentLang: "default",
      showPostsFrom: "Public",
      notificationCounts: {},
      conversationList: [],
      currentConversationThreadList: [],
      currentConversationSocket: undefined,
    };
  },
  mutations: {
    addpost(state, postData) {
      const newpost = {
        id: postData.id,
        post_id: postData.post_id,
        visibility: postData.visibility,
        description: postData.description,
        user_fullname: postData.user_fullname ? postData.user_fullname : "me",
        user_profile_photo: postData.user_profile_photo,
        createdAt: postData.createdAt ? postData.createdAt : new Date().toISOString(),
        showExpanded: postData.showExpanded,
        reaction_count: postData.reaction_count,
        my_reactions: postData.my_reactions,
        share_count: postData.share_count,
        following_user: postData.following_user,
        flag_count: postData.flag_count,
        my_flags: postData.my_flags ? postData.my_flags : [],
        comments: postData.comments ? postData.comments : [],
        comment_count: postData.comment_count ? postData.comment_count : 0,
        shared_by: postData.shared_by ? postData.shared_by : [],
        topics: postData.topics,
        post_by: postData.post_by,
        is_favourite: postData.is_favourite,
        is_edited: false,
        is_active: typeof (postData.is_active) !== 'undefined' ? postData.is_active : true,
        preview: postData.preview,
        preview_type: postData.preview_type,
        tokenized_user: postData.tokenized_user,
        previewData: postData.previewData,
        group_id: postData.group_id,
        is_group_member: postData.is_group_member,
        group_member_type: postData.group_member_type,
        profile_photo_available: postData.profile_photo_available,
        post_origin_tag: postData.post_origin_tag, /** can be Friend, Family, Public, Group or User */
      };

      // check if this post already exists, if yes, update, else add
      var prevPostIdx = state.posts.findIndex((x) => x.post_id === newpost.post_id);

      // console.log('prev-post', prevPostIdx);

      if (prevPostIdx >= 0) {
        newpost.is_edited = true;
        state.posts[prevPostIdx] = newpost;
      } else {
        // add new post
        state.posts.unshift(newpost);
      }

      // console.log("add post", postData);
      try {
        putPost(newpost.id, newpost);
      } catch (err) {
        console.log('indexDB unavailable', err);
      }
    },

    setshowpostsfrom(state, postData) {
      state.showPostsFrom = postData;
    },

    addpostdraft(state, postData) {
      state.postDrafts.unshift(postData);
    },

    setnotificationcounts(state, postsData) {
      state.notificationCounts = postsData;
    },

    setcurrentconversationsocket(state, socketConnection) {
      state.currentConversationSocket = socketConnection;
    },

    deletepostdraft(state, postData) {
      let idx = state.postDrafts.findIndex((x) => x.id === postData.id);
      if (idx >= 0) {
        state.postDrafts.splice(idx, 1);
      }
    },

    setcurrentlang(state, postData) {
      state.currentLang = postData;
    },

    async setconversationlist(state, postData) {
      state.conversationList = postData;

      await setConversationLocalList(postData);
    },

    async setconversationthreadlist(state, postData) {
      state.currentConversationThreadList = postData;
      console.log('Current:', state.currentConversationThreadList);
      await setMessageLocalList(postData);
      if (postData.length > 0) {
        state.currentConversationThreadList = await getMessageLocalList(postData[0].user_id);
      }
    },

    async loadconversationthreadlist(state, userId) {
      console.log('Before Current:', state.currentConversationThreadList);
      if (typeof(userId) !== 'undefined') {
        state.currentConversationThreadList = await getMessageLocalList(userId);
      }
    },

    updateconversation(state, postData) {
      console.log('Index currentConversationThreadList:', state.currentConversationThreadList);
      if (postData.message_id) {
        let messageIdx = state.currentConversationThreadList.findIndex((x) => x.message_id === postData.message_id);
        if (messageIdx < 0) {
          state.currentConversationThreadList.push(postData);
        } else {
          state.currentConversationThreadList[messageIdx] = postData;
        }
      } else {
        state.currentConversationThreadList.push(postData);
        // TODO: also sync with db
      }
    },

    updatemessageid(state, postData) {
      console.log('Index updatemessageid:', state.currentConversationThreadList);
      let oldID = postData.old_id;
      let newID = postData.new_id;
      let messageIdx = state.currentConversationThreadList.findIndex((x) => x.message_id === oldID);
      if (messageIdx >= 0) {
        state.currentConversationThreadList[messageIdx].message_id = newID;
      }
    },

    updatemessagestatus(state, postData) {
      let messageIdx = state.currentConversationThreadList.findIndex((x) => x.message_id === postData.message_id);
      if (messageIdx >= 0) {
        // change delivery status
        console.log('updatemessagestatus', postData);
        var newStatusKeys = Object.keys(postData.delivery_status);

        if ('delivery_status' in state.currentConversationThreadList[messageIdx]) {
          state.currentConversationThreadList[messageIdx].delivery_status[newStatusKeys[0]] = postData.delivery_status[newStatusKeys[0]];
        } else {
          state.currentConversationThreadList[messageIdx].delivery_status = postData.delivery_status;
        }
      }
    },

    updateconversationliststatus(state, postData) {
      console.log('1. updateconversationliststatus:', postData, state.conversationList.length);
      let updateKeys = Object.keys(postData.updateData);
      for(let idx=0; idx<state.conversationList.length; idx++) {
        let theConversation = state.conversationList[idx];
        console.log('2. updateconversationliststatus:', theConversation);
        if (theConversation.user_id === postData.tokenized_user) {
          for (let jdx=0; jdx < updateKeys.length; jdx++) {
            console.log('3. updateconversationliststatus:', updateKeys[jdx]);
            if (updateKeys[jdx] === 'delivery_status' && updateKeys[jdx] in theConversation) {
                console.log('4. updateconversationliststatus:', updateKeys[jdx]);
                state.conversationList[idx].delivery_status[updateKeys[jdx]] = postData.updateData[updateKeys[jdx]];
            } else {
                console.log('5. updateconversationliststatus:', updateKeys[jdx]);
                state.conversationList[idx][updateKeys[jdx]] = postData.updateData[updateKeys[jdx]];
                console.log('5. updateconversationliststatus:', theConversation);
            }
          }
          break;
        } 
      }
    },

    appendconversation(state, postData) {
      let messageIdx = state.currentConversationThreadList.findIndex((x) => x.message_id === postData.message_id);
      if (messageIdx >= 0) return;
      state.currentConversationThreadList.push(postData);
    },

    adduser(state, postData) {
      state.user = postData;

      // try {
      //   putUser("currentUser", postData);
      // } catch (err) {
      //   console.log('indexDB unavailable', err);
      // }
    },

    addfriend(state, postData) {
      state.friends.unshift(postData);

      // console.log("add user", postData);
      // try {
      //   putFriend("firendList", state.friends);
      // } catch (err) {
      //   console.log('indexDB unavailable', err);
      // }
    },

    setposts(state, postData) {
      state.posts = postData;
    },
    updateposts(state, postData) {
      if (postData.length === 0) return;

      let newPosts = 0;
      for (let idx = postData.length - 1; idx >= 0; idx--) {
        let alreadyPresent = state.posts.findIndex((x) => x.post_id === postData[idx].post_id && x.post_origin_tag === postData[idx].post_origin_tag) >= 0;
        if (!alreadyPresent) {
          state.posts.unshift(postData[idx]);
          newPosts++;
        } else {
          state.posts[alreadyPresent] = postData[idx];
        }
      }

      // purge posts that are more than limit
      if (newPosts > 0) {
        let extraPosts = state.posts.length - defaultMaxPosts();
        console.log('updateposts', 'new', newPosts, 'extra', extraPosts)
        if (extraPosts.length > 0) {
          for (let idx = 0; idx < extraPosts.length; idx++) {
            state.posts.pop();
          }
        }
      }
    },

    setpostdrafts(state, postData) {
      state.postDrafts = postData;
    },

    appendposts(state, postData) {
      for (var idx = 0; idx < postData.length; idx++) {
        var alreadyPresent = state.posts.findIndex((x) => x.post_id === postData[idx].post_id && x.post_origin_tag === postData[idx].post_origin_tag) >= 0;
        if (!alreadyPresent) {
          state.posts.push(postData[idx]);
        }
      }
    },

    addnotification(state, notify) {
      var alreadyPresent = state.notifications.filter((n) => n.id === notify.id);
      if (alreadyPresent.length === 0) {
        state.notifications.push(notify);
      }
    },

    setfriends(state, postData) {
      // console.log('settings - setfriends', postData);
      state.friends = postData;
    },

    setfriendrequest(state, postData) {
      // console.log('settings - setfriendrequest', postData);
      state.friendRequestList = postData;
    },

    setfollowers(state, postData) {
      // console.log('settings - setfollowers', postData);
      state.followers = postData;
    },

    setfollowings(state, postData) {
      // console.log('settings - setfollowing', postData);
      state.following = postData;
    },

    setuserprofile(state, postData) {
      // console.log('settings - setuserprofile', postData);
      state.userProfile = postData;
    },

    setcurrentprofilephoto(state, postData) {
      // console.log('settings - setcurrentprofilephoto', postData);
      state.currentProfilePhoto = postData;
    },

    settopiclist(state, postData) {
      state.topicList = postData;
    },

    setcurrentgroup(state, postData) {
      state.currentGroup = postData;
    },

    setcurrentgroupmember(state, postData) {
      state.currentGroupMember = postData;
    },

    setlanglist(state, postData) {
      state.langList = postData;
    },

    addcomment(state, postData) {
      var thePost = state.posts.filter((x) => x.post_id === postData.post_id);
      if (thePost.length > 0) {
        if (typeof (thePost[0].comments) === 'undefined') {
          thePost[0].comments = [];
        }
        thePost[0].comments.unshift(postData);
      }
    },

    addstrings(state, postData) {
      state.strings = postData;
      try {
        putStrings(postData);
      } catch (err) {
        console.log('indexDB unavailable', err);
      }
      // console.log('addstrings', state.strings);
    },

    addChatConnection(state, chatUser) {
      var usrIdx = state.chatConnections.findIndex((x) => x.tokenizedUser === chatUser.tokenizedUser);

      if (usrIdx >= 0) {
        state.chatConnections[usrIdx] = chatUser;
      } else {
        // add new connection
        state.chatConnections.unshift(chatUser);
      }
    },

    addChatMessage(state, obj) {
      const tokenized_id = obj["token_id"];
      const chat_obj = obj["chat"];
      var msg_box = state.chatMessages[tokenized_id];

      if (msg_box) {
        const chat_id = msg_box.findIndex((x) => chat_obj.id === x.id)
        if (chat_id >= 0) {
          state.chatMessages[tokenized_id][chat_id] = chat_obj;
        }
        else {
          state.chatMessages[tokenized_id].unshift(chat_obj);
          state.chatMessages[tokenized_id].sort(function (a, b) { return a.id - b.id });
        }
      } else {
        state.chatMessages[tokenized_id] = [];
        state.chatMessages[tokenized_id].push(chat_obj);
        console.log("New Token NODE - chat in Chache.... for [" + tokenized_id + "]");
      }
    },

    setUserEncryptionKeys(state, keyObj) {
      state.userEncryptionKeys = keyObj;
    }

  },
  actions: {
    addstrings(context, postData) {
      context.commit('addstrings', postData);
    },

    setcurrentlang(context, postData) {
      context.commit('setcurrentlang', postData);
    },

    addpost(context, postData) {
      context.commit('addpost', postData);
    },

    setshowpostsfrom(context, postData) {
      context.commit('setshowpostsfrom', postData);
    },

    addpostdraft(context, postData) {
      context.commit('addpostdraft', postData);
    },

    deletepostdraft(context, postData) {
      context.commit('deletepostdraft', postData);
    },

    adduser(context, postData) {
      context.commit('adduser', postData);
    },

    addnotification(context, postData) {
      context.commit('addnotification', postData);
    },

    setnotificationcounts(context, postsData) {
      context.commit('setnotificationcounts', postsData);
    },

    addfriend(context, postData) {
      context.commit('addfriend', postData);
    },

    setposts(context, postData) {
      context.commit('setposts', postData);
    },
    updateposts(context, postData) {
      context.commit('updateposts', postData);
    },

    setpostdrafts(context, postData) {
      context.commit('setpostdrafts', postData);
    },

    setcurrentconversationsocket(context, socketConnection) {
      context.commit('setcurrentconversationsocket', socketConnection);
    },

    appendposts(context, postData) {
      context.commit('appendposts', postData);
    },

    setfriends(context, postData) {
      context.commit('setfriends', postData);
    },

    setfriendrequest(context, postData) {
      context.commit('setfriendrequest', postData);
    },

    setfollowers(context, postData) {
      context.commit('setfollowers', postData);
    },

    setfollowings(context, postData) {
      context.commit('setfollowings', postData);
    },

    setconversationlist(context, postData) {
      context.commit('setconversationlist', postData);
    },

    setconversationthreadlist(context, postData) {
      console.log('Set con', postData);
      context.commit('setconversationthreadlist', postData);
    },

    loadconversationthreadlist(context, userId) {
      console.log('Load con', userId);
      context.commit('loadconversationthreadlist', userId);
    },

    updateconversation(context, postData) {
      context.commit('updateconversation', postData);
    },

    updatemessageid(context, postData) {
      context.commit('updatemessageid', postData);
    },

    updatemessagestatus(context, postData) {
      context.commit('updatemessagestatus', postData);
    },

    updateconversationliststatus(context, postData) {
      context.commit('updateconversationliststatus', postData);
    },

    appendconversation(context, postData) {
      context.commit('appendconversation', postData);
    },

    setlanglist(context, postData) {
      context.commit('setlanglist', postData)
    },

    addcomment(context, postData) {
      context.commit('addcomment', postData);
    },

    setuserprofile(context, postData) {
      context.commit('setuserprofile', postData);
    },

    setcurrentprofilephoto(context, postData) {
      context.commit('setcurrentprofilephoto', postData);
    },

    settopiclist(context, postData) {
      context.commit('settopiclist', postData);
    },

    setcurrentgroup(context, postData) {
      context.commit('setcurrentgroup', postData);
    },

    setcurrentgroupmember(context, postData) {
      context.commit('setcurrentgroupmember', postData);
    },

    addChatConnection(context, chatUser) {
      context.commit('addChatConnection', chatUser);
    },

    addChatMessage(context, chat_obj) {
      context.commit('addChatMessage', chat_obj);
    },

    setUserEncryptionKeys(context, keyObj) {
      context.commit('setUserEncryptionKeys', keyObj);
    },
  },
  getters: {
    posts(state) {
      return state.posts;
    },
    post(state) {
      return (postId) => {
        return state.posts.find((post) => (post.post_id === postId) || (post.id + '' === postId + ''));
      };
    },

    postDrafts(state) {
      return state.postDrafts;
    },
    postDraft(state) {
      return (postDraftId) => {
        return state.postDrafts.find((postDraft) => postDraft.id === postDraftId);
      };
    },

    friends(state) {
      return state.friends;
    },
    friend(state) {
      return (friendId) => {
        return state.friends.find((friend) => friend.id === friendId);
      };
    },

    friendRequestList(state) {
      return state.friendRequestList;
    },

    followers(state) {
      return state.followers;
    },
    follower(state) {
      return (followerId) => {
        return state.followers.find((friend) => friend.id === followerId);
      };
    },

    following(state) {
      return state.following;
    },

    user(state) {
      return state.user;
    },

    userProfile(state) {
      return state.userProfile;
    },

    strings(state) {
      return state.strings;
    },

    topicList(state) {
      return state.topicList;
    },

    sentNotifications(state) {
      return state.notifications;
    },

    notificationCounts(state) {
      return state.notificationCounts;
    },

    currentProfilePhoto(state) {
      return state.currentProfilePhoto;
    },

    currentGroup(state) {
      return state.currentGroup;
    },

    currentGroupMember(state) {
      return state.currentGroupMember;
    },

    langList(state) {
      return state.langList;
    },

    currentLang(state) {
      return state.currentLang;
    },

    showPostsFrom(state) {
      return state.showPostsFrom;
    },

    chatConnections(state) {
      return state.chatConnections;
    },

    chatMessages(state) {
      return (user_token) => {
        console.log("Cache Store Returning for : ", user_token);
        return state.chatMessages[user_token];
      };

    },

    userEncryptionKeys(state) {
      return state.userEncryptionKeys;
    },

    conversationList(state) {
      return state.conversationList;
    },

    currentConversationThreadList(state) {
      return state.currentConversationThreadList;
    },

    currentConversationSocket(state) {
      return state.currentConversationSocket;
    },
  },
});

export default store;
