<template>
  <ion-content>
    <ion-list lines="none">
      <ion-item
        v-for="user in users"
        :key="user.user_id"
        :value="user.user_id"
        class="ion-padding-horizontal popover_item_style"
      >
        <ion-content>
          <ion-item class="popover_item_style">
            <ion-label>
              <small>
                {{ user.user_name }}
              </small>
            </ion-label>
            <ion-button
              fill="clear"
              size="small"
              slot="end"
              expand="block"
              v-if="user.reaction_type"
            >
              <ion-icon
                slot="icon-only"
                v-for="reaction in localReactionOptions.filter(
                  (x) => user.reaction_type === x.name
                )"
                v-bind:key="reaction.name"
                :src="reaction.src"
              ></ion-icon>
            </ion-button>
          </ion-item>
        </ion-content>
      </ion-item>
    </ion-list>
  </ion-content>
</template>

<script>
import {
  IonContent,
  IonItem,
  IonList,
  IonLabel,
  IonIcon,
  IonButton,
} from "@ionic/vue";
import { defineComponent } from "vue";

import { reactionListArray } from "../../services/utils";

export default defineComponent({
  name: "UserList",
  components: { IonContent, IonItem, IonList, IonLabel, IonIcon, IonButton },
  props: ["users", "parent"],
  data() {
    return {
      localReactionOptions: reactionListArray,
    };
  },
});
</script>
