
<template>
  <ion-item no-lines lines="none">
    <ion-searchbar
      type="text"
      color="light"
      placeholder="Search People to Invite"
      v-model="searchText"
      autofocus="true"
      @keyup.enter="searchUsers"
      class="left-margin-10px"
      clearInput
    >
    </ion-searchbar>
    <ion-button @click="searchUsers" size="medium" class="right-margin-15px">
      <ion-icon slot="icon-only" :icon="icon.search"></ion-icon>
      <ion-spinner
        v-if="userSearchInProgress"
        slot="end"
        name="dots"
      ></ion-spinner>
    </ion-button>
  </ion-item>

  <ion-item no-lines lines="none" v-if="userSearchList.length > 0">
    <ion-label class="left-margin-15px"><small>Search Results:</small></ion-label>
  </ion-item>
  <ion-list no-lines lines="none" v-if="userSearchList.length > 0">
    <ion-item v-for="friend in userSearchList" v-bind:key="friend.id">
      <ion-label color="dark" class="left-margin-15px">
        <small> {{  friend.full_name }} </small>
      </ion-label>
      <ion-button
        slot="end"
        fill="outline"
        color="dark"
        shape="round"
        v-if="!friend.isInvited && !friend.isMember && !(friend.has_active_invite || friend.has_active_join_request)"
        @click="sendInvite(friend)"
      >
        Invite
      </ion-button>
      <ion-label
        slot="end"
        style="text-align: right;"
        v-if="friend.isInvited || friend.has_active_invite || friend.has_active_join_request"
        class="right-margin-15px"
      >
        <small>Invited</small>
      </ion-label>
      <ion-label slot="end" style="text-align: right;" class="right-margin-15px" v-if="friend.isMember">
        <small>Already Member</small>
      </ion-label>
      <ion-spinner
        v-if="sendInviteClicked"
        slot="end"
        name="dots"
      ></ion-spinner>
    </ion-item>
  </ion-list>

  <ion-item no-lines lines="none">
    <ion-label class="left-margin-15px right-margin-15px">
      <small>Suggested List from People you already know:</small>
    </ion-label>
  </ion-item>
  <ion-list no-lines lines="none">
    <ion-item v-for="friend in userListToInvite" v-bind:key="friend.id">
      <ion-label color="dark" class="left-margin-15px" v-if="!friend.isMember">
        <small>
          {{  friend.full_name }}
        </small>
      </ion-label>
      <ion-button
        fill="outline"
        color="dark"
        slot="end"
        shape="round"
        class="right-margin-15px"
        v-if="!friend.isInvited && !friend.isMember && !(friend.has_active_invite || friend.has_active_join_request)"
        @click="sendInvite(friend)"
      >
        Invite
      </ion-button>
      <ion-label
        slot="end"
        style="text-align: right"
        v-if="friend.isInvited || friend.has_active_invite || friend.has_active_join_request"
        class="right-margin-15px"
      >
        <small>Invited</small>
      </ion-label>
      <!--
      <ion-label slot="end" style="text-align: right" class="right-margin-15px" v-if="friend.isMember">
        <small>Already Member</small>
      </ion-label>
      -->
      <ion-spinner
        v-if="sendInviteClicked"
        slot="end"
        name="dots"
      ></ion-spinner>
    </ion-item>
  </ion-list>
</template>

<script>
import {
  IonList,
  IonItem,
  IonLabel,
  IonButton,
  IonSpinner,
  IonSearchbar,
  IonIcon,
  toastController,
} from "@ionic/vue";
import { useRouter } from "vue-router";

import {
  isMobileScreen,
  getColSize,
  getOffsetSize,
  defaultPostsPerFetch,
} from "../../services/utils";

import {
  friends,
  followers,
  following,
  inviteGroupUser,
  userSearch,
} from "../../services/apiCall";
import { getStrings } from "../../services/lang";

import { search } from "ionicons/icons";

export default {
  name: "GroupMemberInvite",
  props: ["group", "invitedMembers"],
  ionViewWillEnter() {
    this.init();
  },
  mounted() {
    this.init();
  },
  components: {
    IonList,
    IonItem,
    IonLabel,
    IonButton,
    IonSpinner,
    IonSearchbar,
    IonIcon,
  },
  setup() {
    const router = useRouter();
    return {
      router,
      getColSize,
      getOffsetSize,
      isMobileScreen,
      friends,
      followers,
      following,
      inviteGroupUser,
      userSearch,
      getStrings,
    };
  },
  data() {
    return {
      sendInviteClicked: false,
      searchText: "",

      userSearchInProgress: false,

      userSearchList: [],

      icon: {
        search,
      },
    };
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      return this.$store.getters.user;
    },
    friendList() {
      return this.$store.getters.friends;
    },
    followerList() {
      return this.$store.getters.followers;
    },
    followingList() {
      return this.$store.getters.following;
    },
    userListToInvite() {
      let friends = this.$store.getters.friends;
      let followers = this.$store.getters.followers;
      let following = this.$store.getters.following;

      let userList = [];
      var addToList = (fromList, toList) => {
        for (let userIdx in fromList) {
          let user = fromList[userIdx];
          if (!toList.find((x) => x.id === user.id)) {
            toList.push(user);
          }
        }
      };
      addToList(friends, userList);
      addToList(followers, userList);
      addToList(following, userList);

      //set if already member
      if (this.group.members) {
        let memberList = this.group.members;
        userList = userList.map((ul) => {
          let alreadyMember = memberList.find((m) => m.member_id === ul.id);
          if (alreadyMember) {
            ul.isMember = true;
          }
          return ul;
        });
      }

      //set if already invited
      if (this.invitedMembers) {
        let memberList = this.invitedMembers;
        userList = userList.map((ul) => {
          let alreadyInvited = memberList.find((m) => m.invited_to === ul.id);
          if (alreadyInvited) {
            ul.isInvited = true;
          }
          return ul;
        });
      }

      return userList;
    },
  },
  methods: {
    async openToast(msg) {
      const toast = await toastController.create({
        message: msg,
        duration: 1500,
      });
      return toast.present();
    },
    init() {
      // TODO: This will need pagination in future
      friends()
        .then(async (res) => {
          this.$store.dispatch("setfriends", res.data.result.friend_list);
        })
        .catch((err) => {
          console.log("friends-err", err);
          // TODO: handle errors
        });

      followers()
        .then(async (res) => {
          this.$store.dispatch("setfollowers", res.data.result.follower_list);
        })
        .catch((err) => {
          console.log("followers-err", err);
          // TODO: handle errors
        });

      following()
        .then(async (res) => {
          this.$store.dispatch("setfollowings", res.data.result.following_list);
        })
        .catch((err) => {
          console.log("following-err", err);
          // TODO: handle errors
        });
    },
    sendInvite(friend) {
      this.sendInviteClicked = true;
      inviteGroupUser({ group_id: this.group.group_id, invited_to: friend.id })
        .then((res) => {
          this.sendInviteClicked = false;
          if (res.data.status === 0) {
            friend.isInvited = true;
          } else {
            if (
              res.data.error.error_message ===
              "Member invite pending for this group"
            ) {
              friend.isInvited = true;
            } else {
              this.openToast(res.data.error.error_message);
            }
          }
        })
        .catch((err) => {
          console.log("inviteGroupUser - err", err);
          this.sendInviteClicked = false;
          this.openToast(this.getStrings(this.strings, "UnableSendInviteTryAgain"));
        });
    },
    searchUsers() {
      this.userSearchInProgress = true;

      if (this.searchText === "") {
        this.userSearchInProgress = false;
        return;
      }

      this.userSearchList = [];
      userSearch({
        search_string: this.searchText.trim(),
        limit: defaultPostsPerFetch(),
        skip: 0,
      })
        .then((res) => {
          if (res.data.status === 0) {
            this.userSearchList = res.data.result.search_list;
          }
          this.userSearchInProgress = false;
        })
        .catch((err) => {
          console.log("userSearch - err", err);
          this.userSearchInProgress = false;
        });
    },
  },
};
</script>