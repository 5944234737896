
<template>
  <conversations-base-layout page-title="Conversations">
    <template v-slot:actions-end>
      <ion-searchbar
        v-if="showInlineSearch"
        v-model="searchText"
        placeholder="Search User or Post"
        class="ion-text-left"
        @keydown.enter="setTimeout(searchPeople, 500)"
        @ionChange="searchPeople"
        autofocus="true"
        inputmode="search"
        animated="true"
        clearIcon
        enterkeyhint
      >
      </ion-searchbar>
      <ion-button
        class="button_nopadding"
        size="getToolButtonSize()"
        @click="onSearchClicked"
      >
        <ion-icon
          v-if="!showInlineSearch"
          src="assets/icon/misc/search.svg"
        ></ion-icon>
        <ion-icon
          v-if="showInlineSearch"
          size="getToolButtonSize()"
          slot="icon-only"
          :icon="icon.closeCircle"
        ></ion-icon>
      </ion-button>
    </template>
    <ion-row>
      <ion-col :size="getColSize()" :offset="getOffsetSize()">
        <conversation-list
          v-if="!searchText.length"
          :conversationList="conversationList"
        ></conversation-list>
        <conversation-list
          v-if="searchText.length"
          :conversationList="searchList"
        ></conversation-list>
      </ion-col>
    </ion-row>
    <ion-fab-button
      color="primary"
      style="position: absolute; bottom: 10px; right: 10px"
      @click="onCreateGroup()"
    >
      <ion-icon :icon="icon.peopleSharp"></ion-icon>
    </ion-fab-button>
  </conversations-base-layout>
</template>

<script>
import {
  IonRow,
  IonCol,
  IonIcon,
  IonFabButton,
  IonSearchbar,
  IonButton,
  toastController,
} from "@ionic/vue";

import {
  videocamOutline,
  callOutline,
  peopleSharp,
  closeCircle,
} from "ionicons/icons";
import { useRouter } from "vue-router";
import {
  isMobileScreen,
  getColSize,
  getOffsetSize,
  logOffApp,
} from "../../services/utils";

import { syncUserConversationList } from "../../services/syncdb";

import { readFromDB } from "../../services/db";
import ConversationList from "../components/ConversationList.vue";
import ConversationsBaseLayout from "../components/base/ConversationsBaseLayout.vue";
import {
  userSearch,
  getAPIKey,
  getConversationConfig,
} from "../../services/apiCall";

const io = require("socket.io-client");

export default {
  name: "ConversationsPage",

  ionViewWillEnter() {
    this.readDBAndInit();
  },

  components: {
    IonRow,
    IonCol,
    IonIcon,
    IonFabButton,
    IonSearchbar,
    IonButton,
    ConversationList,
    ConversationsBaseLayout,
  },

  setup() {
    const router = useRouter();

    return {
      router,
      getColSize,
      getOffsetSize,
      isMobileScreen,
      getAPIKey,
      syncUserConversationList,
      getConversationConfig,
    };
  },

  data() {
    return {
      showInlineSearch: false,

      searchText: "",
      fetchLimit: 10,

      searchList: [],
      searchInProgress: false,

      tokenizedUser: this.$store.getters.user.tokenized_user,

      icon: {
        videocamOutline,
        callOutline,
        peopleSharp,
        closeCircle,
      },

      socket: undefined,

      serverConfig: {},
    };
  },

  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      console.log("user", this.$store.getters.user);
      return this.$store.getters.user;
    },
    conversationList() {
      return this.$store.getters.conversationList;
    },
  },

  methods: {
    async openToast(msg) {
      const toast = await toastController.create({
        message: msg,
        duration: 1500,
      });
      return toast.present();
    },

    async init() {
      console.log("Calling init");
      this.tokenizedUser = this.$store.getters.user.tokenized_user;
      await syncUserConversationList(this.$store);

      try {
        getConversationConfig()
          .then(async (res) => {
            if (res.data.status === 0) {
              this.serverConfig = res.data.result.config;
              this.serverConfig.socketServer =
                this.serverConfig.conversationSocketServer;

              // open socket connection
              this.socket = io(this.serverConfig.socketServer, {
                reconnectionDelayMax: 10000,
                autoConnect: true,
              });

              this.socket.io.on("update", (data) =>
                console.log("socket-update", data)
              );
              this.socket.io.on("error", (data) =>
                console.log("socket-error", data)
              );
              this.socket.io.on("connect_error", (err) =>
                console.log("connect_error", err)
              );
              this.socket.io.on("connect_failed", (err) =>
                console.log("connect_failed", err)
              );
              this.socket.io.on("disconnect", (err) =>
                console.log("disconnect", err)
              );

              console.log("Conversation-init-socket", this.socket);

              this.$store.dispatch("setcurrentconversationsocket", this.socket);
            } else {
              console.log("getConversationConfig - err1", res);
            }
          })
          .catch((err) => {
            console.log("getConversationConfig - err2", err);
            this.openToast("Unable to get room ID: " + err.toString());
            this.loading.dismiss();
          });
      } catch (err) {
        console.log("getConversationConfig - err3", err);
        this.openToast("Unable to initiate conversation: " + err.toString());
        this.loading.dismiss();
      }
    },

    readDBAndInit(callbackObject = this) {
      try {
        readFromDB(this.$store, true)()
          .then(async () => {
            // read from DB done
            if (
              typeof callbackObject.user === "undefined" ||
              typeof callbackObject.user.user_id === "undefined"
            ) {
              this.logOff(callbackObject);
            } else {
              this.init();
            }
          })
          .catch((err) => {
            console.log("indexdb not available", err);
            this.logOff(callbackObject);
          });
      } catch (err) {
        console.log("indexdb not available", err);
        this.logOff(callbackObject);
      }
    },

    async logOff(callbackObject = this) {
      callbackObject.logOutDisabled = true;

      await logOffApp();

      callbackObject.$store.dispatch("adduser", {});

      callbackObject.router.replace("/login");
    },

    async onCreateGroup() {
      this.router.push("/conversations/group/123");
    },

    async onSearchClicked() {
      this.showInlineSearch = !this.showInlineSearch;
      this.searchText = "";
    },

    searchPeople() {
      console.log("Search text:", this.searchText);
      // this.conversationList = this.conversationList.filter((y) => y.user.indexOf(this.searchText) !== -1);
      this.searchInProgress = true;
      userSearch({
        search_string: this.searchText.trim(),
        limit: this.fetchLimit,
        skip: 0,
      })
        .then((res) => {
          if (res.data.status === 0) {
            console.log("RES:", res.data.result.search_list);
            this.searchList = res.data.result.search_list.map((x) => {
              var curUser = this.conversationList.filter(
                (y) => y.user_id === x.user_id
              );
              if (curUser.length === 1) return curUser[0];
              return {
                user_id: x.user_id,
                full_name: x.full_name,
                photo_thumbnail: x.photo_thumbnail,
                profile_photo_available: x.profile_photo_available,
                tokenized_user: x.tokenized_user,
              };
            });
          }
          this.searchInProgress = false;
        })
        .catch((err) => {
          console.log("userSearch - err", err);
          this.searchInProgress = false;
        });
    },
  },
};
</script>