
<template>
  <base-layout page-title="Groups" :enableHeaderShrink="true" contentID="_groupsPage">
    <ion-row>
      <ion-col :size="getColSize()" :offset="getOffsetSize()">
        <groups-tab :shareData="paramData" :shareDataType="paramDataType"></groups-tab>
      </ion-col>
    </ion-row>
  </base-layout>
</template>

<script>
import {
  IonRow,
  IonCol
} from "@ionic/vue";
import { homeOutline } from "ionicons/icons";
import { useRouter } from "vue-router";
import { isMobileScreen, getColSize, getOffsetSize, logOffApp } from "../services/utils";
import { readFromDB } from "../services/db";
import GroupsTab from "../components/groups/GroupsTab.vue";

export default {
  name: "GroupsPage",
  ionViewWillEnter() {
    this.init();
  },
  components: {
    IonRow,
    IonCol,
    GroupsTab,
  },
  setup() {
    const router = useRouter();
    return {
      router,
      getColSize,
      getOffsetSize,
      isMobileScreen,
    };
  },
  data() {
    return {
      searchText: "",
      groupSearchInProgress: false,
      paramData: this.$route.params.data ? this.$route.params.data : "",
      paramDataType: this.$route.params.type ? this.$route.params.type : "text",
      icon: {
        homeOutline,
      },
    };
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    init() {
      this.readDB();
    },
    readDB(callbackObject = this) {
      try {
        readFromDB(this.$store, true)()
          .then(async () => {
            // read from DB done
            if (
              typeof callbackObject.user === "undefined" ||
              typeof callbackObject.user.user_id === "undefined"
            ) {
              this.logOff(callbackObject);
            }
          })
          .catch((err) => {
            console.log("indexdb not available", err);
          });
      } catch (err) {
        console.log("indexdb not available", err);
      }
    },
    searchGroups() {
      this.groupSearchInProgress = true;
      this.groupSearchInProgress = false;
    },
    async logOff(callbackObject = this) {
      callbackObject.logOutDisabled = true;

      await logOffApp();

      callbackObject.$store.dispatch("adduser", {});

      callbackObject.router.replace("/login");
    },
  },
};
</script>