<template>
  <chat-base-layout
    page-title="Chats"
    page-default-back-link="/chat"
    :showBackButton="!showCancelButton()"
    v-bind:otheruser="this.chatGroup"
    v-bind:isChatItemSelected="this.isChatItemSelected"
    @menu-cancel="onMenuCancelClicked"
    @menu-reply="onMenuReplyClicked"
    @menu-delete="onMenuDeleteClicked"
    @menu-copy="onMenuCopyClicked"
    @menu-forward="onMenuForwardClicked"
    @base-menu-clicked="openMenuClicked"
    :showCancelButton="showCancelButton()"
  >
    <ion-spinner v-if="isSocketConnectInProgress" name="default"></ion-spinner>

    <group-chat-window
      v-if="currentUser"
      :isWSAvailable="issocket"
      :wsConnection="socket"
      :curUser="currentUser"
      :groupToken="group_id"
      :friendInfo="chatGroup"
      ref="mChatWindow"
      @chat-item-selected="onChatItemSelected"
      :paramData="paramData"
      :paramDataType="paramDataType"
    >
    </group-chat-window>
  </chat-base-layout>
</template>

<script>
import {
  IonSpinner,
  alertController,
  toastController,
  modalController,
  actionSheetController,
  //popoverController,
} from "@ionic/vue";
import { homeOutline, trashOutline, closeOutline, informationOutline } from "ionicons/icons";
import {
  isMobileScreen,
  getColSize,
  getOffsetSize,
} from "../../services/utils";
import { fetchGroup, deleteGroupChat } from "../../services/apiCall";
import { readFromDB } from "../../services/db.js";
import { getStrings } from "../../services/lang";
import {
  getChatFeedURL,
  userJoinChat,
  updateGroupChatStatusWS,
} from "../services/chatApiCall";

import ChatBaseLayout from "../components/ChatBaseLayout.vue";
import GroupChatWindow from "../components/GroupChatWindow.vue";
import SelectionChatUser from "../components/SelectionChatUser.vue";
//import OptionsDeleteAction from "../components/OptionsDeleteAction.vue";
import { useRouter } from "vue-router";

import { Plugins } from "@capacitor/core";
const { Clipboard } = Plugins;

export default {
  name: "GroupChatView",
  async ionViewDidEnter() {
    //console.log("Group ChatsView page did enter", this.user);
    this.readDBAndInit();
  },
  ionViewDidLeave() {
    console.log(" Group ChatsView page did leave");

    if (this.socket) {
      this.socket.close();
    }
  },
  ionViewWillEnter() {
    console.log("Group ChatsView page will enter");
  },
  ionViewWillLeave() {
    console.log("Group ChatsView page will leave");
  },
  components: {
    IonSpinner,
    GroupChatWindow,
    ChatBaseLayout,
  },
  setup() {
    const router = useRouter();

    return {
      router,
      getColSize,
      getOffsetSize,
      isMobileScreen,
      getStrings,
    };
  },
  mounted() {
    //this.init();
  },
  data() {
    return {
      searchText: "",
      groupSearchInProgress: false,
      isSocketConnectInProgress: false,
      icon: {
        homeOutline,
      },
      group_id: this.$route.params.id,
      curuser_token: "-",

      chatGroup: {},

      createPostTopics: [],
      topicsPerPostLimit: 2,

      chatItems: [],

      currentUser: null,
      socket: null,
      issocket: false,
      inUserRefreshMode: false,

      isChatItemSelected: false,
      curSelectedChatItem: {},

      paramData: this.$route.params.data ? this.$route.params.data : "",
      paramDataType: this.$route.params.type ? this.$route.params.type : "text",

      optionIcons: {
        DELETE_FOR_ME: trashOutline,
        DELETE_FOR_ALL: trashOutline,
        CANCEL: closeOutline,
        
        MAIN_MENU_GROUP_INFO: informationOutline,
      },
    };
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    searchGroups() {
      this.groupSearchInProgress = true;
      console.log("searchGroups");
      this.groupSearchInProgress = false;
    },

    showCancelButton() {
      this.paramData = this.$route.params.data ? this.$route.params.data : "";
      this.paramDataType = this.$route.params.type
        ? this.$route.params.type
        : "text";

      let showCancel =
        typeof this.paramData !== "undefined" && this.paramData !== "";

      console.log("showCancel", showCancel);

      return showCancel;
    },

    createNewGroup() {
      console.log("createNewGroup");
    },

    readDBAndInit() {
      try {
        if (this.inUserRefreshMode) return;

        this.paramData = this.$route.params.data ? this.$route.params.data : "";
        this.paramDataType = this.$route.params.type
          ? this.$route.params.type
          : "text";

        this.inUserRefreshMode = true;
        readFromDB(this.$store)()
          .then(async () => {
            this.currentUser = this.$store.getters.user;
            if (
              this.currentUser === null ||
              typeof this.currentUser === "undefined" ||
              typeof this.currentUser.user_id === "undefined"
            ) {
              this.inUserRefreshMode = false;
              this.openToast(
                this.getStrings(this.strings, "LoggingOffUnablecredentials")
              );
              this.logOff(this);
            } else {
              this.inUserRefreshMode = false;
              this.init();

              // window.setTimeout(this.initSocket, 4000);
              this.initSocket();
            }
          })
          .catch((err) => {
            console.log("indexdb not available", err);
            this.inUserRefreshMode = false;

            this.logOff(this);
          });
      } catch (err) {
        console.log("indexdb not available", err);
        this.inUserRefreshMode = false;

        this.logOff(this);
      }
    },

    init() {
      //console.log("fetchProfile", this.chatGroup);
      this.fetchingProfile = true;
      if (typeof this.chatGroup.user_id === "undefined") {
        this.chatGroup.user_id = this.group_id;
      }

      this.chatGroup.isGroup = true;
      this.chatGroup.full_name = "...";
      this.chatGroup.photo = "";
      this.chatGroup.profile = [];
      this.chatGroup.joining_date = "";

      let connections = this.$store.getters.chatConnections;
      let displayInfo = connections.find(
        (friend) => friend.tokenized_group === this.chatGroup.user_id
      );

      if (displayInfo) {
        this.chatGroup.full_name = displayInfo.full_name;
        this.chatGroup.photo = displayInfo.photo_thumbnail;
        this.chatGroup.id = displayInfo.id;

        this.chatGroup.profile = [];
        this.chatGroup.last_seen = "";

        this.fetchingProfile = false;
        this.loadGroupDetailsFromDB();
      } else {
        this.loadGroupDetailsFromDB();
      }
    },

    loadGroupDetailsFromDB() {
      fetchGroup({ group_id: this.chatGroup.user_id })
        .then((res) => {
          this.chatGroup.profile = [];
          if (res.data.status === 0) {
            //console.log("Group Profile to Show :",JSON.stringify(res.data.result));
            this.chatGroup.id = res.data.result.group.id;
            this.chatGroup.full_name = res.data.result.group.group_name;

            var photosection = res.data.result.group.photo_thumbnail;

            if (photosection) {
              this.chatGroup.photo = photosection;
            }
            this.chatGroup.last_seen = new Date().getTime(); //TODO : remove
          }

          console.log("this.chatGroup.profile:", this.chatGroup.profile);

          this.fetchingProfile = false;
        })
        .catch((err) => {
          console.log("GroupProfile - err", err);

          this.fetchingProfile = false;
        });
    },

    initSocket() {
      var self = this;

      var CHAT_BASE_URL = getChatFeedURL();

      console.log(this.currentUser);

      self.curuser_token = this.currentUser.tokenized_user;

      console.log("Friend : " + this.group_id);
      //console.log("URL : " + CHAT_BASE_URL);

      this.isSocketConnectInProgress = true;
      this.socket = new WebSocket(CHAT_BASE_URL);

      // this.socket.onmessage - defined in ChatWindow
      this.socket.onopen = (event) => {
        console.log(event);
        console.log("Successfully connected to the echo websocket server...");

        userJoinChat(self.socket, self.currentUser, self.group_id);
        this.issocket = true;
        this.isSocketConnectInProgress = false;
      };

      this.socket.onclose = (event) => {
        //this.showConnectionError(); // user back click closes connection.
        console.log("WebSocket is closed now.", event);
        //this.quitWindow();
      };

      this.socket.onerror = (event) => {
        console.log(event);
        console.log("Can not connect to websocket server");

        this.isSocketConnectInProgress = false;
        this.issocket = false;
        this.showConnectionError();
      };
    },

    async quitWindow() {
      await this.router.push("/chat/");
    },

    async logOff(callbackObject = this) {
      this.$store.dispatch("adduser", {});

      callbackObject.router.replace("/");
    },

    async showConnectionError() {
      var errorMsg = "<small>Unable to connect server for chat.</small>";
      (
        await alertController.create({
          header: "Connection Error",
          message: errorMsg,
          buttons: ["OK"],
        })
      ).present();
    },

    async openToast(msg) {
      const toast = await toastController.create({
        message: msg,
        duration: 1500,
      });
      return toast.present();
    },

    //For providing menu option to copy string Or Forward and show Item is selected. Later on delete functionality
    async onChatItemSelected(curSelectedChatItem) {
      if (this.curSelectedChatItem) this.curSelectedChatItem.isSelected = false;
      this.curSelectedChatItem = curSelectedChatItem;
      //console.log("Chat View -- Received Chat Item selected : ",JSON.stringify(curSelectedChatItem));
      this.isChatItemSelected = true;
      this.curSelectedChatItem.isSelected = true;
    },

    // Chat Item  selection Action functions
    onMenuReplyClicked() {
      this.$refs.mChatWindow.replyChatMessage(this.curSelectedChatItem);

      this.curSelectedChatItem = {};
      this.isChatItemSelected = false;
    },

    async onMenuDeleteClicked() {
      this.actionSheetOptions = [
        {
          id: "DELETE_FOR_ME",
          name: this.getStrings(this.strings, "DeleteForMe"),
        },
      ];

      const cDeleteForAll = {
        id: "DELETE_FOR_ALL",
        name: this.getStrings(this.strings, "DeleteForEveryone"),
      };
      const cCancelAction = {
        id: "CANCEL",
        name: this.getStrings(this.strings, "Cancel"),
      };

      if (this.curSelectedChatItem.from_user === this.currentUser.user_id) {
        this.actionSheetOptions.push(cDeleteForAll);
      }
      this.actionSheetOptions.push(cCancelAction);

      this.buttons = [];
      for (var idx = 0; idx < this.actionSheetOptions.length; idx++) {
        this.buttons.push({
          text: this.actionSheetOptions[idx].name,
          icon: this.optionIcons[this.actionSheetOptions[idx].id],
          size: "small",
          handler: this.handleAction.bind(
            this,
            this.actionSheetOptions[idx],
            this.actionSheetOptions[idx]
          ),
        });
      }

      const actionSheet = await actionSheetController.create({
        header: "Options",
        buttons: this.buttons,
      });

      await actionSheet.present();
    },

    handleAction(post, actionId) {
      console.log("Selected Action : ", actionId);
      if (actionId.id === "DELETE_FOR_ME") {
        console.log("Delete for me called");
        this.deleteForMeClicked();
      } else if (actionId.id === "DELETE_FOR_ALL") {
        this.deleteForAllClicked();
      } else if (actionId.id === "CANCEL") {
        console.log("cancel clicked");
        this.onMenuCancelClicked();
      } else if (actionId.id === "MAIN_MENU_GROUP_INFO") {
        this.onMenuGroupInfoClicked();
      }
    },

    deleteForAllClicked() {
      console.log("Called Local   Delete for All CLicked");

      const groupChatID = this.curSelectedChatItem.id;
      deleteGroupChat({
        chat_id: this.curSelectedChatItem.id,
        delete_for_all: true,
      })
        .then((res) => {
          //this.otheruser.profile = [];
          if (res.data.status === 0) {
            //console.log("userProfile", JSON.stringify(res));
            updateGroupChatStatusWS(
              this.socket,
              this.currentUser,
              this.group_id,
              groupChatID,
              ""
            );
          }

          //console.log("this.otheruser.profile:", this.otheruser.profile);
          this.onMenuCancelClicked();
        })
        .catch((err) => {
          console.log("delete chat - err", err);
          this.onMenuCancelClicked();
        });
    },

    deleteForMeClicked() {
      console.log("Called Local   Delete for Me");
      //TODO
      const groupChatID = this.curSelectedChatItem.id;
      deleteGroupChat({
        chat_id: groupChatID,
        delete_for_all: false,
      })
        .then((res) => {
          //this.otheruser.profile = [];
          if (res.data.status === 0) {
            //console.log("userProfile", JSON.stringify(res));
            updateGroupChatStatusWS(
              this.socket,
              this.currentUser,
              this.group_id,
              groupChatID,
              ""
            );
          }

          //console.log("this.otheruser.profile:", this.otheruser.profile);
          this.onMenuCancelClicked();
        })
        .catch((err) => {
          console.log("delete chat - err", err);
          this.onMenuCancelClicked();
        });
    },

    deleteActionDismissed() {
      this.onMenuCancelClicked();
    },

    async openMenuClicked() {
      /*this.actionSheetOptions = [
        {
          id: "MAIN_MENU_GROUP_INFO",
          name: this.getStrings(this.strings, "GroupInfo"),
        },
        {
          id: "CANCEL",
          name: this.getStrings(this.strings, "Cancel"),
        },
      ];

      this.buttons = [];
      for (var idx = 0; idx < this.actionSheetOptions.length; idx++) {
        this.buttons.push({
          text: this.actionSheetOptions[idx].name,
          icon: this.optionIcons[this.actionSheetOptions[idx].id],
          size: "small",
          handler: this.handleAction.bind(
            this,
            this.actionSheetOptions[idx],
            this.actionSheetOptions[idx]
          ),
        });
      }

      const actionSheet = await actionSheetController.create({
        header: "Options",
        buttons: this.buttons,
      });

      await actionSheet.present();*/

      const toast = await toastController.create({
        message: "Coming soon ...",
        duration: 1500,
      });
      //console.log("ChatItem status :".this.is);
      return toast.present();
    },

    async onMenuGroupInfoClicked() {
      console.log("Group Info function called");

      this.$router.push("/chat/infocontact");
    },

    async onMenuForwardClicked() {
      console.log("selection forward clicked");

      try {
        const modal = await modalController.create({
          component: SelectionChatUser,
          cssClass: "modal-fullscreen",
        });

        const curChatItem = this.curSelectedChatItem;
        modal.componentProps = {
          postForForwarding: curChatItem,
          isForwardingPost: true,
          wsConnection: this.socket,
          curUser: this.currentUser,
        };

        this.onMenuCancelClicked();

        return modal.present();
      } catch (err) {
        console.log("user list display err", err);
      }
    },

    async onMenuCopyClicked() {
      var textToCopy = "";
      if (undefined !== this.curSelectedChatItem.content) {
        if (undefined !== this.curSelectedChatItem.content.data) {
          textToCopy = this.curSelectedChatItem.content.data;
        }
      }

      if (textToCopy.length > 0) {
        try {
          await Clipboard.write({ string: textToCopy });

          var toastString = "Copied Text :" + textToCopy;

          toastString =
            toastString.length < 30
              ? toastString
              : toastString.substr(0, 30) + "...";
          this.openToast(toastString);
        } catch (err) {
          this.openToast("Failed to copy. Error: " + err);
        }
      }

      this.onMenuCancelClicked();
    },

    onMenuCancelClicked() {
      this.curSelectedChatItem.isSelected = false;
      this.curSelectedChatItem = {};
      this.isChatItemSelected = false;
    },
  }, //END Methods
  created: function () {
    console.log("Created ......chat View");
  },
};
</script>