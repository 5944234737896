<template>
  <ion-content>
    <ion-list>
      <ion-item
        v-for="lang in langList"
        :key="lang.id"
        @click="changeLanguage(lang.id)"
        :value="lang.id"
        lines="none"
        no-lines
      >
        <ion-text class="left-margin-15px bottom-top-margin-5px" v-if="lang.id === currentLang"><b>{{ lang.value }}</b></ion-text>
        <ion-text class="left-margin-15px bottom-top-margin-5px" v-if="lang.id !== currentLang">{{ lang.value }}</ion-text>
        </ion-item
      >
    </ion-list>
  </ion-content>
</template>

<script>
import { IonContent, IonItem, IonList, IonText } from "@ionic/vue";
import { defineComponent } from "vue";

import { loadLang } from "../../services/lang";

export default defineComponent({
  name: "LanguageList",
  props: ["langList", "changeLangCallback", "parent"],
  components: { IonContent, IonItem, IonList, IonText },
  data() {
    return {};
  },
  setup() {
    return {
      loadLang,
    };
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    currentLang() {
      return this.$store.getters.currentLang;
    },
  },
  methods: {
    async changeLanguage(langCode) {
      console.log("changeLanguage", langCode);

      if (this.changeLangCallback) this.changeLangCallback(langCode);

      this.parent.dismiss();
    },
  },
});
</script>
