
<template>
  <span v-if="groups.length > 0">
    <ion-item no-lines lines="none" class="ion-no-padding ion-no-margin">
      <ion-label style="margin-left: 15px">
        <b>{{ groupListName }} ({{ groups.length }})</b>
      </ion-label>
    </ion-item>
    <ion-list class="ion-no-padding ion-no-margin">
      <ion-item v-for="group in groups" v-bind:key="group.id" class="ion-no-padding ion-no-margin" no-lines lines="none">
        <group-item v-if="!group.is_deactivated" :group="group" :shareData="shareData" :shareDataType="shareDataType"></group-item>
      </ion-item>
    </ion-list>
  </span>
</template>

<script>
import { IonList, IonItem, IonLabel } from "@ionic/vue";
import { useRouter } from "vue-router";
import {
  isMobileScreen,
  getColSize,
  getOffsetSize,
} from "../../services/utils";

import GroupItem from "./GroupItem.vue";

export default {
  name: "GroupList",
  props: ["groupListName", "groups", "shareData", "shareDataType"],
  components: {
    IonList,
    IonItem,
    IonLabel,
    GroupItem,
  },
  setup() {
    const router = useRouter();
    return {
      router,
      getColSize,
      getOffsetSize,
      isMobileScreen,
    };
  },
  data() {
    return {};
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {},
};
</script>