<template>
  <base-layout page-title="Menu" :showBackButton="true">
     <ion-row class="ion-no-padding ion-no-pargin" v-if="!showInvite">
      <ion-col
        :size="getColSize()"
        :offset="getOffsetSize()"
        class="ion-no-padding ion-no-pargin"
      >
        <ion-list>
          <ion-item>
            <ion-avatar
              slot="start"
              class="left-margin-15px"
              style="cursor: pointer"
              @click="navigateUserProfile(user.tokenized_user)"
            >
              <ion-img
                v-if="user && !user.photo_thumbnail"
                src="assets/icon/misc/avatar.svg"
              ></ion-img>
              <ion-img
                size="small"
                v-if="user && user.photo_thumbnail"
                :src="user.photo_thumbnail"
              ></ion-img>
            </ion-avatar>
            <ion-label
              style="cursor: pointer"
              @click="navigateUserProfile(user.tokenized_user)"
            >
              <b>{{ getStrings(strings, "Profile") }}</b>
              <p>{{ getStrings(strings, "UserProfile") }}</p>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-avatar
              slot="start"
              size="large"
              class="left-margin-15px"
              @click="navigateSettings()"
              style="cursor: pointer"
            >
              <ion-icon size="large" :icon="settingsOutline"></ion-icon>
            </ion-avatar>
            <ion-label style="cursor: pointer" @click="navigateSettings()">
              <b>{{ getStrings(strings, "Settings") }}</b>
              <p>{{ getStrings(strings, "EditSettings") }}</p>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-avatar
              slot="start"
              class="left-margin-15px"
              style="cursor: pointer"
              @click="navigateFeedback()"
            >
              <ion-icon size="large" :src="pencilOutline"></ion-icon>
            </ion-avatar>
            <ion-label
              style="cursor: pointer"
              @click="navigateFeedback()"
            >
              <b>{{ getStrings(strings, "Feedback") }}</b>
              <p>{{ getStrings(strings, "WriteYourFeedback")}}</p>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-avatar
              slot="start"
              class="left-margin-15px"
              style="cursor: pointer"
              @click="showInvite=true"
            >
              <ion-icon size="large" :src="mail"></ion-icon>
            </ion-avatar>
            <ion-label
              style="cursor: pointer"
              @click="navigateInvite()"
            >
              <b>{{ getStrings(strings, "Invite") }}</b>
              <p>{{ getStrings(strings, "Invite friends and family members")}}</p>
            </ion-label>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
    <ion-row class="ion-no-padding ion-no-pargin left-margin-15px right-margin-15px" v-if="showInvite">
      <ion-col
        :size="getColSize()"
        :offset="getOffsetSize()"
        class="ion-no-padding ion-no-pargin"
      >
        <invite-section></invite-section>
      </ion-col>
    </ion-row>
    <ion-row 
      v-for="mitem in menuItems"
      :key="mitem.name"
      class="ion-no-padding ion-no-pargin"
    >
      <ion-col
        :size="getColSize()"
        :offset="getOffsetSize()"
        class="ion-no-padding ion-no-pargin"
        v-if="!showInvite"
      >
        <ion-item>
          <ion-avatar
            slot="start"
            size="large"
            class="left-margin-15px"
          >
            <ion-icon size="large" color="medium" :icon=mitem.icon></ion-icon>
          </ion-avatar>
          <ion-label color='medium'>
            <b>{{ getStrings(strings, mitem.name) }}</b>
            <p>{{ getStrings(strings, 'Upcoming feature') }}</p>
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
    <!-- Temporary for conversations -->
    <ion-row class="ion-no-padding ion-no-pargin" v-if="!showInvite && (user.user_id <= 5)">
      <ion-col
        :size="getColSize()"
        :offset="getOffsetSize()"
        class="ion-no-padding ion-no-pargin"
      >
        <ion-item>
          <ion-avatar
            slot="start"
            class="left-margin-15px"
            style="cursor: pointer"
            @click="this.$router.push('/conversations');"
          >
            <ion-icon size="large" :src="mail"></ion-icon>
          </ion-avatar>
          <ion-label
            style="cursor: pointer"
            @click="this.$router.push('/conversations');"
          >
            <b>{{ getStrings(strings, "New Conversation") }}</b>
            <p>{{ getStrings(strings, "New Conversation")}}</p>
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
    <!-- End conversation -->
    <ion-row class="ion-no-padding ion-no-pargin" v-if="!showInvite">
      <ion-col
        :size="getColSize()"
        :offset="getOffsetSize()"
        class="ion-no-padding ion-no-pargin"
      >
        <ion-button
          color="primary"
          expand="full"
          @click="logOff()"
          :disabled="logOutDisabled"
          style="text-transform: none"
        >
          {{ getStrings(strings, "LogOut") }}
        </ion-button>
      </ion-col>
    </ion-row>
  </base-layout>
</template>

<script>
import {
  IonButton,
  IonIcon,
  IonRow,
  IonCol,
  IonList,
  IonLabel,
  IonAvatar,
  IonItem,
  IonImg,
  toastController,
} from "@ionic/vue";
import {
  homeOutline,
  settingsOutline,
  logOutOutline,
  pencilOutline,
  mail,
  newspaperOutline,
  gameControllerOutline,
  bagOutline,
  cashOutline,
  manOutline,
  beerOutline,
} from "ionicons/icons";
import { useRouter } from "vue-router";
import { isMobileScreen, getColSize, getOffsetSize, logOffApp } from "../services/utils";
import { readFromDB } from "../services/db";
import { getStrings } from "../services/lang";
import { fetchUserProfilePhoto } from "../services/apiCall";
import InviteSection from "../components/settings/InviteSection.vue";

export default {
  name: "MenuPage",
  ionViewWillEnter() {
    this.init();
  },
  components: {
    IonButton,
    IonIcon,
    IonRow,
    IonCol,
    IonList,
    IonLabel,
    IonAvatar,
    IonItem,
    IonImg,
    InviteSection,
  },
  setup() {
    const router = useRouter();
    return {
      router,
      getColSize,
      getOffsetSize,
      isMobileScreen,
      getStrings,
    };
  },
  data() {
    return {
      homeOutline,
      settingsOutline,
      logOutOutline,
      pencilOutline,
      mail,
      logOutDisabled: false,
      //showInvite: false,
      showInvite: this.$route.params.id === 'invite' ? true : false,
      menuItems: [
        { name: 'Selected News', icon: newspaperOutline },
        { name: 'Professional Space', icon: manOutline },
        { name: 'Market Place', icon: bagOutline },
        { name: 'Gaming', icon: gameControllerOutline },
        { name: 'Financial', icon: cashOutline },
        { name: 'Good to have', icon: beerOutline },
      ],
    };
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    async openToast(msg) {
      const toast = await toastController.create({
        message: msg,
        duration: 1500,
      });
      return toast.present();
    },
    init() {
      console.log("USER:", this.user);
      this.readDB();
      this.fetchProfilePhoto();
    },
    readDB(callbackObject = this) {
      try {
        readFromDB(this.$store, true)()
          .then(async () => {
            // read from DB done
            if (
              typeof callbackObject.user === "undefined" ||
              typeof callbackObject.user.user_id === "undefined"
            ) {
              callbackObject.openToast(callbackObject.getStrings(callbackObject.strings, "LoggingOffUserInvalidSession"));
              this.logOff(callbackObject);
            }
            console.log("current user:", this.$store.getters.user);
          })
          .catch((err) => {
            console.log("indexdb not available", err);
          });
      } catch (err) {
        console.log("indexdb not available", err);
      }
    },
    async logOff(callbackObject = this) {
      callbackObject.logOutDisabled = true;

      await logOffApp();

      callbackObject.$store.dispatch("adduser", {});
          
      callbackObject.router.replace("/login");
    },
    fetchProfilePhoto() {
      fetchUserProfilePhoto()
        .then((res) => {
          if (res.data.status === 0) {
            this.user.photo = res.data.result.profile_photo.photo;
            this.user.photo_thumbnail =
              res.data.result.profile_photo.photo_thumbnail;
          } else {
            console.log("fetchUserProfilePhoto - err", res);
          }
        })
        .catch((err) => {
          console.log("fetchUserProfilePhoto - err", err);
        });
    },
    async navigateUserProfile(tokenizedUser) {
      await this.router.push("/user/profile/" + tokenizedUser);
    },
    async navigateSettings() {
      await this.router.push("/user/profile/all");
    },
    async navigateFeedback() {
      await this.router.push("/feedback");
    },

    async navigateInvite() {
      await this.router.push("/menu/invite");
    },
  },
};
</script>