<template>
  <ion-page ion-no-padding ion-no-margin>
    <ion-header
      ion-no-padding
      ion-no-margin
      :id="contentID ? contentID + '_header' : '_baseLayoutHeader'"
    >
      <ion-toolbar
        mode="md"
        class="next_bar_color"
        ion-no-padding
        ion-no-margin
      >
        <ion-title
          ion-no-padding
          ion-no-margin
          :class="
            !showBackButton ? 'inline-padding-12px' : 'inline-padding-0px'
          "
        >
          <span
            style="font-family: 'ShadowsIntoLight'; font-size: 30px"
            v-if="showAppTitle"
            >{{ appName().toUpperCase() }}</span
          >
          <sup
            style="font-family: 'ShadowsIntoLight'; font-size: 40px"
            v-if="showAppTitle"
            ><small
              ><small><small>β</small></small></small
            ></sup
          >
          <span style="font-size: 15px" v-if="pageTitle && showAppTitle">
            &nbsp;-&nbsp;{{ pageTitle ? getStrings(strings, pageTitle) : "" }}
          </span>
          <span style="font-size: 20px" v-if="pageTitle && !showAppTitle">
            {{ pageTitle ? getStrings(strings, pageTitle) : "" }}
          </span>
        </ion-title>
        <ion-buttons :collapse="true" slot="start">
          <ion-back-button
            v-if="showBackButton"
            class="ion-no-padding ion-no-margin"
            :defaultHref="pageDefaultBackLink"
          ></ion-back-button>
          <slot name="actions-start"></slot>
        </ion-buttons>
        <ion-buttons :collapse="true" slot="end">
          <slot name="actions-end"></slot>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content
      :id="contentID ? contentID : '_baseContent'"
      :scroll-events="true"
      @ionScroll="onScroll"
      fullscreen="true"
    >
      <slot />
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonBackButton,
} from "@ionic/vue";

import {
  appVersion,
  appName,
} from "../../services/utils.js";
import { getStrings } from "../../services/lang";

export default {
  props: [
    "pageTitle",
    "showBackButton",
    "showAppTitle",
    "pageDefaultBackLink",
    "scrollCallback",
    "enableHeaderShrink",
    "contentID",
  ],
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
  },
  mounted() {},
  ionViewDidEnter() {},
  data() {
    return {
      showHeader: true,
      isHeaderShrinkEnabled:
        typeof this.enableHeaderShrink !== "undefined"
          ? this.enableHeaderShrink
          : false,
      headerHeight: -1,
    };
  },
  setup() {
    return {
      appVersion,
      appName,
      getStrings,
    };
  },
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonButtons,
    IonBackButton,
  },
  methods: {
    capitalizeFirstLetter(string) {
      var str = string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
      return str;
    },
    onScroll(ev) {
      if (this.headerHeight === -1) {
        let el = document.getElementById(
          this.contentID ? this.contentID + "_header" : "_baseLayoutHeader"
        );
        this.headerHeight = el.clientHeight;
        console.log("mounted - header - height", this.headerHeight);
      }

      // console.log("onScroll", ev.detail.deltaY, this.showHeader, this.contentID);
      if (!this.isHeaderShrinkEnabled) {
        this.showHeader = true;

        if (typeof this.scrollCallback !== "undefined") {
          this.scrollCallback(ev);
        }

        return;
      }

      const scrollTop = ev.detail.scrollTop;
      let newPosition = -(scrollTop / 10);

      if (newPosition < -this.headerHeight) {
        newPosition = -this.headerHeight;
      }

      let newOpacity = 1 - newPosition / -this.headerHeight;

      let el = document.getElementById(
        this.contentID ? this.contentID + "_header" : "_baseLayoutHeader"
      );
      el.style.top = newPosition + "px";
      el.style.opacity = newOpacity;

      if (typeof this.scrollCallback !== "undefined") {
        this.scrollCallback(ev);
      }
    },
  },
};
</script>