<template>
  <ion-list v-if="type == 'full'">
    <ion-item-sliding class="ion-padding-horizontal ion-no-margin" no-lines lines="none">
      <friend-list-item
        v-for="friend in friends"
        :key="friend.id"
        :friend="friend"
        :type="type"
      ></friend-list-item>
    </ion-item-sliding>
  </ion-list>
  <ion-row v-if="type == 'compact'">
    <ion-col v-for="friend in friends" :key="friend.id">
      <friend-list-item
        :key="friend.id"
        :friend="friend"
        :type="type"
      ></friend-list-item>
    </ion-col>
  </ion-row>
  <ion-infinite-scroll
    @ionInfinite="loadMoreFriends(friends, $event)"
    threshold="100px"
    id="infinite-scroll"
    :disabled="scrollState.scrollDisabled"
  >
    <ion-infinite-scroll-content
      loading-spinner="bubbles"
      loading-text="Loading more Friends..."
    >
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</template>

<script>

import {
  IonList,
  IonItemSliding,
  IonCol,
  IonRow, 
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/vue";
import FriendListItem from "./FriendListItem.vue";
import { userSearch } from "../../services/apiCall";

export default {
  props: ["friends", "type", "searchString", "scrollState", "appendUsers", "limit"],
  components: {
    IonList,
    IonItemSliding,
    IonRow,
    IonCol,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    FriendListItem,
  },
  data() {
    return {
    };
  },
  methods: {
    loadMoreFriends(friends, ev) {
      const sState = this.scrollState;  
      sState.scrollSkip = sState.scrollSkip + this.limit;
      userSearch({
        search_string: this.searchString,
        limit: this.limit,
        skip: sState.scrollSkip,
        })
          .then((res) => {
            if (res.data.status === 0) {
              if (res.data.result.search_list.length === 0) {
                sState.scrollDisabled = true;
              } else {
                this.appendUsers(res.data.result.search_list);
                ev.target.complete();
              }
            } else {
              ev.target.complete();
            }
          })
          .catch((err) => {
            console.log("userSearch - err", err);
            this.searchInProgress = false;
          });
    },
  },
};
</script>