<template>
  <ion-header>
    <ion-toolbar color="primary">
      <ion-title>Choose Filter</ion-title>
      <ion-buttons slot="end">
        <ion-button slot="end" @click="apply()">Apply</ion-button>
        <ion-button slot="end" @click="cancel()">Close</ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <ion-slides
      class="ion-no-padding ion-no-margin"
      :options="slideOpts"
      style="height: 90%"
    >
      <ion-slide class="ion-no-padding ion-no-margin" style="height: 100%">
        <div
          class="swiper-zoom-container ion-no-padding ion-no-margin"
          style="height: 100%"
        >
          <img id="photo" :src="localImageSrc" />
        </div>
      </ion-slide>
    </ion-slides>
  </ion-content>

  <!-- filters -->
  <nav>
    <section id="filterButtons" @click="toggleFilter">
      <div
        v-for="filter in availableFilters"
        v-bind:key="filter.id"
        :id="filter.id"
        :class="filter.class"
      >
        <ion-spinner
          v-if="filter.applyingFilter"
          size="small"
          name="dots"
          color="danger"
        ></ion-spinner>
        <div class="filter-tag">{{ filter.name }}</div>
      </div>
    </section>
  </nav>
</template>

<script>
import {
  IonSpinner,
  IonContent,
  IonButton,
  IonButtons,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonSlide,
  IonSlides,
} from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ApplyFilterModal",
  props: ["imgSrc", "parent", "filter_callback"],
  components: {
    IonSpinner,
    IonContent,
    IonButton,
    IonButtons,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonSlide,
    IonSlides,
  },
  data() {
    return {
      applyingFilter: false,
      localImageSrc: (" " + this.imgSrc).slice(1),
      originalImageSrc: (" " + this.imgSrc).slice(1),
      currentFilter: "normal",
      currentFilterObj: {},
      availableFilters: [
        {
          id: "normal",
          name: "Normal",
          class: "filter normal",
        },
        {
          id: "clarendon",
          name: "Clarendon",
          class: "filter clarendon",
        },
        {
          id: "gingham",
          name: "Gingham",
          class: "filter gingham",
        },
        {
          id: "moon",
          name: "Moon",
          class: "filter moon",
        },
        {
          id: "lark",
          name: "Lark",
          class: "filter lark",
        },
        {
          id: "reyes",
          name: "Reyes",
          class: "filter reyes",
        },
        {
          id: "juno",
          name: "Juno",
          class: "filter juno",
        },
        {
          id: "slumber",
          name: "Slumber",
          class: "filter slumber",
        },
        {
          id: "crema",
          name: "Crema",
          class: "filter crema",
        },
        {
          id: "ludwig",
          name: "Ludwig",
          class: "filter ludwig",
        },
        {
          id: "aden",
          name: "Aden",
          class: "filter aden",
        },
        {
          id: "perpetua",
          name: "Perpetua",
          class: "filter perpetua",
        },
        {
          id: "amaro",
          name: "Amaro",
          class: "filter amaro",
        },
        {
          id: "mayfair",
          name: "Mayfair",
          class: "filter mayfair",
        },
        {
          id: "rise",
          name: "Rise",
          class: "filter rise",
        },
        {
          id: "hudson",
          name: "Hudson",
          class: "filter hudson",
        },
        {
          id: "valencia",
          name: "Valencia",
          class: "filter valencia",
        },
        {
          id: "xpro2",
          name: "X-Pro II",
          class: "filter xpro2",
        },
        {
          id: "sierra",
          name: "Sierra",
          class: "filter sierra",
        },
        {
          id: "willow",
          name: "Willow",
          class: "filter willow",
        },
        {
          id: "lofi",
          name: "Lo-Fi",
          class: "filter lofi",
        },
        {
          id: "inkwell",
          name: "Ink-well",
          class: "filter inkwell",
        },
        {
          id: "hefe",
          name: "He-Fe",
          class: "filter hefe",
        },
        {
          id: "nashville",
          name: "Nashville",
          class: "filter nashville",
        },
        {
          id: "stinson",
          name: "Stinson",
          class: "filter stinson",
        },
        {
          id: "vesper",
          name: "Vesper",
          class: "filter vesper",
        },
        {
          id: "earlybird",
          name: "Early Bird",
          class: "filter earlybird",
        },
        {
          id: "brannan",
          name: "Brannan",
          class: "filter brannan",
        },
        {
          id: "sutro",
          name: "Sutro",
          class: "filter sutro",
        },
        {
          id: "toaster",
          name: "Roaster",
          class: "filter toaster",
        },
        {
          id: "walden",
          name: "Walden",
          class: "filter walden",
        },
        {
          id: "1977",
          name: "1977",
          class: "filter one1977",
        },
        {
          id: "kelvin",
          name: "Kelvin",
          class: "filter kelvin",
        },
        {
          id: "maven",
          name: "Maven",
          class: "filter maven",
        },
        {
          id: "ginza",
          name: "Ginza",
          class: "filter ginza",
        },
        {
          id: "skyline",
          name: "Skyline",
          class: "filter skyline",
        },
        {
          id: "dogpatch",
          name: "Dogpatch",
          class: "filter dogpatch",
        },
        {
          id: "brooklyn",
          name: "Brooklyn",
          class: "filter brooklyn",
        },
        {
          id: "helena",
          name: "Helena",
          class: "filter helena",
        },
        {
          id: "ashby",
          name: "Ashby",
          class: "filter ashby",
        },
        {
          id: "charmes",
          name: "Charmes",
          class: "filter charmes",
        },
      ],
    };
  },
  mounted() {
    this.availableFilters.map((x) => {
      x["applyingFilter"] = false;
    });
  },
  computed: {
    filterous() {
      return window.splFilter;
    },
  },
  setup() {
    const slideOpts = {
      zoom: true,
      initialSlide: 1,
      allowTouchMove: false,
      autoHeight: true,
      centeredSlides: true,
      followFinger: false,
      longSwipes: false,
      noSwiping: true,
    };
    return { slideOpts };
  },
  methods: {
    getFilterButton(target) {
      var button;
      if (target.classList.contains("filter")) {
        button = target;
      } else if (target.parentNode.classList.contains("filter")) {
        button = target.parentNode;
      }
      return button;
    },
    async toggleFilter(ev) {
      let filterButton = this.getFilterButton(ev.target);
      if (!filterButton) return;

      this.currentFilter = filterButton.id;
      this.currentFilterObj = this.availableFilters.filter(
        (x) => x.id === this.currentFilter
      )[0];

      await this.applyFilter();
    },
    async applyFilter() {
      try {
        this.currentFilterObj.applyingFilter = true;

        // first get the original image
        this.localImageSrc = (" " + this.originalImageSrc).slice(1);

        setTimeout(async () => {
          var imageDOM = document.getElementById("photo");
          if (!imageDOM) return;

          imageDOM.src = this.localImageSrc;
          let filteredImg = await this.filterous
            .importImage(imageDOM, { format: "image/jpeg" })
            .applyInstaFilter(this.currentFilter);
          this.localImageSrc = filteredImg.canvas.toDataURL("image/jpeg");

          this.currentFilterObj.applyingFilter = false;
        }, 1000);
      } catch (e) {
        this.currentFilterObj.applyingFilter = false;
        console.log("takePhoto - err", e);
      }
    },
    async cancel() {
      // cancel the filter
      this.currentFilter = "normal";
      await this.applyFilter();

      let filteredImg = (" " + this.localImageSrc).slice(1);

      if (typeof this.filter_callback !== "undefined")
        this.filter_callback(filteredImg);

      if (typeof this.parent !== "undefined") this.parent.dismiss();
    },
    async apply() {
      let filteredImg = (" " + this.localImageSrc).slice(1);

      if (typeof this.filter_callback !== "undefined")
        this.filter_callback(filteredImg);

      if (typeof this.parent !== "undefined") this.parent.dismiss();
    },
  },
});
</script>
