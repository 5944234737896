<template>
  <base-layout
    page-title=""
    showAppTitle="true"
    :scrollCallback="onScroll"
    :enableHeaderShrink="true"
    id="thisPage"
    contentID="_postsPage"
  >
    <template v-slot:actions-end>
      <ion-searchbar
        v-if="showInlineSearch"
        v-model="inlineSearchText"
        placeholder="Search User or Post"
        class="ion-text-left search_box"
        @keydown.enter="inLineSearch"
        autofocus="true"
        inputmode="search"
        animated="true"
        clearIcon
        enterkeyhint
      >
      </ion-searchbar>
      <ion-button
        @click="reloadApp()"
        v-if="appUpdateRequired && !showInlineSearch"
        color="warning"
        size="getToolButtonSize()"
      >
        <b
          ><ion-icon
            color="warning"
            slot="icon-only"
            :icon="icon.cloudDownload"
          ></ion-icon
        ></b>
      </ion-button>

      <!-- search -->
      <ion-button
        @click="
          isMobileScreen()
            ? this.$router.push('/search')
            : (showInlineSearch = !showInlineSearch)
        "
        size="getToolButtonSize()"
        class="button_nopadding"
      >
        <ion-icon
          v-if="!showInlineSearch"
          src="assets/icon/misc/search.svg"
        ></ion-icon>
        <ion-icon
          slot="icon-only"
          v-if="showInlineSearch"
          size="getToolButtonSize()"
          :icon="icon.closeCircle"
        ></ion-icon>
      </ion-button>

      <ion-button
        @click="this.$router.push('/menu')"
        size="getToolButtonSize()"
        v-if="!showInlineSearch"
      >
        <ion-icon src="assets/icon/misc/menu.svg"></ion-icon>
      </ion-button>
    </template>

    <ion-refresher
      slot="fixed"
      @ionRefresh="doRefresh($event)"
      :disabled="inPostRefreshMode"
    >
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>

    <!-- <ion-row v-if="showAnnouncement">
      <ion-col :size="getColSize(false)" :offset="getOffsetSize(false)">
        <ion-item no-lines lines="none" >
          <ion-badge style="background-color:rgba(255, 255, 0, 0.2)">
            <ion-label class='ion-text-wrap' style="text-align: left;">
            This to announce new feature on NEXT. Now you android app can be viewd in dark mode.
            </ion-label>
            <ion-button fill="clear" color="warning" @click="showAnnouncement=false">close</ion-button>
          </ion-badge>
        </ion-item>
      </ion-col>
    </ion-row> -->

    <ion-row>
      <ion-col :size="getColSize(false)" :offset="getOffsetSize(false)">
        <ion-segment
          @ionChange="segmentChanged($event)"
          :value="showPostsFrom"
          :disabled="inPostRefreshMode"
          v-model="showPostsFrom"
          class="animate-fading"
        >
          <ion-segment-button
            v-for="tab in tabs"
            v-bind:key="tab.value"
            :value="tab.value"
            layout="icon-end"
            class="
              no-ripple
              segment-button-no-ripple
              ion-no-padding ion-no-margin
            "
          >
            <sup v-if="newTabPosts[tab.name]"
              ><ion-icon
                color="danger"
                src="assets/icon/misc/dot.svg"
              ></ion-icon
            ></sup>
            <ion-label class="ion-no-padding ion-no-margin"
              >{{ getStrings(strings, tab.name) }}
            </ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col
        :size="getColSize(false)"
        :offset="getOffsetSize(false)"
        class="ion-no-padding ion-no-margin"
      >
        <!-- public tab -->
        <span v-if="showPostsFrom === 'Public'" class="animate-fading">
          <ion-row class="grid_layout_nopadding">
            <ion-col size="12" class="grid_layout_nopadding">
              <create-post-form
                @save-post="savePost"
                :restrictSelection="topicsPerPostLimit"
                :topics="createPostTopics"
                visibilityMode="Public"
              ></create-post-form>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="4" v-if="!showFilters">
              <ion-button
                size="small"
                shape="round"
                fill="clear"
                @click="toggleFilters()"
              >
                <ion-icon :icon="icon.funnel"></ion-icon>&nbsp;
                {{ getStrings(strings, "FilterTopics") }}
              </ion-button>
            </ion-col>
            <ion-col size="4" v-if="showFilters">
              <ion-button
                size="small"
                shape="round"
                fill="clear"
                @click="toggleFilters()"
              >
                <ion-icon :icon="icon.trash"></ion-icon>&nbsp;
                {{ getStrings(strings, "ClearFilters") }}
              </ion-button>
            </ion-col>
          </ion-row>
          <topics
            v-if="showFilters"
            :topics="filterPostTopics"
            :callbackObjects="topicFilterChanged"
          ></topics>
        </span>

        <!-- friends tab -->
        <span v-if="showPostsFrom === 'Friend'" class="animate-fading">
          <ion-row class="grid_layout_nopadding">
            <ion-col size="12" class="grid_layout_nopadding">
              <create-post-form
                @save-post="savePost"
                :restrictSelection="topicsPerPostLimit"
                :topics="createPostTopics"
                visibilityMode="Friend"
                postPlaceHolderMessage="Hangout with friends"
              ></create-post-form>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="4" v-if="!showFilters">
              <ion-button
                size="small"
                shape="round"
                fill="clear"
                @click="toggleFilters()"
              >
                <ion-icon :icon="icon.funnel"></ion-icon>&nbsp;
                {{ getStrings(strings, "FilterTopics") }}
              </ion-button>
            </ion-col>
            <ion-col size="4" v-if="showFilters">
              <ion-button
                size="small"
                shape="round"
                fill="clear"
                @click="toggleFilters()"
              >
                <ion-icon :icon="icon.trash"></ion-icon>
                &nbsp;{{ getStrings(strings, "ClearFilters") }}
              </ion-button>
            </ion-col>
          </ion-row>
          <topics
            v-if="showFilters"
            :topics="filterPostTopics"
            :callbackObjects="topicFilterChanged"
          ></topics>
        </span>

        <!-- family tab -->
        <span v-if="showPostsFrom === 'Family'" class="animate-fading">
          <ion-row class="grid_layout_nopadding">
            <ion-col size="12" class="grid_layout_nopadding">
              <create-post-form
                @save-post="savePost"
                :restrictSelection="topicsPerPostLimit"
                :topics="createPostTopics"
                visibilityMode="Family"
                postPlaceHolderMessage="Share with your family"
              ></create-post-form>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="4" v-if="!showFilters">
              <ion-button
                size="small"
                shape="round"
                fill="clear"
                @click="toggleFilters()"
              >
                <ion-icon :icon="icon.funnel"></ion-icon>&nbsp;
                {{ getStrings(strings, "FilterTopics") }}
              </ion-button>
            </ion-col>
            <ion-col size="4" v-if="showFilters">
              <ion-button
                size="small"
                shape="round"
                fill="clear"
                @click="toggleFilters()"
              >
                <ion-icon :icon="icon.trash"></ion-icon>&nbsp;
                {{ getStrings(strings, "ClearFilters") }}
              </ion-button>
            </ion-col>
          </ion-row>
          <topics
            v-if="showFilters"
            :topics="filterPostTopics"
            :callbackObjects="topicFilterChanged"
          ></topics>
        </span>

        <!-- group tab -->
        <span v-if="showPostsFrom === 'Group'" class="animate-fading">
          <ion-row>
            <ion-col size="12" class="ion-no-padding ion-no-margin">
              <groups-tab></groups-tab>
            </ion-col>
          </ion-row>
        </span>
      </ion-col>
    </ion-row>
    <ion-row
      class="ion-no-padding ion-no-margin"
      v-if="showPostsFrom != 'Group'"
    >
      <ion-col
        class="ion-no-padding ion-no-margin"
        :size="getColSize(false)"
        :offset="getOffsetSize(false)"
      >
        <ion-item v-if="inPostRefreshMode && posts.length === 0" class="animate-fading">
          <ion-thumbnail slot="start">
            <ion-skeleton-text></ion-skeleton-text>
          </ion-thumbnail>
          <ion-label>
            <h3>
              <ion-skeleton-text
                animated
                style="width: 80%"
              ></ion-skeleton-text>
            </h3>
            <p>
              <ion-skeleton-text
                animated
                style="width: 60%"
              ></ion-skeleton-text>
            </p>
            <p>
              <ion-skeleton-text
                animated
                style="width: 30%"
              ></ion-skeleton-text>
            </p>
          </ion-label>
          <br /><br />
        </ion-item>

        <span
          class="animate-fading"
          v-if="posts.length > 0"
        >
          <posts-list
            :posts="posts"
            :fetchPostAPI="listPosts"
            :showPostsFrom="showPostsFrom"
            :scrollState="scrollState"
          ></posts-list>
        </span>

        <ion-item
          class="ion-padding-horizontal ion-no-margin animate-fading"
          v-if="posts.length === 0 && !inPostRefreshMode"
        >
          <ion-label>
            <h4>{{ getStrings(strings, "NothingToShowHere") }}</h4>
            <h6>{{ getStrings(strings, "PullToRefresh") }}</h6>
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-fab
      slot="fixed"
      vertical="bottom"
      horizontal="end"
      v-if="enableScrollToTop"
    >
      <ion-fab-button
        size="small"
        @click="scrollUpPosts()"
        translucent="true"
        class="animate-fading-1"
      >
        <ion-icon :icon="icon.arrowUpCircleOutline"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </base-layout>
</template>

<script>
import {
  IonButton,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonIcon,
  IonRow,
  IonCol,
  IonRefresher,
  IonRefresherContent,
  IonSkeletonText,
  IonThumbnail,
  IonItem,
  IonSearchbar,
  IonFab,
  IonFabButton,
  toastController,
} from "@ionic/vue";
import {
  add,
  search,
  personCircle,
  menuOutline,
  notifications,
  notificationsOutline,
  reorderFourOutline,
  refresh,
  wine,
  restaurant,
  videocam,
  pin,
  closeCircle,
  ellipse,
  americanFootball,
  trendingUp,
  radio,
  medkit,
  planet,
  funnel,
  trash,
  refreshCircleOutline,
  chatbubbleEllipses,
  chatbubbleEllipsesOutline,
  cloudDownload,
  arrowUpCircleOutline,
} from "ionicons/icons";

import { useRouter } from "vue-router";

import PostsList from "../components/posts/PostList.vue";
import Topics from "../components/posts/Topics";
import { getStrings } from "../services/lang";

import { initDB } from "../services/db";
import { initConversationDB } from "../services/conversationdb";

import { createGesture } from "@ionic/vue";

import {
  listPosts,
  topicList,
  listPostDrafts,
  deployedAppVersion,
  fetchUserProfilePhoto,
  logoff,
} from "../services/apiCall.js";
import {
  getColSize,
  getOffsetSize,
  defaultPostsPerFetch,
  appVersion,
  processPreview,
  isMobileScreen,
  isAndroid,
  isIOS,
  logOffApp,
} from "../services/utils.js";
import { setPosts, readFromDB, clearAllPosts } from "../services/db.js";

import CreatePostForm from "../components/posts/CreatePostForm.vue";

import GroupsTab from "../components/groups/GroupsTab.vue";

export default {
  components: {
    PostsList,
    IonButton,
    IonSegment,
    IonSegmentButton,
    IonRow,
    IonCol,
    IonLabel,
    IonIcon,
    IonRefresher,
    IonRefresherContent,
    IonSkeletonText,
    IonThumbnail,
    IonItem,
    IonSearchbar,
    IonFab,
    IonFabButton,
    CreatePostForm,
    Topics,
    GroupsTab,
  },
  ionViewDidEnter() {
    console.log("Post page did enter");
    this.onEnterInit();
  },
  ionViewDidLeave() {
    this.postPageLeft = true;
    console.log("Post page did leave");
  },
  ionViewWillEnter() {
    this.postPageLeft = false;
    console.log("Post page will enter");
  },
  mounted() {
    this.readDBAndInit();
    // TODO: Disabled to fix these issues
    // (1) Text selection gesture is conflicting
    // (2) Tag selection gesture is conflicting
    // this.addGestures();
  },
  ionViewWillLeave() {
    this.postPageLeft = true;
    console.log("Post page will leave");
    this.$store.dispatch("setshowpostsfrom", this.showPostsFrom);
  },
  watch: {
    "$route.params.tab": function (tabName) {
      this.segmentChanged({ detail: { value: this.getTabValue(tabName) } });
    },
  },
  setup() {
    return {
      getColSize,
      getOffsetSize,
      setPosts,
      appVersion,
      deployedAppVersion,
      processPreview,
      listPosts,
      isMobileScreen,
      logoff,
      getStrings,
    };
  },
  data() {
    const router = useRouter();

    return {
      theAppName: "Posts",
      inlineSearchText: "",
      appUpdateRequired: false,
      showInlineSearch: false,
      router,
      newTabPosts: {
        Public: false,
        Friends: false,
        Family: false,
      },

      icon: {
        add,
        search,
        personCircle,
        menuOutline,
        notifications,
        notificationsOutline,
        reorderFourOutline,
        refresh,
        wine,
        restaurant,
        videocam,
        pin,
        closeCircle,
        ellipse,
        americanFootball,
        trendingUp,
        radio,
        medkit,
        planet,
        funnel,
        trash,
        refreshCircleOutline,
        chatbubbleEllipses,
        chatbubbleEllipsesOutline,
        cloudDownload,
        arrowUpCircleOutline,
      },

      showFilters: false,
      showPostsFrom: "",
      showAnnouncement: false,

      tabs: [
        {
          name: "Friends",
          value: "Friend",
        },
        {
          name: "Family",
          value: "Family",
        },
        {
          name: "Public",
          value: "Public",
        },
        // {
        //   name: "Groups",
        //   value: "Group",
        // },
      ],

      scrollState: {
        scrollDisabled: false,
        scrollSkip: 0,
        isFavourite: false,
        topic_filter: [],
        post_origin_tag: "",
      },

      createPostTopics: [],
      filterPostTopics: [],
      topicsPerPostLimit: 2,

      refreshEventObject: null,
      inPostRefreshMode: false,

      postPageLeft: false,

      enableScrollToTop: false,

      topicFilterChanged: () => {
        var filterByTopics = this.filterPostTopics
          .filter((x) => x.isSelected)
          .map((x) => x.topic_name);

        this.refreshPosts(this.showPostsFrom, filterByTopics);
      },
    };
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      // console.log("user", this.$store.getters.user);
      return this.$store.getters.user;
    },
    posts() {
      console.log("posts", this.showPostsFrom);
      return this.$store.getters.posts.filter(
        (p) => p.post_origin_tag === this.showPostsFrom
      );
    },
    friends() {
      // console.log("friends", this.$store.getters.friends);
      return this.$store.getters.friends;
    },
    topics() {
      // console.log("topics", this.$store.getters.topicList);
      return this.$store.getters.topicList;
    },
  },
  methods: {
    async openToast(msg) {
      const toast = await toastController.create({
        message: msg,
        duration: 1500,
      });
      return toast.present();
    },
    addGestures() {
      const thisPage = document.getElementById("thisPage");

      let startSpan = 0;
      let endSpan = 0;

      const slideTab = (direction) => {
        // console.log('slideTab', direction, this.showPostsFrom);
        let currentTabPosition = this.tabs.findIndex(
          (x) => x.value === this.showPostsFrom
        );
        // console.log('slideTab [cur]', currentTabPosition);
        if (direction === "right") {
          currentTabPosition++;
        } else if (direction === "left") {
          currentTabPosition--;
        }

        if (currentTabPosition > this.tabs.length - 1) currentTabPosition = 0;
        if (currentTabPosition < 0) currentTabPosition = this.tabs.length - 1;

        // console.log('slideTab', direction, currentTabPosition);

        this.showPostsFrom = this.tabs[currentTabPosition].value;
        this.refreshPosts(this.showPostsFrom);
      };
      // console.log("enter", pageRef, thisPage);
      const gesture = createGesture({
        el: thisPage,
        gestureName: "swipe",
        onStart: (detail) => {
          if (
            detail.event.target.toString().toLowerCase().indexOf("textarea") >=
            0
          )
            return;

          if (detail.type === "pan") {
            startSpan = detail.currentX;
            endSpan = 0;
          }
        },
        onEnd: (detail) => {
          if (
            detail.event.target.toString().toLowerCase().indexOf("textarea") >=
            0
          )
            return;

          if (detail.type === "pan") {
            endSpan = detail.currentX;

            if (startSpan - endSpan > 15) {
              console.log("Right swipe");
              slideTab("right");
            }
            if (endSpan - startSpan > 15) {
              console.log("Left swipe");
              slideTab("left");
            }
          }
        },
      });

      gesture.enable(true);
    },
    reloadApp() {
      if (isAndroid() || isIOS()) {
        window.open("http://onelink.to/8yvjtj");
      } else {
        window.location.reload();
      }
    },
    async doRefresh(ev) {
      console.log("doRefresh", ev);
      this.refreshEventObject = ev;
      this.refreshView();
    },
    getTabValue(tabName) {
      if (tabName === "friend") {
        return "Friend";
      } else if (tabName === "public") {
        return "Public";
      } else if (tabName === "family") {
        return "Family";
      } else if (tabName === "group") {
        return "Group";
      } else {
        return undefined;
      }
    },
    async onEnterInit() {
      this.postPageLeft = false;

      if (!this.inPostRefreshMode) {
        try {
          this.inPostRefreshMode = true;

          try {
            // init the DB
            await initDB();
            await initConversationDB();
          } catch (err) {
            console.log("indexDB not available", err);
          }

          readFromDB(this.$store, true)()
            .then(async () => {
              let theUser = this.$store.getters.user;
              if (
                typeof theUser === "undefined" ||
                typeof theUser.user_id === "undefined"
              ) {
                this.inPostRefreshMode = false;
                this.openToast(
                  this.getStrings(this.strings, "LoggingOffUnablecredentials")
                );
                this.logOff(this);
              } else {
                this.inPostRefreshMode = false;
                localInit(this);
              }
            })
            .catch((err) => {
              console.log("indexdb not available", err);
              this.inPostRefreshMode = false;
              if (err.toString().indexOf("ciphertext is undefined") >= 0) {
                this.openToast(
                  this.getStrings(this.strings, "LoggingOffUnablecredentials")
                );
                this.logOff(this);
              } else {
                localInit(this);
              }
            });
        } catch (err) {
          console.log("indexdb not available", err);
          this.inPostRefreshMode = false;
          localInit(this);
        }
      }

      let localInit = (callbackObject = this) => {
        callbackObject.init(callbackObject, true);

        if (callbackObject.$store.getters.showPostsFrom) {
          callbackObject.showPostsFrom =
            callbackObject.$store.getters.showPostsFrom;
        }

        if (callbackObject.posts.length === 0) {
          callbackObject.refreshPosts(callbackObject.showPostsFrom);
        }
      };
    },
    readDBAndInit() {
      this.createPostTopics = JSON.parse(
        JSON.stringify(this.$store.getters.topicList)
      );
      this.filterPostTopics = JSON.parse(
        JSON.stringify(this.$store.getters.topicList)
      );

      try {
        if (this.inPostRefreshMode) return;

        this.inPostRefreshMode = true;

        readFromDB(this.$store)()
          .then(async () => {
            let theUser = this.$store.getters.user;
            if (
              typeof theUser === "undefined" ||
              typeof theUser.user_id === "undefined"
            ) {
              this.inPostRefreshMode = false;
              this.openToast(
                this.getStrings(this.strings, "LoggingOffUnablecredentials")
              );
              this.logOff(this);
            } else {
              this.inPostRefreshMode = false;
              this.init(this);
            }
          })
          .catch((err) => {
            console.log("indexdb not available", err);
            this.inPostRefreshMode = false;
            if (err.toString().indexOf("ciphertext is undefined") >= 0) {
              this.openToast(
                this.getStrings(this.strings, "LoggingOffUnablecredentials")
              );
              this.logOff(this);
            } else {
              this.init(this);
            }
          });
      } catch (err) {
        console.log("indexdb not available", err);
        this.inPostRefreshMode = false;
        this.init(this);
      }
    },
    init(callbackObject = this, onlySettings = false) {
      this.showPostsFrom = this.$route.params.tab
        ? this.getTabValue(this.$route.params.tab)
          ? this.getTabValue(this.$route.params.tab)
          : this.showPostsFrom
          ? this.showPostsFrom
          : "Public"
        : this.showPostsFrom
        ? this.showPostsFrom
        : "Public";

      // fetch topic list
      topicList()
        .then((res) => {
          // console.log("topicList", res);

          if (res.data.status === 0) {
            callbackObject.$store.dispatch(
              "settopiclist",
              res.data.result.topic_list
            );

            if (!onlySettings) {
              this.createPostTopics = JSON.parse(
                JSON.stringify(this.$store.getters.topicList)
              );
              this.filterPostTopics = JSON.parse(
                JSON.stringify(this.$store.getters.topicList)
              );
            }
          }
        })
        .catch((err) => {
          console.log("topicList - err", err);
        });

      // refresh post drafts
      this.refreshDraftPosts();

      if (!onlySettings) {
        // refresh posts
        this.refreshPosts(this.showPostsFrom);

        // check app version
        this.checkAppVersion();
      }

      // fetch photo of current user
      this.fetchUserPhoto();
    },
    checkAppVersion() {
      deployedAppVersion()
        .then((res) => {
          console.log(
            "app and deployed versions:",
            res.data.result.app_version,
            appVersion()
          );
          if (res.data.status === 0) {
            var version = res.data.result.app_version;
            this.appUpdateRequired = false;
            if (
              isAndroid() &&
              this.isLowerVersion(appVersion(), version.android)
            ) {
              this.appUpdateRequired = true;
            } else if (
              isIOS() &&
              this.isLowerVersion(appVersion(), version.ios)
            ) {
              this.appUpdateRequired = true;
            } else if (
              !isAndroid() &&
              !isIOS() &&
              this.isLowerVersion(appVersion(), version.webapp)
            ) {
              this.appUpdateRequired = true;
            }
          } else {
            this.appUpdateRequired = false;
          }
        })
        .catch((err) => {
          console.log("deployedAppVersion", err);
          this.appUpdateRequired = false;
        });
    },
    isLowerVersion(myVersion, newVersion) {
      var isLower = false;
      var splittedMyVersion = myVersion.split(".").map((x) => parseInt(x));
      var splittedNewVersion = newVersion.split(".").map((x) => parseInt(x));
      for (var idx = 0; idx < splittedMyVersion.length; idx++) {
        if (splittedMyVersion[idx] < splittedNewVersion[idx]) {
          isLower = true;
          break;
        }
      }
      return isLower;
    },
    refreshView() {
      this.refreshPosts(this.showPostsFrom);
      this.refreshDraftPosts();
    },
    refreshDraftPosts() {
      listPostDrafts()
        .then((res) => {
          // console.log("listPostDrafts", res);

          if (res.data.status === 0) {
            this.$store.dispatch("setpostdrafts", res.data.result.draft_list);
          }
        })
        .catch((err) => {
          console.log("listPostDrafts - err", err);
        });
    },
    async refreshPosts(filterBy = "Public", filterTopics = []) {
      console.log("refreshPosts", filterBy, this.posts.length);
      // console.log(
      //   "refreshPost",
      //   this.inPostRefreshMode,
      //   filterBy,
      //   this.showPostsFrom
      // );

      if (this.inPostRefreshMode) return;

      this.inPostRefreshMode = true;

      if (filterBy === "Group") {
        await this.$store.dispatch("setposts", []);
        await clearAllPosts();

        this.inPostRefreshMode = false;
        return;
      }

      var cond = {
        filtered_by: filterBy,
        topic_filter: filterTopics,
        limit: defaultPostsPerFetch(),
        skip: 0,
      };

      if (filterBy == "Me") {
        cond.is_favourite = this.showFilters;
      }

      listPosts(cond)
        .then(async (res) => {
          this.scrollState.scrollDisabled = false;
          this.scrollState.scrollSkip = 0;
          this.scrollState.isFavourite = cond.is_favourite;
          this.scrollState.topic_filter = cond.topic_filter;
          this.scrollState.post_origin_tag = this.showPostsFrom;
          // console.log('listPost', this.scrollState.post_origin_tag);

          if (res.data.status === 0) {
            var processedPosts = res.data.result.post_list.map((onePost) => {
              try {
                var previewData = processPreview(onePost);
                onePost["previewData"] = previewData;
                onePost["post_origin_tag"] = this.showPostsFrom;
                return onePost;
              } catch (ignored) {
                console.log("listPost - err [ parse error ]", ignored, onePost);
                return onePost;
              }
            });

            // console.log(
            //   "listPost",
            //   processedPosts,
            //   !this.postPageLeft,
            //   this.showPostsFrom
            // );

            if (!this.postPageLeft) {
              // update the cache
              await this.$store.dispatch("updateposts", processedPosts);
              await setPosts(processedPosts);
            }

            if (this.refreshEventObject !== null) {
              this.refreshEventObject.target.complete();
              this.refreshEventObject = null;
            }

            this.inPostRefreshMode = false;

            this.scrollUpPosts();
          } else {
            this.inPostRefreshMode = false;
            console.log("listPosts - err1", res);
            if (this.refreshEventObject !== null) {
              this.refreshEventObject.target.complete();
              this.refreshEventObject = null;
            }
            // we have been logged out, kick the user out from here
            if (
              res.data.error.error_message === "Incorrect user tokens" ||
              res.data.error.error_message === "Invalid user session"
            ) {
              this.openToast(
                this.getStrings(this.strings, "LoggingOffUserInvalidSession")
              );
              this.logOff(this);
            }
          }
        })
        .catch(async (err) => {
          console.log("listPosts - err", err);
          this.inPostRefreshMode = false;

          // server error, kick the user out from here
          if (this.refreshEventObject !== null) {
            this.refreshEventObject.target.complete();
            this.refreshEventObject = null;
          }

          if (err.toString().indexOf("ciphertext is undefined") >= 0) {
            this.openToast(
              this.getStrings(this.strings, "LoggingOffUnablecredentials")
            );
            this.logOff(this);
          } else {
            this.openToast(
              this.getStrings(this.strings, "EnsureConnectedToInternet")
            );
          }
        });
    },
    segmentChanged(ev) {
      // console.log("Segment changed", ev, ev.detail.value);
      this.showPostsFrom = ev.detail.value;

      if (this.showPostsFrom === "Friend") {
        this.refreshPosts(
          "Friend",
          this.filterPostTopics
            .filter((x) => x.isSelected)
            .map((x) => x.topic_name)
        );
      } else if (this.showPostsFrom === "Public") {
        this.refreshPosts(
          "Public",
          this.filterPostTopics
            .filter((x) => x.isSelected)
            .map((x) => x.topic_name)
        );
      } else if (this.showPostsFrom === "Me") {
        this.refreshPosts(
          "Me",
          this.filterPostTopics
            .filter((x) => x.isSelected)
            .map((x) => x.topic_name)
        );
      } else if (this.showPostsFrom === "Family") {
        this.refreshPosts(
          "Family",
          this.filterPostTopics
            .filter((x) => x.isSelected)
            .map((x) => x.topic_name)
        );
      } else if (this.showPostsFrom === "Follower") {
        this.refreshPosts(
          "Follower",
          this.filterPostTopics
            .filter((x) => x.isSelected)
            .map((x) => x.topic_name)
        );
      } else if (this.showPostsFrom === "Group") {
        // clear all posts cache
        this.$store.dispatch("setposts", []);
        clearAllPosts();
      }
    },
    savePost(postData) {
      this.$store.dispatch("addpost", postData);
      this.refreshView();
    },
    toggleFilters() {
      this.showFilters = !this.showFilters;

      if (this.showPostsFrom == "Me") {
        this.filterPostTopics.map((x) => (x.isSelected = false));
        this.refreshPosts("Me");
      } else if (this.showPostsFrom == "Public") {
        this.filterPostTopics.map((x) => (x.isSelected = false));
        this.refreshPosts("Public", []);
      } else if (this.showPostsFrom == "Friend") {
        this.filterPostTopics.map((x) => (x.isSelected = false));
        this.refreshPosts("Friend", []);
      } else if (this.showPostsFrom == "Family") {
        this.filterPostTopics.map((x) => (x.isSelected = false));
        this.refreshPosts("Family", []);
      }
    },
    inLineSearch() {
      console.log("inLineSearch", this.inlineSearchText);
      this.router.push("/search/" + this.inlineSearchText);
    },
    async logOff(callbackObject = this) {
      callbackObject.logOutDisabled = true;

      await logOffApp();

      callbackObject.$store.dispatch("adduser", {});

      callbackObject.router.replace("/login");
    },
    fetchUserPhoto() {
      this.$store.dispatch("setcurrentprofilephoto", {});
      fetchUserProfilePhoto({ only_thumbnail: true })
        .then((res) => {
          if (res.data.status === 0) {
            this.user.photo = res.data.result.profile_photo.photo;
            this.user.photo_thumbnail =
              res.data.result.profile_photo.photo_thumbnail;

            this.$store.dispatch("setcurrentprofilephoto", {
              photo: res.data.result.profile_photo.photo,
              photo_thumbnail: res.data.result.profile_photo.photo_thumbnail,
              is_photo_available: true,
            });
          } else {
            console.log("fetchUserProfilePhoto - err", res);
            this.$store.dispatch("setcurrentprofilephoto", {
              is_photo_available: false,
            });
          }
        })
        .catch((err) => {
          console.log("fetchUserProfilePhoto - err", err);
          this.$store.dispatch("setcurrentprofilephoto", {
            is_photo_available: false,
          });
        });
    },
    openChat() {
      this.router.push("/chat");
    },
    scrollUpPosts() {
      let el = document.getElementById("_postsPage");
      // console.log('scrollUpPosts', el);
      el.scrollToTop(300);
    },
    onScroll(ev) {
      // console.log('onScroll', ev);
      if (ev.detail.scrollTop > 250) {
        this.enableScrollToTop = true;
      } else {
        this.enableScrollToTop = false;
      }
    },
  },
};
</script>