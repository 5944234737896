<template>
  <ion-list id="list-chat" class="ion-no-padding ion-no-margin">
    <span v-for="chatItem in chatItemsList" :key="chatItem.id">
      <chat-item-view
        :chatItem="chatItem"
        :curUser="curUser"
        @reply-chat="replyChatMessage"
        @message-read="updateChatStatusRead"
        @chat-item-selected="onItemSelected"
      ></chat-item-view>
    </span>
  </ion-list>
</template>

<script>
//TODO : refresh lost for showing - msg time difference with current time

import { IonList } from "@ionic/vue";
import {
  personCircle,
  callOutline,
  checkmarkDoneCircleOutline,
} from "ionicons/icons";
import { useRouter } from "vue-router";
import {
  getColSize,
  getOffsetSize,
  formatChatTime,
  isMobileScreen,
} from "../../services/utils";

import ChatItemView from "./ChatItemView.vue";

export default {
  name: "ChatList",
  emits: [
    "reply-chat",
    "forward-chat",
    "request-load-chats",
    "message-read",
    "chat-item-selected",
  ],
  props: [
    "chatItemsList",
    "curusertoken",
    "curUser",
    "scrollState",
    "loadScrollChats",
    "mutationCallback",
  ],

  ionViewDidEnter() {
    //console.log("Chat list did enter", this.user);
  },
  ionViewDidLeave() {
    console.log("Chat list did leave");
  },
  ionViewWillEnter() {
    console.log("Chat list will enter");
  },
  ionViewWillLeave() {
    console.log("Chat list will leave");
  },
  mounted() {
    console.log("Chat list mounted");
    this.init();
  },
  activated() {
    console.log("Chat list activated");
  },
  components: {
    IonList,

    ChatItemView,
  },
  setup() {
    const router = useRouter();
    return {
      router,
      isMobileScreen,
      getColSize,
      getOffsetSize,
      formatChatTime,
    };
  },
  data() {
    return {
      icon: {
        personCircle,
        callOutline,
        checkmarkDoneCircleOutline,
      },
    };
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      return this.$store.getters.user;
    },
  },

  methods: {
    init() {
      let mutationObserver = new MutationObserver((mutation) => {
        console.log("mutation occured", mutation);
        if (this.mutationCallback) {
          this.mutationCallback();
        }
      });
      var domElement = document.getElementById("list-chat");

      if (domElement) mutationObserver.observe(domElement, { childList: true });
    },

    replyChatMessage(selChatItem) {
      this.$emit("reply-chat", selChatItem);
    },

    loadMoreChats(ev) {
      this.loadScrollChats(ev);
    },

    updateChatStatusRead(selChatItem) {
      this.$emit("message-read", selChatItem);
    },

    onItemSelected(selChatItem) {
      this.$emit("chat-item-selected", selChatItem);
    },

    isSentByMe(curChatItem) {
      if (curChatItem.group_id) {
        return curChatItem.from_user == this.curUser.user_id ? true : false;
      } else {
        return curChatItem.from_user_id == this.curUser.user_id ? true : false;
      }
    },
  },
};
</script>