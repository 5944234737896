<template>
  <ion-item v-if="type == 'full'" no-lines lines="none">
    <ion-avatar size="large" slot="start" style="cursor: pointer;" @click="this.$router.push('/user/profile/' + friend.tokenized_user);">
      <ion-img
        v-if="friend.photo_thumbnail"
        size="large"
        :src="friend.photo_thumbnail"
        @ionError="removePhoto(friend)"
      ></ion-img>
      <ion-icon
        v-if="!friend.photo_thumbnail"
        size="large"
        :icon="person"
      ></ion-icon>
    </ion-avatar>
    <ion-label @click="this.$router.push('/user/profile/' + friend.tokenized_user);">
      <h2 class="ion-text-wrap;" style="cursor: pointer;">{{ friend.full_name }}</h2>
      <h4 class="ion-text-wrap" style="color: lightgrey;">{{ friend.my_desc }}</h4>
      <p class="ion-text-wrap">{{ friend.recentPost }}</p>
    </ion-label>
  </ion-item>
  <ion-item v-if="type == 'full'" no-lines lines="none">
    <ion-button
      size="small"
      shape="round"
      color="primary"
      :disabled="disabledButtonClickRequest"
      v-if="
        !friend.is_friend &&
        !friend.is_request &&
        !friend.request_sent &&
        !friend.un_friended &&
        friend.id !== user.user_id
      "
      @click="sendFriendRequestClickHandler(friend)"
    >
      <small>Friend Request</small>
      <ion-spinner v-if="disabledButtonClickRequest" name="dots"></ion-spinner>
    </ion-button>
    <ion-button
      size="small"
      shape="round"
      color="primary"
      :disabled="disabledButtonClickRequest"
      v-if="friend.is_friend && !friend.un_friended"
      @click="unFriendRequestClickHandler(friend)"
    >
      <small>Un-Friend</small>
      <ion-spinner v-if="disabledButtonClickRequest" name="dots"></ion-spinner>
    </ion-button>
    <ion-button
      size="small"
      shape="round"
      color="primary"
      :disabled="disabledButtonClickRequest"
      v-if="friend.is_follower && !friend.un_followed && friend.id !== user.user_id"
      @click="unFollowRequestClickHandler(friend)"
    >
      <small>Un-Follow</small>
      <ion-spinner v-if="disabledButtonClickRequest" name="dots"></ion-spinner>
    </ion-button>
    <ion-button
      size="small"
      shape="round"
      color="primary"
      v-if="!friend.is_family && !friend.family_request_sent && !friend.family_request_received && friend.id !== user.user_id"
      @click="addFamilyRequestClickHandler(friend)"
    >
      <small>Add Family</small>
    </ion-button>
        <ion-button
      size="small"
      shape="round"
      color="primary"
      v-if="friend.is_family && friend.id !== user.user_id"
      @click="unFamilyRequestClickHandler(friend)"
    >
      <small>Un-Family</small>
    </ion-button>
  </ion-item>
  <ion-item v-if="type == 'full'" no-lines lines="none">
    <ion-button
      size="small"
       fill="clear"
      shape="round"
      color="primary"
      v-if="!friend.is_family && friend.family_request_sent && friend.id !== user.user_id"
    >
      <small>Family Request Sent</small>
    </ion-button>
    <ion-button
      size="small"
      fill="clear"
      shape="round"
      cloor="primary"
      v-if="
        !friend.is_friend &&
        friend.request_sent &&
        !friend.accept_request &&
        !friend.decline_request &&
        !friend.is_request
      "
    >
      <small>Friend Request Sent</small>
    </ion-button>
    <ion-button
      size="small"
      fill="clear"
      shape="round"
      cloor="success"
      v-if="friend.accept_request"
    >
      <small>Request Accepted</small>
    </ion-button>
    <ion-button
      size="small"
      fill="clear"
      shape="round"
      cloor="warning"
      v-if="friend.decline_request"
    >
      <small>Request Rejected</small>
    </ion-button>
        <ion-button
      size="small"
      fill="clear"
      shape="round"
      cloor="success"
      v-if="friend.accept_family_request"
    >
      <small>Family Request Accepted</small>
    </ion-button>
    <ion-button
      size="small"
      fill="clear"
      shape="round"
      cloor="warning"
      v-if="friend.decline_family_request"
    >
      <small>Family Request Rejected</small>
    </ion-button>
    <ion-button
      size="small"
      fill="clear"
      shape="round"
      cloor="warning"
      v-if="friend.un_friended"
    >
      <small>Un-friended</small>
    </ion-button>
    <ion-button
      size="small"
      fill="clear"
      shape="round"
      cloor="warning"
      v-if="friend.un_followed"
    >
      <small>Un-followed</small>
    </ion-button>
  </ion-item>
  <ion-item v-if="type == 'full' && friend.is_request" no-lines lines="none">
    <small>Friend Request&nbsp;</small>
    <ion-button
      size="small"
      color="success"
      shape="round"
      v-if="friend.is_request"
      :disabled="disabledButtonClickRequest"
      @click="acceptFriendRequestClickHandler(friend)"
    >
      <small>Accept</small>
      <ion-spinner v-if="disabledButtonClickRequest" name="dots"></ion-spinner>
    </ion-button>
    <ion-button
      size="small"
      color="danger"
      shape="round"
      v-if="friend.is_request"
      :disabled="disabledButtonClickRequest"
      @click="declineFriendRequestClickHandler(friend)"
    >
      <small>Reject</small>
      <ion-spinner v-if="disabledButtonClickRequest" name="dots"></ion-spinner>
    </ion-button>
  </ion-item>
  <ion-item v-if="type == 'full' && friend.family_request_received" no-lines lines="none">
    <small>Family Request&nbsp;</small>
    <ion-button
      size="small"
      color="success"
      shape="round"
      v-if="friend.family_request_received"
      :disabled="disabledButtonClickRequest"
      @click="acceptFamilyRequestClickHandler(friend)"
    >
      <small>Accept</small>
      <ion-spinner v-if="disabledButtonClickRequest" name="dots"></ion-spinner>
    </ion-button>
    <ion-button
      size="small"
      color="danger"
      shape="round"
      v-if="friend.family_request_received"
      :disabled="disabledButtonClickRequest"
      @click="declineFamilyRequestClickHandler(friend)"
    >
      <small>Reject</small>
      <ion-spinner v-if="disabledButtonClickRequest" name="dots"></ion-spinner>
    </ion-button>
  </ion-item>
  <ion-chip v-if="type == 'compact'" outline color="secondary">
    <ion-icon :icon="person"></ion-icon>
    <ion-label>{{ friend.full_name }}</ion-label>
    <ion-button
      size="small"
      fill="clear"
      shape="round"
      v-if="friend.is_request"
    >
      <ion-icon size="small" :icon="personAdd" slot="icon-only"></ion-icon>
    </ion-button>
    <ion-icon :icon="closeCircle"></ion-icon>
  </ion-chip>
</template>

<script>
import {
  IonItem,
  IonLabel,
  IonIcon,
  IonButton,
  IonChip,
  IonAvatar,
  IonImg,
  IonSpinner,
  popoverController,
} from "@ionic/vue";

import { person, personAdd, closeCircle } from "ionicons/icons";

import FamilyRequestOptions from "./FamilyRequestOptions.vue";

import {
  friendRequest,
  acceptFriendRequest,
  declineFriendRequest,
  acceptFamilyRequest,
  declineFamilyRequest,
  unFriend,
  unFollow,
  removeFamilyRelation,
} from "../../services/apiCall";

export default {
  props: ["friend", "type"],
  components: {
    IonItem,
    IonLabel,
    IonIcon,
    IonButton,
    IonChip,
    IonAvatar,
    IonImg,
    IonSpinner,
  },
  data() {
    return {
      person,
      personAdd,
      closeCircle,
      disabledButtonClickRequest: false, 
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    sendFriendRequestClickHandler(friend) {
      this.disabledButtonClickRequest = true;

      friendRequest({ request_to: friend.id })
        .then((res) => {
          if (res.data.status === 0) {
            friend.request_sent = true;
          }
          this.disabledButtonClickRequest = false;
        })
        .catch((err) => {
          console.log("sendFriendRequestClickHandler - err", err);
          this.disabledButtonClickRequest = false;
        });
    },

    acceptFriendRequestClickHandler(friend) {
      this.disabledButtonClickRequest = true;
      acceptFriendRequest({ friend_request_id: friend.friend_request_id })
        .then((res) => {
          if (res.data.status === 0) {
            friend.is_friend = true;
            friend.is_follower = true;
            friend.is_following = true;
            friend.is_request = false;
            friend.accept_request = true;
          }
          this.disabledButtonClickRequest = false;
        })
        .catch((err) => {
          console.log("acceptFriendRequestClickHandler - err", err);
          this.disabledButtonClickRequest = false;
        });
    },

    declineFriendRequestClickHandler(friend) {
      this.disabledButtonClickRequest = true;

      declineFriendRequest({ friend_request_id: friend.friend_request_id })
        .then((res) => {
          if (res.data.status === 0) {
            friend.decline_request = true;
            friend.is_request = false;
          }
          this.disabledButtonClickRequest = false;
        })
        .catch((err) => {
          console.log("declineFriendRequestClickHandler - err", err);
          this.disabledButtonClickRequest = false;
        });
    },
    acceptFamilyRequestClickHandler(friend) {
      this.disabledButtonClickRequest = true;
      acceptFamilyRequest({ family_request_id: friend.family_request_id })
        .then((res) => {
          if (res.data.status === 0) {
            friend.is_family = true;
            friend.family_request_received = false;
            friend.accept_family_request = true;
          }
          this.disabledButtonClickRequest = false;
        })
        .catch((err) => {
          console.log("acceptFamilyRequestClickHandler - err", err);
          this.disabledButtonClickRequest = false;
        });
    },
    declineFamilyRequestClickHandler(friend) {
      this.disabledButtonClickRequest = true;

      declineFamilyRequest({ family_request_id: friend.family_request_id })
        .then((res) => {
          if (res.data.status === 0) {
            friend.decline_family_request = true;
            friend.family_request_received = false;
          }
          this.disabledButtonClickRequest = false;
        })
        .catch((err) => {
          console.log("declineFamilyRequestClickHandler - err", err);
          this.disabledButtonClickRequest = false;
        });
    },

    unFriendRequestClickHandler(friend) {
      this.disabledButtonClickRequest = true;

      unFriend({ unfriend_user_id: friend.id })
        .then((res) => {
          if (res.data.status === 0) {
            friend.is_friend = false;
            friend.is_follower = false;
            friend.is_following = false;
            friend.un_friended = true;
          }
          this.disabledButtonClickRequest = false;
        })
        .catch((err) => {
          console.log("unFriendRequestClickHandler - err", err);
          this.disabledButtonClickRequest = false;
        });
    },

    unFamilyRequestClickHandler(friend) {
      this.disabledButtonClickRequest = true;

      removeFamilyRelation({ remove_relation_user_id: friend.id })
        .then((res) => {
          if (res.data.status === 0) {
            friend.is_family = false;
          }
          this.disabledButtonClickRequest = false;
        })
        .catch((err) => {
          console.log("unFamilyRequestClickHandler - err", err);
          this.disabledButtonClickRequest = false;
        });
    },

    unFollowRequestClickHandler(friend) {
      this.disabledButtonClickRequest = true;
      unFollow({ unfollow_user_id: friend.id })
        .then((res) => {
          if (res.data.status === 0) {
            friend.is_following = false;
            friend.un_followed = true;
          }
          this.disabledButtonClickRequest = false;
        })
        .catch((err) => {
          console.log("unFollowRequestClickHandler - err", err);
          this.disabledButtonClickRequest = false;
        });
    },

    async addFamilyRequestClickHandler(friend, ev) {
      friend.family_request_sent = true;
      const popover = await popoverController.create({
        component: FamilyRequestOptions,
        event: ev,
        translucent: true,
      });
      popover.componentProps = { userId: this.friend.user_id, parent: popover };
      return popover.present();
    },

    removePhoto(friend) {
      delete friend.photo_thumbnail;
    }
  },
};
</script>