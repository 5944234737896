import { createRouter, createWebHistory } from '@ionic/vue-router';

import PostsPage from '../pages/PostsPage.vue';
import ProfileAndSettingsPage from '../pages/ProfileAndSettingsPage.vue';
import LoadingPage from '../pages/LoadingPage.vue';
import LoginPage from '../pages/LoginPage.vue';
import SignupPage from '../pages/SignupPage.vue';
import VerifyPage from '../pages/VerifyPage.vue';
import PasswordChangePage from '../pages/PasswordChangePage.vue';
import PasswordResetPage from '../pages/PasswordResetPage.vue';
import SearchEverythingPage from '../pages/SearchEverythingPage.vue';
import GroupsPage from '../pages/GroupsPage.vue';
import ExternalSharePage from '../pages/ExternalSharePage.vue';
import ShareSelectionPage from '../pages/ShareSelectionPage.vue';
import MenuPage from '../pages/MenuPage.vue';
import NotificationsPage from '../pages/NotificationsPage.vue';
import ChatView from '../chat/pages/ChatView.vue';
import GroupChatView from "../chat/pages/GroupChatView.vue";
import ChatUsers from '../chat/pages/ChatUsers.vue';
import ChatSearchUserPage from "../chat/pages/ChatSearchUserPage.vue";
import InfoContactPage from "../chat/pages/InfoContactPage.vue";
import FeedbackPage from '../pages/FeedbackPage.vue';
import ViewFeedbackPage from '../pages/ViewFeedbackPage.vue';
import Conversations from '../conversations/pages/Conversations.vue';
import SingleConversation from '../conversations/pages/SingleConversation.vue';
import VideoConversation from '../conversations/pages/VideoConversation.vue';
import GroupConversation from '../conversations/pages/GroupConversation.vue';

const routes = [
  {
    path: '/',
    redirect: '/loading',
  },
  {
    path: '/loading',
    component: LoadingPage,
  },
  {
    path: '/posts',
    redirect: '/tabs/posts'
  },
  {
    path: '/verify',
    component: VerifyPage
  },
  {
    path: '/verify/:me',
    component: VerifyPage
  },
  {
    path: '/posts/:postid',
    redirect: { name: 'singlepost' }
  },
  {
    path: '/posts/add',
    component: () => import('../pages/AddPostPage.vue')
  },
  {
    path: '/search',
    component: SearchEverythingPage
  },
  {
    path: '/search/:searchtxt',
    component: SearchEverythingPage
  },
  {
    path: '/posts/tabs/:tab',
    redirect: { name: 'tabbedpost' }
  },
  {
    path: '/user',
    redirect: '/user/profile'
  },
  {
    path: '/user/profile',
    redirect: '/user/profile/all'
  },
  {
    path: '/user/profile/:profileid',
    component: () => import('../pages/UserProfilePage.vue')
  },
  {
    path: '/user/profile/all',
    component: ProfileAndSettingsPage
  },
  {
    path: '/user/password',
    redirect: '/user/password/change'
  },
  {
    path: '/user/password/change',
    component: PasswordChangePage
  },
  {
    path: '/user/password/reset',
    component: PasswordResetPage
  },
  {
    path: '/user/password/reset/:me',
    component: PasswordResetPage
  },
  {
    path: '/user/password/reset/:me/:code',
    component: PasswordResetPage
  },
  {
    path: '/friends/list',
    redirect: '/friends/list'
  },
  {
    path: '/login',
    component: LoginPage
  },
  {
    path: '/signup',
    component: SignupPage
  },
  {
    path: '/signup/:email/:rcode',
    component: SignupPage
  },
  {
    path: '/groups',
    redirect: '/tabs/groups'
  },
  {
    path: '/groups/:groupid',
    redirect: { name: 'singlegroup' }
  },
  {
    path: '/groups/:groupid/:action',
    redirect: { name: 'singlegroup' }
  },
  {
    path: '/groups/:groupid/:sharedata/:shardatatype',
    component: () => import('../pages/GroupsDetailPage.vue'),
  },
  {
    path: '/sharegroup/:type/:data',
    component: GroupsPage
  },
  {
    path: '/share/:type/:data',
    component: ExternalSharePage
  },
  {
    path: '/share/:type/:data/:tokenizedgroup',
    component: ExternalSharePage
  },
  {
    path: '/shareselect/:type/:data',
    component: ShareSelectionPage
  },
  {
    path: '/chat/:id',
    component: ChatView
  },
  {
    path: '/conversations',
    component: Conversations
  },
  {
    path: '/conversations/:convid/:name',
    component: SingleConversation
  },
  {
    path: '/conversations/:convid/:name/:roomid',
    component: SingleConversation
  },
  {
    path: '/conversations/call/:calltype/:vchatuserid',
    component: VideoConversation
  },
  {
    path: '/conversations/call/:calltype/:vchatuserid/:roomid',
    component: VideoConversation
  },
  {
    path: '/conversations/group/:convid',
    component: GroupConversation
  },
  {
    path: '/chat/:id/:type/:data',
    component: ChatView
  },
  {
    path: '/groupchat/:id',
    component: GroupChatView
  },
  {
    path: '/groupchat/:id/:type/:data',
    component: GroupChatView
  },
  {
    path: '/chat',
    component: ChatUsers
  },
  {
    path: '/chat/search',
    component: ChatSearchUserPage
  },
  {
    path: '/chat/search/:searchtxt',
    component: ChatSearchUserPage
  },
  {
    path: '/chat/infocontact',
    component: InfoContactPage
  },
  {
    path: '/chats/:type/:data',
    redirect: { name: 'sharechat' }
  },
  {
    path: '/menu',
    component: MenuPage
  },
  {
    path: '/menu/:id',
    component: () => import('../pages/MenuPage.vue')
  },
  {
    path: '/notifications',
    component: NotificationsPage
  },
  {
    path: '/notifications/:viewid',
    component: () => import('../pages/NotificationsPage.vue')
  },
  {
    path: '/feedback',
    component: FeedbackPage
  },
  {
    path: '/feedback/view',
    component: ViewFeedbackPage
  },
  {
    path: '/credential/change',
    component: () => import('../pages/ChangeEmailPhonePage.vue')
  },
  {
    path: '/page/:pageid',
    component: () => import('../pages/ProfessionalPage.vue')
  },
  {
    path: '/page/list',
    component: () => import('../pages/ProfessionalSettingPage.vue')
  },
  {
    path: '/tabs/',
    component: () => import('../pages/TabsView.vue'),
    children: [
      {
        path: '',
        redirect: '/tabs/posts'
      },
      {
        path: 'posts',
        component: PostsPage,
      },
      {
        path: 'posts/tabs/:tab',
        name: 'tabbedpost',
        component: PostsPage,
      },
      {
        path: 'posts/:postid',
        name: 'singlepost',
        component: () => import('../pages/PostDetailsPage.vue')
      },
      {
        path: 'chats',
        component: ChatUsers,
      },
      {
        path: 'chats/:type/:data',
        name: 'sharechat',
        component: ChatUsers,
      },
      {
        path: 'groups',
        component: GroupsPage,
      },
      {
        path: 'groups/:groupid/:action',
        name: 'singlegroup',
        component: () => import('../pages/GroupsDetailPage.vue'),
      },
      {
        path: 'notifications',
        component: NotificationsPage,
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
