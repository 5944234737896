<template>
  <base-layout
    page-title="Notifications"
    :showBackButton="false"
    :scrollCallback="onScroll"
    contentID="_notificationsPage"
    :enableHeaderShrink="true"
  >
    <ion-row class="ion-no-padding ion-no-pargin" v-if="!showSettings">
      <ion-col
        :size="getColSize()"
        :offset="getOffsetSize()"
        class="ion-no-padding ion-no-pargin"
      >
        <ion-toolbar class="ion-no-padding ion-no-pargin min-height-30px">
          <ion-button
            size="small"
            fill="clear"
            slot="end"
            shape="round"
            @click="openNotificationPopover"
          >
            <ion-icon
              size="small"
              color="dark"
              slot="icon-only"
              :icon="icon.ellipsisHorizontal"
            ></ion-icon>
          </ion-button>
        </ion-toolbar>
      </ion-col>
    </ion-row>
    <ion-row class="ion-no-padding ion-no-pargin" v-if="fetchingNotifications">
      <ion-col
        :size="getColSize()"
        :offset="getOffsetSize()"
        class="ion-no-padding ion-no-pargin center_align_col"
      >
        <ion-spinner style="padding-top: 200px"> </ion-spinner>
      </ion-col>
    </ion-row>
    <!-- notifications settings -->
    <ion-row class="ion-no-padding ion-no-pargin" v-if="showSettings">
      <ion-col
        :size="getColSize()"
        :offset="getOffsetSize()"
        class="ion-no-padding ion-no-pargin"
      >
        <notification-settings></notification-settings>
      </ion-col>
    </ion-row>
    <!-- show request notifications -->
    <ion-row
      class="ion-no-padding ion-no-pargin"
      v-if="requestNotificationsView.notifications.length > 0 && !showSettings"
    >
      <ion-col
        :size="getColSize()"
        :offset="getOffsetSize()"
        class="ion-no-padding ion-no-pargin"
      >
        <ion-item no-lines lines="none">
          <ion-title slot="start" mode="md">
            <b>Request</b>
          </ion-title>
          <ion-button
            color="primary"
            size="medium"
            slot="end"
            fill="clear"
            @click="showLessHandler()"
            style="text-transform: none"
            v-if="showRequestNotifications"
          >
            <p>Back</p>
          </ion-button>
          <ion-button
            color="primary"
            size="medium"
            slot="end"
            fill="clear"
            @click="showAllRequestsHandler()"
            style="text-transform: none"
            v-if="!showRequestNotifications"
          >
            <p>Show All</p>
          </ion-button>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row
      class="ion-no-padding ion-no-pargin"
      v-if="requestNotificationsView.notifications.length > 0 && !showSettings"
    >
      <ion-col
        :size="getColSize()"
        :offset="getOffsetSize()"
        class="ion-no-padding ion-no-pargin"
      >
        <ion-list>
          <ion-item
            v-for="notification in requestNotificationsView.notifications"
            :key="notification.id"
            :value="notification.id"
            class="bottom-margin-10px"
          >
            <ion-avatar
              slot="start"
              size="large"
              class="comment-avatar"
              style="
                cursor: pointer;
                min-width: 50px;
                min-height: 50px;
                margin-top: 0px;
                margin-left: 15px;
                padding-top: 0px;
              "
            >
              <ion-img
                v-if="
                  (!notification.photo_thumbnail ||
                    !notification.photo_thumbnail_available) &&
                  notification.metadata &&
                  notification.metadata.type === 'group'
                "
                src="assets/icon/misc/group.svg"
              ></ion-img>
              <ion-img
                v-if="
                  (!notification.photo_thumbnail ||
                    !notification.photo_thumbnail_available) &&
                  notification.metadata &&
                  (notification.metadata.type === 'user' ||
                    notification.metadata.type === 'post')
                "
                src="assets/icon/misc/avatar.svg"
              ></ion-img>
              <ion-img
                @ionError="notification.photo_thumbnail_available = false"
                v-if="
                  notification.photo_thumbnail &&
                  notification.photo_thumbnail_available
                "
                :src="notification.photo_thumbnail"
              ></ion-img>
            </ion-avatar>
            <ion-label
              class="
                ion-no-padding ion-no-margin ion-text-wrap
                bottom-margin-10px
                right-margin-15px
              "
              style="margin-left: 75px"
            >
              <ion-label
                class="ion-no-padding ion-no-margin ion-text-wrap"
                style="cursor: pointer"
                @click="notificationAction(notification)"
              >
                <b>{{ notification.notification_text }}</b>
              </ion-label>
              <p>{{ getElapsedTime(notification.createdAt) }}</p>
              <span v-if="notification.is_accept_reject_request">
                <ion-button
                  @click="acceptRelation(notification)"
                  size="small"
                  fill="outline"
                  shape="round"
                  color="dark"
                  :disabled="notification.is_accept_clicked"
                >
                  Accept
                </ion-button>
                <ion-button
                  @click="rejectRelation(notification)"
                  size="small"
                  fill="outline"
                  shape="round"
                  color="dark"
                  :disabled="notification.is_reject_clicked"
                >
                  Reject
                </ion-button>
              </span>
            </ion-label>
          </ion-item>
          <!-- load more -->
          <ion-infinite-scroll
            v-if="showRequestNotifications"
            @ionInfinite="loadMoreNotifications($event)"
            threshold="100px"
            id="infinite-scroll"
            :disabled="scrollState.scrollDisabled"
          >
            <ion-infinite-scroll-content
              loading-spinner="bubbles"
              loading-text="Loading more Requests ..."
            >
            </ion-infinite-scroll-content>
          </ion-infinite-scroll>
        </ion-list>
      </ion-col>
    </ion-row>
    <!-- Other notifications -->
    <ion-row
      class="ion-no-padding ion-no-pargin"
      v-if="
        !showRequestNotifications &&
        !showSettings &&
        requestNotificationsView.notifications.length
      "
    >
      <ion-col
        :size="getColSize()"
        :offset="getOffsetSize()"
        class="ion-no-padding ion-no-pargin"
      >
        <ion-item no-lines lines="none">
          <ion-title slot="start" mode="md">
            <b>{{ getStrings(strings, "Other") }}</b>
          </ion-title>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row
      class="ion-no-padding ion-no-pargin"
      v-if="!showRequestNotifications && !showSettings"
    >
      <ion-col
        :size="getColSize()"
        :offset="getOffsetSize()"
        class="ion-no-padding ion-no-pargin"
      >
        <ion-list>
          <ion-item
            v-for="notification in notificationsView.notifications"
            :key="notification.id"
            :value="notification.id"
            class="bottom-margin-10px"
          >
            <ion-avatar
              slot="start"
              size="large"
              class="comment-avatar"
              style="
                cursor: pointer;
                min-width: 50px;
                min-height: 50px;
                margin-top: 0px;
                margin-left: 15px;
                padding-top: 0px;
              "
            >
              <ion-img
                v-if="
                  (!notification.photo_thumbnail ||
                    !notification.photo_thumbnail_available) &&
                  notification.metadata &&
                  notification.metadata.type === 'group'
                "
                src="assets/icon/misc/group.svg"
              ></ion-img>
              <ion-img
                v-if="
                  (!notification.photo_thumbnail ||
                    !notification.photo_thumbnail_available) &&
                  notification.metadata &&
                  (notification.metadata.type === 'user' ||
                    notification.metadata.type === 'post')
                "
                src="assets/icon/misc/avatar.svg"
              ></ion-img>
              <ion-img
                @ionError="notification.photo_thumbnail_available = false"
                v-if="
                  notification.photo_thumbnail &&
                  notification.photo_thumbnail_available
                "
                :src="notification.photo_thumbnail"
              ></ion-img>
            </ion-avatar>
            <ion-label
              class="
                ion-no-padding ion-no-margin ion-text-wrap
                bottom-margin-10px
                right-margin-15px
              "
              style="margin-left: 75px"
            >
              <ion-label
                class="ion-no-padding ion-no-margin ion-text-wrap"
                style="cursor: pointer"
                @click="notificationAction(notification)"
              >
                <span v-if="notification.is_read">{{
                  notification.notification_text
                }}</span>
                <b v-if="!notification.is_read">{{
                  notification.notification_text
                }}</b>
              </ion-label>
              <p>
                {{ getElapsedTime(notification.createdAt) }}
                <ion-text
                  @click="
                    markNotificationsRead(notification, notificationsView)
                  "
                  size="small"
                  color="primary"
                  style="cursor: pointer; color: #005ca8"
                  v-if="!notification.is_read"
                >
                  &nbsp;&nbsp;&nbsp;Mark Read
                </ion-text>
              </p>
            </ion-label>
          </ion-item>
          <ion-infinite-scroll
            @ionInfinite="loadMoreNotifications($event)"
            threshold="100px"
            id="infinite-scroll"
            :disabled="scrollState.scrollDisabled"
          >
            <ion-infinite-scroll-content
              loading-spinner="bubbles"
              loading-text="Loading more Notifications..."
            >
            </ion-infinite-scroll-content>
          </ion-infinite-scroll>
        </ion-list>
      </ion-col>
    </ion-row>
    <ion-fab
      slot="fixed"
      vertical="bottom"
      horizontal="end"
      v-if="enableScrollToTop"
    >
      <ion-fab-button
        size="small"
        @click="scrollUpPosts()"
        translucent="true"
        class="animate-fading-1"
      >
        <ion-icon :icon="icon.arrowUpCircleOutline"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </base-layout>
</template>

<script>
import {
  IonButton,
  IonIcon,
  IonRow,
  IonCol,
  IonList,
  IonLabel,
  IonText,
  IonAvatar,
  IonImg,
  IonItem,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonToolbar,
  IonTitle,
  IonSpinner,
  IonFab,
  IonFabButton,
  popoverController,
} from "@ionic/vue";

import { ellipsisHorizontal, arrowUpCircleOutline } from "ionicons/icons";

import { useRouter } from "vue-router";

import {
  isMobileScreen,
  getColSize,
  getOffsetSize,
  formatDate,
  defaultMaxNotifications,
  defaultNotificationsPerFetch,
  getElapsedTime,
  logOffApp,
} from "../services/utils";

import { readFromDB } from "../services/db";

import {
  getNotifications,
  markNotificationAsRead,
  acceptRequest,
  declineRequest,
} from "../services/apiCall.js";

import { getStrings } from "../services/lang";

import NotificationOptions from "../components/notifications/NotificationOptions.vue";
import NotificationSettings from "../components/settings/NotificationSettings.vue";

export default {
  name: "NotificationPage",
  ionViewWillEnter() {
    this.init();
  },

  components: {
    IonButton,
    IonIcon,
    IonRow,
    IonCol,
    IonList,
    IonLabel,
    IonText,
    IonAvatar,
    IonImg,
    IonItem,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonToolbar,
    IonTitle,
    IonSpinner,
    IonFab,
    IonFabButton,
    NotificationSettings,
  },
  setup() {
    const router = useRouter();
    return {
      router,
      getColSize,
      getOffsetSize,
      isMobileScreen,
      getStrings,
    };
  },
  data() {
    return {
      icon: {
        arrowUpCircleOutline,
        ellipsisHorizontal,
      },

      popoverController,
      formatDate,
      getElapsedTime,
      logOutDisabled: false,
      notificationsView: {
        notifications: [],
        new_count: 0,
      },
      requestNotificationsView: {
        notifications: [],
        new_count: 0,
      },
      scrollState: {
        scrollDisabled: false,
        scrollSkip: 0,
      },
      limit: defaultNotificationsPerFetch(),
      requestStartLimit: 2,
      requestsOnly: false,
      showRequestNotifications: false,
      showSystemBackButton: true,
      showSettings: this.$route.params.viewid === "settings",
      fetchingNotifications: false,

      enableScrollToTop: false,
    };
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    init() {
      this.fetchingNotifications = true;
      this.readDB();
      this.refreshNotifications(false);
      this.refreshNotifications(true);
      this.fetchingNotifications = false;
    },
    readDB(callbackObject = this) {
      try {
        readFromDB(this.$store, true)()
          .then(async () => {
            // read from DB done
            if (
              typeof callbackObject.user === "undefined" ||
              typeof callbackObject.user.user_id === "undefined"
            ) {
              this.logOff(callbackObject);
            }
          })
          .catch((err) => {
            console.log("indexdb not available", err);
          });
      } catch (err) {
        console.log("indexdb not available", err);
      }
    },
    async refreshNotifications(requestsOnly) {
      var recLimit = defaultNotificationsPerFetch();
      if (requestsOnly) recLimit = this.requestStartLimit;
      getNotifications({
        requests_only: requestsOnly,
        limit: recLimit,
        skip: 0,
      })
        .then((res) => {
          if (res.data.status === 0) {
            if (requestsOnly) {
              this.requestNotificationsView.notifications =
                res.data.result.notifications;
              this.requestNotificationsView.new_count =
                res.data.result.new_count;
              let chatsCount = this.$store.getters.notificationCounts.new_chats;
              this.$store.dispatch("setnotificationcounts", {
                new_count: this.requestNotificationsView.new_count,
                new_chats: chatsCount,
              });
            } else {
              this.notificationsView.notifications =
                res.data.result.notifications;
              this.notificationsView.new_count = res.data.result.new_count;
              let chatsCount = this.$store.getters.notificationCounts.new_chats;
              this.$store.dispatch("setnotificationcounts", {
                new_count: this.notificationsView.new_count,
                new_chats: chatsCount,
              });
            }
          } else {
            // we have been logged out, kick the user out from here
            if (res.data.error.error_message === "Incorrect user tokens") {
              this.logOff(this);
            }
          }
        })
        .catch((err) => {
          console.log("getNotifications - err", err);
        });
    },
    async logOff(callbackObject = this) {
      this.logOutDisabled = true;
      await logOffApp();

      this.$store.dispatch("adduser", {});

      callbackObject.router.replace("/login");
    },
    loadMoreNotifications(ev) {
      const sState = this.scrollState;
      sState.scrollSkip = sState.scrollSkip + this.limit;
      var cond = {
        limit: this.limit,
        skip: sState.scrollSkip,
        requests_only: this.showRequestNotifications ? true : false,
      };

      getNotifications(cond)
        .then(async (res) => {
          if (
            this.notificationsView.length === defaultMaxNotifications() ||
            res.data.result.notifications.length === 0
          ) {
            sState.scrollDisabled = true;
          }
          if (
            res.data.status === 0 &&
            res.data.result.notifications.length !== 0
          ) {
            if (this.showRequestNotifications) {
              this.requestNotificationsView.notifications =
                this.requestNotificationsView.notifications.concat(
                  res.data.result.notifications
                );
            } else {
              this.notificationsView.notifications =
                this.notificationsView.notifications.concat(
                  res.data.result.notifications
                );
            }

            ev.target.complete();
          } else {
            ev.target.complete();
          }
        })
        .catch((err) => {
          console.log("fetchNotificationsAPI - err", err);
        });
    },
    async openNotificationPopover(ev) {
      const popover = await popoverController.create({
        component: NotificationOptions,
        event: ev,
        translucent: true,
        cssClass: "userlist_popover",
      });

      var options = [
        { id: "MARK_READ", name: this.getStrings(this.strings, "MarkAllRead") },
        {
          id: "SETTINGS",
          name: this.getStrings(this.strings, "NotificationSettings"),
        },
      ];
      popover.componentProps = {
        notificationActions: options,
        parent: popover,
        notificationsView: this.notificationsView,
      };
      return popover.present();
    },
    notificationAction(notification) {
      this.notificationActionClicked = true;
      markNotificationAsRead({ notification_id: [notification.id] })
        .then((res) => {
          if (res.data.status === 0) notification.is_read = true;

          if (typeof notification.metadata !== "undefined") {
            if (notification.metadata.type === "user") {
              this.router.push(
                "/user/profile/" + notification.metadata.tokenized_user
              );
            } else if (notification.metadata.type === "post") {
              this.router.push("/posts/" + notification.metadata.id);
            } else if (notification.metadata.type === "group") {
              if (
                typeof notification.metadata.visibility_type !== "undefined" &&
                notification.metadata.visibility_type === "Professional_Page"
              ) {
                this.router.push(
                  "/page/" + notification.metadata.tokenized_group
                );
              } else {
                this.router.push(
                  "/groups/" + notification.metadata.tokenized_group + "/show"
                );
              }
            }
          }

          this.notificationActionClicked = false;
        })
        .catch((err) => {
          console.log("markNotificationAsRead - err", err);
          this.notificationActionClicked = false;
        });
    },
    markNotificationsRead(notification, notificationsView) {
      markNotificationAsRead({ notification_id: [notification.id] })
        .then((res) => {
          if (res.data.status === 0) {
            notification.is_read = true;
            notificationsView.new_count = notificationsView.new_count - 1;
            let chatsCount = this.$store.getters.notificationCounts.new_chats;
            this.$store.dispatch("setnotificationcounts", {
              new_count: this.notificationsView.new_count,
              new_chats: chatsCount,
            });
          }
        })
        .catch((err) => {
          console.log("markNotificationAsRead - err", err);
        });
    },
    acceptRelation(notification) {
      if (notification.is_accept_clicked) return;
      notification.is_accept_clicked = true;
      acceptRequest(notification.notification_type, notification.metadata)
        .then((res) => {
          if (res.data.status === 0) {
            var newNotification = res.data.result.notification;
            notification.is_accept_reject_request = false;
            notification.notification_text = newNotification.notification_text;
            notification.notification_type = newNotification.notification_type;
            notification.id = newNotification.id;
            notification.metadata = newNotification.metadata;
            notification.is_accept_clicked = false;
          } else {
            console.log("acceptRequestClickHandler - err1", res);
            notification.is_accept_clicked = false;
          }
        })
        .catch((err) => {
          console.log("acceptRequestClickHandler - err2", err);
          notification.is_accept_clicked = false;
        });
    },
    rejectRelation(notification) {
      if (notification.is_reject_clicked) return;
      notification.is_reject_clicked = true;
      declineRequest(notification.notification_type, notification.metadata)
        .then((res) => {
          if (res.data.status === 0) {
            var newNotification = res.data.result.notification;
            notification.is_accept_reject_request = false;
            notification.notification_text = newNotification.notification_text;
            notification.notification_type = newNotification.notification_type;
            notification.id = newNotification.id;
            notification.metadata = newNotification.metadata;
            notification.is_reject_clicked = false;
          } else {
            console.log("Reject request - err1", res);
            notification.is_reject_clicked = false;
          }
        })
        .catch((err) => {
          console.log("Reject request - err2", err);
          notification.is_reject_clicked = false;
        });
    },
    showAllRequestsHandler() {
      this.showRequestNotifications = true;
      this.scrollState.scrollDisabled = false;
      this.requestStartLimit = this.limit;
      this.refreshNotifications(true);
    },
    showLessHandler() {
      this.showRequestNotifications = false;
      this.scrollState.scrollDisabled = false;
      this.requestStartLimit = 2;
      this.refreshNotifications(true);
    },
    scrollUpPosts() {
      let el = document.getElementById("_notificationsPage");
      el.scrollToTop(300);
    },
    onScroll(ev) {
      // console.log('onScroll', ev);
      if (ev.detail.scrollTop > 250) {
        this.enableScrollToTop = true;
      } else {
        this.enableScrollToTop = false;
      }
    },
  },
};
</script>