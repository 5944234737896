<template>
  <ion-content>
    <ion-list>
      <ion-item>
        <ion-button
          color="dark"
          fill="clear"
          size="small"
          @click="takePhoto"
          style="text-transform: none"
        >
          Photo
          <ion-icon :icon="icon.imageOutline" slot="start"></ion-icon>
        </ion-button>
      </ion-item>
      <ion-item>
        <ion-button
          color="dark"
          fill="clear"
          size="small"
          @click="showUploadVideo"
          style="text-transform: none"
        >
          Video
          <ion-icon :icon="icon.videocamOutline" slot="start"></ion-icon>
        </ion-button>
      </ion-item>
      <!--<ion-item>
        <ion-button
          color="dark"
          fill="clear"
          size="small"
          @click="showGallery"
          style="text-transform: none"
        >
          Gallery
          <ion-icon :icon="icon.imagesOutline" slot="start"></ion-icon>
        </ion-button>
      </ion-item>   -->
    </ion-list>
    <input
      type="file"
      id="video_file"
      name="video_file"
      accept="video/mp4"
      style="display: none"
      @change="uploadVideo"
      hidden
    />
  </ion-content>
</template>

<script>
import {
  IonContent,
  IonItem,
  IonList,
  IonButton,
  IonIcon,
  //modalController,
  alertController,
  loadingController,
} from "@ionic/vue";

import { defineComponent } from "vue";

import { imageOutline, videocamOutline, /*imagesOutline*/ } from "ionicons/icons";

import { Plugins, CameraResultType, CameraSource } from "@capacitor/core";

import Jimp from "jimp";

const { Camera, /*FileSelector*/ } = Plugins;

//import "capacitor-file-selector"; // Comment out this line when building android/iOS app<br/>

//npm install capacitor-file-selector
import {
  imagePostSize,
  maxUploadSize,
  isAndroid,
  isIOS,
} from "../../../services/utils";

import { presignedURL, uploadImage } from "../../../services/apiCall";
import { getUser } from "../../../services/db";

export default defineComponent({
  name: "ChatMediaUploadOptions",
  props: [/*"post",*/ "photoPost", "parent"],
  components: {
    IonContent,
    IonItem,
    IonList,
    IonButton,
    IonIcon,
  },
  setup() {
    return {
      presignedURL,
      uploadImage,
      imagePostSize,
      maxUploadSize,
      isAndroid,
      isIOS,
    };
  },
  data() {
    return {
      videoFileEle: document.getElementById("video_file"),
      icon: {
        imageOutline,
        videocamOutline,
        /*imagesOutline,*/
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    async showAlert(title, msg) {
      const anAlert = await alertController.create({
        header: title,
        message: msg,
        buttons: ["OK"],
      });
      await anAlert.present();
    },
    async uploadMediaFile(
      fileName,
      fileBlob,
      sizeInBytes,
      loader,
      isVideo = false
    ) {
      if (sizeInBytes > maxUploadSize()) {
        this.showAlert(
          "Media upload error",
          "Maximum upload size exeeded. Max: " +
            maxUploadSize() / 1024 / 1024 +
            "mb."
        );
        loader.dismiss();
        return;
      }

      // convert to file object
      const fileObject = new File([fileBlob], fileName);

      var photoPost = this.photoPost;

      // start the upload process
      presignedURL({ name: fileName })
        .then((res) => {
          console.log("presignedURL", res);
          if (res.data.status === 0) {
            uploadImage(res.data.result.put_url, fileObject)
              .then((res2) => {
                console.log("uploadImage", res2);
                // this.enteredDescription = res.data.result.get_url;
                photoPost.is_photo_post = !isVideo;
                photoPost.is_video_post = isVideo;
                photoPost.photo_url = res.data.result.get_url;

                loader.dismiss();
              })
              .catch((err2) => {
                console.log("uploadImage - err", err2);
                this.showAlert(
                  "Media upload error",
                  "We were unable to upload your media, please try again"
                );
                loader.dismiss();
              });
          }
        })
        .catch((err) => {
          console.log("presignedURL - err", err);
          this.showAlert(
            "Media upload error",
            "We were unable to upload your media, please try again"
          );
          loader.dismiss();
        });
    },
    async takePhoto() {
      console.log("takePhoto");

      this.parent.dismiss();

      const loading = await loadingController.create({
        message: "Processing image...",
      });

      try {
        const imgSize = imagePostSize();

        const photo = await Camera.getPhoto({
          resultType: CameraResultType.Uri,
          source: CameraSource.Prompt,
          width: imgSize,
          preserveAspectRatio: true,
          correctOrientation: true,
        });

        const filterCallback = async (filteredImg) => {
          const filterLoading = await loadingController.create({
            message: "Uploading image...",
          });
          await filterLoading.present();

          // form a unique file name
          let user = this.$store.getters.user;
          if (undefined === user.auth) {
            user = await getUser("currentUser");
          }

          const fileName =
            user.user_id +
            "_" +
            user.auth.token +
            "_" +
            user.auth.session +
            "_" +
            Date.now() +
            "." +
            photo.format;

          var photoPost = this.photoPost;
          photoPost.photo_object_name = fileName;

          // create a image blob
          const imgBlob = await (await fetch(filteredImg)).blob();

          // get the blob size
          const sizeInBytes = imgBlob.size;

          this.uploadMediaFile(fileName, imgBlob, sizeInBytes, filterLoading);
        };

        await loading.present();
        // rescale to 500px
        let imageObj = await Jimp.read(photo.webPath);
        console.log("photo", photo);

        if (
          photo.format === "png" ||
          photo.format === "jpg" ||
          photo.format === "jpeg"
        ) {
          if (imageObj.getWidth() > imgSize) {
            let ratio = imgSize / imageObj.getWidth();
            this.takenImageUrl = await imageObj
              .scale(ratio)
              .getBase64Async("image/" + photo.format);
          } else {
            this.takenImageUrl = await imageObj.getBase64Async("image/png");
          }

          loading.dismiss();
          filterCallback(photo.webPath);
        } else {
          loading.dismiss();
          filterCallback(photo.webPath);
        }
      } catch (err) {
        console.log("photoTaking - err", err);
        this.showAlert(
          "Image upload error",
          "We were unable to upload your image, please try again"
        );
        loading.dismiss();
      }
    },
    async showUploadVideo() {
      console.log("showUploadVideo");

      this.parent.dismiss();

      this.videoFileEle = document.getElementById("video_file");
      this.videoFileEle.click();
    },
    async uploadVideo() {
      console.log("uploadVideo", this.videoFileEle.files);
      if (this.videoFileEle.files.length === 1) {
        let videoFile = this.videoFileEle.files[0];
        let sizeInBytes = videoFile.size;

        const loading = await loadingController.create({
          message: "Uploading video...",
        });

        loading.present();

        let user = this.$store.getters.user;
        if (undefined === user.auth) {
          user = await getUser("currentUser");
        }

        const fileName =
          user.user_id +
          "_" +
          user.auth.token +
          "_" +
          user.auth.session +
          "_" +
          Date.now() +
          ".mp4";

        var photoPost = this.photoPost;
        photoPost.photo_object_name = fileName;

        this.uploadMediaFile(fileName, videoFile, sizeInBytes, loading, true);
      }
    },


//https://github.com/hinddeep/capacitor-file-selector
    async showGallery() {

     /* let multiple_selection = true;
      //let ext = [".jpg",".png",".pdf",".jpeg"] // list of extensions
      let ext = ["MP3", "ImaGes"]; // combination of extensions or category
      //let ext = ["videos", "audios", "images"] // list of all category
      //let ext = ["*"] // Allow any file type
      ext = ext.map((v) => v.toLowerCase());
      let formData = new FormData();

      let selectedFile = await FileSelector.fileSelector({
        multiple_selection: multiple_selection,
        ext: ext,
      });

      this.parent.dismiss();
      //if (this.platform.is("android")) {
      if (isAndroid()) {
        let paths = JSON.parse(selectedFile.paths);
        let original_names = JSON.parse(selectedFile.original_names);
        let extensions = JSON.parse(selectedFile.extensions);
        for (let index = 0; index < paths.length; index++) {
          const file = await fetch(paths[index]).then((r) => r.blob());
          formData.append(
            "myfile[]",
            file,
            original_names[index] + extensions[index]
          );
        }
        //} else if (this.platform.is("ios")) {
      } else if (isIOS()) {
        for (let index = 0; index < selectedFile.paths.length; index++) {
          const file = await fetch(selectedFile.paths[index]).then((r) =>
            r.blob()
          );
          formData.append(
            "myfile[]",
            file,
            selectedFile.original_names[index] + selectedFile.extensions[index]
          );
        }
      } else {
        FileSelector.addListener("onFilesSelected", (data) => {
          console.log("File selected Listener data :: ", JSON.stringify(data));
          for (var i = 0; i < data.length; i++) {
            formData.append(
              "myfile[]",
              data.item(i),
              data.item(i).name + data.item(i).type
            );

            console.log("File selected Listener :: ", data.item[i]);
          }
        });
      }*/
    },
  },
});
</script>
