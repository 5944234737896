
<template>
  <conversations-base-layout page-title="New Group">
    <ion-row>
      <ion-col :size="getColSize()" :offset="getOffsetSize()">
        <form @submit.prevent="handleNewGroup">
          <ion-item no-lines lines="none" class="signup-item-style">
            <ion-label class="left-margin-15px"
              >{{ getStrings(strings, "GroupName") }}:</ion-label
            ><br />
            <ion-input
              v-model="form.groupName"
              id="groupName"
              class="right-margin-15px"
              required
              placeholder="Min 10 chars, Max 50 char, and Min 2 words"
            ></ion-input>
          </ion-item>
          <ion-item no-lines lines="none" class="signup-item-style">
            <ion-label class="left-margin-15px"
              >{{ getStrings(strings, "GroupDescription") }}:</ion-label
            >
            <ion-textarea
              auto-grow="true"
              autocomplete="on"
              autocapitalize="on"
              spellcheck="true"
              rows="1"
              v-model="form.groupDescription"
              id="groupDescription"
              required
              no-padding
              no
              margin
              class="right-margin-15px"
              placeholder="Write group description"
            ></ion-textarea>
          </ion-item>
        </form>
      </ion-col>
    </ion-row>
    <ion-item no-lines lines="none">
      <ion-label
        v-if="selectedPeople.length < 1"
        class="left-margin-15px right-margin-15px ion-text-wrap"
      >
        Please select at least one person to create group
      </ion-label>
      <ion-row v-if="selectedPeople.length > 0">
        <ion-col
          size="12/selectedPeople.length"
          v-for="p in selectedPeople"
          :key="p.id"
        >
          <ion-button
            color="light"
            size="small"
            shape="round"
            class="button_image_overlap"
            style="max-height: 15px; max-width: 15px"
            @click="selectPeople(p)"
          >
            <ion-icon
              slot="icon-only"
              color="dark"
              :icon="icon.closeCircle"
            ></ion-icon>
          </ion-button>
          <ion-avatar size="small" style="max-height: 30px; max-width: 30px">
            <ion-icon slot="icon-only" :icon="icon.personOutline"></ion-icon>
            <ion-img
              v-if="!user.photo"
              src="assets/icon/misc/avatar.svg"
            ></ion-img>
          </ion-avatar>
          <small>{{ p.user }}</small>
        </ion-col>
      </ion-row>
    </ion-item>
    <ion-item no-lines lines="none">
      <ion-button
        slot="end"
        class="right-margin-15px"
        :disabled="selectedPeople.length < 1"
        >Create</ion-button
      >
    </ion-item>
    <ion-row>
      <ion-col>
        <ion-list class="ion-no-padding ion-no-margin">
          <ion-item
            @click="selectPeople(p)"
            lines="none"
            style="align-items: start !important; margin-bottom: 5px"
            v-for="p in people"
            v-bind:key="p.id"
          >
            <ion-avatar
              size="small"
              slot="start"
              style="max-height: 30px; max-width: 30px"
            >
              <ion-icon slot="icon-only" :icon="icon.personOutline"></ion-icon>
              <ion-img
                v-if="!user.photo"
                src="assets/icon/misc/avatar.svg"
              ></ion-img>
            </ion-avatar>
            <ion-label style="margin-top: 0px; line-height: 1.4">
              <b>{{ p.user }}</b>
            </ion-label>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
  </conversations-base-layout>
</template>

<script>
import {
  IonRow,
  IonCol,
  IonInput,
  IonTextarea,
  IonLabel,
  IonItem,
  IonButton,
  IonList,
  IonAvatar,
  IonIcon,
  IonImg,
} from "@ionic/vue";
import { personOutline, closeCircle } from "ionicons/icons";
import { useRouter } from "vue-router";
import {
  isMobileScreen,
  getColSize,
  getOffsetSize,
  logOffApp,
} from "../../services/utils";

import { getStrings } from "../../services/lang";
import { readFromDB } from "../../services/db";

import ConversationsBaseLayout from "../components/base/ConversationsBaseLayout.vue";

export default {
  name: "CreateConversationGroup",
  ionViewWillEnter() {
    this.readDBAndInit();
  },
  components: {
    IonRow,
    IonCol,
    IonInput,
    IonTextarea,
    IonLabel,
    IonItem,
    IonButton,
    IonList,
    IonAvatar,
    IonIcon,
    IonImg,
    ConversationsBaseLayout,
  },
  setup() {
    const router = useRouter();
    return {
      router,
      getStrings,
      getColSize,
      getOffsetSize,
      isMobileScreen,
    };
  },
  data() {
    return {
      form: {
        groupName: "",
        groupDescription: "",
      },
      icon: {
        personOutline,
        closeCircle,
      },
      selectedPeople: [],
      people: [
        {
          id: "1",
          user: "People 1",
          user_dp: "icon1",
          user_id: this.$store.getters.user.tokenized_id,
        },
        {
          id: "2",
          user: "People 2",
          user_dp: "icon2",
          user_id: this.$store.getters.user.tokenized_id,
        },
        {
          id: "3",
          user: "People 3",
          user_dp: "icon3",
          user_id: this.$store.getters.user.tokenized_id,
        },
        {
          id: "4",
          user: "People 4",
          user_dp: "icon4",
          user_id: this.$store.getters.user.tokenized_id,
        },
      ],
    };
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    init() {},

    readDBAndInit(callbackObject = this) {
      try {
        readFromDB(this.$store, true)()
          .then(async () => {
            // read from DB done
            if (
              typeof callbackObject.user === "undefined" ||
              typeof callbackObject.user.user_id === "undefined"
            ) {
              this.logOff(callbackObject);
            } else {
              this.init();
            }
          })
          .catch((err) => {
            console.log("indexdb not available", err);
            this.logOff(callbackObject);
          });
      } catch (err) {
        console.log("indexdb not available", err);
        this.logOff(callbackObject);
      }
    },

    async logOff(callbackObject = this) {
      callbackObject.logOutDisabled = true;

      await logOffApp();

      callbackObject.$store.dispatch("adduser", {});

      callbackObject.router.replace("/login");
    },
    selectPeople(p) {
      console.log("Selected people:", p);
      var idx = this.selectedPeople.findIndex((x) => x.id === p.id);
      if (idx > -1) {
        this.selectedPeople.splice(idx, 1);
      } else {
        this.selectedPeople.push(p);
      }
    },
  },
};
</script>