
<template>
  <conversations-thread-base-layout
    page-title="Conversation Thread"
    :initiateAudioCall="initiateAudioCall"
    :initiateVideoCall="initiateVideoCall"
    @send-message="sendMessage"
  >
    <template v-slot:profile-photo-slot>
      <ion-avatar
        size="small"
        style="min-width: 20px; min-height: 20px; cursor: pointer"
        class="top-margin-15px bottom-margin-15px right-margin-10px"
      >
        <ion-img
          size="small"
          :src="profilePhotoThumbnail"
          @ionError="flagPhotoNotAvailable()"
        ></ion-img>
      </ion-avatar>
      <ion-label> {{ fullName }} </ion-label>
    </template>
    <ion-row>
      <ion-col>
        <conversation-thread
          :conversationThreadList="currentConversationThreadList"
        ></conversation-thread>
      </ion-col>
    </ion-row>
    <ion-row> </ion-row>
  </conversations-thread-base-layout>
</template>

<script>
import {
  IonRow,
  IonCol,
  IonAvatar,
  IonImg,
  IonLabel,
  toastController,
} from "@ionic/vue";
import { videocamOutline, callOutline } from "ionicons/icons";
import { useRouter } from "vue-router";
import {
  isMobileScreen,
  getColSize,
  getOffsetSize,
  logOffApp,
  formatDateHHMM,
} from "../../services/utils";

import { getChatRoomID } from "../../services/microservice";
import {
  //updateDBMessageId,
  addMessageToDB,
  updateDBMessageStatus,
} from "../../services/conversationdb";

import { syncUserConversationThreadList } from "../../services/syncdb";
import { getProfilePicture, getAPIKey } from "../../services/apiCall";
import { readFromDB } from "../../services/db";
import ConversationThread from "../components/ConversationThread.vue";
import ConversationsThreadBaseLayout from "../components/base/ConversationsThreadBaseLayout.vue";
import md5 from "md5";

export default {
  name: "ConversationsPage",

  ionViewWillEnter() {
    this.readDBAndInit();
  },

  components: {
    IonRow,
    IonCol,
    IonAvatar,
    IonImg,
    IonLabel,
    ConversationThread,
    ConversationsThreadBaseLayout,
  },

  setup() {
    const router = useRouter();
    return {
      router,
      getColSize,
      getOffsetSize,
      isMobileScreen,
      getProfilePicture,
      formatDateHHMM,
      getAPIKey,
      syncUserConversationThreadList,
    };
  },

  data() {
    return {
      icon: {
        videocamOutline,
        callOutline,
      },

      profilePhotoThumbnail: "assets/icon/misc/avatar.svg",
      otherTokenizedEntity: this.$route.params.convid,
      fullName: window.atob(this.$route.params.name),

      roomID: undefined,
    };
  },

  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      return this.$store.getters.user;
    },
    currentConversationThreadList() {
      return this.$store.getters.currentConversationThreadList;
    },
    currentConversationSocket() {
      return this.$store.getters.currentConversationSocket;
    },
  },

  methods: {
    async openToast(msg) {
      const toast = await toastController.create({
        message: msg,
        duration: 1500,
      });
      return toast.present();
    },

    async init() {
      this.otherTokenizedEntity = this.$route.params.convid;
      this.tokenizedUser = this.$store.getters.user.tokenized_user;
      
      let newMessageList = await syncUserConversationThreadList(
        this.otherTokenizedEntity,
        this.$store
      );

      await this.createOrFetchRoomID();
      await this.joinConversationSocket();

      this.scrollMessageListToBottom();
      this.getProfileThumbnail();

      this.sendDeliveryNotification(newMessageList);
    },

    async sendDeliveryNotification(newMessages) {
      let socket = this.$store.getters.currentConversationSocket;
      let currentUser = this.$store.getters.user;

      if (socket && socket.connected) {
        let joinInfo = {
          room_id: this.roomID,
          tokenized_user: currentUser.tokenized_user,
          tokenized_entity_id: this.otherTokenizedEntity,
          entity_type: "user",
          user_auth: currentUser,
        };
        
        // TODO: check correctness
        newMessages.map((msg) => {
          socket.emit(
            "message-delivered",
            { 
              receiver_msg: {
                new_id: msg.message_id, 
                tokenized_user: msg.tokenized_user,
              }, 
              sender_msg: {
                new_id: msg.orignial_message_id, 
                tokenized_user: msg.tokenized_entity_id,
              }
            },
            joinInfo
          );
        });
      }
    },

    async createOrFetchRoomID() {
      this.roomID = this.$route.params.roomid
        ? this.$route.params.roomid
        : undefined;

      let currentUser = this.$store.getters.user;

      if (!this.roomID) {
        let joinInfo = {
          room_id: this.roomID,
          tokenized_user: currentUser.tokenized_user,
          tokenized_entity_id: this.otherTokenizedEntity,
          entity_type: "user",
          user_auth: currentUser,
        };
        this.roomID = await getChatRoomID(joinInfo);
        if (typeof this.roomID === "undefined") {
          console.log("unable to creare roomID");
        }
      } else {
        console.log("[old chat room]", this.roomID);
      }
    },

    async joinConversationSocket() {
      let currentUser = this.$store.getters.user;
      let socket = this.$store.getters.currentConversationSocket;

      if (!this.isConnected()) {
        // socket is not connected
        // TODO: handle network issues
        this.openToast('No Network connection, unable to start a conversation');
        return;
      }

      let joinInfo = {
        room_id: this.roomID,
        tokenized_user: currentUser.tokenized_user,
        tokenized_entity_id: this.otherTokenizedEntity,
        entity_type: "user",
        user_auth: currentUser,
      };
      socket.emit("join-room", joinInfo);

      // listen to events
      socket.on("new-message", async (message, joinInfo) => {
        console.log("new-message", message, joinInfo);

        let currentUser = this.$store.getters.user;
        // TODO: route this message to appropriate thread
        // 1. check if this is for current conversation, if yes update memcache, and then update the db
        // 2. else save this to local db for appropriate conversation
        let msgCopy = JSON.parse(JSON.stringify(message));
        // TODO: backend message_id replaced with front-end client_message_id
        msgCopy.message_id = msgCopy.client_message_id;
        if (joinInfo.tokenized_user !== currentUser.tokenized_user) {
          // message receiver
          console.log('Receiver:', joinInfo);
          msgCopy.sent_by_me = false;
          this.$store.dispatch("updateconversation", msgCopy);
          await addMessageToDB(joinInfo.tokenized_user, msgCopy);
          this.$store.dispatch("updateconversationliststatus", {
            tokenized_user: joinInfo.tokenized_user,
            updateData: { last_message: msgCopy.message, last_message_time: msgCopy.time, sent_by_me: false },
          });

          // emit delivery event
          socket.emit(
            "message-delivered",
            { receiver_msg: message.receiver, sender_msg: message.sender },
            joinInfo
            );

        } else {
          // message sender
          msgCopy.sent_by_me = true;

          // 3b. delivery_status update
          console.log('Sender:', joinInfo);
          msgCopy.delivery_status = { Sent: msgCopy.time };
          //console.log("new-messsage/updateconversation/=", msgCopy);
          this.$store.dispatch("updateconversation", msgCopy);
          await addMessageToDB(joinInfo.tokenized_entity_id, msgCopy);
          this.$store.dispatch("updateconversationliststatus", {
            tokenized_user: joinInfo.tokenized_entity_id,
            updateData: { last_message: msgCopy.message, last_message_time: msgCopy.time, sent_by_me: true },
          });
        }

        // 4. fire scroll event
        this.scrollMessageListToBottom();
      });

      // message delivery
      socket.on("message-delivered", (messageIDs, joinInfo) => {
        console.log("message-delivered", messageIDs, joinInfo);

        let currentUser = this.$store.getters.user;
        // TODO: update the delivery status of the message in cache and localDB
        //console.log('message-delivered/currentUser.tokenized_user:', currentUser.tokenized_user);
        if (messageIDs.receiver_msg.tokenized_user === currentUser.tokenized_user) {
          //console.log("I am receiver r:", messageIDs.receiver_msg.tokenized_user, "s:", messageIDs.sender_msg.tokenized_user);
          // receiver
          this.$store.dispatch("updatemessagestatus", {
            message_id: messageIDs.receiver_msg.client_message_id,
            delivery_status: messageIDs.receiver_msg.delivery_status,
          });
          this.$store.dispatch("updateconversationliststatus", {
            tokenized_user: messageIDs.sender_msg.tokenized_user,
            updateData: { 'last_message_delivery_status': 'Delivred' },
          });
          updateDBMessageStatus(messageIDs.sender_msg.tokenized_user, messageIDs.receiver_msg.client_message_id, messageIDs.receiver_msg.delivery_status);
        } else {
          //console.log("I am sender s:", messageIDs.sender_msg.tokenized_user, "r:", messageIDs.receiver_msg.tokenized_user);
          // sender
          this.$store.dispatch("updatemessagestatus", {
            message_id: messageIDs.sender_msg.client_message_id,
            delivery_status: messageIDs.sender_msg.delivery_status,
          });
          this.$store.dispatch("updateconversationliststatus", {
            tokenized_user: messageIDs.receiver_msg.tokenized_user,
            updateData: { 'last_message_delivery_status': 'Delivred' },
          });
          updateDBMessageStatus(messageIDs.receiver_msg.tokenized_user, messageIDs.sender_msg.client_message_id, messageIDs.sender_msg.delivery_status);
        }
      });

      // any other event
      socket.onAny((eventName, eventArgs) => {
        console.log("on-event-any", eventName, eventArgs);
      });
    },
    readDBAndInit(callbackObject = this) {
      try {
        readFromDB(this.$store, true)()
          .then(async () => {
            // read from DB done
            if (
              typeof callbackObject.user === "undefined" ||
              typeof callbackObject.user.user_id === "undefined"
            ) {
              this.logOff(callbackObject);
            } else {
              this.init();
            }
          })
          .catch((err) => {
            console.log("indexdb not available", err);
            this.logOff(callbackObject);
          });
      } catch (err) {
        console.log("indexdb not available", err);
        this.logOff(callbackObject);
      }
    },

    async logOff(callbackObject = this) {
      callbackObject.logOutDisabled = true;

      await logOffApp();

      callbackObject.$store.dispatch("adduser", {});

      callbackObject.router.replace("/login");
    },

    scrollMessageListToBottom() {
      document.getElementById("_conversationThreadContent").scrollToBottom();
      // document.getElementById("_lastConversationItem").scrollIntoView();
    },

    getProfileThumbnail() {
      getProfilePicture(this.otherTokenizedEntity)
        .then((res) => {
          this.profilePhotoThumbnail = res;
        })
        .catch((err) => {
          console.log("Profile URL - err", err);
        });
    },

    flagPhotoNotAvailable() {
      this.profilePhotoThumbnail = "assets/icon/misc/avatar.svg";
    },

    isConnected() {
      let socket = this.$store.getters.currentConversationSocket;
      if (typeof socket === "undefined") return false;
      return socket.connected;
    },

    async sendMessage(messagePayload) {
      console.log("sendMessage", messagePayload);

      // 1. queue this message, put in the the memcache and localdb
      let msgid = this.getClientMessageId(this.otherTokenizedEntity);
      messagePayload.message_id = msgid;
      messagePayload.client_message_id = msgid;
      messagePayload.sent_by_me = true;
      messagePayload.user_id = this.otherTokenizedEntity;
      messagePayload.time = new Date();
      messagePayload.delivery_status = { Queued: Date.now() };

      this.$store.dispatch("updateconversation", messagePayload);

      // add to local database
      // await setMessageLocalList([messagePayload]);
      await addMessageToDB(this.otherTokenizedEntity, messagePayload);

      // 2. async call send message, if success mark as sent, and update the message_id (as id locally)
      // 3. if 2. fails - enable retry
      // 4. async update for delivery

      // acually send the message via socket signal
      let currentUser = this.$store.getters.user;
      let joinInfo = {
        room_id: this.roomID,
        tokenized_user: currentUser.tokenized_user,
        tokenized_entity_id: this.otherTokenizedEntity,
        entity_type: "user",
        user_auth: currentUser,
      };

      let msgCopy = JSON.parse(JSON.stringify(messagePayload));
      let socket = this.$store.getters.currentConversationSocket;
      //console.log('Send message:', joinInfo, msgCopy);

      // TODO: check connection and sending errors
      if (this.isConnected()) {
        socket.emit("send-message", msgCopy, joinInfo);
      } else {
        this.openToast("Not connected to server");
      }

      //console.log(this.$store.getters.currentConversationThreadList);
      // var preview = {};
      // if (messagePayload.type === 'image') {
      //   preview = {
      //       images: [messagePayload.attached_image.url],
      //       attached_image: messagePayload.attached_image,
      //     }
      // }
      // this.conversationThreadList.push({
      //   id: messagePayload.message + "" + Date.now(),
      //   message: messagePayload.message
      //   user_id: this.$store.getters.user.tokenized_id,
      //   time: this.formatDateHHMM(Date.now()),
      //   status: "Sent", // ['Queued', 'Sent', 'Delivered', 'Seen']
      //   sent_by_me: true,
      //   is_previous_user_same: false, // check this,
      //   type: messagePayload.type,
      //   preview: preview,
      //   new_message: true,
      // });
    },

    initiateAudioCall() {
      this.$router.replace(
        "/conversations/call/audio/" + this.otherTokenizedEntity
      );
    },

    initiateVideoCall() {
      this.$router.replace(
        "/conversations/call/video/" + this.otherTokenizedEntity
      );
    },
    getClientMessageId(userTokenizedId) {
      return md5(Date.now() + userTokenizedId + Math.random());
    }
  },
};
</script>