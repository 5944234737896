<template>
  <ion-item class="left-margin-15px" v-if="!inReplyMode">
    <ion-avatar
      size="small"
      slot="start"
      style="max-width: 30px; max-height: 30px"
      v-if="!currentProfilePhoto.photo_thumbnail"
    >
      <ion-icon
          size="small"
          :icon="personCircleOutline"
          style="min-width: 30px; min-height: 30px"
      >
      </ion-icon>
    </ion-avatar>
    <ion-avatar
      size="small"
      slot="start"
      style="max-width: 30px; max-height: 30px"
      v-if="currentProfilePhoto.photo_thumbnail && !localEditMode"
    >
      <ion-img
        size="small"
        :src="currentProfilePhoto.photo_thumbnail"
      ></ion-img>
    </ion-avatar>
  </ion-item>
  <ion-grid class="ion-no-margin ion-no-padding">
    <ion-row class="ion-no-margin ion-no-padding" :class=" !localEditMode ? 'comment_edit_box' : 'box2' ">
      <ion-col class="ion-no-margin ion-no-padding">
        <ion-row v-if="localEditMode" class="ion-no-margin ion-no-padding">
          <ion-col class="ion-no-margin ion-no-padding">
            <ion-item
              no-lines
              lines="none"
              class="ion-no-margin ion-no-padding"
            >
              <ion-label
                size="small"
                slot="start"
                color="secondary"
                class="ion-text-wrap"
              >
                <small>
                  {{ getStrings(strings, "Editing") }} ...
                </small>
              </ion-label>
              <ion-button
                size="small"
                fill="clear"
                slot="end"
                color="danger"
                @click="cancelEdit()"
              >
                <ion-icon size="small" :icon="closeCircleOutline"></ion-icon>
              </ion-button>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-item no-lines lines="none">
          <ion-textarea
            auto-grow="true"
            autocomplete="on"
            autocapitalize="on"
            spellcheck="true"
            inputmode="text"
            rows="1"
            color="dark"
            size="small"
            v-model="commentDescription"
            placeholder="Be constructive and humble."
            class="ion-no-margin ion-no-padding boxInner"
          ></ion-textarea>
        </ion-item>
        <ion-row
          class="ion-no-margin ion-no-padding"
          v-if="commentDescription.length > 0"
        >
          <ion-col class="ion-no-margin ion-no-padding">
            <ion-item
              no-lines
              v-if="commentDescription.length > 0"
              lines="none"
              class="ion-no-margin ion-no-padding"
            >
              <ion-button
                :disabled="commentDescription.length <= 0 || disableComment"
                fill="solid"
                size="small"
                color="primary"
                slot="end"
                shape="round"
                v-if="!inEditMode"
                @click="sendComment()"
              >
                {{ getStrings(strings, "Comment") }}
                <ion-icon slot="end" :icon="chatboxEllipsesOutline"></ion-icon>
                <ion-spinner
                  v-if="disableComment"
                  slot="end"
                  name="dots"
                ></ion-spinner>
              </ion-button>
              <ion-button
                :disabled="commentDescription.length <= 0 || disableComment"
                fill="solid"
                size="small"
                color="primary"
                slot="end"
                shape="round"
                v-if="localEditMode"
                @click="updateTheComment()"
              >
                {{ getStrings(strings, "Update") }}
                <ion-icon slot="end" :icon="chatboxEllipsesOutline"></ion-icon>
                <ion-spinner
                  v-if="disableComment"
                  slot="end"
                  name="dots"
                ></ion-spinner>
              </ion-button>
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import {
  IonItem,
  IonButton,
  IonIcon,
  IonTextarea,
  IonLabel,
  IonRow,
  IonCol,
  IonGrid,
  IonSpinner,
  IonAvatar,
  IonImg,
  alertController,
} from "@ionic/vue";
import { chatboxEllipsesOutline, closeCircleOutline, personCircleOutline } from "ionicons/icons";

import { postComment, updateComment } from "../../services/apiCall.js";

import { formatDate, processNestedComments } from "../../services/utils";
import { getStrings } from "../../services/lang";

export default {
  props: [
    "post",
    "commentToEdit",
    "inEditMode",
    "newComment",
    "inReplyMode",
    "replyToComment",
  ],
  components: {
    IonItem,
    IonButton,
    IonIcon,
    IonTextarea,
    IonLabel,
    IonRow,
    IonCol,
    IonGrid,
    IonSpinner,
    IonAvatar,
    IonImg,
  },
  data() {
    return {
      chatboxEllipsesOutline,
      closeCircleOutline,
      personCircleOutline,
      commentDescription: this.commentToEdit ? this.commentToEdit.content : "",
      formatDate,
      disableComment: false,
      localEditMode: this.inEditMode,
    };
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      return this.$store.getters.user;
    },
    currentProfilePhoto() {
      return this.$store.getters.currentProfilePhoto;
    },
  },
  setup() {
    return {
      getStrings,
    };
  },
  methods: {
    async showAlert(title, msg) {
      const anAlert = await alertController.create({
        header: title,
        message: msg,
        buttons: ["OK"],
      });
      await anAlert.present();
    },
    sendComment() {
      this.disableComment = true;

      // console.log("sendComment", this.post.post_id, this.commentDescription);
      var localReplyComment = this.replyToComment;

      if (localReplyComment) {
        if (localReplyComment.reply_count) {
          localReplyComment.reply_count += 1;
        } else {
          localReplyComment.reply_count = 1;
        }
      }

      postComment(
        this.post.post_id,
        this.commentDescription,
        this.replyToComment ? this.replyToComment.id : -1
      )
        .then(async (res) => {
          // console.log("post comment", res);
          var localPost = this.post;
          if (res.data.status === 0) {
            const postData = {
              content: this.commentDescription,
              post_id: this.post.post_id,
              id: res.data.result.comment_id,
              comment_id: res.data.result.comment_id,
              comment_by_name: "me",
              comment_by: this.user.user_id,
              is_edited: false,
              is_active: true,
              createdAt: new Date().toISOString(),
              reply_to_comment: this.replyToComment
                ? this.replyToComment.id
                : -1,
            };

            localPost.comment_count = this.post.comment_count + 1;
            var localNewComment = this.newComment;
            localNewComment.added = true;

            this.$store.dispatch("addcomment", postData);

            this.commentDescription = "";
            this.disableComment = false;

            localPost.comments = processNestedComments(localPost.comments);

            if (localReplyComment) {
              localReplyComment.reply_count = res.data.result.reply_count;
              localReplyComment.inReplyMode = false;
            }
          } else {
            console.log("post comment err - 1", res.data.error);
          }
        })
        .catch(async (err) => {
          console.log("post comment err - 2", err);
        });
    },
    updateTheComment() {
      // console.log("updateComment");

      updateComment(
        this.post.post_id,
        this.commentToEdit.id,
        this.commentDescription
      )
        .then(async (res) => {
          // console.log("update comment", res);
          if (res.data.status === 0) {
            var editedComment = this.commentToEdit;
            editedComment.content = this.commentDescription;
            editedComment.is_edited = true;
            editedComment.is_active = true;

            this.commentDescription = "";
            this.disableComment = false;

            this.cancelEdit();
          } else {
            console.log("update comment err - 1", res.data.error);
            await this.showAlert(
              "Comment Update Error",
              "Unable to update comment. Error is: " +
                res.data.error.error_message
            );
          }
        })
        .catch(async (err) => {
          console.log("update comment err - 2", err);
          await this.showAlert(
            "Comment Update Error",
            "Unable to update comment, try again."
          );
        });
    },
    cancelEdit() {
      this.localEditMode = false;
      var comment = this.commentToEdit;
      comment.inEditMode = false;
    },
  },
};
</script>