<template>
  <ion-content>
    <ion-list class="ion-padding-horizontal ion-no-margin">
      <ion-item
        v-for="postDraft in postDrafts"
        :key="postDraft.id"
        :value="postDraft.id"
        no-padding
        no-margin
      >
        <ion-content no-padding no-margin>
          <ion-item lines="none" no-lines no-padding no-margin>
            <ion-label size="small" class="ion-text-wrap"
              ><small>{{ truncateWithEllipses(postDraft, 50) }}</small>
            </ion-label>
          </ion-item>
          <ion-item lines="none" no-lines no-padding no-margin>
            <ion-label size="small" color="medium"
              ><small
                ><sup>{{ formatDate(postDraft.createdAt, strings) }}</sup></small
              ></ion-label
            >
          </ion-item>
          <ion-item lines="none" no-lines no-padding no-margin>
            <ion-button
              size="small"
              fill="outline"
              slot="start"
              shape="round"
              @click="loadDraft(postDraft)"
              ><small>{{ getStrings(strings, "Load") }}</small></ion-button
            >
            <ion-button
              fill="clear"
              size="small"
              slot="end"
              shape="round"
              @click="deleteDraft(postDraft)"
            >
              <ion-icon
                color="danger"
                slot="icon-only"
                :icon="trashOutline"
              ></ion-icon>
            </ion-button>
          </ion-item>
          <hr style="border-top: 1px dotted grey" />
        </ion-content>
      </ion-item>
    </ion-list>
  </ion-content>
</template>

<script>
import {
  IonContent,
  IonItem,
  IonList,
  IonButton,
  IonLabel,
  IonIcon,
} from "@ionic/vue";
import { defineComponent } from "vue";

import { useRouter } from "vue-router";
import { trashOutline } from "ionicons/icons";

import { fetchPostDraft, deletePostDraft } from "../../services/apiCall.js";

import { formatDate } from "../../services/utils";
import { getStrings } from "../../services/lang";

export default defineComponent({
  name: "PostDraftList",
  props: ["postDrafts", "parent", "loadDraftCallback"],
  components: { IonContent, IonItem, IonList, IonButton, IonLabel, IonIcon },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
  },
  setup() {
    return {
      getStrings,
    };
  },
  data() {
    const router = useRouter();
    return {
      trashOutline,
      router,
      formatDate,
      fetchPostDraft,
      deletePostDraft,
    };
  },
  methods: {
    truncateWithEllipses(postDraft, max) {
      if (postDraft.preview) return postDraft.preview;
      var text = postDraft.description;
      return text.substr(0, max - 1) + (text.length > max ? "..." : "");
    },
    loadDraft(postDraft) {
      // console.log("loadDraft", postDraft);
      const postDraftObj = postDraft;
      fetchPostDraft(postDraft.id)
        .then((res) => {
          // console.log("fetchPostDraft", res);
          if (res.data.status === 0) {
            var draftPostObj = {
              id: postDraftObj.id,
              draft_id: postDraftObj.id,
              preview: res.data.result.preview,
              preview_type: res.data.result.preview_type,
              post_draft_content: res.data.result.post_draft_content,
              content_type: res.data.result.content_type,
              visibility: res.data.result.visibility,
              topics: res.data.result.topics,
              createdAt: postDraftObj.createdAt,
            };
            this.loadDraftCallback(draftPostObj);
            this.parent.dismiss();
          } else {
            console.log("fetchPostDraft - err1", res);
            this.parent.dismiss();
          }
        })
        .catch((err) => {
          console.log("fetchPostDraft - err2", err);
          this.parent.dismiss();
        });
    },
    deleteDraft(postDraft) {
      // console.log("postDraft", postDraft);
      deletePostDraft({ draft_id: postDraft.id })
        .then((res) => {
          // console.log("deletePostDraft", res);
          if (res.data.status === 0) {
            this.$store.dispatch("deletepostdraft", postDraft);
          }
          this.parent.dismiss();
        })
        .catch((err) => {
          console.log("deletePostDraft - err", err);
          this.parent.dismiss();
        });
    },
  },
});
</script>
