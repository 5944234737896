import { isPlatform } from '@ionic/vue';

import { clearObjectStore, closeDB } from './db';
import { logoff } from './apiCall';
import { getStrings } from "./lang";

import Jimp from "jimp";

const APP_VERSION = "0.6.4";

/** list of all reactions */
export const reactionListArray = [
    { name: "Like", src: "assets/icon/reactions/like.svg" },
    { name: "Heart", src: "assets/icon/reactions/love.svg" },
    { name: "Smile", src: "assets/icon/reactions/haha.svg" },
    { name: "Sad", src: "assets/icon/reactions/sad.svg" },
    { name: "Scream", src: "assets/icon/reactions/scream.svg" },
    { name: "Pray", src: "assets/icon/reactions/pray.svg" },
    { name: "Fire", src: "assets/icon/reactions/fire.svg" },
];

export function appName() {
    return "next";
}

export function appVersion() {
    return APP_VERSION;
}

export function profileImageSize() {
    return 300; // 300 px
}

export function imagePostSize() {
    return 800; // 500 px
}

export function maxUploadSize() {
    return 25 * 1024 * 1024;
}

export function isDesktopScreen() {
    return isPlatform('desktop');
}

export function isTabletScreen() {
    return isPlatform('tablet') && !isDesktopScreen();
}

export function isMobileScreen() {
    return isPlatform('mobile') && !isTabletScreen();
}

export function isAndroid() {
    return isPlatform('android');
}

export function isIOS() {
    return isPlatform('ios');
}

export function isPWA() {
    return isPlatform('pwa');
}

export function getToolButtonSize() {
    if (isDesktopScreen() || isTabletScreen()) return 'default';
    else if (isMobileScreen()) return 'small';
    else return 'default';
}

export function defaultPostsPerFetch() {
    return 5;
}

export function defaultNotificationsPerFetch() {
    return 10;
}

export function defaultMaxPosts() {
    return 100;
}

export function defaultMaxNotifications() {
    return 100;
}

export function getFullColSize() {
    if (isDesktopScreen() || isTabletScreen()) {
        return 6;
    } else {
        return 12;
    }
}

export function getMargin(twoXPush) {
    if (isDesktopScreen()) {
        if (twoXPush) {
            return "margin-top: 180px;";
        } else {
            return "margin-top: 100px;";
        }
    } else {
        if (twoXPush) {
            return "margin-top: 20px;";
        } else {
            return "margin-top: 10px;";
        }
    }
}

export function getTitleFont() {
    if (isDesktopScreen()) {
        return "font-size: 25px; font-weight: 200;";
    } else {
        return "font-size: 20px; font-weight: 400;"
    }
}

export function getColSize(deskAndTabletDifferent = false) {
    var colSize = 12;

    if (isDesktopScreen()) {
        colSize = deskAndTabletDifferent ? 8 : 6;  // 8 : 6
    } else if (isTabletScreen()) {
        colSize = 8;
    } else if (isMobileScreen()) {
        colSize = 12;
    }

    // console.log('col size ', colSize);

    return colSize;
}

export function getOffsetSize(deskAndTabletDifferent = false) {
    var colOffset = 0;

    if (isDesktopScreen()) {
        colOffset = deskAndTabletDifferent ? 2 : 3; // 2 : 3
    } else if (isTabletScreen()) {
        colOffset = 2;
    } else if (isMobileScreen()) {
        colOffset = 0;
    }

    // console.log('offset size ', colOffset);

    return colOffset;
}

var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export function formatDate(dateStr, strObj = undefined) {
    var dateObj = new Date(dateStr);

    var hours = dateObj.getHours();
    if (hours < 10) hours = "0" + hours;

    var minutes = dateObj.getMinutes();
    if (minutes < 10) minutes = "0" + minutes;

    var monthName = months[dateObj.getMonth()]
    if (typeof (strObj) !== undefined) {
        monthName = getStrings(strObj, monthName);
    }

    var newDateFormat = monthName + " " + dateObj.getDate() + " " + dateObj.getFullYear() + ", " + hours + ":" + minutes;

    return newDateFormat;
}

export function formatDateDDMM(dateStr) {
    var dateObj = new Date(dateStr);

    var newDateFormat = dateObj.getDate() + " " + months[dateObj.getMonth()];

    return newDateFormat;
}

export function formatDateYYYY(dateStr) {
    var dateObj = new Date(dateStr);

    var newDateFormat = dateObj.getFullYear();

    return newDateFormat;
}

export function formatShortDate(dateStr) {
    var dateObj = new Date(dateStr);

    var newDateFormat = months[dateObj.getMonth()] + " " + dateObj.getDate() + " " + dateObj.getFullYear();

    return newDateFormat;
}

export function formatDateHHMM(dateStr) {
    var dateObj = new Date(dateStr);

    var hours = dateObj.getHours();
    if (hours < 10) hours = "0" + hours;

    var minutes = dateObj.getMinutes();
    if (minutes < 10) minutes = "0" + minutes;

    return hours + ":" + minutes;
}

export function formatChatTime(timeval) {
    if (typeof (timeval) === 'undefined') return "..";
    if (timeval == 0) return "..";

    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    var lastSeen = new Date(timeval).getTime();
    var currentTime = new Date().getTime();
    var diffTime = currentTime - lastSeen;

    var diffSeconds = diffTime / 1000;
    if (diffSeconds < 1) return "1 sec ago";
    if (diffSeconds < 60) return "" + parseInt(diffSeconds) + " sec ago";

    var diffMinutes = diffTime / (60 * 1000);

    if (diffMinutes < 60) return "" + parseInt(diffMinutes) + " min ago";

    var diffHours = diffMinutes / 60;
    if (diffHours < 24) return "" + parseInt(diffHours) + " hrs ago";

    var dateObj = new Date(timeval);

    var hours = dateObj.getHours();
    var dayca = "AM";
    if (hours > 12) {
        dayca = "PM";
        hours = hours - 12;
    }

    var minutesstr =
        dateObj.getMinutes() < 10
            ? "0" + dateObj.getMinutes()
            : dateObj.getMinutes();
    var hoursstr = hours < 10 ? "0" + hours : hours;

    var newDateFormat = hoursstr + ":" + minutesstr + " " + dayca;
    //if(diffHours < 24) return newDateFormat;
    if (diffHours < 48) {
        return "Y'day " + newDateFormat;
    }

    if (diffHours < 168) { // A Week
        return days[dateObj.getDay()] + " " + newDateFormat;
    }

    newDateFormat = months[dateObj.getMonth()] + " " + dateObj.getDate() + ", " + newDateFormat;
    return newDateFormat;
}

export function formatChatTimeForUL(timeval) {
    if (typeof (timeval) === 'undefined') return "..";
    if (timeval == 0) return "..";

    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const monthsShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    var lastSeen = new Date(timeval).getTime();
    var currentTime = new Date().getTime();
    var diffTime = currentTime - lastSeen;

    //var diffSeconds = diffTime / 1000;
    //if (diffSeconds < 120) return "online";

    var diffMinutes = diffTime / (60 * 1000);

    if (diffMinutes < 60) return "" + parseInt(diffMinutes) + " min ago";

    var diffHours = diffMinutes / 60;
    if (diffHours < 24) return "" + parseInt(diffHours) + " hrs ago";

    var dateObj = new Date(timeval);

    var hours = dateObj.getHours();
    var dayca = "AM";
    if (hours > 12) {
        dayca = "PM";
        hours = hours - 12;
    }

    //var minutesstr = dateObj.getMinutes() < 10 ? "0" + dateObj.getMinutes() : dateObj.getMinutes();

    var hoursstr = hours < 10 ? "0" + hours : hours;

    var newDateFormat = hoursstr + /*":" + minutesstr +*/ " " + dayca;
    if (diffHours < 48) {
        return "Y'day ";//+newDateFormat;
    }

    if (diffHours < 168) { // A Week
        return days[dateObj.getDay()] + " " + newDateFormat;
    }

    newDateFormat = monthsShort[dateObj.getMonth()] + " " + dateObj.getDate() /*+ ", " + newDateFormat*/;
    return newDateFormat;
}

export function isMinAge(dob) {
    var today = (new Date()).getTime();
    var birthDate = new Date(dob).getTime();
    var diffTime = today - birthDate;
    var inYears = diffTime / (1000 * 60 * 60 * 24 * 365);

    // console.log('isMinAge', inYears);

    return (inYears >= 13);
}

export function lastMessageTime(dateStr) {
    var dateObj = new Date(dateStr);
    var todayDate = new Date();
    var msgTime = '';
    var hrs = (todayDate.getTime() - dateObj.getTime()) / 1000 / 60 / 60;
    if (hrs <= 24) {
        msgTime =  dateObj.getHours() + ":" + dateObj.getMinutes();
    } else if (hrs <= 48) {
        msgTime = "Yesterday"
    } else {
        msgTime = dateObj.getDate() + "/" + (dateObj.getMonth() + 1) + "/" + dateObj.getFullYear();
    }
    return msgTime;
}

export function getElapsedTime(dateStr) {
    var dateObj = new Date(dateStr);
    var todayDate = new Date();
    var seconds = (todayDate.getTime() - dateObj.getTime()) / 1000;

    var elapsedTime = Math.round(seconds) + ' Sec';
    if (seconds > 60) {
        var minutes = seconds / 60;
        elapsedTime = Math.round(minutes) + ' Minutes'
        if (minutes > 60) {
            var hours = minutes / 60;
            elapsedTime = Math.round(hours) + ' Hours'
            if (hours > 24) {
                var days = hours / 24;
                elapsedTime = Math.round(days) + ' Days'
                if (days > 30) {
                    var months = days / 30;
                    elapsedTime = Math.round(months) + ' Months';
                    if (months > 12) {
                        var years = months / 12;
                        elapsedTime = Math.round(years) + ' Years';
                    }
                }
            }
        }
    }

    return elapsedTime;
}

export function formatMysqlDate(dateStr) {

    var dateObj = new Date(dateStr);

    var hoursstr = dateObj.getHours() < 10 ? "0" + dateObj.getHours() : dateObj.getHours();
    var minutesstr = dateObj.getMinutes() < 10 ? "0" + dateObj.getMinutes() : dateObj.getMinutes();
    var secondstr = dateObj.getSeconds() < 10 ? "0" + dateObj.getSeconds() : dateObj.getSeconds();

    var timestr = hoursstr + ":" + minutesstr + ":" + secondstr;

    var monthstr = (dateObj.getMonth() + 1) < 10 ? "0" + (dateObj.getMonth() + 1) : (dateObj.getMonth() + 1);
    var datestr = dateObj.getDate() < 10 ? "0" + dateObj.getDate() : dateObj.getDate();

    var newDateFormat = dateObj.getFullYear() + "-" + monthstr + "-" + datestr + " " + timestr;

    return newDateFormat;
}

export function urlify(text) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function (url) {
        return '<a class="item-text-wrap dont-break-out" style="color:#3880ff;" href="' + url + '" target="_susamvadLink"><small class="item-text-wrap dont-break-out">' + url + '</small></a> <small style="color:orange"><sub>[Note: external link, click with caution]</sub></small>';
    });
}

export function extracURLs(text) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;

    var urlList = [];
    text.replace(urlRegex, function (url) {
        urlList.push(url);
    });

    return urlList;
}

export function make2CharName(userName) {
    if (typeof(userName) === 'undefined') return '';
    var userNames = userName.toUpperCase().split(' ');
    var shortName = userName.toUpperCase().substring(0, 2);
    if (userNames.length > 1) {
        shortName = userNames[0][0] + userNames[1][0];
    }
    return shortName;
}

export function tagAndMentions(text) {
    var hashTagMentionRegex = /\s([@#][\w_-]+)/g;
    return text.replace(hashTagMentionRegex, function (tag) {
        return '<span style="color:#3880ff;">' + tag + '</span>';
    });
}

export function preFormat(text) {
    text = text.replaceAll("\n", "<br/>");
    text = text.replaceAll("\r", "<br/>");
    return '<span class="item-text-wrap ion-text-wrap dont-break-out line_hight_one_point_four">' + text + '</span>';
}

export function checkPasswordRules(password) {
    // 8 char min, one special char, one upper char, and one numeric

    var passwordRules = {};

    passwordRules.minEightChars = password.length >= 8;
    passwordRules.minOneNumeric = /[0-9]/.test(password);
    passwordRules.minOneAlphabet = /[A-z]/.test(password);
    //passwordRules.minOneUpperCase = /[A-Z]/.test(password);
    //passwordRules.minOneSpecialChar = /[#$%@&!^*~]/.test(password);

    return passwordRules;
}

export function suggestTags(text) {
    const count = (str, pattern) => {
        const re = pattern;
        return ((str || '').match(re) || []).length;
    };

    // simple tag suggestions 
    // TODO: this should be replaced with a leaning model
    var tagSuggest = {
        'Space': (v) => count(v, /\bspace\b|\bplanet\b/ig),
        'COVID': (v) => count(v, /\bcovid\b|\bcororna\b|\boxygen\b|\bbed\b/ig),
        'Tech': (v) => count(v, /\bios\b|\bandroid\b|\bmicrosoft\b|\bapple\b|\bgoogle\b|\btechnology\b|\btech\b/ig),
        'Politics': (v) => count(v, /\bpolitics\b/ig),
    };

    var tagKeys = Object.keys(tagSuggest);

    var tagCounts = tagKeys.map((x) => {
        return [x, tagSuggest[x](text)];
    }).filter((x) => x[1] > 0).sort((a, b) => a[1] > b[1]);

    console.log('suggestTags', text);

    return tagCounts;
}

/** ref: https://stackoverflow.com/questions/34818020/javascript-regex-url-extract-domain-only/34818545 */
export function domainFromURL(url) {
    if (typeof(url) === 'undefined') return '';
    let result;
    let exp = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?=]+)/im;
    let match = url.match(exp);
    if (match) {
        result = match[1];
        match = result.match(/^[^.]+\.(.+\..+)$/)
        if (match) {
            result = match[1];
        }
    }
    // console.log('domainFromURL', result);
    return result
}

export function processPreview(post) {
    var previewData = {};

    if (post.is_active && post.preview_type === 'Document') {
        previewData = JSON.parse(post.preview);

        var imgSrc = previewData.images;
        if (typeof imgSrc === "undefined") imgSrc = previewData.img;
        else imgSrc = imgSrc[0];

        if (typeof imgSrc === "undefined") imgSrc = previewData.favicons[0];

        previewData['imgSrc'] = imgSrc;

        var siteName = domainFromURL(previewData.siteName);
        if (typeof siteName === "undefined") siteName = domainFromURL(previewData.domain);
        if (typeof siteName === "undefined") siteName = domainFromURL(previewData.url);

        previewData['siteName'] = siteName;

        if (typeof previewData.title === "undefined") previewData.title = "";
        if (typeof previewData.description === "undefined") {
            previewData.description = "";
        }
    } else if (post.is_active && post.preview_type === 'Media') {
        if (!post.is_active) return post.preview;

        previewData = JSON.parse(post.preview);
        previewData['imgSrc'] = previewData.images[0];

        // console.log('image_object', previewData);

        if (previewData['image_object_name'].endsWith(".mp4")) {
            previewData['is_video_post'] = true;
        } else {
            previewData['is_video_post'] = false;
        }
    }

    return previewData;
}

export function processNestedComments(commentList) {
    var newCommentList = [];

    // first get top level comments
    newCommentList = commentList.filter((comment) => comment.reply_to_comment === -1);

    try {
        // the add any replies to the top level comment list
        commentList.map((comment) => {
            if (comment.reply_to_comment !== -1) {
                var replyToComment = newCommentList.filter((x) => x.id === comment.reply_to_comment)[0];
                if (!replyToComment['replies']) replyToComment['replies'] = []
                replyToComment['replies'].unshift(comment); // add this to replies list
            }
        })
    } catch (err) {
        return commentList;
    }

    return newCommentList;
}

/** ref: https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript */
export function b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
}

export async function logOffApp() {
    try {
        await logoff();
        await clearObjectStore();
        await closeDB();
        localStorage.setItem("defaultRoute", "/login");
        localStorage.removeItem("user");
    } catch (err) {
        console.log('logoff err', err);
    }
}

export function isValidEmail(email) {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
}

export function isValidPhone(phone) {
    return /^\d+$/.test(phone);
}

export async function scaleImage(imgFile, fileName) {
    const imgSize = imagePostSize();
    try {
            let imageObj = await Jimp.read(await imgFile.arrayBuffer());
            if (imageObj.getWidth() > imgSize) {
            let ratio = imgSize / imageObj.getWidth();
            let scaledObject = new Buffer(await imageObj.scale(ratio).quality(80).getBufferAsync(Jimp.AUTO));
            return new File([scaledObject], fileName);
        } else {
            return new File([imgFile], fileName);
        }
    } catch (ignored) {
        ignored;
        console.log(ignored);
        return new File([imgFile], fileName);
    }
}

