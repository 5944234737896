<template>
  <ion-grid class="animate-fading-2">
    <span>
      <ion-row>
        <ion-col
          :size="getColSize(false)"
          :offset="getOffsetSize(false)"
          class="ion-no-padding ion-no-margin"
        >
          <ion-list>
            <ion-item
              v-for="ff in filteredUserList(userFFList)"
              :key="ff"
              :ff="ff"
            >
              <ion-grid
                class="ion-no-padding ion-no-margin"
                @click="navigateProfile(ff)"
              >
                <ion-row
                  v-if="isItemSelected(ff)"
                  class="ion-no-padding ion-no-margin chat_user_selected"
                  nowrap
                >
                  <ion-col
                    size="auto"
                    class="ion-no-padding ion-no-margin"
                    style="margin-left: 4px"
                  >
                    <ion-item class="ion-no-padding ion-no-margin" lines="none">
                      <ion-avatar
                        slot="start"
                        size="large"
                        style="
                          min-width: 50px;
                          min-height: 50px;
                          cursor: pointer;
                        "
                        @click="navigateProfile(ff)"
                      >
                        <ion-img
                          v-if="!ff.profile_photo_available && !ff.is_group"
                          size="large"
                          src="assets/icon/misc/avatar.svg"
                        ></ion-img>
                        <ion-img
                          v-if="!ff.profile_photo_available && ff.is_group"
                          size="large"
                          src="assets/icon/misc/group.svg"
                        ></ion-img>
                        <ion-img
                          @ionError="flagProfilePhotoNotAvailable(ff)"
                          v-if="
                            ff.photo_thumbnail && ff.profile_photo_available
                          "
                          size="large"
                          :src="ff.photo_thumbnail"
                        ></ion-img>
                      </ion-avatar>
                    </ion-item>
                  </ion-col>
                  <ion-col>
                    <ion-grid class="ion-no-margin">
                      <ion-row class="ion-no-padding ion-no-margin" nowrap>
                        <ion-col
                          class="ion-no-padding ion-no-margin"
                          style="margin-top: 6px"
                        >
                          <ion-item lines="none">
                            <ion-text>
                              <b
                                style="
                                  cursor: pointer;
                                  white-space: nowrap;
                                  text-overflow: ellipsis;
                                "
                                >{{ getFormattedUserName(ff.full_name) }}</b
                              >
                            </ion-text>
                          </ion-item>
                        </ion-col>
                      </ion-row>
                      <ion-row nowrap>
                        <ion-col class="ion-no-padding ion-no-margin">
                          <ion-item lines="none">
                            <ion-text style="margin-top: 5px">
                              <span
                                style="
                                  cursor: pointer;
                                  white-space: nowrap;
                                  text-overflow: ellipsis;
                                  font-size: 14px;
                                "
                                >{{
                                  getFormatedRecentText(ff.recent_text)
                                }}</span
                              >
                            </ion-text>
                          </ion-item>
                        </ion-col>
                      </ion-row>
                    </ion-grid>
                  </ion-col>
                </ion-row>

                <!--  Not Selected  -->
                <ion-row
                  v-if="!isItemSelected(ff)"
                  class="ion-no-padding ion-no-margin"
                  nowrap
                >
                  <ion-col
                    size="auto"
                    class="ion-no-padding ion-no-margin"
                    style="margin-left: 4px"
                  >
                    <ion-item class="ion-no-padding ion-no-margin" lines="none">
                      <ion-avatar
                        slot="start"
                        size="large"
                        style="
                          min-width: 50px;
                          min-height: 50px;
                          cursor: pointer;
                        "
                        @click="navigateProfile(ff)"
                      >
                        <ion-img
                          v-if="!ff.profile_photo_available && !ff.is_group"
                          size="large"
                          src="assets/icon/misc/avatar.svg"
                        ></ion-img>
                        <ion-img
                          v-if="!ff.profile_photo_available && ff.is_group"
                          size="large"
                          src="assets/icon/misc/group.svg"
                        ></ion-img>
                        <ion-img
                          @ionError="flagProfilePhotoNotAvailable(ff)"
                          v-if="
                            ff.photo_thumbnail && ff.profile_photo_available
                          "
                          size="large"
                          :src="ff.photo_thumbnail"
                        ></ion-img>
                      </ion-avatar>
                    </ion-item>
                  </ion-col>
                  <ion-col>
                    <ion-grid class="ion-no-margin">
                      <ion-row class="ion-no-padding ion-no-margin" nowrap>
                        <ion-col
                          class="ion-no-padding ion-no-margin"
                          style="margin-top: 6px"
                        >
                          <ion-item lines="none">
                            <ion-text>
                              <b
                                style="
                                  cursor: pointer;
                                  white-space: nowrap;
                                  text-overflow: ellipsis;
                                "
                                >{{ getFormattedUserName(ff.full_name) }}</b
                              >
                            </ion-text>
                          </ion-item>
                        </ion-col>
                      </ion-row>
                      <ion-row nowrap>
                        <ion-col class="ion-no-padding ion-no-margin">
                          <ion-item lines="none">
                            <ion-text style="margin-top: 5px">
                              <span
                                style="
                                  cursor: pointer;
                                  white-space: nowrap;
                                  text-overflow: ellipsis;
                                  font-size: 14px;
                                "
                                >{{
                                  getFormatedRecentText(ff.recent_text)
                                }}</span
                              >
                            </ion-text>

                            <ion-avatar
                              v-if="
                                ff.new_chat_count > 0 && ff.new_chat_count < 100
                              "
                              slot="end"
                              size="small"
                              class="ion-no-padding ion-no-margin"
                              style="
                                background-color: #2fdf75;
                                max-width: 22px;
                                max-height: 22px;
                              "
                            >
                              <ion-label
                                style="color: black"
                                class="ion-text-center"
                                ><small>{{
                                  ff.new_chat_count
                                }}</small></ion-label
                              >
                            </ion-avatar>
                            <ion-badge
                              v-if="ff.new_chat_count > 99"
                              color="success"
                              slot="end"
                              size="small"
                              style="border-radius: 100%"
                            >
                              <ion-label
                                style="color: black"
                                class="ion-text-center"
                                ><small>{{
                                  ff.new_chat_count
                                }}</small></ion-label
                              >
                            </ion-badge>
                          </ion-item>
                        </ion-col>
                      </ion-row>
                    </ion-grid>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-item>
          </ion-list>
        </ion-col>
      </ion-row>
    </span>
  </ion-grid>
</template>

<script>
import {
  IonItem,
  IonGrid,
  IonRow,
  IonAvatar,
  IonImg,
  IonList,
  IonCol,
  IonText,
  IonBadge,
  IonLabel,
  toastController,
} from "@ionic/vue";

import {
  getColSize,
  getOffsetSize,
  formatChatTimeForUL,
} from "../../services/utils.js";

import { useRouter } from "vue-router";

import { caretForwardOutline, caretDownOutline } from "ionicons/icons";

export default {
  props: [
    "tokenizedUser",
    "isSelfUser",
    "userFFList",
    "isForwarding",
    "forwardData",
  ],
  components: {
    IonItem,
    IonGrid,
    IonRow,
    IonAvatar,
    IonImg,
    IonList,
    IonCol,
    IonText,
    IonBadge,
    IonLabel,
  },

  async ionViewDidEnter() {
    console.log("selection UserList page did enter", this.user);
  },
  ionViewDidLeave() {
    console.log("selection UserList page did leave");
  },
  ionViewWillEnter() {
    console.log("selection UserList page will enter");
  },
  ionViewWillLeave() {
    console.log("selection UserList page will leave");
  },

  mounted() {
    console.log("selection  UserList -- Mounted!   :", this.isForwarding);
    //this.init();
  },

  data() {
    const router = useRouter();

    return {
      router,
      caretForwardOutline,
      caretDownOutline,
    };
  },
  setup() {
    return {
      getColSize,
      getOffsetSize,
      formatChatTimeForUL,
    };
  },
  methods: {
    async navigateProfile(ff) {
      if (this.isForwarding) {
        if (ff.isSelected) {
          console.log("Navigate Profile --- Profle >> false");
          ff["isSelected"] = false;
        } else {
          console.log("Navigate Profile --- Profle >> true");
          ff["isSelected"] = true;
        }
      } else {
        if (ff.is_group) {
          //this.openToast("Group Chat coming soon...");
          await this.router.push("/groupchat/" + ff.tokenized_group);
        } else {
          await this.router.push("/chat/" + ff.tokenizedUser);
        }
      }
    },

    getFormatedRecentText(recentTextObj) {
      if (undefined === recentTextObj) return "No recent text";
      if (undefined === recentTextObj.data) return "No recent text";

      //Check Condition for content of data recently posted and show msg   e.g. Video
      let txt = recentTextObj.data;
      if (0 === txt.length) {
        if ("video_post" === recentTextObj.msgtype) {
          txt = "shared Video...";
        } else if ("photo_post" === recentTextObj.msgtype) {
          txt = "shared Image...";
        } else if ("audio_post" === recentTextObj.msgtype) {
          txt = "shared Audio...";
        }
      }

      if (txt.length > 30) txt = txt.substring(0, 30) + "...";

      return txt;
    },

    getFormattedUserName(usrName) {
      if (usrName.length > 18) usrName = usrName.substring(0, 15) + "...";

      return usrName;
    },

    filteredUserList(listObj) {
      return listObj
        .filter((s) => s.full_name.includes("")) //TODO : Filter based on input String
        .sort((b, a) => new Date(a.createdAt) - new Date(b.createdAt));
    },

    getNewChatCount(countitem) {
      if (countitem) {
        console.log("New Item Count :", countitem);
        if (0 === countitem) return "";

        return countitem;
      }

      return 299;
    },

    async openToast(msg) {
      const toast = await toastController.create({
        message: msg,
        duration: 5000,
      });
      return toast.present();
    },

    flagProfilePhotoNotAvailable(user) {
      user.profile_photo_available = false;
    },

    isItemSelected(user) {
      if (user.isSelected) return true;

      return false;
    },

    getSelectedUsersList() {
      var selectionList = [];
      this.userFFList.forEach((obj) => {
        if (obj.isSelected) {
          if (obj.is_group) {
            let putObj = { token: obj.tokenized_group, is_group: true };
            selectionList.push(putObj);
          } else {
            let putObj = { token: obj.tokenizedUser, is_group: false };
            selectionList.push(putObj);
          }
        }
      });

      return selectionList;
    },
  }, //END - methods
  //group_name
};
</script>