<template>
  <base-layout
    page-title="Conversations"
    :enableHeaderShrink="true"
    contentID="_conversationsPage"
  >
      

  </base-layout>
</template>

<script>
import {
  //IonIcon,
  //IonButton,
  //IonLoading,
  //IonSearchbar,
  //IonItem,
  //IonLabel,
  //IonCol,
  //IonRow,
  //IonGrid,
  //actionSheetController,
  // popoverController,
} from "@ionic/vue";
import {
  closeCircle,
  search,
  ellipsisVertical,
  //addOutline,
  //closeOutline,
} from "ionicons/icons";
import {
  getColSize,
  getOffsetSize,
  isMobileScreen,
} from "../../services/utils";

import { useRouter } from "vue-router";
import { getUser } from "../../services/db";
//import { friendsInChat, getUserEncryptionKeys } from "../../services/apiCall";
//import ChatUserList from "../components/ChatUserList.vue";
//import CreateNewGroupForm from "../../components/groups/CreateNewGroupForm.vue";
import { getStrings } from "../../services/lang";

export default {
  name: "InfoContactPage",

  components: {
    //IonIcon,
    //IonButton,
    //IonItem,
    //IonCol,
    //IonRow,
    //IonGrid,
    //IonLabel,
    //IonLoading,
    //ChatUserList,
    //IonSearchbar,
    //CreateNewGroupForm,
  },
  ionViewWillLeave() {
    console.log("ChatUsers page will leave");
    if (this.friendsRefreshCallback !== null)
      clearInterval(this.friendsRefreshCallback);
  },

  setup() {
    return {
      getColSize,
      getOffsetSize,
      isMobileScreen,
      getStrings,
    };
  },
  data() {
    const router = useRouter();
    return {
      router,
      icon: { closeCircle, search, ellipsisVertical },


    };
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    theFriendsList() {
      return this.$store.getters.friends;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  async ionViewDidEnter() {
    this.currentUser = await getUser("currentUser");
    this.checkCurrentUserEncryptionkey();
    this.loadFriends();

    this.init();
  },

  methods: {
    addFriend(friendData) {
      this.$store.dispatch("addfriend", friendData);
    },
    init() {
      this.friendsRefreshCallback = setInterval(this.updateFriends, 30000);
    },
   
  },
};
</script>