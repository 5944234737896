<template>
  <ion-item lines="none" no-lines class="ion-no-padding ion-no-margin">
    <ion-label class="left-margin-15px">Select Notification Mode</ion-label>
    <ion-select
      placeholder="Select"
      v-model="currentMode"
      interface="popover"
      @ionChange="fetchNotificationEvents"
      class="ion-padding-horizontal ion-no-margin"
    >
      <ion-select-option
        class="ion-padding-horizontal ion-no-margin"
        v-for="mode in modes"
        v-bind:key="mode.mode_type"
        :value="mode.mode_type"
      >{{ mode.display_text }}
      </ion-select-option>
    </ion-select>
  </ion-item>
  <ion-list v-if="events.length > 0">
    <ion-item
      lines="none"
      no-lines
      class="ion-padding-horizontal ion-no-margin"
      v-for="event in events"
      v-bind:key="event.event_type"
      :value="event.event_type"
    >
      <ion-label>{{ event.description }}</ion-label>
      <ion-checkbox slot="end" v-model="event.is_enabled" @ionChange="saveNotificationSetting(event)"></ion-checkbox>
    </ion-item>
  </ion-list>
</template>

<script>
import {
  IonSelect,
  IonSelectOption,
  IonItem,
  IonLabel,
  IonList,
  IonCheckbox,
} from "@ionic/vue";
import { defineComponent } from "vue";

import {
  listNotificationModes,
  listNotificationEvents,
  updateNotificationSettings,
} from "../../services/apiCall.js";

export default defineComponent({
  name: "NotificationSettings",
  components: {
    IonSelect,
    IonSelectOption,
    IonItem,
    IonLabel,
    IonList,
    IonCheckbox,
  },
  data() {
    return {
      currentMode: "",
      modes: [],
      events: [],
    };
  },
  mounted() {
    listNotificationModes()
      .then((res) => {
        console.log("listNotificationModes", res);
        if (res.data.status === 0) {
          this.modes = res.data.result.modes;
        }
      })
      .catch((err) => {
        console.log("listNotificationModes - err", err);
      });
  },
  methods: {
    fetchNotificationEvents(ev) {
      console.log("fetchNotificationEvents", ev);
      listNotificationEvents({ notification_mode: this.currentMode })
        .then((res) => {
          console.log("listNotificationEvents", res);
          if (res.data.status === 0) {
            this.events = res.data.result.events;
          }
        })
        .catch((err) => {
          console.log("listNotificationEvents - err", err);
        });
    },
    saveNotificationSetting(event) {
      console.log('saveNotificationSetting', event);
      updateNotificationSettings({event_type: event.event_type, event_mode: this.currentMode, is_enabled: event.is_enabled}).then((res) => {
        console.log('updateNotificationSettings', res);
      }).catch((err) => {
        console.log('updateNotificationSettings - err', err);
      });
    },
  },
  setup() {
    return {
      listNotificationModes,
      listNotificationEvents,
      updateNotificationSettings,
    };
  },
});
</script>
