<template>
  <ion-item class="single-comment-item">
    <ion-avatar
      slot="start"
      style="max-width: 30px; max-height: 30px; cursor: pointer"
      class="comment-avatar"
      v-if="!comment.photo_thumbnail"
      @click="this.$router.push('/user/profile/' + comment.tokenized_user)"
    >
      <ion-icon
        size="small"
        :icon="personCircleOutline"
        style="min-width: 30px; min-height: 30px"
      >
      </ion-icon>
    </ion-avatar>
    <ion-avatar
      slot="start"
      style="max-width: 30px; max-height: 30px; cursor: pointer"
      class="comment-avatar"
      v-if="comment.photo_thumbnail"
      @click="this.$router.push('/user/profile/' + comment.tokenized_user)"
    >
      <ion-img :src="comment.photo_thumbnail"></ion-img>
    </ion-avatar>
    <ion-grid
      class="ion-padding-start ion-no-margin line_hight_one comment_grid"
    >
      <ion-row class="center_align_row ion-no-padding ion-no-margin">
        <ion-col color="primary" class="ion-no-padding ion-no-margin">
          <ion-label
            color="dark"
            @click="
              this.$router.push('/user/profile/' + comment.tokenized_user)
            "
          >
            <small
              ><b style="cursor: pointer"> {{ comment.comment_by_name }}</b
              ><small style="color: #808080"
                >&nbsp; {{ formatDate(comment.createdAt, strings) }}</small
              >
            </small>
          </ion-label>
        </ion-col>
        <ion-col class="right_align_col ion-no-padding ion-no-margin">
          <ion-button
            color="medium"
            size="small"
            fill="clear"
            class="button_nopadding button_minimal"
            v-if="comment.is_edited && comment.is_active"
          >
            <small><b>(edited)</b></small>
          </ion-button>
          <reaction-by-button
            size="small"
            :commentId="comment.id"
            :reactionCount="comment.reaction_count"
          ></reaction-by-button>
          <ion-button
            size="small"
            fill="clear"
            color="primary"
            class="button_nopadding button_minimal"
            style="text-transform: none"
            v-if="comment.flag_count > 0"
          >
            <small>{{ comment.flag_count }} Flags</small>
          </ion-button>
          <!-- dummy buttom to handle spacing between comment text and user name -->
          <ion-button
            color="medium"
            size="small"
            fill="clear"
            class="button_nopadding button_minimal"
            v-if="
              !comment.is_edited &&
              comment.flag_count === 0 &&
              !comment.reaction_count
            "
          >
            <small><b></b></small>
          </ion-button>
        </ion-col>
      </ion-row>
      <!-- the comment -->
      <ion-row class="ion-no-padding ion-no-margin" v-if="!comment.inEditMode">
        <ion-col class="comment_text_col">
          <div
            class="ion-no-padding ion-no-margin comment_text_style"
            :innerHTML="preFormat(tagAndMentions(urlify(comment.content)))"
          ></div>
        </ion-col>
      </ion-row>
      <!-- comment interaction buttons -->
      <ion-row class="ion-no-padding ion-no-margin" v-if="!comment.inEditMode">
        <ion-col class="ion-no-padding ion-no-margin">
          <ion-button
            fill="clear"
            size="small"
            @click="openReactionsPopover($event, comment)"
            :disabled="!comment.is_active"
            style="text-transform: none"
            class="button_nopadding"
          >
            <ion-icon
              color="medium"
              size="small"
              :icon="thumbsUpOutline"
              v-if="noReactionByMe(comment)"
            ></ion-icon>

            <ion-icon
              slot="icon-only"
              v-for="reaction in localReactionOptions.filter((x) =>
                hasMyReaction(comment, x.name)
              )"
              v-bind:key="reaction.name"
              :src="reaction.src"
              size="small"
            ></ion-icon>
          </ion-button>
          <ion-button
            fill="clear"
            size="small"
            color="medium"
            style="text-transform: none"
            v-if="comment.is_active && comment.reply_to_comment === -1"
            @click="replyToComment(comment)"
          >
            <ion-icon
              slot="icon-only"
              :icon="arrowUndoCircleOutline"
            ></ion-icon>
          </ion-button>
          <ion-button
            fill="clear"
            size="small"
            style="text-transform: none"
            class="button_nopadding"
            :disabled="showRepliesClicked"
            v-if="
              comment.is_active &&
              comment.reply_to_comment === -1 &&
              comment.reply_count > 0
            "
            @click="showRepliesHandler(comment)"
          >
            <span v-if="!showReplies">
              {{ comment.reply_count }}
              {{ getStrings(strings, "Replies") }}</span
            >
            <span v-if="showReplies">{{
              getStrings(strings, "HideReplies")
            }}</span>
            <ion-spinner
              v-if="showRepliesClicked"
              slot="end"
              name="dots"
            ></ion-spinner>
          </ion-button>
        </ion-col>
        <ion-col
          class="col_layout_nopadding_right ion-no-padding ion-no-margin"
        >
          <ion-button
            fill="clear"
            size="small"
            style="text-transform: none"
            class="button_nopadding"
            v-if="user.user_id === comment.comment_by && comment.is_active"
            @click="editTheComment(comment)"
          >
            <ion-icon
              color="primary"
              slot="icon-only"
              size="small"
              :icon="createOutline"
            ></ion-icon>
          </ion-button>
          <ion-button
            fill="clear"
            size="small"
            style="text-transform: none"
            class="button_nopadding"
            v-if="user.user_id === comment.comment_by && comment.is_active"
            @click="deleteTheComment(comment)"
          >
            <ion-icon
              color="danger"
              size="small"
              slot="icon-only"
              :icon="trashOutline"
            ></ion-icon>
          </ion-button>
          <ion-button
            fill="clear"
            size="small"
            class="button_nopadding"
            style="text-transform: none"
            :disabled="
              user.user_id === comment.comment_by || !comment.is_active
            "
            v-if="user.user_id !== comment.comment_by"
            @click="openFlagPopover($event, comment)"
          >
            <ion-icon
              v-if="comment.flag_count <= 0"
              slot="icon-only"
              size="small"
              color="medium"
              :icon="flag"
            ></ion-icon>
            <ion-icon
              v-if="comment.flag_count > 0"
              slot="icon-only"
              size="small"
              color="danger"
              :icon="flag"
            ></ion-icon>
            <span
              v-if="comment.flag_count && comment.commented_by === user.user_id"
            >
              <sup
                ><b>{{ comment.flag_count }}</b></sup
              >
            </span>
            <ion-label color="medium" size="small"
              ><small
                >&nbsp;&nbsp;{{ getStrings(strings, "Flag") }}</small
              ></ion-label
            >
          </ion-button>
        </ion-col>
      </ion-row>
      <!-- comment edit -->
      <ion-row v-if="comment.inEditMode && comment.is_active">
        <ion-col>
          <post-comment-box
            :inEditMode="true"
            :post="post"
            :commentToEdit="comment"
          ></post-comment-box>
        </ion-col>
      </ion-row>
      <!-- comment reply -->
      <ion-row
        class="ion-no-padding ion-no-margin"
        v-if="comment.inReplyMode && comment.is_active"
      >
        <ion-col class="ion-no-padding ion-no-margin" size="12">
          <post-comment-box
            :post="post"
            :inReplyMode="true"
            :replyToComment="comment"
            :newComment="newComment"
          ></post-comment-box>
        </ion-col>
      </ion-row>
      <!-- comment reply list -->
      <ion-row
        class="ion-no-padding ion-no-margin"
        v-if="
          comment.reply_to_comment === -1 &&
          comment['replies'] &&
          comment['replies'].length > 0 &&
          showReplies
        "
      >
        <ion-col
          class="ion-no-padding ion-no-margin"
          size="12"
          v-for="reply in comment['replies']"
          v-bind:key="reply.id"
        >
          <single-comment :comment="reply" :post="post"></single-comment>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-item>
</template>

<script>
import {
  IonLabel,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonItem,
  IonAvatar,
  IonImg,
  IonSpinner,
  popoverController,
  alertController,
} from "@ionic/vue";
import {
  flag,
  thumbsUpOutline,
  thumbsUp,
  createOutline,
  trashOutline,
  arrowUndoCircleOutline,
  personCircleOutline,
} from "ionicons/icons";

import { useRouter } from "vue-router";

import { defineComponent } from "vue";
import { deleteComment, replyList } from "../../services/apiCall.js";
import {
  formatDate,
  preFormat,
  tagAndMentions,
  urlify,
  reactionListArray,
} from "../../services/utils";

import { getStrings } from "../../services/lang";
import ReactionByButton from "../activity/ReactionByButton.vue";
import CommentReactionOptions from "./CommentReactionOptions.vue";
import CommentFlagOptions from "./CommentFlagOptions.vue";
import PostCommentBox from "./PostCommentBox.vue";

export default defineComponent({
  name: "SingleComment",
  props: ["comment", "post"],
  components: {
    IonLabel,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    IonItem,
    IonAvatar,
    IonImg,
    IonSpinner,
    ReactionByButton,
    PostCommentBox,
  },
  setup() {
    const router = useRouter();
    return {
      preFormat,
      tagAndMentions,
      urlify,
      replyList,
      router,
      getStrings,
    };
  },
  data() {
    return {
      flag,
      thumbsUpOutline,
      thumbsUp,
      createOutline,
      trashOutline,
      arrowUndoCircleOutline,
      personCircleOutline,
      formatDate,
      deleteComment,
      reactionName: "Like",
      showReplies: false,
      showRepliesClicked: false,
      newComment: { added: false },
      localReactionOptions: reactionListArray,
    };
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    async openReactionsPopover(ev, theComment) {
      const popover = await popoverController.create({
        component: CommentReactionOptions,
        event: ev,
        translucent: true,
      });
      popover.componentProps = { comment: await theComment, parent: popover };

      return popover.present();
    },

    async openFlagPopover(ev, theComment) {
      const popover = await popoverController.create({
        component: CommentFlagOptions,
        event: ev,
        translucent: true,
      });
      popover.componentProps = { comment: await theComment, parent: popover };
      return popover.present();
    },
    hasMyReaction(comment, rtype) {
      if (comment.my_reactions) {
        var hasReaction =
          comment.my_reactions.findIndex((x) => x.reaction_type === rtype) >= 0;
        if (hasReaction)
          this.reactionName = comment.my_reactions[0].reaction_type;
        return hasReaction;
      } else {
        return false;
      }
    },
    noReactionByMe(comment) {
      if (comment.my_reactions && comment.my_reactions.length > 0) {
        return false;
      }
      return true;
    },
    deleteTheComment(comment) {
      this.confirmDelete(comment);
    },
    async confirmDelete(comment) {
      const anAlert = await alertController.create({
        title: "Confirm Delete Comment",
        message:
          '<span style="text-align: justify; text-justify: inter-word;">Deleting a comment cannot be undone.<br/><small>For regulatory requirement, your comment content may still be archived for sometime as per local laws.</small><br/><br/><b>Do you really want to delete?</b></span>',
        buttons: [
          {
            text: "Cancel",
            role: "cancel",
            handler: () => {
              console.log("Cancel clicked");
            },
          },
          {
            text: "Confirm",
            handler: () => {
              this.deleteComment(this.post.id, comment.id)
                .then((res) => {
                  if (res.data.status === 0) {
                    comment.is_active = false;
                    comment.content = "You have deleted this comment.";
                  } else {
                    console.log("deletePost - err1");
                  }
                })
                .catch((err) => {
                  console.log("deletePost - err2", err);
                });
            }, // end handler
          },
        ],
      });
      await anAlert.present();
    },
    editTheComment(comment) {
      comment.inEditMode = true;
    },
    replyToComment(comment) {
      comment.inReplyMode = true;
    },
    showRepliesHandler(comment) {
      this.showRepliesClicked = true;

      this.showReplies = !this.showReplies;

      if (this.showReplies) {
        replyList({ post_id: this.post.id, comment_id: comment.id })
          .then((res) => {
            if (res.data.status === 0) {
              comment["replies"] = res.data.result.reply_list;
              this.showRepliesClicked = false;
            } else {
              this.showReplies = false;
              this.showRepliesClicked = false;
            }
          })
          .catch((err) => {
            console.log("replyList - err", err);
            this.showReplies = false;
            this.showRepliesClicked = false;
          });
      } else {
        this.showRepliesClicked = false;
      }
    },
  },
});
</script>
