<template>
  <!-- invite someone -->
  <br />
  <ion-item no-lines lines="none">
    <ion-text>
      Invite someone to become member of NEXT <br />
      <small>
        You can invite up to 5 people at a time. <br />
      </small>
    </ion-text>
  </ion-item>
  <ion-item no-lines lines="none">
    <ion-button fill="clear" v-if="emailSelected" @click="emailSelected=false">Email</ion-button>
    <ion-button v-if="!emailSelected">Email</ion-button>
    <ion-button fill="clear" v-if="!emailSelected" @click="emailSelected=true">Phone</ion-button>
    <ion-button v-if="emailSelected">Phone</ion-button>
  </ion-item>
  <span v-if="!emailSelected">
  <ion-item no-lines lines="none">
    <ion-label>
      <small>
        Separate each email with a comma (,). <br />
      </small>
    </ion-label>
  </ion-item>
  <ion-item no-lines lines="none">
    <ion-textarea
      type="text"
      inputmode="text"
      rows="2"
      auto-grow="true"
      placeholder="Enter email"
      v-model="inviteEmail"
      :disabled="inviteClicked"
    ></ion-textarea>
  </ion-item>
  <ion-item no-lines lines="none">
    <ion-button
      :disabled="inviteClicked || inviteEmail.length < 1"
      shape="round"
      color="primary"
      @click="inviteByEmail()"
    >
      Invite
      <ion-spinner v-if="inviteClicked" slot="end" name="dots"></ion-spinner
    ></ion-button>
  </ion-item>
  <ion-item v-if="emailList.length > 0">
    <ion-list>
      <ion-label color="secondary" size="small" v-for="email in emailList" v-bind:key="email.email">
        <small>{{ email.email }} : {{email.sent ? "Invite Sent" : email.error}}</small>
      </ion-label>
    </ion-list>
  </ion-item>
  </span>
  <span v-if="emailSelected">
    <ion-item no-lines lines="none">
      <ion-label>
        <small>
          Separate each phone with a comma (,).<br/><br/>
        </small>
      </ion-label>
    </ion-item>
    <ion-item no-lines lines="none">
      <ion-select
        class="ion-no-padding ion-no-margin"
        v-model="country_code"
        required
      >
        <ion-select-option
          v-for="country in countryNames"
          v-bind:key="country.tel_code"
          :value="country.tel_code"
          class="ion-no-padding ion-no-margin"
          no-lines
          lines="none"
          >{{ '+' + country.tel_code + ' ' + country.name }}</ion-select-option>
      </ion-select>
    </ion-item>
    <ion-item no-lines lines="none">
      <ion-textarea
        type="text"
        rows="2"
        auto-grow="true"
        placeholder="Enter Phone Numbers"
        v-model="invitePhone"
        :disabled="inviteClicked"
      ></ion-textarea>
    </ion-item>
    <ion-item no-lines lines="none">
      <ion-button
        :disabled="inviteClicked || invitePhone.length < 1"
        shape="round"
        color="primary"
        @click="inviteByPhone()"
      >
        Invite
        <ion-spinner v-if="inviteClicked" slot="end" name="dots"></ion-spinner>
      </ion-button>
  </ion-item>
  <ion-item v-if="phoneList.length > 0">
    <ion-list>
      <ion-label color="secondary" size="small" v-for="phone in phoneList" v-bind:key="phone.phone">
        <small>{{ phone.phone }} : {{ phone.sent ? "Invite Sent" : phone.error}}</small>
      </ion-label>
    </ion-list>
  </ion-item>
  </span>
  <br/>
</template>

<script>
import {
  IonButton,
  IonItem,
  IonTextarea,
  IonLabel,
  IonSpinner,
  IonText,
  IonList,
  IonSelect,
  IonSelectOption,
  alertController,
} from "@ionic/vue";

import { referUser } from "../../services/apiCall";
import { countryList } from "../../services/countries.js";

import { isValidEmail, isValidPhone } from "../../services/utils";

export default {
  name: "InviteSection",
  components: {
    IonButton,
    IonItem,
    IonTextarea,
    IonLabel,
    IonSpinner,
    IonText,
    IonList,
    IonSelect,
    IonSelectOption,
  },
  setup() {
    return {
      countryList,
    };
  },
  mounted() {
    this.init();
  },
  ionViewDidEnter() {
    this.init();
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  data() {
    return {
      inviteEmail: "",
      invitePhone: "",
      inviteClicked: false,
      emailList: [],
      phoneList: [],
      emailSelected: false,
      countryNames: this.countryList(),
      country: "India",
      country_code: "91",
    };
  },
  methods: {
    init() {
      this.emailList = [];
      this.phoneList = [];
      this.inviteEmail = "";
      this.invitePhone = "";
      this.inviteClicked = false;
    },
    async showAlert(title, msg) {
      const anAlert = await alertController.create({
        header: title,
        message: msg,
        buttons: ["OK"],
      });
      await anAlert.present();
    },
    async inviteByEmail() {
      if (this.inviteClicked) return;

      this.inviteClicked = true;

      this.inviteEmail = this.inviteEmail.trim();

      this.emailList = this.inviteEmail.split(",").map((x) => {
        let email = { email: x.trim(), sent: false, error: "Sending..." };
        return email;
      });

      this.emailList = this.emailList.filter((y) => y.email.length > 0);

      if (this.emailList.length > 5) {
        this.emailList = [];
        this.showAlert(
          "Invite Count Exeeded",
          "At a time you can invite only 5 of your friends."
        );
        this.inviteClicked = false;
        return;
      }

      let emailTasks = this.emailList.map((inviteEmail) => {
        return new Promise((resolve, reject) => {
          if (!isValidEmail(inviteEmail.email)) {
            inviteEmail.sent = false;
            (inviteEmail.error = "Invalid Email"),
              "Please provide a valid Email";
            return reject("error for '" + inviteEmail.email + "'");
          }

          referUser({ referred_email: inviteEmail.email })
            .then((res) => {
              if (res.data.status === 0) {
                inviteEmail.sent = true;
                return resolve("sent " + inviteEmail.email);
              } else {
                inviteEmail.sent = false;
                inviteEmail.error = res.data.error.error_message;
                return reject("error for '" + inviteEmail.email + "'");
              }
            })
            .catch((err) => {
              inviteEmail.sent = false;
              inviteEmail.error =
                "Could not send invite, try again. Error: " + err.toString();
              return reject("error for '" + inviteEmail.email + "'");
            });
        });
      });

      Promise.all(emailTasks)
        .then((success) => {
          console.log("All email tasks Done " + success);
          this.inviteClicked = false;
          this.inviteEmail = "";
        })
        .catch((err) => {
          console.log("All email tasks Err " + err);
          this.inviteClicked = false;
        });
    },
    async inviteByPhone() {
      if (this.inviteClicked) return;
      this.inviteClicked = true;
      this.phoneList = this.invitePhone.split(",").map((x) => {
        let phone = { phone: x.trim(), sent: false, error: "Sending..." };
        return phone;
      });

      this.phoneList = this.phoneList.filter((y) => y.phone.length > 0);

      if (this.phoneList.length > 5) {
        this.phoneList = [];
        this.showAlert(
          "Invite Count Exeeded",
          "At a time you can invite only 5 of your friends."
        );
        this.inviteClicked = false;
        return;
      }

      let sendSMSTasks = this.phoneList.map((invitePhone) => {
        return new Promise((resolve, reject) => {
          if (!isValidPhone(invitePhone.phone)) {
            invitePhone.sent = false;
            (invitePhone.error = "Invalid phone"),
              "Please provide a valid phone";
            return reject("error for '" + invitePhone.phone + "'");
          }

          referUser({ referred_phone: invitePhone.phone })
            .then((res) => {
              if (res.data.status === 0) {
                invitePhone.sent = true;
                return resolve("sent " + invitePhone.phone);
              } else {
                invitePhone.sent = false;
                invitePhone.error = res.data.error.error_message;
                return reject("error for '" + invitePhone.phone + "'");
              }
            })
            .catch((err) => {
              invitePhone.sent = false;
              invitePhone.error =
                "Could not send invite, try again. Error: " + err.toString();
              return reject("error for '" + invitePhone.phone + "'");
            });
        });
      });

      Promise.all(sendSMSTasks)
        .then((success) => {
          console.log("All phone tasks Done " + success);
          this.inviteClicked = false;
          this.inviteEmail = "";
        })
        .catch((err) => {
          console.log("All phone tasks Err " + err);
          this.inviteClicked = false;
        });
    },
  },
};
</script>
