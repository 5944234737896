<template>
  <chat-base-layout
    page-title="Chats"
    page-default-back-link="/chat"
    :showBackButton="!showCancelButton()"
    v-bind:otheruser="this.otheruser"
    v-bind:isChatItemSelected="this.isChatItemSelected"
    @menu-cancel="onMenuCancelClicked"
    @menu-reply="onMenuReplyClicked"
    @menu-delete="onMenuDeleteClicked"
    @menu-copy="onMenuCopyClicked"
    @menu-forward="onMenuForwardClicked"
    @base-menu-clicked="openMenuClicked"
    :showCancelButton="showCancelButton()"
  >
    <ion-spinner v-if="isSocketConnectInProgress" name="default"></ion-spinner>

    <chat-window
      v-if="currentUser"
      :isWSAvailable="issocket"
      :wsConnection="socket"
      :curUser="currentUser"
      :curUserKeys="curUserKeys"
      :friendToken="friend_id"
      v-bind:friendInfo="this.otheruser"
      ref="mChatWindow"
      @chat-item-selected="onChatItemSelected"
      :paramData="paramData"
      :paramDataType="paramDataType"
    >
    </chat-window>
  </chat-base-layout>
</template>

<script>
import {
  IonSpinner,
  alertController,
  toastController,
  modalController,
  actionSheetController,
} from "@ionic/vue";
import { homeOutline } from "ionicons/icons";
import { useRouter } from "vue-router";
import {
  isMobileScreen,
  getColSize,
  getOffsetSize,
} from "../../services/utils";
import {
  chatFriendsProfile,
  deleteChat,
  deleteChatForMe,
  getUserEncryptionKeys,
} from "../../services/apiCall";
import { readFromDB } from "../../services/db.js";
import { getStrings } from "../../services/lang";
import {
  getChatFeedURL,
  userJoinChat,
  notifyDeleteChatWS,
} from "../services/chatApiCall";

import { trashOutline, closeOutline } from "ionicons/icons";

import ChatWindow from "../components/ChatWindow.vue";

import ChatBaseLayout from "../components/ChatBaseLayout.vue";
import SelectionChatUser from "../components/SelectionChatUser.vue";

import { Plugins } from "@capacitor/core";
const { Clipboard } = Plugins;

export default {
  name: "ChatView",
  async ionViewDidEnter() {
    this.readDBAndInit();
  },
  ionViewDidLeave() {
    console.log("ChatsView page did leave");

    if (this.socket) {
      this.socket.close();
    }
  },
  ionViewWillEnter() {
    console.log("ChatsView page will enter");
  },
  ionViewWillLeave() {
    console.log("ChatsView page will leave");
  },
  components: {
    IonSpinner,
    ChatWindow,
    ChatBaseLayout,
  },
  setup() {
    const router = useRouter();

    return {
      router,
      getColSize,
      getOffsetSize,
      isMobileScreen,
      getStrings,
    };
  },
  mounted() {},
  data() {
    return {
      searchText: "",
      groupSearchInProgress: false,
      isSocketConnectInProgress: false,
      icon: {
        homeOutline,
      },
      friend_id: this.$route.params.id,
      curuser_token: "-",

      otheruser: {},

      createPostTopics: [],
      topicsPerPostLimit: 2,

      chatItems: [],

      currentUser: null,
      curUserKeys: null,
      socket: null,
      issocket: false,
      inUserRefreshMode: false,

      isChatItemSelected: false,
      curSelectedChatItem: {},

      paramData: this.$route.params.data ? this.$route.params.data : "",
      paramDataType: this.$route.params.type ? this.$route.params.type : "text",

      optionIcons: {
        DELETE_FOR_ME: trashOutline,
        DELETE_FOR_ALL: trashOutline,
        CANCEL: closeOutline,
      },
    };
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    searchGroups() {
      this.groupSearchInProgress = true;
      console.log("searchGroups");
      this.groupSearchInProgress = false;
    },

    createNewGroup() {
      console.log("createNewGroup");
    },

    showCancelButton() {
      this.paramData = this.$route.params.data ? this.$route.params.data : "";
      this.paramDataType = this.$route.params.type
        ? this.$route.params.type
        : "text";

      let showCancel =
        typeof this.paramData !== "undefined" && this.paramData !== "";

      console.log("showCancel", showCancel);

      return showCancel;
    },

    readDBAndInit() {
      try {
        if (this.inUserRefreshMode) return;

        this.paramData = this.$route.params.data ? this.$route.params.data : "";
        this.paramDataType = this.$route.params.type
          ? this.$route.params.type
          : "text";

        this.inUserRefreshMode = true;

        readFromDB(this.$store)()
          .then(async () => {
            this.currentUser = this.$store.getters.user;
            if (
              this.currentUser === null ||
              typeof this.currentUser === "undefined" ||
              typeof this.currentUser.user_id === "undefined"
            ) {
              this.inUserRefreshMode = false;
              this.openToast(
                this.getStrings(this.strings, "LoggingOffUnablecredentials")
              );
              this.logOff(this);
            } else {
              this.inUserRefreshMode = false;
              this.init();

              // window.setTimeout(this.initSocket, 4000);
              this.initSocket();
            }
          })
          .catch((err) => {
            console.log("indexdb not available", err);
            this.inUserRefreshMode = false;

            this.logOff(this);
          });
      } catch (err) {
        console.log("indexdb not available", err);
        this.inUserRefreshMode = false;

        this.logOff(this);
      }
    },

    init() {
      this.fetchingProfile = true;
      if (typeof this.otheruser.user_id === "undefined") {
        this.otheruser.user_id = this.friend_id;
      }

      //TODO: Action if keys not available
      this.curUserKeys = this.$store.getters.userEncryptionKeys;
      if (!this.curUserKeys.private_key) {
        this.updateUserEncryptionkey();
      }

      this.otheruser.isGroup = false;
      this.otheruser.full_name = "...";
      this.otheruser.photo = "";
      this.otheruser.profile = [];
      this.otheruser.joining_date = "";

      let connections = this.$store.getters.chatConnections;
      let displayInfo = connections.find(
        (friend) => friend.tokenizedUser === this.otheruser.user_id
      );
      //console.log("OTHER USER:"+JSON.stringify(displayInfo));

      if (displayInfo) {
        this.otheruser.full_name = displayInfo.full_name;
        this.otheruser.photo = displayInfo.photo_thumbnail;
        this.otheruser.id = displayInfo.id;

        this.otheruser.profile = [];
        this.otheruser.last_seen = "";

        if (displayInfo.public_key) {
          this.otheruser.public_key = displayInfo.public_key;
        }

        this.fetchingProfile = false;
        this.loadOtherUserDetails();
      } else {
        this.loadOtherUserDetails();
      }
    },

    loadOtherUserDetails() {
      chatFriendsProfile({ profile_user_id: this.otheruser.user_id })
        .then((res) => {
          this.otheruser.profile = [];
          if (res.data.status === 0) {
            // basic profile
            this.otheruser.id = res.data.result.id;
            this.otheruser.full_name = res.data.result.full_name;
            var userProfileData = res.data.result.user_profile;
            this.otheruser.last_seen = res.data.result.last_seen;

            if (res.data.result.public_key) {
              this.otheruser.public_key = res.data.result.public_key;
            } //ELSE generate & get key

            // photo
            var profilePhoto = userProfileData.filter(
              (x) => x.section_name === "PROFILE_PHOTO"
            );
            if (profilePhoto.length === 1) {
              profilePhoto = profilePhoto[0];
              var photo = profilePhoto.fields.filter(
                (x) => x.field_name === "profile_photo"
              );
              if (photo.length === 1) this.otheruser.photo = photo[0].value;
            }
          }

          console.log("this.otheruser.profile:", this.otheruser.profile);

          this.fetchingProfile = false;
        })
        .catch((err) => {
          console.log("userProfile - err", err);

          this.fetchingProfile = false;
        });
    },

    initSocket() {
      var self = this;

      var CHAT_BASE_URL = getChatFeedURL();

      self.curuser_token = this.currentUser.tokenized_user;

      console.log("Friend : " + this.friend_id);
      console.log("URL : " + CHAT_BASE_URL);

      this.isSocketConnectInProgress = true;
      this.socket = new WebSocket(CHAT_BASE_URL);

      // this.socket.onmessage - defined in ChatWindow
      this.socket.onopen = (event) => {
        console.log(event);
        console.log("Successfully connected to the echo websocket server...");

        userJoinChat(self.socket, self.currentUser, self.friend_id);
        this.issocket = true;
        this.isSocketConnectInProgress = false;
      };

      this.socket.onclose = (event) => {
        //this.showConnectionError(); // user back click closes connection.
        console.log("WebSocket is closed now.", event);
        //this.quitWindow();
      };

      this.socket.onerror = (event) => {
        console.log(event);
        console.log("Can not connect to websocket server");

        this.isSocketConnectInProgress = false;
        this.issocket = false;
        this.showConnectionError();
      };
    },

    async quitWindow() {
      await this.router.push("/chat/");
    },

    async logOff(callbackObject = this) {
      this.$store.dispatch("adduser", {});

      callbackObject.router.replace("/");
    },

    async showConnectionError() {
      var errorMsg = "<small>Unable to connect server for chat.</small>";
      (
        await alertController.create({
          header: "Connection Error",
          message: errorMsg,
          buttons: ["OK"],
        })
      ).present();
    },

    async openToast(msg) {
      const toast = await toastController.create({
        message: msg,
        duration: 1500,
      });
      return toast.present();
    },

    //For providing menu option to copy string Or Forward and show Item is selected. Later on delete functionality
    async onChatItemSelected(curSelectedChatItem) {
      if (this.curSelectedChatItem) this.curSelectedChatItem.isSelected = false;
      this.curSelectedChatItem = curSelectedChatItem;
      this.isChatItemSelected = true;
      this.curSelectedChatItem.isSelected = true;
    },

    async openMenuClicked() {
      const toast = await toastController.create({
        message: "Coming soon ...",
        duration: 1500,
      });
      return toast.present();
    },

    // Chat Item  selection Action functions
    async onMenuDeleteClicked() {
      this.actionSheetOptions = [
        {
          id: "DELETE_FOR_ME",
          name: this.getStrings(this.strings, "DeleteForMe"),
        },
      ];

      const cDeleteForAll = {
        id: "DELETE_FOR_ALL",
        name: this.getStrings(this.strings, "DeleteForEveryone"),
      };
      const cCancelAction = {
        id: "CANCEL",
        name: this.getStrings(this.strings, "Cancel"),
      };

      if (this.curSelectedChatItem.from_user_id === this.currentUser.user_id) {
        this.actionSheetOptions.push(cDeleteForAll);
      }
      this.actionSheetOptions.push(cCancelAction);

      this.buttons = [];
      for (var idx = 0; idx < this.actionSheetOptions.length; idx++) {
        this.buttons.push({
          text: this.actionSheetOptions[idx].name,
          icon: this.optionIcons[this.actionSheetOptions[idx].id],
          size: "small",
          handler: this.handleAction.bind(
            this,
            this.actionSheetOptions[idx],
            this.actionSheetOptions[idx]
          ),
        });
      }

      const actionSheet = await actionSheetController.create({
        header: "Options",
        buttons: this.buttons,
      });

      await actionSheet.present();
    },

    handleAction(post, actionId) {
      //console.log("Selected Action : ", actionId);
      if (actionId.id === "DELETE_FOR_ME") {
        this.deleteChatForSelf();
      } else if (actionId.id === "DELETE_FOR_ALL") {
        this.deleteChatForAll();
      } else if (actionId.id === "CANCEL") {
        this.onMenuCancelClicked();
      }
    },

    async deleteChatForSelf() {
      var self = this;
      console.log(
        "selection Delete clicked : ",
        JSON.stringify(this.curSelectedChatItem)
      );

      deleteChatForMe({
        chat_id: this.curSelectedChatItem.id,
        chat_from_user_id: this.curSelectedChatItem.from_user_id,
      })
        .then((res) => {
          this.otheruser.profile = [];
          if (res.data.status === 0) {
            console.log("userProfile", JSON.stringify(res));

            /* notifyDeleteChatWS(
              self.socket,
              self.currentUser,
              self.friend_id,
              self.curSelectedChatItem.id
            );*/

            //UpdateSpecificChatStatus
            const receivedChat = res.data.result.chat_message[0];
            if (receivedChat.deleted) {
              const content = {
                msg_id: receivedChat.id,
                deleted: receivedChat.deleted,
              };
              self.updateDeletedChatStatus(content);
            } else {
              const content = {
                msg_id: receivedChat.id,
                new_status: receivedChat.status,
              };
              self.updateSpecificChatStatus(content);
            }
          }

          console.log("this.otheruser.profile:", this.otheruser.profile);
          this.onMenuCancelClicked();
        })
        .catch((err) => {
          console.log("delete chat - err", err);
          this.onMenuCancelClicked();
        });
    },

    updateSpecificChatStatus(content) {
      this.$refs.mChatWindow.updateSpecificChatStatusCaller(content);
    },

    updateDeletedChatStatus(content) {
      this.$refs.mChatWindow.updateDeletedChatStatusCaller(content);
    },

    async deleteChatForAll() {
      var self = this;
      console.log(
        "selection Delete clicked : ",
        JSON.stringify(this.curSelectedChatItem)
      );

      deleteChat({ chat_id: this.curSelectedChatItem.id })
        .then((res) => {
          this.otheruser.profile = [];
          if (res.data.status === 0) {
            console.log("userProfile", JSON.stringify(res));
            notifyDeleteChatWS(
              self.socket,
              self.currentUser,
              self.friend_id,
              self.curSelectedChatItem.id
            );
          }

          //console.log("this.otheruser.profile:", this.otheruser.profile);
          this.onMenuCancelClicked();
        })
        .catch((err) => {
          console.log("delete chat - err", err);
          this.onMenuCancelClicked();
        });
    },

    async onMenuForwardClicked() {
      console.log("selection forward clicked");

      try {
        const modal = await modalController.create({
          component: SelectionChatUser,
          cssClass: "modal-fullscreen",
        });

        const curChatItem = this.curSelectedChatItem;
        modal.componentProps = {
          postForForwarding: curChatItem,
          isForwardingPost: true,
          wsConnection: this.socket,
          curUser: this.currentUser,
        };

        this.onMenuCancelClicked();
        return modal.present();
      } catch (err) {
        console.log("user list display err", err);
      }
    },

    onMenuReplyClicked() {
      this.$refs.mChatWindow.replyChatMessage(this.curSelectedChatItem);

      this.curSelectedChatItem = {};
      this.isChatItemSelected = false;
    },

    async onMenuCopyClicked() {
      var textToCopy = "";
      if (undefined !== this.curSelectedChatItem.content) {
        if (undefined !== this.curSelectedChatItem.content.data) {
          textToCopy = this.curSelectedChatItem.content.data;
        }
      }

      if (textToCopy.length > 0) {
        try {
          await Clipboard.write({ string: textToCopy });

          var toastString = "Copied Text :" + textToCopy;

          toastString =
            toastString.length < 30
              ? toastString
              : toastString.substr(0, 30) + "...";
          this.openToast(toastString);
        } catch (err) {
          this.openToast("Failed to copy. Error: " + err);
        }
      }

      this.onMenuCancelClicked();
    },

    onMenuCancelClicked() {
      this.curSelectedChatItem.isSelected = false;
      this.curSelectedChatItem = {};
      this.isChatItemSelected = false;
    },

    updateUserEncryptionkey() {
      this.curUserKeys = this.$store.getters.userEncryptionKeys;
      if (!this.curUserKeys.private_key) {
        getUserEncryptionKeys({
          other_user_id: this.currentUser.tokenized_user,
        })
          .then((res) => {
            if (res.data.status === 0) {
              const result = res.data.result;
              var pubKey = result.public_key;
              var priKey = result.private_key;

              this.currentUser["public_key"] = pubKey;
              this.currentUser["private_key"] = priKey;

              var keyObj = {
                public_key: pubKey,
                private_key: priKey,
              };

              this.curUserKeys = keyObj;
              this.$store.dispatch("setUserEncryptionKeys", keyObj);
            }
          })
          .catch((err) => {
            console.log("userProfile - err", err);

            this.fetchingProfile = false;
          });
      } else {
        //console.log("Private Key already Set : " + curUserKays.private_key);
      }
    },
  }, //END Methods
  created: function () {
    //console.log("Created ......chat View");
  },
};
</script>