
<template>
  <ion-row class="animate-fading">
    <ion-col
      class="
        ion-no-padding
        ion-no-margin
        ion-justify-content-center
        ion-align-items-center
      "
    >
      <ion-item no-lines lines="none" class="ion-no-padding ion-no-margin">
        <ion-badge v-if="activeStep !== 1" color="dark" class="left-margin-15px">1</ion-badge>
        <ion-badge v-if="activeStep === 1" color="primary" class="left-margin-15px"><b>1</b></ion-badge>
        &nbsp;
        <hr
          style="
            display: inline-block;
            height: 2px;
            width: 45%;
            border-width: 0;
            color: gray;
            background-color: gray;
          "
          class="ion-no-padding ion-no-margin wizard-line"
        />
        &nbsp;
        <ion-badge v-if="activeStep !== 2" color="dark">2</ion-badge>
        <ion-badge v-if="activeStep === 2" color="primary">2</ion-badge>
        &nbsp;
        <hr
          style="
            display: inline-block;
            height: 2px;
            width: 45%;
            border-width: 0;
            color: gray;
            background-color: gray;
          "
          class="ion-no-padding ion-no-margin wizard-line"
        />
        &nbsp;
        <ion-badge v-if="activeStep !== 3" color="dark"  class="right-margin-15px">3</ion-badge>
        <ion-badge v-if="activeStep === 3" color="primary"  class="right-margin-15px">3</ion-badge>
      </ion-item>
    </ion-col>
  </ion-row>

  <!-- create group -->
  <ion-row v-if="activeStep === 1" class="animate-fading">
    <ion-col class="ion-no-padding ion-no-margin">
      <form @submit.prevent="handleNewGroup">
        <ion-item no-lines lines="none" class="signup-item-style">
          <ion-label class="left-margin-15px">{{ getStrings(strings, 'GroupName') }}:</ion-label><br/>
          <ion-input
            v-model="form.groupName"
            id="groupName"
            class="right-margin-15px"
            required
            placeholder="Min 10 chars, Max 50 char, and Min 2 words"
          ></ion-input>
        </ion-item>
        <ion-item no-lines lines="none" class="signup-item-style">
          <ion-label class="left-margin-15px">{{ getStrings(strings, 'GroupDescription') }}:</ion-label>
          <ion-textarea
            auto-grow="true"
            autocomplete="on"
            autocapitalize="on"
            spellcheck="true"
            rows="1"
            v-model="form.groupDescription"
            id="groupDescription"
            required
            no-padding
            no margin
            class="right-margin-15px"
            placeholder="Write group description"
          ></ion-textarea>
        </ion-item>
        <ion-item no-lines lines="none" class="signup-item-style" v-if="!isChatGroupCreateCalled">
          <ion-label class="left-margin-15px">{{ getStrings(strings, 'GroupVisibility') }}:</ion-label>
          <ion-select
            class="ion-padding-horizontal ion-no-margin"
            multiple="false"
            id="groupVisibiliy"
            interface="popover"
            v-model="form.groupVisibility"
          >
            <ion-select-option class="ion-padding-horizontal ion-no-margin" value="Public">{{ getStrings(strings, 'Public') }}</ion-select-option>
            <ion-select-option class="ion-padding-horizontal ion-no-margin" value="Closed">{{ getStrings(strings, 'ClosedGroup') }}</ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item no-lines lines="none" class="signup-item-style" v-if="!isChatGroupCreateCalled">
          <ion-label class="left-margin-15px">{{ getStrings(strings, 'MemberJoiningMode') }}:</ion-label>
          <ion-select
            class="ion-padding-horizontal ion-no-margin"
            multiple="false"
            id="groupMemberJoining"
            interface="popover"
            v-model="form.groupMemberJoining"
          >
            <ion-select-option class="ion-padding-horizontal ion-no-margin" value="Open">{{ getStrings(strings, 'Open') }}</ion-select-option>
            <ion-select-option class="ion-padding-horizontal ion-no-margin" value="Request">{{ getStrings(strings, 'Request') }}</ion-select-option>
            <ion-select-option class="ion-padding-horizontal ion-no-margin" value="Invitation">{{ getStrings(strings, 'Invitation') }}</ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item no-lines lines="none" class="signup-item-style" v-if="!isChatGroupCreateCalled">
          <ion-label class="left-margin-15px">{{ getStrings(strings, 'IsGroupSearchable') }}:</ion-label>
          <ion-checkbox
            id="groupSearchable"
            slot="end"
            style="margin-right: 20px"
            v-model="form.groupSearchable"
            required
          ></ion-checkbox>
        </ion-item>
        <ion-item>
          <ion-button
            size="medium"
            slot="end"
            :disabled="newGroupClicked"
            type="submit"
            class="right-margin-15px"
          >
            {{ getStrings(strings, 'CreateGroup') }}
          </ion-button>
          <ion-spinner v-if="newGroupClicked" name="dots"></ion-spinner>
        </ion-item>
      </form>
    </ion-col>
  </ion-row>

  <!-- upload group profile photo -->
  <ion-row v-if="activeStep === 2" class="animate-fading" style="margin-left: 12px; margin-right: 12px;">
    <ion-col size="12" class="ion-no-padding ion-no-margin">
      <ion-item no-lines lines="none">
        <ion-label><b>{{ getStrings(strings, 'UploadGroupProfilePhoto') }}</b></ion-label>
      </ion-item>
    </ion-col>
    <ion-col size="12" class="ion-no-padding ion-no-margin">
      <group-profile-photo-setting :group="group"></group-profile-photo-setting>
    </ion-col>
    <ion-col size="12" class="ion-no-padding ion-no-margin">
      <ion-item no-lines lines="none">
        <ion-button size="medium" slot="end" @click="showInviteMembers">
          {{ getStrings(strings, 'Next') }}
        </ion-button>
      </ion-item>
    </ion-col>
  </ion-row>

  <!-- Invite members -->
  <ion-row v-if="activeStep === 3" class="animate-fading" style="margin-left: 12px; margin-right: 12px;">
    <ion-col size="12" class="ion-no-padding ion-no-margin">
      <ion-item no-lines lines="none">
        <ion-label><b>{{ getStrings(strings, 'InviteMembers') }}</b></ion-label>
      </ion-item>
    </ion-col>
    <ion-col size="12" class="ion-no-padding ion-no-margin">
      <group-member-invite :group="group"></group-member-invite>
    </ion-col>
    <ion-col size="12" class="ion-no-padding ion-no-margin">
      <ion-item no-lines lines="none">
        <ion-button size="medium" slot="end" @click="doneGroupCreation">
          {{ getStrings(strings, 'Done') }}
        </ion-button>
      </ion-item>
    </ion-col>
  </ion-row>
</template>

<script>
import {
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonSpinner,
  IonSelect,
  IonSelectOption,
  IonCheckbox,
  IonBadge,
  IonTextarea,
  toastController,
  alertController,
} from "@ionic/vue";
import { useRouter } from "vue-router";
import {
  isMobileScreen,
  getColSize,
  getOffsetSize,
} from "../../services/utils";

import { createGroup } from "../../services/apiCall";
import { getStrings } from "../../services/lang";

import GroupProfilePhotoSetting from "./GroupProfilePhotoSetting.vue";
import GroupMemberInvite from "./GroupMemberInvite.vue";

export default {
  name: "CreateNewGroupForm",
  props: ["createGroupCallback", "isChatGroupCreateCalled"],
  ionViewDidEnter() {
    this.activeStep = 1;
  },
  mounted() {
    this.activeStep = 1;
  },
  components: {
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    IonSpinner,
    IonSelect,
    IonSelectOption,
    IonCheckbox,
    IonBadge,
    IonTextarea,
    GroupProfilePhotoSetting,
    GroupMemberInvite,
  },
  setup() {
    const router = useRouter();
    return {
      router,
      getColSize,
      getOffsetSize,
      isMobileScreen,
      createGroup,
      getStrings,
    };
  },
  data() {
    return {
      form: {
        groupName: "",
        groupDescription: "",
        groupVisibility: "Closed",
        groupMemberJoining: "Request",
        groupSearchable: false,
      },
      newGroupClicked: false,
      activeStep: 1,
      group: {},
      errors: [],
    };
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    async openToast(msg) {
      const toast = await toastController.create({
        message: msg,
        duration: 1500,
      });
      return toast.present();
    },
    async showError() {
      var errorMsg = this.errors.join("<br/>* ");
      errorMsg =
        "<small>Please correct the following error(s):<br/>* " +
        errorMsg +
        "</small>";
      (
        await alertController.create({
          header: "Group Creation Failed",
          message: errorMsg,
          buttons: ["OK"],
        })
      ).present();
    },
    validateForm() {
      this.errors = [];

      if (!this.form.groupName) {
        this.errors.push("Group Name is empty");
      }
      if (!this.form.groupDescription) {
        this.errors.push("Group Description is empty");
      }

      if (this.form.groupName.length < 10 || this.form.groupName.length > 50) {
        this.errors.push(
          "Group Name should have min 10 characters and maximum 50 characters"
        );
      }

      let groupWords = this.form.groupName.trim().split(/\s+/);
      if (groupWords < 2) {
        this.errors.push("Group Name should have at least 2 words");
      }

      return this.errors.length > 0 ? false : true;
    },
    handleNewGroup() {
      this.newGroupClicked = true;

      let inputGroupVisibility = this.form.groupVisibility;
      let inputMemberJoiningWay = this.form.groupMemberJoining;
      let inputGroupSearchable =  this.form.groupSearchable;

      if(this.isChatGroupCreateCalled){
        inputGroupVisibility = "Closed";
        inputMemberJoiningWay = "Invitation";
        inputGroupSearchable = false;
      }

      if (this.validateForm()) {
        createGroup({
          name: this.form.groupName,
          description: this.form.groupDescription,
          is_searchable: inputGroupSearchable,
          visibility_type: inputGroupVisibility,
          joining_by: inputMemberJoiningWay,
        })
          .then((res) => {
            if (res.data.status === 0) {
              this.activeStep = 2;
              this.group["group_id"] = res.data.result.group_id;
            }

            this.newGroupClicked = false;
          })
          .catch((err) => {
            console.log("createGroup - err", err);

            this.newGroupClicked = false;
          });
      } else {
        this.newGroupClicked = false;
        this.showError();
      }
    },
    showInviteMembers() {
      this.activeStep = 3;
    },
    doneGroupCreation() {
      this.form = {
        groupName: "",
        groupDescription: "",
        groupVisibility: "Closed",
        groupMemberJoining: "Request",
        groupSearchable: false,
      };
      this.activeStep = 1;
      this.openToast(this.getStrings(this.strings, "GroupCreated"));
      if (this.createGroupCallback) this.createGroupCallback();
    },
  },
};
</script>