<template>
  <ion-grid
    style="height: 100%; display: flex; flex-flow: column"
    class="ion-no-padding ion-no-margin"
  >
    <ion-content id="chatContent" name="chatContent" :scroll-events="true">
      <ion-row
        style="flex-grow: 1"
        v-if="isFirstScrollDone"
        class="ion-no-padding ion-no-margin"
      >
        <ion-col
          :size="getColSize(false)"
          :offset="getOffsetSize(false)"
          class="ion-no-padding ion-no-margin"
        >
          <ion-infinite-scroll
            position="top"
            @ionInfinite="loadScrollChats($event)"
            threshold="100px"
            id="infinite-scroll"
            :disabled="scrollState.scrollDisabled"
          >
            <ion-infinite-scroll-content
              loading-spinner="bubbles"
              loading-text="Loading more chats..."
            >
            </ion-infinite-scroll-content>
          </ion-infinite-scroll>
          <chat-list
            :chatItemsList="chatItemsList"
            :curUser="curUser"
            @reply-chat="replyChatMessage"
            @request-load-chats="loadScrollChats"
            @message-read="updateChatStatusRead"
            @chat-item-selected="onChatItemSelected"
            :scrollState="scrollState"
            :loadScrollChats="loadScrollChats"
            :mutationCallback="scrollToBottom"
          >
          </chat-list>
        </ion-col>
      </ion-row>
    </ion-content>
    <ion-row
      style="
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 15px;
        margin-top: 5px;
      "
    >
      <ion-col :size="getColSize(false)" :offset="getOffsetSize(false)">
        <chat-box
          @send-chat="newChatMessage"
          @close-reply-box="onCloseReplyBox"
          :isReplying="isReplyingToMessage"
          :replyContent="replyMessageContent"
          :isWSAvailable="isWSAvailable"
          :paramData="paramData"
          :paramDataType="paramDataType"
        >
        </chat-box>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import {
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/vue";
import { useRouter } from "vue-router";
import {
  isMobileScreen,
  getColSize,
  getOffsetSize,
  defaultPostsPerFetch,
} from "../../services/utils";

import ChatList from "./ChatList.vue";
import ChatBox from "./ChatBox.vue";

import {
  sendGroupChat,
  updateGroupChatStatusWS,
} from "../services/chatApiCall";
import {
  fetchGroupChatInRange,
  updateGroupChatStatus,
  fetchGroupChatsAfterTime,
} from "../../services/apiCall.js";

export default {
  name: "GroupChatWindow",
  emits: ["chat-item-selected"],
  props: [
    "isWSAvailable",
    "wsConnection",
    "curUser",
    "groupToken",
    "friendInfo",
    "paramData",
    "paramDataType",
  ],
  watch: {
    wsConnection: function () {
      console.log("From Group Chat Window : Connection updated");
      this.socketConnection = this.wsConnection;
      this.initWS();
    },
  },

  ionViewDidEnter() {
    console.log("Group Chat window did enter", this.user);
  },
  ionViewDidLeave() {
    console.log("Group Chat window did leave");
  },
  ionViewWillEnter() {
    console.log("Group Chat window will enter");
  },
  ionViewWillLeave() {
    console.log("Group Chat window will leave");
  },
  mounted() {
    //console.log("Group Chat window mounted", this.curUser);
    this.init();

    if (this.wsConnection) this.initWS();
  },
  activated() {
    console.log("Group Chat window activated");
  },
  components: {
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    ChatBox,
    ChatList,
  },
  setup() {
    const router = useRouter();
    return {
      router,
      isMobileScreen,
      getColSize,
      getOffsetSize,
    };
  },
  data() {
    return {
      socketConnection: this.wsConnection,

      chatItemsList: [],

      fetchingNewMessages: false,

      isReplyingToMessage: false,
      replyMessageContent: [],

      firstLoadCount: 15,
      scrollState: {
        scrollDisabled: false,
        scrollSkip: 0,
      },

      limit: defaultPostsPerFetch(),

      isFirstScrollDone: false,

      timeLatestChat: -1,
      timeStartChat: -1,

      unsendChatStatusUpdate: [],
    };
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      return this.$store.getters.user;
    },
  },

  methods: {
    init() {
      this.fnFetchNewChats();
    },

    initWS() {
      var self = this;

      //console.log("Initializing WebSocket call back  --  from GroupChatWindow");
      this.socketConnection.onmessage = function (event) {
        var msg = JSON.parse(event.data);
        //console.log("gc Received :" + JSON.stringify(msg));

        switch (msg.type) {
          case "users-checkedin":
            console.log(event);

            break;

          case "send-chat":
            var contentobj = msg.payload;
            self.appendReceivedChat(contentobj);
            break;

          case "send-group-chat":
            var contentobj1 = msg.payload;
            //console.log(JSON.stringify(contentobj));
            self.appendReceivedChat(contentobj1);
            break;

          case "update-chat-status":
            var contentobje = msg.payload;
            self.updateSpecificChatStatus(contentobje);
        }
      };

      if (self.unsendChatStatusUpdate.length > 0) {
        self.waitForSocketConnection(
          this.socketConnection,
          self.sendUnsentStatusUpdate
        );
        //self.sendUnsentStatusUpdate();
      }
    },

    updateChatCreateTime() {
      this.chatItemsList.forEach((x) => {
        var curTime = new Date(x.createdAt).getTime();
        if (this.timeLatestChat === -1) {
          this.timeLatestChat = curTime;
        } else {
          if (this.timeLatestChat < curTime) this.timeLatestChat = curTime;
        }

        if (this.timeStartChat === -1) {
          this.timeStartChat = curTime;
        } else {
          if (this.timeStartChat > curTime) this.timeStartChat = curTime;
        }
      });

      console.log(
        "Time  Latest :" +
          this.timeLatestChat +
          "     --     time start : " +
          this.timeStartChat
      );
    },

    fnFetchNewChats() {
      if (this.fetchingNewMessages) return;
      this.fetchingNewMessages = true;

      this.chatItemsList = [];
      var sState = this.scrollState;
      sState.scrollSkip = 0;

      let cache_msgs = this.$store.getters.chatMessages(this.groupToken);
      if (cache_msgs) {
        this.chatItemsList.splice(0, this.chatItemsList.length, ...cache_msgs);
        sState.scrollSkip = cache_msgs.length;

        window.setTimeout(this.scrollToBottom, 1000);
        this.fetchingNewMessages = false;
        this.isFirstScrollDone = true;

        this.updateChatCreateTime();

        fetchGroupChatsAfterTime({
          group_id: this.groupToken,
          fetchTime: this.timeLatestChat,
        })
          .then((res) => {
            if (res.data.status === 0) {
              let newMessages = res.data.result.chat_messages;

              newMessages.forEach((objMsg) => {
                this.appendReceivedChat(objMsg);
              });

              console.log("Fetchingg Chats Timely -- appended");
              console.log(JSON.stringify(res.data));
            } else {
              console.log("Fetchingg Chats Timely -- empty chats");
              console.log(JSON.stringify(res.data));
            }
          })
          .catch((err) => {
            this.fetchingNewMessages = false;
            console.log("Fetch new time Chats - err", err);
          });
      } else {
        fetchGroupChatInRange({
          group_id: this.groupToken,
          limit: this.firstLoadCount,
          skip: 0,
        })
          .then((res) => {
            if (res.data.status === 0) {
              //console.log(JSON.stringify(res.data.result.chat_messages));
              let newMessages = res.data.result.chat_messages;

              //console.log("Total Messages : " + newMessages.length);
              this.chatItemsList = res.data.result.chat_messages;

              //TODO:
              newMessages.forEach((objMsg) => {
                var obj = { chat: objMsg, token_id: this.groupToken };
                this.$store.dispatch("addChatMessage", obj);
              });

              sState.scrollSkip = newMessages.length;
            }

            window.setTimeout(this.scrollToBottom, 1000);
            this.fetchingNewMessages = false;
            this.isFirstScrollDone = true;
          })
          .catch((err) => {
            this.fetchingNewMessages = false;
            console.log("Fetch new Chats - err", err);
          });
      }
    },

    loadScrollChats(ev) {
      if (this.fetchingNewMessages) {
        ev.target.complete();
        return;
      }

      this.fetchingNewMessages = true;

      const sState = this.scrollState;
      var cond = {
        group_id: this.groupToken,
        limit: this.limit,
        skip: sState.scrollSkip,
      };
      sState.scrollSkip = sState.scrollSkip + this.limit;

      fetchGroupChatInRange(cond)
        .then((res) => {
          if (res.data.status === 0) {
            //console.log(JSON.stringify(res.data.result.chat_messages));
            let newMessages = res.data.result.chat_messages;

            if (newMessages.length > 0) {
              this.chatItemsList = [...newMessages, ...this.chatItemsList];
            }

            //TODO;
            newMessages.forEach((objMsg) => {
              var obj = { chat: objMsg, token_id: this.groupToken };
              this.$store.dispatch("addChatMessage", obj);
            });

            this.scrollState.scrollSkip = this.chatItemsList.length;
            ev.target.complete();

            if (newMessages.length == 0) {
              sState.scrollDisabled = true;
            }
          } else {
            ev.target.complete();
          }

          this.fetchingNewMessages = false;
        })
        .catch((err) => {
          this.fetchingNewMessages = false;
          console.log("Fetch new Chats - err", err);
        });
    },

    // TODO : Check conflict with chat status in cache
    async updateChatStatusRead(selChatItem) {
      const curUserID = this.curUser.id;

      //const localId = selChatItem.id;
      if (selChatItem.from_user == this.curUser.user_id) return;
      let chat_status = selChatItem["chat_status"];
      if (chat_status) {
        var found_index = chat_status.findIndex((x) => x.user_id === curUserID);

        if (-1 !== found_index) {
          if (chat_status[found_index].status !== "new") return;
        }
      }
      //console.log("Update chat status {" +selChatItem.from_user +"):" +JSON.stringify(selChatItem));

      var cond = {
        group_id: this.groupToken,
        msg_id: selChatItem.id,
        new_status: "read",
      };

      updateGroupChatStatus(cond)
        .then((res) => {
          if (res.data.status === 0) {
            //console.log(JSON.stringify(res.data) + "  FOR : "+ localId);

            let new_chat_status = res.data.result.chat_status;

            let content = {
              msg_id: new_chat_status.chat_id,
              new_status: new_chat_status.status,
            };
            this.updateSpecificChatStatus(content);

            //TODO;
            //const obj = { chat: res.data.result, token_id: this.groupToken };
            //this.$store.dispatch("addChatMessage", obj);
          } else {
            console.log("ERROR:" + JSON.stringify(res.data));
          }
        })
        .catch((err) => {
          console.log("Update new Chats status- err", err);
        });

      if (!this.isWSAvailable) {
        let chatUpdate = {
          curUser: this.curUser,
          groupToken: this.groupToken,
          message_id: selChatItem.id,
          new_status: cond.new_status,
        };

        this.unsendChatStatusUpdate.push(chatUpdate);
        return; //TODO: que and update on connection available
      } else {
        updateGroupChatStatusWS(
          this.wsConnection,
          this.curUser,
          this.groupToken,
          cond.msg_id,
          cond.new_status
        );
      }
    },

    // Make the function wait until the connection is made...
    waitForSocketConnection(socket, callback) {
      var self = this;
      setTimeout(function () {
        if (socket.readyState === 1) {
          console.log("Connection is made");
          if (callback != null) {
            callback();
          }
        } else {
          console.log("wait for connection...");
          self.waitForSocketConnection(socket, callback);
        }
      }, 100); // wait 5 milisecond for the connection...
    },

    sendUnsentStatusUpdate() {
      for (let i = 0; i < this.unsendChatStatusUpdate.length; i++) {
        let obj = this.unsendChatStatusUpdate[i];

        updateGroupChatStatusWS(
          this.wsConnection,
          obj.curUser,
          obj.groupToken,
          obj.message_id,
          obj.new_status
        );
      }
    },

    //TODO : - fn for updating status when all users read message
    async updateSpecificChatStatus(content) {
      //console.log("update chat Status:"+JSON.stringify(content));
      const msg_id = content.msg_id;

      var foundIndex = this.chatItemsList.findIndex((x) => x.id == msg_id);
      if (-1 !== foundIndex) {
        this.chatItemsList[foundIndex].status = content.new_status;
      }
    },

    async appendReceivedChat(message) {
      //console.log("Append Called");
      if (this.friendInfo) {
        const group_id = this.friendInfo.id;
        const myid = this.curUser.user_id;

        if (message.group_id === group_id) {
          const index = message.id;
          var foundIndex = this.chatItemsList.findIndex((x) => x.id == index);
          if (-1 !== foundIndex) {
            this.chatItemsList[foundIndex] = message;

            //TODO;
            const obj = { chat: message, token_id: this.groupToken };
            this.$store.dispatch("addChatMessage", obj);
          } else {
            if (this.chatItemsList.length > 0) {
              const firstID = this.chatItemsList[0].id;
              if (message.id < firstID) {
                var newMessages = [];
                newMessages.push(message);
                let newList = [...newMessages, ...this.chatItemsList];
                this.chatItemsList.splice(
                  0,
                  this.chatItemsList.length,
                  newList
                );
              } else {
                this.chatItemsList.push(message);
              }
            } else {
              this.chatItemsList.push(message);
            }

            //TODO;
            const obj = { chat: message, token_id: this.groupToken };
            this.$store.dispatch("addChatMessage", obj);
          }

          this.scrollState.scrollSkip = this.chatItemsList.length;

          if (message.from_user === myid) {
            window.setTimeout(this.scrollToBottom, 300);
          }
        }
      }
    },

    newChatMessage(message) {
      console.log("New Chat Message by : " + JSON.stringify(this.curUser));
      var postData = {
        msgtype: message.type,
        message: message.message,
        sender_name: this.curUser.full_name,
      };

      if ("text" != message.type) {
        postData["media_path"] = message.media_path;
      }

      var replyTo = message.replyto;
      this.isReplyingToMessage = false; // Close reply window

      sendGroupChat(
        this.wsConnection,
        this.curUser,
        this.groupToken,
        postData,
        replyTo
      );
    },

    onCloseReplyBox() {
      this.isReplyingToMessage = false;
    },

    onChatItemSelected(curSelectedChatItem) {
      this.$emit("chat-item-selected", curSelectedChatItem);
    },

    replyChatMessage(selChatItem) {
      this.isReplyingToMessage = true;
      this.replyMessageContent = selChatItem;

      //this.copyToClipBoard(JSON.stringify(selChatItem));
      console.log(
        "Inside Group ChatWindow - Replying to Message :",
        JSON.stringify(selChatItem)
      );
    },

    getContent() {
      return document.getElementById("chatContent");
    },

    scrollToBottom() {
      console.log("scrollToBottom called");
      this.getContent().scrollToBottom(0);
    },

    scrollToTop() {
      console.log("scrollToTop called");
      this.getContent().scrollToTop(0);
    },
  }, //END - Methods
};
</script>