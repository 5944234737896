
<template>
  <ion-item
    @click="openConversationThread"
    lines="none"
    style="align-items: start !important; margin-bottom: 5px"
  >
    <ion-avatar
      size="small"
      slot="start"
      style="max-height: 30px; max-width: 30px"
    >
      <ion-img
        size="small"
        :src="profilePhotoThumbnail"
        @ionError="flagPhotoNotAvailable()"
      ></ion-img>
    </ion-avatar>
    <ion-label style="margin-top: 0px; line-height: 1.4">
      <b>{{ this.conversation.full_name }}</b
      ><br />
      <ion-icon
        size="small"
        :color="
          conversation.last_message_delivery_status === 'Seen' ? 'success' : ''
        "
        style="display: inline-block; vertical-align: middle"
        :icon="getSendStatusIcon(conversation.last_message_delivery_status)"
        v-if="conversation.sent_by_me"
      >
      </ion-icon>
      <small style="color: grey"
        >&nbsp;{{ this.conversation.last_message }}</small
      >
    </ion-label>
    <ion-label
      style="
        margin-top: 0px;
        margin-right: 5px;
        text-align: right;
        max-width: 30%;
      "
    >
      <ion-label
        v-if="this.conversation.new_msg_count === 0"
        style="font-size: 11px; margin-bottom: 10px"
      >
        {{ lastMessageTime(this.conversation.last_message_time) }}<br />
      </ion-label>
      <ion-label
        v-if="this.conversation.new_msg_count > 0"
        style="font-size: 11px; margin-bottom: 10px; color: #008cff"
      >
        {{ lastMessageTime(this.conversation.last_message_time) }}<br />
      </ion-label>
      <ion-avatar
        v-if="this.conversation.new_msg_count"
        size="small"
        class="ion-no-padding ion-no-margin"
        style="
          background-color: #008cff;
          max-width: 18px;
          max-height: 18px;
          position: absolute;
          right: 5px;
        "
      >
        <ion-label
          style="
            color: black;
            text-align: right;
            font-size: 14px;
            line-height: 1.2;
          "
          class="ion-text-center"
          ><small> {{ this.conversation.new_msg_count }}</small></ion-label
        >
      </ion-avatar>
    </ion-label>
  </ion-item>
</template>

<script>
import { useRouter } from "vue-router";
import { IonItem, IonLabel, IonAvatar, IonImg, IonIcon } from "@ionic/vue";
import {
  personOutline,
  checkmarkDoneOutline,
  checkmarkOutline,
  syncOutline,
} from "ionicons/icons";
import { lastMessageTime } from "../../services/utils.js";
import { getProfilePicture } from "../../services/apiCall";

export default {
  name: "ConversationListItem",
  props: ["conversation"],

  components: { IonItem, IonLabel, IonAvatar, IonImg, IonIcon },
  mounted() {
    this.init();
  },
  setup() {
    const router = useRouter();
    return {
      router,
      lastMessageTime,
      getProfilePicture,
    };
  },

  data() {
    return {
      profilePhotoThumbnail: "assets/icon/misc/avatar.svg",
      icon: {
        personOutline,
        checkmarkDoneOutline,
        checkmarkOutline,
        syncOutline,
      },
    };
  },

  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      return this.$store.getters.user;
    },
  },

  methods: {
    init() {
      this.getProfileThumbnail();
    },

    getEncodedName() {
      return window.btoa(this.conversation.full_name);
    },

    getSendStatusIcon(sendStatus) {
      if (sendStatus === "Sent") return this.icon.checkmarkOutline;
      else if (sendStatus === "Delivered")
        return this.icon.checkmarkDoneOutline;
      else if (sendStatus === "Seen") return this.icon.checkmarkDoneOutline;
      else return this.icon.syncOutline;
    },
    getProfileThumbnail() {
      getProfilePicture(this.conversation.tokenized_user)
        .then((res) => {
          this.profilePhotoThumbnail = res;
        })
        .catch((err) => {
          console.log("Profile URL - err", err);
        });
    },
    flagPhotoNotAvailable() {
      this.profilePhotoThumbnail = "assets/icon/misc/avatar.svg";
    },
    async openConversationThread() {
      await this.$store.dispatch("setconversationthreadlist", []);

      this.$router.push(
        "/conversations/" +
          this.conversation.tokenized_user +
          "/" +
          this.getEncodedName()
      );
    },
  },
};
</script>