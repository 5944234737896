<template>
  <base-layout
    page-title="Conversations"
    :enableHeaderShrink="true"
    contentID="_conversationsPage"
  >
    <template v-slot:actions-end>
      <ion-searchbar
        v-if="showInlineSearch"
        v-model="inlineSearchText"
        placeholder="Search User"
        class="ion-text-left search_box"
        @keydown.enter="inLineSearch"
        autofocus="true"
        inputmode="search"
        animated="true"
        clearIcon
        enterkeyhint
      >
      </ion-searchbar>
      <ion-button
        @click="
          isMobileScreen()
            ? this.$router.push('/chat/search')
            : (showInlineSearch = !showInlineSearch)
        "
        size="getToolButtonSize()"
        class="button_nopadding"
      >
        <ion-icon
          v-if="!showInlineSearch"
          src="assets/icon/misc/search.svg"
        ></ion-icon>
        <ion-icon
          slot="icon-only"
          v-if="showInlineSearch"
          size="getToolButtonSize()"
          :icon="icon.closeCircle"
        ></ion-icon>
      </ion-button>

      <ion-button
        size="getToolButtonSize()"
        fill="clear"
        @click="openMenu()"
        v-if="!showInlineSearch"
      >
        <ion-icon :icon="icon.ellipsisVertical"></ion-icon>
      </ion-button>
    </template>
    <ion-grid class="animate-fading-1">
      <ion-row>
        <chat-user-list
          v-if="userFFList.length > 0 && !createNewGroupClicked"
          :tokenizedUser="user.user_id"
          :isSelfUser="true"
          :userFFList="userFFList"
          :paramData="paramData"
          :paramDataType="paramDataType"
        >
        </chat-user-list>
      </ion-row>

      <ion-row>
        <ion-col
          :size="getColSize(false)"
          :offset="getOffsetSize(false)"
          class="ion-no-padding ion-no-margin"
        >
          <ion-item
            v-if="
              userFFList.length === 0 &&
              isFirstSearchDone &&
              !createNewGroupClicked
            "
            lines="none"
          >
            <span
              class="item-text-wrap ion-text-wrap dont-break-out"
              style="font-size: 16px; text-align: center"
            >
              {{ getDisplaySearchString() }}
            </span>
          </ion-item>
        </ion-col>
      </ion-row>

      <!-- new group creation -->
      <ion-row class="animate-fading ion-no-padding ion-no-margin">
        <ion-col
          :size="getColSize(false)"
          :offset="getOffsetSize(false)"
          class="ion-no-padding ion-no-margin"
        >
          <ion-item
            v-if="createNewGroupClicked"
            class="ion-no-padding ion-no-margin"
            no-lines
            lines="none"
          >
            <ion-label class="ion-text-center"
              ><b>{{ getStrings(strings, "DescribeyourGroup") }}</b></ion-label
            >
            <ion-button
              slot="end"
              color="primary"
              size="medium"
              @click="createNewGroupClicked = false"
              style="margin-right: 15px"
            >
              {{ getStrings(strings, "Cancel") }}
            </ion-button>
          </ion-item>
        </ion-col>
      </ion-row>

      <ion-row
        class="animate-fading ion-no-padding ion-no-margin"
        v-if="createNewGroupClicked"
      >
        <ion-col
          :size="getColSize(false)"
          :offset="getOffsetSize(false)"
          class="ion-no-padding ion-no-margin"
        >
          <create-new-group-form
            :createGroupCallback="createGroupCallback"
            :isChatGroupCreateCalled="true"
          ></create-new-group-form>
        </ion-col>
      </ion-row>
    </ion-grid>

    <ion-loading :is-open="fetchingFriendList"> </ion-loading>
  </base-layout>
</template>

<script>
import {
  IonLoading,
  IonSearchbar,
  IonIcon,
  IonItem,
  IonLabel,
  IonCol,
  IonRow,
  IonGrid,
  IonButton,
  actionSheetController,
  // popoverController,
} from "@ionic/vue";
import {
  closeCircle,
  search,
  ellipsisVertical,
  addOutline,
  closeOutline,
} from "ionicons/icons";
import {
  getColSize,
  getOffsetSize,
  isMobileScreen,
} from "../../services/utils";

import { useRouter } from "vue-router";
import { getUser } from "../../services/db";
import { friendsInChat, getUserEncryptionKeys } from "../../services/apiCall";
import ChatUserList from "../components/ChatUserList.vue";
//import UserListMenuOptions from "../components/menu/UserListMenuOptions.vue";
import CreateNewGroupForm from "../../components/groups/CreateNewGroupForm.vue";
import { getStrings } from "../../services/lang";

export default {
  components: {
    IonIcon,
    IonItem,
    IonCol,
    IonRow,
    IonGrid,
    IonLabel,
    IonButton,
    IonLoading,
    ChatUserList,
    IonSearchbar,
    CreateNewGroupForm,
  },
  ionViewWillLeave() {
    console.log("ChatUsers page will leave");
    if (this.friendsRefreshCallback !== null)
      clearInterval(this.friendsRefreshCallback);
  },

  setup() {
    return {
      getColSize,
      getOffsetSize,
      isMobileScreen,
      getStrings,
    };
  },
  data() {
    const router = useRouter();
    return {
      router,
      icon: { closeCircle, search, ellipsisVertical },

      userFFList: [],
      fetchingFriendList: false,
      isFirstSearchDone: false,

      friendsRefreshCallback: null,

      showInlineSearch: false,
      inlineSearchText: "",

      paramData: this.$route.params.data ? this.$route.params.data : "",
      paramDataType: this.$route.params.type ? this.$route.params.type : "text",

      createNewGroupClicked: false,

      optionIcons: {
        CREATE_GROUP: addOutline,
        CANCEL: closeOutline,
      },
    };
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    theFriendsList() {
      return this.$store.getters.friends;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  async ionViewDidEnter() {
    this.currentUser = await getUser("currentUser");
    this.checkCurrentUserEncryptionkey();
    this.loadFriends();

    this.init();
  },

  methods: {
    addFriend(friendData) {
      this.$store.dispatch("addfriend", friendData);
    },
    init() {
      this.friendsRefreshCallback = setInterval(this.updateFriends, 30000);
    },
    loadFriends() {
      this.fetchingFriendList = true;
      let friendList = this.$store.getters.chatConnections;
      if (friendList.length > 0) {
        this.userFFList = friendList;
        this.fetchingFriendList = false;
        this.updateFriends();

        this.isFirstSearchDone = true;
      } else {
        friendsInChat({ tokenized_user: this.tokenizedUser })
          .then((res) => {
            if (res.data.status === 0) {
              //console.log(JSON.stringify(res.data.result.friend_list));
              this.userFFList = res.data.result.friend_list;
              this.userFFList.forEach((obj) => {
                this.$store.dispatch("addChatConnection", obj);
              });
            }
            this.fetchingFriendList = false;
            this.isFirstSearchDone = true;
          })
          .catch((err) => {
            console.log("friend list - err", err);
            this.fetchingFriendList = false;
            this.logOff();
            this.isFirstSearchDone = true;
          });
      }
    },

    updateFriends() {
      friendsInChat({ tokenized_user: this.tokenizedUser })
        .then((res) => {
          if (res.data.status === 0) {
            const newList = res.data.result.friend_list;
            //console.log(JSON.stringify(newList));
            if (this.userFFList.length === newList.length) {
              for (var i = 0; i < this.userFFList.length; i++) {
                this.$store.dispatch("addChatConnection", this.userFFList[i]);

                if (this.userFFList[i].is_group) {
                  var result1 = newList.find((obj) => {
                    return (
                      obj.tokenized_group === this.userFFList[i].tokenized_group
                    );
                  });

                  if (result1) {
                    this.userFFList[i].recent_text = result1.recent_text;
                    this.userFFList[i].new_chat_count = result1.new_chat_count;
                    this.userFFList[i].createdAt = "";
                    this.userFFList[i].createdAt = result1.createdAt;
                  }
                } else {
                  var result = newList.find((obj) => {
                    return (
                      obj.tokenizedUser === this.userFFList[i].tokenizedUser
                    );
                  });

                  if (result) {
                    this.userFFList[i].recent_text = result.recent_text;
                    this.userFFList[i].new_chat_count = result.new_chat_count;
                    this.userFFList[i].createdAt = "";
                    this.userFFList[i].createdAt = result.createdAt;
                  }
                }
              }
            } else {
              this.userFFList = newList;
            }

            //console.log(JSON.stringify(this.userFFList));
          }
        })
        .catch((err) => {
          console.log("friend list - err", err);
          this.logOff();
        });
    },

    checkCurrentUserEncryptionkey() {
      var curUserKeys = this.$store.getters.userEncryptionKeys;
      if (! curUserKeys.private_key) {
        getUserEncryptionKeys({
          other_user_id: this.currentUser.tokenized_user,
        })
          .then((res) => {
            if (res.data.status === 0) {
              const result = res.data.result;
              var pubKey = result.public_key;
              var priKey = result.private_key;

              this.currentUser["public_key"] = pubKey;
              this.currentUser["private_key"] = priKey;

              var keyObj = {
                "public_key":pubKey,
                "private_key":priKey,
              };

              this.$store.dispatch("setUserEncryptionKeys", keyObj);
            }
          })
          .catch((err) => {
            console.log("userProfile - err", err);

            this.fetchingProfile = false;
          });
      } else {
        //console.log("Private Key already Set : " + curUserKays.private_key);
      }
    },

    handleAction(post, actionId) {
      console.log("Selected Action : ", actionId);
      if (actionId.id === "CREATE_GROUP") {
        this.onCreateGroupClicked();
      } else if (actionId.id === "CANCEL") {
        this.createGroupCallback();
      }
    },

    async openMenu() {
      this.actionSheetOptions = [
        {
          id: "CREATE_GROUP",
          name: this.getStrings(this.strings, "CreateGroup"),
        },
        { id: "CANCEL", name: this.getStrings(this.strings, "Cancel") },
      ];

      this.buttons = [];
      for (var idx = 0; idx < this.actionSheetOptions.length; idx++) {
        this.buttons.push({
          text: this.actionSheetOptions[idx].name,
          icon: this.optionIcons[this.actionSheetOptions[idx].id],
          size: "small",
          handler: this.handleAction.bind(
            this,
            this.actionSheetOptions[idx],
            this.actionSheetOptions[idx]
          ),
        });
      }

      const actionSheet = await actionSheetController.create({
        header: "Options",
        buttons: this.buttons,
      });

      await actionSheet.present();
    },

    async onCreateGroupClicked() {
      this.createNewGroupClicked = true;
    },

    createGroupCallback() {
      this.init();
      this.createNewGroupClicked = false;
    },

    async logOff() {
      this.$store.dispatch("adduser", {});
      this.router.replace("/");

      if (this.friendsRefreshCallback !== null)
        clearInterval(this.friendsRefreshCallback);
    },

    inLineSearch() {
      console.log("inLineSearch", this.inlineSearchText);
      this.router.push("/chat/search/" + this.inlineSearchText);
    },

    getDisplaySearchString() {
      //return this.getStrings(this.strings, "SearchUsersToStartConversation");
      return "Start new Conversations by searching users.";
    },
  },
};
</script>