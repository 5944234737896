<template>
  <ion-button
    fill="clear"
    size="small"
    color="primary"
    class="button_nopadding button_minimal"
    style="text-transform: none"
    @click="openUserListPopover"
    v-if="reactionCount > 0"
  >
    <span v-if="!this.commentId">
      {{ reactionCount }} {{ reactionCount === 1 ? getStrings(strings, "Reaction") : getStrings(strings, "Reactions") }}
    </span>
    <span v-if="this.commentId">
      {{ reactionCount }}  {{ reactionCount === 1 ? getStrings(strings, "Reaction") : getStrings(strings, "Reactions") }}
    </span>
  </ion-button>
</template>

<script>
import { thumbsUpOutline, thumbsUpSharp } from "ionicons/icons";
import { IonButton, popoverController } from "@ionic/vue";
import { defineComponent } from "vue";

import UserList from "./UserList.vue";
import { reactedBy, commentReactedBy } from "../../services/apiCall.js";
import { getStrings } from "../../services/lang";

export default defineComponent({
  name: "ReactionByButton",
  props: ["postId", "reactionCount", "commentId"],
  components: {
    IonButton,
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
  },
  setup() {
    return {
      getStrings,
    };
  },
  data() {
    return {
      thumbsUpOutline,
      thumbsUpSharp,
      popoverController,
      reactedByUsers: [],
    };
  },
  methods: {
    async getReactedByUsers() {
      if (this.commentId) {
        await commentReactedBy({ comment_id: this.commentId })
          .then(async (res) => {
            if (res.data.status == 0) {
              this.reactedByUsers = res.data.result.reaction_list;
              //console.log("getReactedByUsers called", this.reactionCount);
            }
          })
          .catch(async (err) => {
            console.log("reacted by api - err", err);
          });
      } else {
        await reactedBy({ post_id: this.postId })
          .then(async (res) => {
            if (res.data.status == 0) {
              this.reactedByUsers = res.data.result.reaction_list;
              //console.log("getReactedByUsers called", this.reactionCount);
            }
          })
          .catch(async (err) => {
            console.log("reacted by api - err", err);
          });
      }
    },
    async openUserListPopover(ev) {
      const popover = await popoverController.create({
        component: UserList,
        event: ev,
        translucent: true,
        cssClass: "userlist_popover",
      });

      await this.getReactedByUsers();

      //console.log("reacted by", this.postId, this.reactedByUsers);
      popover.componentProps = {
        users: this.reactedByUsers,
        parent: popover,
      };
      return popover.present();
    },
  },
});
</script>
