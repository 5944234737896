
import { openDB } from 'idb';
const CryptoJS = require('crypto-js');

const APP_KEY = '2b1cb114de2ee3d5696179930ae19ea0';

let _posts = [], _user = {}, _friends = [], _strings = [];

let _isIndexDBAvailable = true;

let _db = undefined;

let _hasObjectStoreOpened = false;

initDB();

export async function openObjectStore() {
    var tx = (await _db).transaction((await _db).objectStoreNames, "readwrite");

    _posts = tx.objectStore('posts');
    _user = tx.objectStore('user');
    _friends = tx.objectStore('friends');
    _strings = tx.objectStore('strings');

    _hasObjectStoreOpened = true;
}

export async function clearObjectStore() {
    if (!_isIndexDBAvailable) return;

    // clear the object stores 
    await openObjectStore();

    _posts.clear();
    _user.clear();
    _friends.clear();
    _strings.clear();
}

export async function closeDB() {
    if (!_isIndexDBAvailable) return;
    (await _db).close();
}

export async function initDB() {
    try {
        var t1 = Date.now();
        if (_db) {
            await closeDB();
        }

        _db = openDB("susamvad", 1, {
            upgrade(db, oldVersion, newVersion, transaction) {
                // …
                console.log("upgrade db", db, oldVersion, newVersion, transaction);

                _posts = db.createObjectStore('posts');
                _user = db.createObjectStore('user');
                _friends = db.createObjectStore('friends');
                _strings = db.createObjectStore('strings');
            },
            blocked() {
                // …
            },
            blocking() {
                // …
            },
            terminated() {
                // …
            },
        });

        await _db;

        // await clearObjectStore();

        if (!_hasObjectStoreOpened) {
            await openObjectStore();
        }

        _isIndexDBAvailable = true;
        console.log('Initdb Time:', Date.now() - t1);
        return;
    } catch (err) {
        console.log('initDB - indexdb not available', err);
        _isIndexDBAvailable = false;
    }
}

export async function putPost(key, value) {
    if (!_isIndexDBAvailable) {
        _posts[key] = value;
        return;
    }
    return (await _db).put('posts', value, key);
}

export async function getPost(key) {
    if (!_isIndexDBAvailable) {
        return _posts[key];
    }
    return (await _db).get('posts', key);
}

export async function getPosts() {
    if (!_isIndexDBAvailable) return Object.values(_posts);
    var posts = await (await _db).getAllKeys('posts');
    var postList = [];
    for (var postKey in posts) {
        postList.push(await getPost(posts[postKey]));
    }
    return postList;
}

export async function setPosts(postList) {
    if (!_isIndexDBAvailable) {
        _posts = postList;
        return;
    }
    try {
        for (var post in postList) {
            var thePost = postList[post];
            // console.log('the post', post, thePost);
            await putPost(thePost.id, thePost);
        }
    } catch (err) {
        console.log("indexdb no available", err);
    }
}

export async function clearAllPosts() {
    try {
        await _posts.clear();
    } catch(ignored) {
        ignored;
    }
}

export async function putUser(key, value) {
    if (!_isIndexDBAvailable) {
        _user = value;
        return;
    }
    // console.log('putuser', key, value);
    let encryptUser = CryptoJS.AES.encrypt(JSON.stringify(value), APP_KEY).toString();
    localStorage.setItem("user", encryptUser);

    // console.log('[enc] putuser', key, encryptUser);
    return (await _db).put('user', encryptUser, key);
}

export async function getUser(key) {
    if (!_isIndexDBAvailable) {
        return _user;
    }

    try {
        let encryptUser = await ((await _db).get('user', key));
        localStorage.setItem("user", encryptUser);
        // console.log('[enc] getuser', encryptUser);
        let decryptUser = CryptoJS.AES.decrypt(encryptUser, APP_KEY).toString(CryptoJS.enc.Utf8);
        // console.log('getuser', decryptUser);
        return JSON.parse(decryptUser);
    } catch(err) {
        let encryptUser = localStorage.getItem("user");
        let decryptUser = CryptoJS.AES.decrypt(encryptUser, APP_KEY).toString(CryptoJS.enc.Utf8);
        return JSON.parse(decryptUser);
    }
}

export async function putFriend(key, value) {
    if (!_isIndexDBAvailable) {
        _friends = value;
        return;
    }
    // console.log('putuser', key, value);
    return (await _db).put('friends', value, key);
}

export async function getFriends() {
    if (!_isIndexDBAvailable) {
        return _friends;
    }
    var flist = await (await _db).get('friends', 'friendList');

    if (typeof (flist) === 'undefined') flist = [];

    // console.log('getFriends', flist);

    return flist;
}

export async function setFriends(friendList) {
    if (!_isIndexDBAvailable) {
        _friends = friendList;
        return;
    }
    (await _db).put('friends', friendList, 'friendList');
}

export async function putStrings(value) {
    if (!_isIndexDBAvailable) {
        _strings = value;
        return;
    }
    return (await _db).put('strings', value, 'strings');
}

export async function getStrings(key) {
    if (!_isIndexDBAvailable) {
        return _strings;
    }
    // console.log('getStrings', key);
    return (await _db).get('strings', key);
}

export function readFromDB($store, onlySettings = false) {
    return async () => {
        await $store.dispatch("adduser", await getUser('currentUser'));
        await $store.dispatch("addstrings", await getStrings('strings'));

        if (!onlySettings) {
            await $store.dispatch("setposts", await (await getPosts()).sort((a, b) => {
                var aDate = new Date(a.createdAt).getTime();
                var bDate = new Date(b.createdAt).getTime();
                return (bDate - aDate);
            })); // resort based on createdAt
            await $store.dispatch("setfriends", await getFriends());
        }
    };
}
