<template>
  <ion-content>
    <ion-list class="ion-no-padding ion-no-margin">
      <ion-item
        class="ion-padding-horizontal ion-no-margin"
        v-for="person in peopleList"
        v-bind:key="person.tokenized_user"
        @click="personSelected(person)"
        :value="person.tokenized_user"
        no-lines
        lines="no"
      >
        <ion-avatar
          slot="start"
          size="small"
          style="min-width: 50px; min-height: 50px; cursor: pointer"
          class="top-margin-10px bottom-margin-10px left-margin-10px"
        >
          <ion-img
            v-if="!person.profile_photo.photo_thumbnail"
            size="small"
            src="assets/icon/misc/avatar.svg"
          ></ion-img>
          <ion-img
            v-if="person.profile_photo.photo_thumbnail"
            size="small"
            :src="person.profile_photo.photo_thumbnail"
          ></ion-img>
        </ion-avatar>
        <ion-label>&nbsp;{{ person.full_name }}</ion-label>
      </ion-item>
    </ion-list>
  </ion-content>
</template>
<script>
import {
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonAvatar,
  IonImg,
} from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "MentionsList",
  props: ["peopleList", "parent", "personSelectedCallback"],
  components: {
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    IonAvatar,
    IonImg,
  },
  mounted() {
    console.log("MentionsList page mounted");
    this.init();
  },
  data() {
    return {
    };
  },
  methods: {
    init() {},
    personSelected(person) {
      console.log("personSelected", person.full_name);
      this.parent.dismiss();
      if (this.personSelectedCallback) this.personSelectedCallback(person);
    },
  },
});
</script>
