const CHAT_BASE_URL = "wss://next-chat.vlifesciences.com";

export function getChatFeedURL() {
    return CHAT_BASE_URL + "/feed";
}

export async function userJoinChat(wsconn, usr, other_user_or_group="") {
    var parcel = {
        type: 'checkin',
        payload: {
            'userid': usr.user_id,
            'fullname': usr.full_name,
            'usertoken': usr.tokenized_user,
            'auth': {
                'token': usr.auth.token,
                'session': usr.auth.session,
            },
            'other':other_user_or_group,
        }
    };

    //console.log("Sending Join Request : " + JSON.stringify(parcel));
    wsconn.send(JSON.stringify(parcel));
}

export async function sendGroupChat(wsconn, usr, tousr, postData, replyTo) {
    var parcel = {
        type: 'simplegroupchatmessage',
        payload: {
            'userid': usr.user_id,
            'fullname': usr.full_name,
            'usertoken': usr.tokenized_user,
            'auth': {
                'token': usr.auth.token,
                'session': usr.auth.session,
            },

            'togroup': tousr,
            'replyto': replyTo,

            'content': postData,
        }
    };

    console.log("Sending Group chat : " + JSON.stringify(parcel));
    wsconn.send(JSON.stringify(parcel));
}


export async function forwardGroupChat(wsconn, usr, tousr, postData, replyTo, forwardInfo) {
    var parcel = {
        type: 'simplegroupchatmessage',
        payload: {
            'userid': usr.user_id,
            'fullname': usr.full_name,
            'usertoken': usr.tokenized_user,
            'auth': {
                'token': usr.auth.token,
                'session': usr.auth.session,
            },

            'togroup': tousr,
            'replyto': replyTo,

            'content': postData,

            'forwardfrom':  forwardInfo.from, //"User", //Group
            'forwardid': forwardInfo.fwdID, // id - entry in fwd table
            'forwardchatid': forwardInfo.chatid,
        }
    };

    console.log("Sending Group chat : " + JSON.stringify(parcel));
    wsconn.send(JSON.stringify(parcel));
}

export async function sendChat(wsconn, usr, tousr, postData, replyTo) {
    var parcel = {
        type: 'simplechatmessage',
        payload: {
            'userid': usr.user_id,
            'fullname': usr.full_name,
            'usertoken': usr.tokenized_user,
            'auth': {
                'token': usr.auth.token,
                'session': usr.auth.session,
            },

            'touser': tousr,
            'replyto': replyTo,

            'content': postData,
        }
    };

    console.log("Sending chat : " + JSON.stringify(parcel));
    wsconn.send(JSON.stringify(parcel));
}

export async function forwardChat(wsconn, usr, tousr, postData, replyTo, forwardInfo) {
    var parcel = {
        type: 'simplechatmessage',
        payload: {
            'userid': usr.user_id,
            'fullname': usr.full_name,
            'usertoken': usr.tokenized_user,
            'auth': {
                'token': usr.auth.token,
                'session': usr.auth.session,
            },

            'touser': tousr,
            'replyto': replyTo,

            'content': postData,

            'forwardfrom':  forwardInfo.from, //"User", //Group
            'forwardid': forwardInfo.fwdID, // id - entry in fwd table
            'forwardchatid': forwardInfo.chatid,
        }
    };

    console.log("Sending chat : " + JSON.stringify(parcel));
    wsconn.send(JSON.stringify(parcel));
}

export async function notifyDeleteChatWS(wsconn, usr, tousr,msgid) {
    var parcel = {
        type: 'deletechat',
        payload: {
            'userid': usr.user_id,
            'fullname': usr.full_name,
            'usertoken': usr.tokenized_user,
            'auth': {
                'token': usr.auth.token,
                'session': usr.auth.session,
            },

            'touser': tousr,
            'msgid':msgid,    
        }
    };

    //console.log("Deleting chat : " + JSON.stringify(parcel));
    wsconn.send(JSON.stringify(parcel));
}

export async function updateChatStatusWS(wsconn, usr, tousr,msgid, newStatus) {
    var parcel = {
        type: 'updatechatstatus',
        payload: {
            'userid': usr.user_id,
            'fullname': usr.full_name,
            'usertoken': usr.tokenized_user,
            'auth': {
                'token': usr.auth.token,
                'session': usr.auth.session,
            },

            'touser': tousr,

            'msgid':msgid,
            'newstatus':newStatus
        }
    };

    //console.log("Updating chat status: " + JSON.stringify(parcel));
    wsconn.send(JSON.stringify(parcel));
}

export async function updateGroupChatStatusWS(wsconn, usr, groupid,msgid, newStatus) {
    var parcel = {
        type: 'updategroupchatstatus',
        payload: {
            'userid': usr.user_id,
            'fullname': usr.full_name,
            'usertoken': usr.tokenized_user,
            'auth': {
                'token': usr.auth.token,
                'session': usr.auth.session,
            },

            'groupid': groupid,

            'msgid':msgid,
            'newstatus':newStatus
    
        }
    };

    console.log("chat api Updating group chat status: " + JSON.stringify(parcel));
    wsconn.send(JSON.stringify(parcel));
}

export async function getFriendsList(wsconn, usr) {
    var parcel = {
        type: 'friendlist',
        payload: {
            'userid': usr.user_id,
            'fullname': usr.full_name,
            'usertoken': usr.tokenized_user,
            'auth': {
                'token': usr.auth.token,
                'session': usr.auth.session,
            }
        }
    };

    console.log("Sending Friends Request : " + JSON.stringify(parcel));
    wsconn.send(JSON.stringify(parcel));
}
