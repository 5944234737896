<template>
  <ion-card class="ion-no-margin ion-margin-bottom animate-fading">
    <ion-card-content class="ion-no-padding ion-no-margin">
      <span no-lines lines="none" class="ion-no-padding ion-no-margin">
        <!-- post header -->
        <span no-lines lines="none">
          <post-header :post="post" :editedPost="postEditCheck"></post-header>
        </span>

        <div v-if="!post.edit_mode" class="ion-no-padding ion-no-margin">
          <!-- text posts -->
          <ion-item
            no-lines
            lines="none"
            v-if="post.is_active && post.preview_type === 'Text'"
            class="
              ion-padding-horizontal ion-justify-content-center ion-text-wrap
              word-wrap:
              break-word;
              word-break;
            "
          >
            <ion-text
              :class="
                post.reaction_count > 0 ||
                post.comment_count > 0 ||
                post.share_count > 0
                  ? 'overflow-text ion-justify-content-center'
                  : 'overflow-text text_post_padding ion-justify-content-center'
              "
              v-if="!post.showExpanded"
            >
              <div class="line_hight_one_point_four">
                <span
                  :innerHTML="preFormat(truncateWithEllipses(post, 150))"
                ></span>
                <a
                  @click="showFullPost(post)"
                  style="color: #808080; cursor: pointer"
                >
                  <small>
                    <span v-if="post.preview.length > 150">
                      <b>&nbsp;Read More</b>
                      <ion-spinner v-if="expandClicked" slot="end" name="dots">
                      </ion-spinner>
                    </span>
                  </small>
                </a>
              </div>
            </ion-text>
            <div
              :class="
                post.reaction_count > 0 ||
                post.comment_count > 0 ||
                post.share_count > 0
                  ? 'overflow-text ion-justify-content-center line_hight_one_point_four animate-fade-in'
                  : 'overflow-text text_post_padding ion-justify-content-center line_hight_one_point_four animate-fade-in'
              "
              v-if="post.showExpanded"
              :innerHTML="preFormat(tagAndMentions(urlify(post.description)))"
            ></div>
          </ion-item>

          <!-- document and image post preview -->
          <ion-item
            no-lines
            lines="none"
            v-if="
              post.is_active &&
              (post.preview_type === 'Document' ||
                post.preview_type === 'Media') &&
              typeof generateLinkPreviewContent(post) !== 'undefined' &&
              generateLinkPreviewContent(post) !== ''
            "
            class="
              ion-padding-horizontal
              ion-no-margin
              ion-align-self-center
              ion-justify-content-center
              ion-text-wrap
              word-wrap:
              break-word;
              word-break;
            "
            :innerHTML="generateLinkPreviewContent(post)"
          >
          </ion-item>

          <!-- link and document preview -->
          <ion-item
            no-lines
            lines="none"
            v-if="post.is_active && post.preview_type === 'Document'"
            class="
              ion-no-padding
              ion-no-margin
              ion-align-self-center
              ion-justify-content-center
              ion-text-wrap
              word-wrap:
              break-word;
              word-break;
            "
          >
            <a
              target="_nextExternalLink"
              style="text-decoration: none"
              :href="post.previewData.url"
            >
              <ion-card
                class="
                  ion-padding-vertical-photo
                  ion-justify-content-center
                  ion-no-margin
                  ion-align-self-center
                  ion-text-wrap
                  full_width
                "
              >
                <span style="width: 100%" class="animate-fading">
                  <ion-img
                    width="100%"
                    alt=".  .(No Image Available).  ."
                    :src="post.previewData.imgSrc"
                    class="animate-fading"
                  ></ion-img>
                </span>

                <ion-card-header
                  class="
                    ion-padding-vertical-photo
                    ion-padding-horizontal
                    ion-no-margin
                  "
                >
                  <ion-card-subtitle class="ion-no-margin">
                    <a target="_nextExternalLink" :href="post.previewData.url"
                      >{{ post.previewData.siteName }}
                    </a></ion-card-subtitle
                  ><ion-card-title class="ion-no-margin">
                    {{ post.previewData.title }}
                  </ion-card-title></ion-card-header
                ><ion-card-content
                  class="
                    ion-padding-horizontal ion-no-margin
                    card_content_custom
                  "
                >
                  <div class="ion-no-margin">
                    {{ post.previewData.description }}
                  </div>
                </ion-card-content></ion-card
              >
            </a>
          </ion-item>

          <!-- image/video only post -->
          <ion-item
            no-lines
            lines="none"
            v-if="post.is_active && post.preview_type === 'Media'"
            class="
              ion-no-padding
              ion-no-margin
              ion-text-wrap
              ion-align-self-center
              ion-justify-content-center
              ion-text-wrap
              word-wrap:
              break-word;
              word-break;
            "
            @click="toggleBackdrop(post)"
          >
            <span
              style="width: 100%"
              class="animate-fading ion-justify-content-center"
              v-if="
                !post.previewData.is_video_post &&
                !post.previewData.is_photo_album
              "
            >
              <ion-img
                alt=".  .(Image not available or Link expired).  ."
                @ionError="onImageLoadError(post)"
                class="
                  ion-padding-vertical-photo
                  ion-justify-content-center
                  ion-no-margin
                  ion-align-self-center
                  ion-text-wrap
                  animate-fading
                "
                :src="post.previewData.imgSrc"
              ></ion-img>

              <span
                v-if="
                  post.previewData.sale_specifics &&
                  post.previewData.sale_specifics !== ''
                "
              >
                <ion-row v-if="!this.post.previewData.is_sold">
                  <ion-col class="left-margin-5px">
                    <small
                      >Pay to <b>{{ post.user_fullname }}</b> by scanning using
                      any UPI app.</small
                    >
                  </ion-col>
                </ion-row>
                <ion-row v-if="!this.post.previewData.is_sold">
                  <ion-col
                    size="4"
                    offset="4"
                    class="ion-justify-content-center"
                  >
                    <qrcode-vue :value="getPaymentCode(post)"></qrcode-vue>
                  </ion-col>
                </ion-row>
                <ion-row v-if="!this.post.previewData.is_sold">
                  <ion-col class="left-margin-5px">
                    <small
                      ><b>Disclaimer</b>: Payments are directly made to the
                      seller to the UPI handle they provide. Please contact
                      seller directly for any purchase related query. NEXT or
                      its parent company is not responsible for any issues with
                      payments or reciept of the products sold by the
                      seller.</small
                    >
                  </ion-col>
                </ion-row>
                <ion-row v-if="this.post.previewData.is_sold">
                  <ion-col size="6" offset="3">
                    <ion-text color="danger">
                      <h1><b>SOLD OUT</b></h1>
                    </ion-text>
                  </ion-col>
                </ion-row>
              </span>
            </span>
            <span
              style="width: 100%"
              class="animate-fading ion-justify-content-center"
              v-if="post.previewData.is_photo_album"
            >
              <ion-row class="ion-no-padding ion-no-margin">
                <ion-col
                  :size="calculateColSize(post)"
                  :key="photo"
                  v-for="photo in post.previewData.images"
                  class="center_image_col"
                  @click="toggleBackdrop(post, photo)"
                >
                  <ion-item
                    no-lines
                    lines="none"
                    class="ion-no-padding ion-no-margin"
                  >
                    <ion-img
                      alt="Loading..."
                      @ionError="onAlbumImageLoadError(post, photo)"
                      class="
                        ion-justify-content-center
                        ion-no-margin
                        ion-align-self-center
                        ion-text-wrap
                        animate-fading
                        gallery-item
                      "
                      :src="photo"
                    ></ion-img>
                  </ion-item>
                </ion-col>
              </ion-row>
            </span>
            <span
              style="width: 100%"
              class="animate-fading ion-justify-content-center"
              v-if="
                post.previewData.is_video_post &&
                !post.previewData.is_photo_album
              "
            >
              <video
                class="video_player ion-justify-content-center"
                :src="post.previewData.imgSrc"
                type="video/mp4"
                controls
                autoplay
                playsinline
                muted
                @error="onImageLoadError(post)"
              ></video>
            </span>
          </ion-item>
          <ion-item
            no-lines
            lines="none"
            v-if="!post.is_active"
            class="
              ion-padding-horizontal ion-justify-content-center ion-text-wrap
              word-wrap:
              break-word;
              word-break;
            "
          >
            <ion-text class="overflow-text">
              {{ post.preview }}
            </ion-text>
          </ion-item>

          <!-- post footer -->
          <post-footer
            :postId="post.id"
            :post="post"
            :showComments="showComments"
          ></post-footer>
        </div>
      </span>
    </ion-card-content>
  </ion-card>
</template>

<script>
import {
  IonItem,
  IonText,
  IonSpinner,
  IonCard,
  IonImg,
  IonRow,
  IonCol,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  popoverController,
  modalController,
} from "@ionic/vue";

import {
  book,
  repeatOutline,
  thumbsUp,
  arrowRedoOutline,
  chatboxEllipsesOutline,
} from "ionicons/icons";

import {
  fetchPost,
  fetchPostContent,
  downloadImage,
  renewImageURL,
} from "../../services/apiCall.js";
import { putPost } from "../../services/db.js";

import {
  formatDate,
  isMobileScreen,
  preFormat,
  tagAndMentions,
  urlify,
  isIOS,
} from "../../services/utils";
import PostFooter from "./PostFooter.vue";
import PostHeader from "./PostHeader.vue";
import PostImageModal from "./PostImageModal.vue";

import QrcodeVue from "qrcode.vue";

import CryptoJS from "crypto-js";

export default {
  props: ["post", "isSinglePost"],
  components: {
    IonItem,
    IonText,
    IonSpinner,
    IonCard,
    IonCardContent,
    IonImg,
    IonRow,
    IonCol,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    PostFooter,
    PostHeader,
    QrcodeVue,
  },
  data() {
    return {
      urlify,
      tagAndMentions,
      preFormat,
      book,
      repeatOutline,
      thumbsUp,
      chatboxEllipsesOutline,
      arrowRedoOutline,
      popoverController,
      disableLikeButton: false,
      isCommentListVisible: false,
      expandClicked: false,
      commentCount: this.post.comment_count,
      showComments: this.isSinglePost ? this.isSinglePost : false,
      postEditCheck: {
        edit_mode: false,
      },
      userListView: {
        users: [],
        count: 2,
      },
    };
  },
  setup() {
    return {
      fetchPost,
      putPost,
      formatDate,
      isMobileScreen,
      renewImageURL,
      isIOS,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    truncateWithEllipses(post, max) {
      if (post.preview) return post.preview;
      var text = post.description;
      return text.substr(0, max - 1) + (text.length > max ? "..." : "");
    },
    showFullPost(post) {
      this.expandClicked = true;
      fetchPostContent(post.post_id)
        .then(async (res) => {
          if (res.data.status === 0) {
            post.description = res.data.result.post_content;
            post.content_type = res.data.result.content_type;
            post.showExpanded = !post.showExpanded;
            this.expandClicked = false;
          } else {
            console.log("post content fetch err - 1", res.data.error);
            this.expandClicked = false;
          }
        })
        .catch((err) => {
          console.log("post content fetch err - 2", err);
          this.expandClicked = false;
        });
    },
    async toggleBackdrop(post, photo = "") {
      if (post.previewData.is_photo_album && photo === "") return;
      try {
        var previewData = post.previewData;

        if (post.previewData.is_video_post) return; // not for video posts at the moment

        var imgSrc = previewData.images[0];
        var allImages = [];

        if (previewData.is_photo_album) {
          imgSrc = "";
          allImages = previewData.images;
        }

        const modal = await modalController.create({
          component: PostImageModal,
          mode: "md",
        });
        var currentIndex = allImages.indexOf(photo);
        modal.componentProps = {
          title: post.user_fullname,
          imgSrc: imgSrc,
          parent: modal,
          albumImages: allImages,
          currentPhotoIndex: currentIndex,
        };
        return modal.present();
      } catch (err) {
        console.log("preview err", err);
      }
    },
    generateLinkPreviewContent(post) {
      if (!post.is_active) return post.preview;

      try {
        var previewData = post.previewData;
        if (typeof previewData.userContent !== "undefined") {
          return preFormat(tagAndMentions(urlify(previewData.userContent)));
        } else {
          return "";
        }
      } catch (err) {
        console.log("preview err", err);
      }
    },
    onImageLoadError(post) {
      // attempt to download the file again to see what the error actually is
      downloadImage(post.previewData.imgSrc)
        .then((res) => {
          console.log("downloadImage", post.previewData.imgSrc, res);
        })
        .catch((err) => {
          if (err.response.data.indexOf("Request has expired") >= 0) {
            // if the request has expired, try to regenerate the Link and update the post
            renewImageURL({
              name: post.previewData.image_object_name,
              post_id: post.post_id,
            })
              .then((res) => {
                if (typeof post.preview === "string") {
                  try {
                    post.preview = JSON.parse(post.preview);
                  } catch (ignored) {
                    console.log("preview parse error", ignored);
                  }
                }
                if (res.data.status === 0) {
                  post.preview["images"][0] =
                    res.data.result.updated_preview.images[0];
                  post.previewData.images[0] =
                    res.data.result.updated_preview.images[0];
                  post.previewData.imgSrc =
                    res.data.result.updated_preview.images[0];
                }
              })
              .catch((err) => {
                console.log("renewImageURL - err", err);
              });
          }
        });
    },
    onAlbumImageLoadError(post, photo) {
      // attempt to download the file again to see what the error actually is
      var filterIndex = post.previewData.image_object_name_list.findIndex(
        (x) => photo.indexOf(x) !== -1
      );
      downloadImage(photo)
        .then((res) => {
          console.log("downloadImage", photo, res);
        })
        .catch((err) => {
          if (err.response.data.indexOf("Request has expired") >= 0) {
            // if the request has expired, try to regenerate the Link and update the post
            renewImageURL({
              name: post.previewData.image_object_name_list[filterIndex],
              post_id: post.post_id,
            })
              .then((res) => {
                if (typeof post.preview === "string") {
                  try {
                    post.preview = JSON.parse(post.preview);
                  } catch (ignored) {
                    console.log("preview parse error", ignored);
                  }
                }
                if (res.data.status === 0) {
                  post.preview["images"][0] =
                    res.data.result.updated_preview.images[0];
                  post.previewData.images[filterIndex] =
                    res.data.result.updated_preview.images[0];
                  post.previewData.imgSrc =
                    res.data.result.updated_preview.images[0];
                }
              })
              .catch((err) => {
                console.log("renewImageURL - err", err);
              });
          }
        });
    },
    getPaymentCode(post) {
      if (!post.previewData) return "";
      if (!post.previewData.sale_specifics) return "";
      try {
        let saleSpecifics = JSON.parse(
          CryptoJS.AES.decrypt(
            post.previewData.sale_specifics,
            this.post.tokenized_user
          ).toString(CryptoJS.enc.Utf8)
        );
        let payURI =
          "upi://pay?pa=" +
          saleSpecifics.upi +
          "&pn=" +
          this.post.user_fullname +
          "&tn=" +
          saleSpecifics.note +
          "&am=" +
          saleSpecifics.amount;
        return payURI;
      } catch (ignored) {
        console.log("getPaymentCode - err", ignored);
        return "";
      }
    },
    calculateColSize(post) {
      try {
        var colSizes = [12, 12, 6, 4, 6, 4, 4, 3, 3, 4, 3];
        return colSizes[post.previewData.images.length];
      } catch (e) {
        return 12;
      }
    },
  },
};
</script>