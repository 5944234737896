
<template>
  <!-- group search -->
  <ion-row class="animate-fading ion-no-padding ion-no-margin" v-if="shareData">
    <ion-col
      size="12"
      class="ion-no-padding ion-no-margin"
    >
      <ion-item class="ion-no-padding ion-no-margin" no-lines lines="none">
        <ion-label slot="start" class="left-margin-15px"><b>{{ getStrings(strings, 'Select group to share') }}</b></ion-label> 
        <ion-button
          slot="end"
          class="right-margin-15px"
          @click="cancelShare()"
        >
        {{ getStrings(strings, 'Cancel') }}
        </ion-button>

      </ion-item>
    </ion-col>
  </ion-row>
  <ion-row class="animate-fading ion-no-padding ion-no-margin" v-if="!createNewGroupClicked">
    <ion-col class="ion-no-padding ion-no-margin">
      <ion-item class="ion-no-padding ion-no-margin" no-lines lines="none">
        <ion-searchbar
          type="text"
          placeholder="Search Groups"
          v-model="searchText"
          autofocus="true"
          @keyup.enter="searchTheGroups"
          @ionChange="searchTheGroups"
          :disabled="groupSearchInProgress"
          debounce="1000"
          clearInput
        >
        </ion-searchbar>
      </ion-item>
    </ion-col>
  </ion-row>
  <ion-row class="animate-fading ion-no-padding ion-no-margin" v-if="groupSearchInProgress || groupsLoading">
    <ion-col class="ion-no-padding ion-no-margin">
      <ion-item class="ion-no-padding ion-no-margin" no-lines lines="none">
        <ion-spinner
          v-if="groupSearchInProgress || groupsLoading"
          slot="start"
          name="dots"
        ></ion-spinner>
      </ion-item>
    </ion-col>
  </ion-row>

  <!-- new group creation -->
  <ion-row class="animate-fading ion-no-padding ion-no-margin" v-if="!shareData">
    <ion-col size="12" class="ion-no-padding ion-no-margin">
      <ion-item
        no-lines lines="none"
        class="ion-no-padding ion-no-margin"
        v-if="!createNewGroupClicked"
      >
        <ion-button
          slot="end"
          size="normal"
          shape="round"
          fill="clear"
          @click="createNewGroup"
          style="ion-no-padding ion-no-margin"
        >
          + {{ getStrings(strings, 'CreateGroup') }}
        </ion-button>
      </ion-item>

      <ion-item
        v-if="createNewGroupClicked"
        class="ion-no-padding ion-no-margin"
        no-lines
        lines="none"
      >
        <ion-label class="ion-text-center"
          ><b>{{ getStrings(strings, 'DescribeyourGroup') }}</b></ion-label
        >
        <ion-button
          slot="end"
          color="primary"
          size="medium"
          @click="createNewGroupClicked = false"
          style="margin-right: 15px"
        >
          {{ getStrings(strings, 'Cancel') }}
        </ion-button>
      </ion-item>
    </ion-col>
  </ion-row>

  <ion-row class="animate-fading ion-no-padding ion-no-margin" v-if="createNewGroupClicked">
    <ion-col
      size="12"
      class="ion-no-padding ion-no-margin"
    >
      <create-new-group-form :createGroupCallback="createGroupCallback" :isChatGroupCreateCalled="false"></create-new-group-form>
    </ion-col>
  </ion-row>

  <!-- searched group list -->
  <ion-row
    v-if="searchList.length > 0 && !createNewGroupClicked"
    class="animate-fading ion-no-padding ion-no-margin"
  >
    <ion-col size="12" class="ion-no-padding ion-no-margin">
      <group-list
        :groupListName="'Searched Groups'"
        :groups="searchList"
        :shareData="shareData"
        :shareDataType="shareDataType"
      >
      </group-list>
    </ion-col>
  </ion-row>

  <!-- Join group request list -->
  <ion-row class="animate-fading ion-no-padding ion-no-margin" v-if="!createNewGroupClicked && searchList.length === 0">
    <ion-col
      size="12"
      v-if="joinGroupRequestList.length > 0"
      class="ion-no-padding ion-no-margin"
    >
      <group-list
        :groupListName="getStrings(strings, 'GroupJoinRequests')"
        :groups="joinGroupRequestList"
      ></group-list>
    </ion-col>
  </ion-row>
  <!-- Invite group list -->
  <ion-row class="animate-fading ion-no-padding ion-no-margin" v-if="!createNewGroupClicked && searchList.length === 0">
    <ion-col
      size="12"
      v-if="inviteGroupList.length > 0"
      class="ion-no-padding ion-no-margin"
    >
      <group-list
        :groupListName="getStrings(strings, 'Group') + ' ' + getStrings(strings, 'Invites')"
        :groups="inviteGroupList"
      ></group-list>
    </ion-col>
  </ion-row>
  <!-- admin group list -->
  <ion-row class="animate-fading ion-no-padding ion-no-margin" v-if="!createNewGroupClicked && searchList.length === 0">
    <ion-col
      size="12"
      v-if="adminGroupList.length > 0"
      class="ion-no-padding ion-no-margin"
    >
      <group-list
        :groupListName="getStrings(strings, 'Administered') + ' ' + getStrings(strings, 'Groups')"
        :groups="adminGroupList"
      ></group-list>
    </ion-col>
  </ion-row>

  <!-- member group list -->
  <ion-row class="animate-fading ion-no-padding ion-no-margin" v-if="!createNewGroupClicked && searchList.length === 0">
    <ion-col
      size="12"
      v-if="memberGroupList.length > 0"
      class="ion-no-padding ion-no-margin"
    >
      <group-list
        :groupListName="getStrings(strings, 'Member') + ' ' + getStrings(strings, 'Groups')"
        :groups="memberGroupList"
        :shareData="shareData"
        :shareDataType="shareDataType"
      ></group-list>
    </ion-col>
  </ion-row>

  <!-- public group list -->
  <ion-row class="animate-fading" v-if="!createNewGroupClicked && searchList.length === 0 && !shareData">
    <ion-col
      size="12"
      v-if="publicGroupList.length > 0"
      class="ion-no-padding ion-no-margin"
    >
      <group-list
        :groupListName="getStrings(strings, 'Public') + ' ' + getStrings(strings, 'Groups')"
        :groups="publicGroupList"
      ></group-list>
    </ion-col>
  </ion-row>
  <br/>
</template>

<script>
import {
  IonButton,
  IonRow,
  IonCol,
  IonItem,
  IonSearchbar,
  IonSpinner,
  IonLabel,
} from "@ionic/vue";
import { search } from "ionicons/icons";
import { useRouter } from "vue-router";
import {
  isMobileScreen,
  getColSize,
  getOffsetSize,
} from "../../services/utils";
import { getStrings } from "../../services/lang";

import { listGroups, searchGroup } from "../../services/apiCall";

import GroupList from "./GroupList.vue";

import CreateNewGroupForm from "./CreateNewGroupForm.vue";

export default {
  name: "GroupsTab",
  props: ["shareData", "shareDataType"],
  mounted() {
    console.log("GroupsTab tab mounted");
    this.init();
    this.groupsLoading = false;
  },

  components: {
    IonButton,
    IonRow,
    IonCol,
    IonItem,
    IonSearchbar,
    IonSpinner,
    IonLabel,
    GroupList,
    CreateNewGroupForm,
  },
  setup() {
    const router = useRouter();
    return {
      router,
      isMobileScreen,
      getColSize,
      getOffsetSize,
      listGroups,
      getStrings,
    };
  },
  data() {
    return {
      searchText: "",
      groupsLoading: true,
      groupSearchInProgress: false,
      icon: {
        search,
      },
      createNewGroupClicked: false,
      memberGroupList: [],
      adminGroupList: [],
      publicGroupList: [],
      searchList: [],
      inviteGroupList: [],
      joinGroupRequestList: [],
    };
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    init() {
      // list member only group
      console.log('Shre Group:', this.shareData, this.shareDataType);
      listGroups({ list_type: "member" })
        .then((res) => {
          if (res.data.status === 0) {
            var groupList = res.data.result.group_list;
            if (!this.shareData) {
              this.memberGroupList = groupList.filter((y) => y.group_member_type !== 'Admin');
              this.adminGroupList = groupList.filter((y) => y.group_member_type === 'Admin');
              this.inviteGroupList = res.data.result.invite_group_list;
              this.joinGroupRequestList = res.data.result.join_group_request_list;
            } else {
              this.memberGroupList = groupList;
            }
            
          }
        })
        .catch((err) => {
          console.log("listGroups (member) - err", err);
        });

      // list public group
      listGroups({ list_type: "public" })
        .then((res) => {
          console.log('public - groups', res);
          if (res.data.status === 0) {
            this.publicGroupList = res.data.result.group_list.filter((y) => y.is_group_member === false);
            this.publicGroupList = this.publicGroupList.filter((y) => y.has_active_invite === false);
            this.publicGroupList = this.publicGroupList.filter((y) => y.has_active_join_request === false);
          }
        })
        .catch((err) => {
          console.log("listGroups (public) - err", err);
        });
    },
    searchTheGroups() {
      this.groupSearchInProgress = true;

      this.searchList = [];

      if (this.searchText === "") {
        this.groupSearchInProgress = false;
        return;
      }

      searchGroup({ search_string: this.searchText })
        .then((res) => {
          console.log('searched - groups', res);
          if (res.data.status === 0) {
            this.searchList = res.data.result.group_list;
          }

          this.groupSearchInProgress = false;
        })
        .catch((err) => {
          this.groupSearchInProgress = false;
          console.log("searchGroups - err", err);
        });
    },
    createNewGroup() {
      this.createNewGroupClicked = true;
      console.log("createNewGroup");
    },
    createGroupCallback() {
      this.init();
      this.createNewGroupClicked = false;
    },
    async cancelShare() {
      await this.router.replace("/posts");
    },
  },
};
</script>