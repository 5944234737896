<template>
  <ion-content fullscreen="true">
    <ion-slides :options="slideOpts" style="height: 100%">
      <ion-slide style="height: 100%">
        <div class="swiper-zoom-container" style="height: 100%">
          <img :src="imgSrc" />
        </div>
      </ion-slide>
    </ion-slides>
    <ion-button
      shape="round"
      color="light"
      @click="close()"
      class="over-slider image_close_button"
    >
      <ion-icon
        color="dark"
        slot="icon-only"
        size="large"
        :icon="closeOutline"
      ></ion-icon>
    </ion-button>
    <ion-button
      shape="round"
      color="light"
      @click="download(imgSrc)"
      class="over-slider image_close_button"
      style="left:50px;"
    >
      <ion-icon
        color="dark"
        slot="icon-only"
        size="large"
        :icon="saveOutline"
      ></ion-icon>
    </ion-button>   
  </ion-content>
</template>

<script>
import { closeOutline, saveOutline } from "ionicons/icons";
import {
  IonContent,
  IonButton,
  IonSlide,
  IonSlides,
  IonIcon,
} from "@ionic/vue";

import { defineComponent } from "vue";

export default defineComponent({
  name: "PostImageModal",
  props: ["title", "imgSrc", "parent"],
  data() {
    return {
      closeOutline,
      saveOutline,
    };
  },
  components: {
    IonContent,
    IonButton,
    IonSlide,
    IonSlides,
    IonIcon,
  },
  mounted() {},
  setup() {
    const slideOpts = {
      zoom: true,
      initialSlide: 1,
      allowTouchMove: false,
      autoHeight: true,
      centeredSlides: true,
      followFinger: false,
      longSwipes: false,
      noSwiping: true,
    };
    return { slideOpts };
  },
  methods: {
    close() {
      if (typeof this.parent !== "undefined") this.parent.dismiss();
    },

//https://www.joshmorony.com/using-the-capacitor-filesystem-api-to-store-photos/
    download(url) {
      window.open(url, "_blank");
    },
  },
});
</script>
