<template>
  <ion-grid
    style="height: 100%; display: flex; flex-flow: column"
    class="ion-no-padding ion-no-margin"
  >
    <ion-row
      style="flex-grow: 1"
      v-if="isFirstScrollDone"
      class="ion-no-padding ion-no-margin"
    >
      <ion-col
        :size="getColSize(false)"
        :offset="getOffsetSize(false)"
        class="ion-no-padding ion-no-margin"
      >
        <ion-content id="chatContent" name="chatContent" :scroll-events="true">
          <ion-infinite-scroll
            position="top"
            @ionInfinite="loadScrollChats($event)"
            threshold="100px"
            id="infinite-scroll"
            :disabled="scrollState.scrollDisabled"
          >
            <ion-infinite-scroll-content
              loading-spinner="bubbles"
              loading-text="Loading more chats..."
            >
            </ion-infinite-scroll-content>
          </ion-infinite-scroll>
          <chat-list
            :chatItemsList="chatItemsList"
            :curUser="curUser"
            @reply-chat="replyChatMessage"
            @request-load-chats="loadScrollChats"
            @message-read="updateChatStatusRead"
            @chat-item-selected="onChatItemSelected"
            :scrollState="scrollState"
            :loadScrollChats="loadScrollChats"
            :mutationCallback="scrollToBottom"
          >
          </chat-list>
        </ion-content>
      </ion-col>
    </ion-row>
    <ion-row
      style="
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 15px;
        margin-top: 5px;
      "
    >
      <ion-col :size="getColSize(false)" :offset="getOffsetSize(false)">
        <chat-box
          @send-chat="newChatMessage"
          @close-reply-box="onCloseReplyBox"
          :isReplying="isReplyingToMessage"
          :replyContent="replyMessageContent"
          :isWSAvailable="isWSAvailable"
          :paramData="paramData"
          :paramDataType="paramDataType"
        >
        </chat-box>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import {
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/vue";
import { useRouter } from "vue-router";
import {
  isMobileScreen,
  getColSize,
  getOffsetSize,
  defaultPostsPerFetch,
} from "../../services/utils";

import ChatList from "./ChatList.vue";
import ChatBox from "./ChatBox.vue";

import { sendChat, updateChatStatusWS } from "../services/chatApiCall";
import {
  fetchChatInRange,
  updateChatStatus,
  fetchChatsAfterTime,
} from "../../services/apiCall.js";

import { Crypt /*, RSA*/ } from "hybrid-crypto-js";
var crypt = new Crypt({ md: "sha512" });

export default {
  name: "ChatWindow",
  emits: ["chat-item-selected"],
  props: [
    "isWSAvailable",
    "wsConnection",
    "curUser",
    "curUserKeys",
    "friendToken",
    "friendInfo",
    "paramData",
    "paramDataType",
  ],
  watch: {
    wsConnection: function () {
      console.log("From Chat Window : Connection updated");
      this.socketConnection = this.wsConnection;
      this.initWS();
    },
  },

  ionViewDidEnter() {
    console.log("Chat window did enter", this.user);
  },
  ionViewDidLeave() {
    console.log("Chat window did leave");
  },
  ionViewWillEnter() {
    console.log("Chat window will enter");
  },
  ionViewWillLeave() {
    console.log("Chat window will leave");
  },
  mounted() {
    //console.log("Chat window mounted");
    this.init();
    if (this.wsConnection) this.initWS();
    //this.initWS();
  },
  activated() {
    console.log("Chat window activated");
  },
  components: {
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    ChatBox,
    ChatList,
  },
  setup() {
    const router = useRouter();
    return {
      router,
      isMobileScreen,
      getColSize,
      getOffsetSize,
    };
  },
  data() {
    return {
      socketConnection: this.wsConnection,

      chatItemsList: [],

      fetchingNewMessages: false,

      isReplyingToMessage: false,
      replyMessageContent: [],

      firstLoadCount: 15,
      scrollState: {
        scrollDisabled: false,
        scrollSkip: 0,
      },

      limit: defaultPostsPerFetch(),

      isFirstScrollDone: false,

      timeLatestChat: -1,
      timeStartChat: -1,

      unsendChatStatusUpdate: [],
    };
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      return this.$store.getters.user;
    },
  },

  methods: {
    init() {
      this.fnFetchNewChats();
    },

    initWS() {
      var self = this;

      //console.log("Initializing WebSocket call back");
      this.socketConnection.onmessage = function (event) {
        var msg = JSON.parse(event.data);
        //console.log("Received :" + JSON.stringify(msg));

        switch (msg.type) {
          case "users-checkedin":
            console.log(event);

            break;

          case "send-chat":
            var contentobj = msg.payload;
            //console.log(JSON.stringify(contentobj));
            self.appendReceivedChat(contentobj);
            break;

          case "update-chat-status":
            var contentobje = msg.payload;
            self.updateSpecificChatStatus(contentobje);
            break;

          case "delete-chat":
            var contentobj2 = msg.payload;
            self.updateDeletedChatStatus(contentobj2);
            break;
        }
      };

      if (self.unsendChatStatusUpdate.length > 0) {
        self.waitForSocketConnection(
          this.socketConnection,
          self.sendUnsentStatusUpdate
        );
        //self.sendUnsentStatusUpdate();
      }
    },

    updateChatCreateTime() {
      this.chatItemsList.forEach((x) => {
        var curTime = new Date(x.createdAt).getTime();
        if (this.timeLatestChat === -1) {
          this.timeLatestChat = curTime;
        } else {
          if (this.timeLatestChat < curTime) this.timeLatestChat = curTime;
        }

        if (this.timeStartChat === -1) {
          this.timeStartChat = curTime;
        } else {
          if (this.timeStartChat > curTime) this.timeStartChat = curTime;
        }
      });

      //console.log("Time  Latest :" +this.timeLatestChat +" -- time start : " +this.timeStartChat);
    },

    fnFetchNewChats() {
      if (this.fetchingNewMessages) return;
      this.fetchingNewMessages = true;

      //console.log("Fetching New Chats....");
      this.chatItemsList = [];
      var sState = this.scrollState;
      sState.scrollSkip = 0;

      let cache_msgs = this.$store.getters.chatMessages(this.friendToken);
      if (cache_msgs) {
        this.chatItemsList.splice(0, this.chatItemsList.length, ...cache_msgs);
        sState.scrollSkip = cache_msgs.length;

        window.setTimeout(this.scrollToBottom, 1000);
        this.fetchingNewMessages = false;
        this.isFirstScrollDone = true;

        this.updateChatCreateTime();

        fetchChatsAfterTime({
          from_user_id: this.friendToken,
          fetchTime: this.timeLatestChat,
        })
          .then((res) => {
            if (res.data.status === 0) {
              let newMessages = res.data.result.chat_messages;
              //console.log("fnFetchNewChats-fetchChatsAfterTime:"+JSON.stringify(this.curUser));
              newMessages.forEach((objMsg) => {
                this.appendReceivedChat(objMsg);
              });

              //console.log("Fetchingg Chats Timely -- appended");
            } else {
              console.log("Fetchingg Chats Timely -- empty chats");
              //console.log(JSON.stringify(res.data));
            }
          })
          .catch((err) => {
            this.fetchingNewMessages = false;
            console.log("Fetch new time Chats - err", err);
          });
      } else {
        fetchChatInRange({
          from_user_id: this.friendToken,
          limit: this.firstLoadCount,
          skip: 0,
        })
          .then((res) => {
            if (res.data.status === 0) {
              let newMessages = res.data.result.chat_messages;

              //console.log("fnFetchNewChats-fetchChatInRange:" +JSON.stringify(this.curUserKeys));
              if (undefined !== this.curUserKeys) {
                const private_key = this.curUserKeys.private_key;
                newMessages.forEach((objMsg) => {
                  if (objMsg.content.encdata) {
                    try {
                      let decrypted = crypt.decrypt(
                        private_key,
                        objMsg.content.encdata
                      );
                      let msg = decodeURIComponent(decrypted.message);
                      objMsg.content["data"] = msg;
                    } catch (err) {
                      objMsg.content["data"] = objMsg.content.encdata;
                    }
                  }
                });
              }

              //console.log("Total Messages : " + newMessages.length);
              this.chatItemsList = newMessages;

              newMessages.forEach((objMsg) => {
                var obj = { chat: objMsg, token_id: this.friendToken };
                this.$store.dispatch("addChatMessage", obj);
              });

              sState.scrollSkip = newMessages.length;
            }

            window.setTimeout(this.scrollToBottom, 1000);
            this.fetchingNewMessages = false;
            this.isFirstScrollDone = true;
          })
          .catch((err) => {
            this.fetchingNewMessages = false;
            console.log("Fetch new Chats - err", err);
          });
      }
    },

    loadScrollChats(ev) {
      if (this.fetchingNewMessages) {
        ev.target.complete();
        return;
      }

      this.fetchingNewMessages = true;

      const sState = this.scrollState;
      var cond = {
        from_user_id: this.friendToken,
        limit: this.limit,
        skip: sState.scrollSkip,
      };
      sState.scrollSkip = sState.scrollSkip + this.limit;

      fetchChatInRange(cond)
        .then((res) => {
          if (res.data.status === 0) {
            //console.log(JSON.stringify(res.data.result.chat_messages));
            let newMessages = res.data.result.chat_messages;

            if (newMessages.length > 0) {
              this.chatItemsList = [...newMessages, ...this.chatItemsList];
            }
            this.scrollState.scrollSkip = this.chatItemsList.length;

            if (undefined !== this.curUserKeys) {
              const private_key = this.curUserKeys.private_key;
              newMessages.forEach((objMsg) => {
                if (objMsg.content.encdata) {
                  console.log("Found encrypted message");

                  let decrypted = crypt.decrypt(
                    private_key,
                    objMsg.content.encdata
                  );
                  let msg = decodeURIComponent(decrypted.message);
                  objMsg.content["data"] = msg;
                  //console.log("decrypted message : ", objMsg.content["data"]);
                }
              });
            }

            newMessages.forEach((objMsg) => {
              var obj = { chat: objMsg, token_id: this.friendToken };
              this.$store.dispatch("addChatMessage", obj);
            });

            ev.target.complete();

            if (newMessages.length == 0) {
              sState.scrollDisabled = true;
            }
          } else {
            // window.setTimeout(this.scrollToTop, 300);
            ev.target.complete();
          }

          this.fetchingNewMessages = false;
        })
        .catch((err) => {
          this.fetchingNewMessages = false;
          console.log("Fetch new Chats - err", err);
        });
    },

    // TODO : Check conflict with chat status in cache
    async updateChatStatusRead(selChatItem) {
      const localId = selChatItem.id;
      //console.log("New msg from other user mark it as Read [" + selChatItem.id + "]");
      var cond = {
        from_user_id: this.friendToken,
        msg_id: selChatItem.id,
        new_status: "read",
      };

      updateChatStatus(cond)
        .then((res) => {
          if (res.data.status === 0) {
            //console.log(JSON.stringify(res.data) + "  FOR : "+ localId);

            let content = {
              msg_id: localId,
              new_status: "read",
            };
            this.updateSpecificChatStatus(content);
          } else {
            console.log("ERROR:" + JSON.stringify(res.data));
          }
        })
        .catch((err) => {
          console.log("Update new Chats status- err", err);
        });

      if (!this.isWSAvailable) {
        let chatUpdate = {
          curUser: this.curUser,
          friendToken: this.friendToken,
          message_id: selChatItem.id,
          new_status: cond.new_status,
        };

        this.unsendChatStatusUpdate.push(chatUpdate);
        return; //TODO: que and update on connection available
      } else {
        updateChatStatusWS(
          this.wsConnection,
          this.curUser,
          this.friendToken,
          cond.msg_id,
          cond.new_status
        );
      }
    },

    // Make the function wait until the connection is made...
    waitForSocketConnection(socket, callback) {
      var self = this;
      setTimeout(function () {
        if (socket.readyState === 1) {
          console.log("Connection is made");
          if (callback != null) {
            callback();
          }
        } else {
          console.log("wait for connection...");
          self.waitForSocketConnection(socket, callback);
        }
      }, 100); // wait 5 milisecond for the connection...
    },

    sendUnsentStatusUpdate() {
      for (let i = 0; i < this.unsendChatStatusUpdate.length; i++) {
        let obj = this.unsendChatStatusUpdate[i];

        updateChatStatusWS(
          this.wsConnection,
          obj.curUser,
          obj.friendToken,
          obj.message_id,
          obj.new_status
        );
      }
    },

    async updateSpecificChatStatus(content) {
      //{"type":"update-chat-status","payload":{"msg_id":59,"new_status":"read"}}
      const msg_id = content.msg_id;

      var foundIndex = this.chatItemsList.findIndex((x) => x.id == msg_id);
      if (-1 !== foundIndex) {
        this.chatItemsList[foundIndex].status = content.new_status;
      }
    },

    updateSpecificChatStatusCaller(content){
      this.updateSpecificChatStatus(content)
    },

    async updateDeletedChatStatus(content) {
      // {"msg_id":177,"deleted":true}
      const msg_id = content.msg_id;

      var foundIndex = this.chatItemsList.findIndex((x) => x.id == msg_id);
      if (-1 !== foundIndex) {
        this.chatItemsList[foundIndex].deleted = content.deleted;
      }
    },
    
    updateDeletedChatStatusCaller(content) {
      this.updateDeletedChatStatus(content);
    },

    async appendReceivedChat(message) {
      if (this.friendInfo) {
        const fnid = this.friendInfo.id;
        const myid = this.curUser.user_id;

        try {
          if (undefined !== this.curUserKeys) {
            const private_key = this.curUserKeys.private_key;
            if (message.content.encdata) {
              const decrypted = crypt.decrypt(
                private_key,
                message.content.encdata
              );
              const msg = decodeURIComponent(decrypted.message);
              message.content["data"] = msg;
            }
          }
        } catch (err) {
          this.fetchingNewMessages = false;
          console.log("Append new Chats - err", err);
          return;
        }

        if (
          (message.from_user_id === fnid && message.to_user_id === myid) ||
          (message.from_user_id === myid && message.to_user_id === fnid)
        ) {
          const index = message.id;
          var foundIndex = this.chatItemsList.findIndex((x) => x.id == index);
          if (-1 !== foundIndex) {
            this.chatItemsList[foundIndex] = message;
            const obj = { chat: message, token_id: fnid };
            this.$store.dispatch("addChatMessage", obj);
          } else {
            this.chatItemsList.push(message);
            const obj = { chat: message, token_id: fnid };
            this.$store.dispatch("addChatMessage", obj);
          }

          this.scrollState.scrollSkip = this.chatItemsList.length;

          if (message.from_user_id === myid) {
            window.setTimeout(this.scrollToBottom, 300);
          }
        }
      }
    },

    newChatMessage(message) {
      var messageString = message.message;

      var postData = {
        msgtype: message.type,
        message: ".", //message.message,
      };

      //TODO:Encryption - only sending encrypted - block normal data
      if (this.friendInfo.public_key) {
        //console.log("Encrypted USING : "+this.friendInfo.public_key);
        messageString = crypt.encrypt(
          this.friendInfo.public_key,
          messageString
        );
        //console.log("Encrypted String:"+messageString);

        messageString = this.sanitizeString(messageString);
        postData["encmessage"] = messageString;
      }

      if ("text" != message.type) {
        postData["media_path"] = message.media_path;
      }

      var replyTo = message.replyto;
      this.isReplyingToMessage = false; // Close reply window
      //console.log("SENDING chat - ", JSON.stringify(postData));

      sendChat(
        this.wsConnection,
        this.curUser,
        this.friendToken,
        postData,
        replyTo
      );
    },

    onCloseReplyBox() {
      this.isReplyingToMessage = false;
    },

    onChatItemSelected(curSelectedChatItem) {
      this.$emit("chat-item-selected", curSelectedChatItem);
    },

    replyChatMessage(selChatItem) {
      this.isReplyingToMessage = true;
      this.replyMessageContent = selChatItem;

      //this.copyToClipBoard(JSON.stringify(selChatItem));
      console.log(
        "Inside ChatWindow - Replying tp Message :",
        JSON.stringify(selChatItem)
      );
    },

    getContent() {
      return document.getElementById("chatContent");
    },

    scrollToBottom() {
      console.log("scrollToBottom called");
      this.getContent().scrollToBottom(0); //500 - animation delay
    },

    scrollToTop() {
      console.log("scrollToTop called");
      this.getContent().scrollToTop(0);
    },

    sanitizeString(str) {
      var str1 = str.trim();
      str1 = str1.replaceAll('"', '\\"');
      str1 = str1.replaceAll("\n", "\\n");
      str1 = str1.replaceAll("\r", "\\r");
      str1 = encodeURIComponent(str1);

      return str1;
    },
  }, //END - Methods
};
</script>