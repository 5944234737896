<template>
  <ion-page ion-no-padding ion-no-margin>
    <ion-header ion-no-padding ion-no-margin>
      <ion-toolbar
        mode="md"
        color="primary"
        class="ion-no-padding ion-no-margin"
        v-if="!isChatItemSelected"
      >
        <ion-item color="primary">
          <ion-buttons
            slot="start"
            size="small"
            style="padding: 0px"
            class="ion-no-padding ion-no-margin"
          >
            <ion-back-button
              size="small"
              slot="icon-only"
              style="padding: 0px"
              class="ion-no-padding ion-no-margin"
              v-if="showBackButton"
            ></ion-back-button>
            <ion-button v-if="showCancelButton" @click="this.$router.replace('/tabs/chats');">
              <ion-icon :icon="icon.arrowBackOutline"></ion-icon>
            </ion-button>
          </ion-buttons>

          <ion-avatar
            size="small"
            style="min-width: 40px; min-height: 40px; cursor: pointer"
            class="top-margin-15px bottom-margin-15px right-margin-10px"
            ion-no-padding
            ion-no-margin
          >
            <ion-img
              v-if="!otheruser.photo && !this.otheruser.isGroup"
              size="large"
              src="assets/icon/misc/avatar.svg"
            ></ion-img>
            <ion-img
              v-if="!otheruser.photo && this.otheruser.isGroup"
              size="large"
              src="assets/icon/misc/group.svg"
            ></ion-img>
            <ion-img
              v-if="otheruser.photo"
              size="large"
              :src="otheruser.photo"
            ></ion-img>
          </ion-avatar>
          <ion-text style="color: #efefef" v-if="otheruser.full_name">
            <span class="br">
              <b
                style="
                  cursor: pointer;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                "
                >{{ otheruser.full_name }}</b
              >
              <small
                v-if="
                  !isUserOnline(otheruser.last_seen) &&
                  undefined !== otheruser.last_seen
                "
                class="br"
                style="color: #afafaf"
                >{{ formatChatTime(otheruser.last_seen) }}</small
              >
              <small
                v-if="isUserOnline(otheruser.last_seen)"
                class="br"
                style="color: #afafaf"
                >Online
              </small>
            </span>
          </ion-text>
          <ion-buttons slot="end" class="ion-no-padding ion-no-margin" v-if="!hideChatControls">
            <ion-button size="small" fill="clear" @click="openVideo()">
              <ion-icon
                slot="icon-only"
                :icon="icon.videocamOutline"
              ></ion-icon>
            </ion-button>
            <ion-button size="small" fill="clear" @click="openCall()">
              <ion-icon slot="icon-only" :icon="icon.callOutline"></ion-icon>
            </ion-button>
            <ion-button size="small" fill="clear" @click="openMenu()">
              <ion-icon
                slot="icon-only"
                :icon="icon.ellipsisVertical"
              ></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-item>
      </ion-toolbar>

      <ion-toolbar
        mode="md"
        color="primary"
        class="ion-no-padding ion-no-margin"
        v-if="isChatItemSelected"
      >
        <ion-item color="primary">
          <ion-buttons
            slot="start"
            size="small"
            style="padding: 0px"
            class="ion-no-padding ion-no-margin"
            @click="selectionCancelClicked()"
          >
            <ion-button
              size="small"
              fill="clear"
              @click="selectionCancelClicked()"
            >
              <ion-icon
                slot="icon-only"
                :icon="icon.arrowBackOutline"
              ></ion-icon>
            </ion-button>
          </ion-buttons>

          <ion-buttons slot="end" class="ion-no-padding ion-no-margin">
            <ion-button
              size="small"
              fill="clear"
              @click="selectionReplyClicked()"
            >
              <ion-icon
                slot="icon-only"
                src="assets/icon/misc/arrow-undo-outline.svg"
              ></ion-icon>
            </ion-button>
            <ion-button
              size="small"
              fill="clear"
              @click="selectionDeleteClicked()"
            >
              <ion-icon
                slot="icon-only"
                src="assets/icon/misc/trash-outline.svg"
              ></ion-icon>
            </ion-button>
            <ion-button
              size="small"
              fill="clear"
              @click="selectionCopyClicked()"
            >
              <ion-icon
                slot="icon-only"
                src="assets/icon/misc/copy-outline.svg"
              ></ion-icon>
            </ion-button>
            <ion-button
              size="small"
              fill="clear"
              @click="selectionForwardClicked()"
            >
              <ion-icon
                slot="icon-only"
                src="assets/icon/misc/arrow-redo-outline.svg"
              ></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-item>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <slot />
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonItem,
  IonContent,
  IonButton,
  IonButtons,
  IonBackButton,
  IonAvatar,
  IonImg,
  IonText,
  IonIcon,
  toastController,
} from "@ionic/vue";

import {
  videocamOutline,
  callOutline,
  ellipsisVertical,
  copyOutline,
  trashOutline,
  arrowUndoOutline,
  arrowRedoOutline,
  arrowBackOutline,
} from "ionicons/icons";

import {
  appVersion,
  appName,
  isMobileScreen,
  formatChatTime,
} from "../../services/utils.js";

export default {
  emits: [
    "menu-cancel",
    "menu-reply",
    "menu-delete",
    "menu-copy",
    "menu-forward",
    "base-menu-clicked",
  ],
  props: [
    "pageTitle",
    "showBackButton",
    "pageDefaultBackLink",
    "otheruser",
    "isChatItemSelected",
    "showCancelButton",
    "hideChatControls",
  ],
  setup() {
    return { appVersion, appName, isMobileScreen, formatChatTime };
  },
  data() {
    return {
      icon: {
        videocamOutline,
        callOutline,
        ellipsisVertical,
        copyOutline,
        trashOutline,
        arrowUndoOutline,
        arrowRedoOutline,
        arrowBackOutline,
      },
    };
  },
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonItem,
    IonContent,
    IonButton,
    IonButtons,
    IonBackButton,
    IonAvatar,
    IonImg,
    IonText,
    IonIcon,
  },
  methods: {
    capitalizeFirstLetter(string) {
      var str = string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
      return str;
    },

    async openVideo() {
      const toast = await toastController.create({
        message: "Coming soon ...",
        duration: 1500,
      });
      //console.log("ChatItem status :".this.is);
      return toast.present();
      // this.$router.replace("/videochat/" + this.otheruser.user_id);
    },
    async openCall() {
      const toast = await toastController.create({
        message: "Coming soon ...",
        duration: 1500,
      });
      //console.log("ChatItem status :".this.is);
      return toast.present();
    },

    async openMenu() {
      this.$emit("base-menu-clicked");
    },

    isUserOnline(recentTime) {
      //console.log(recentTime);
      var lastSeen = new Date(recentTime).getTime();
      var currentTime = new Date().getTime();
      var diffTime = currentTime - lastSeen;

      if (diffTime < 120 * 1000) return true;
      return false;
    },

    selectionCancelClicked() {
      this.$emit("menu-cancel");
    },
    selectionReplyClicked() {
      this.$emit("menu-reply");
    },
    selectionForwardClicked() {
      this.$emit("menu-forward");
    },
    selectionCopyClicked() {
      this.$emit("menu-copy");
    },
    selectionDeleteClicked() {
      this.$emit("menu-delete");
    },
  },
};
</script>