<template>
  <ion-page>
    <ion-content
      id="_conversationThreadContent"
      :scroll-events="true"
      fullscreen="true"
    >
      <ion-fab vertical="top" horizontal="start" slot="fixed">
        <ion-fab-button
          size="small"
          color="medium"
          style="position: absolute; left: 10px; top: 20px"
          @click="onCallToggle"
        >
          <ion-icon size="small" :icon="icon.arrowBackOutline"></ion-icon>
        </ion-fab-button>
      </ion-fab>
      <ion-fab vertical="top" horizontal="end" slot="fixed">
        <ion-fab-button
          size="small"
          :color="speakerOff ? 'medium' : 'dark'"
          style="position: absolute; right: 10px; top: 20px"
          @click="onSpeakerToggle()"
        >
          <ion-icon
            size="small"
            :icon="speakerOff ? icon.volumeMuteOutline : icon.volumeHighOutline"
          ></ion-icon>
        </ion-fab-button>
      </ion-fab>
      <ion-grid style="margin-top: 75px">
        <ion-row>{{roomID}}</ion-row>
        <ion-row id="video-grid-1"> </ion-row>
        <ion-row id="video-grid-2"> </ion-row>
      </ion-grid>
    </ion-content>
    <ion-footer style="position: absolute; bottom: 10px">
      <ion-row style="margin-bottom: 10px">
        <ion-col class="center_image_col">
          <ion-fab-button color="medium" @click="onCameraReverse()">
            <ion-icon :icon="icon.cameraReverseOutline"></ion-icon>
          </ion-fab-button>
        </ion-col>
        <ion-col class="center_image_col">
          <ion-fab-button
            :color="micOff ? 'medium' : 'tertiary'"
            @click="onMicToggle()"
          >
            <ion-icon
              :icon="micOff ? icon.micOffOutline : icon.micOutline"
            ></ion-icon>
          </ion-fab-button>
        </ion-col>
        <ion-col class="center_image_col">
          <ion-fab-button
            :color="videocamOff ? 'medium' : 'secondary'"
            @click="onVideoToggle()"
          >
            <ion-icon
              :icon="
                videocamOff ? icon.videocamOffOutline : icon.videocamOutline
              "
            ></ion-icon>
          </ion-fab-button>
        </ion-col>
        <ion-col class="center_image_col">
          <ion-fab-button
            :color="callDisconnected ? 'danger' : 'success'"
            @click="onCallToggle()"
          >
            <ion-icon :icon="icon.callOutline"></ion-icon>
          </ion-fab-button>
        </ion-col>
      </ion-row>
    </ion-footer>
  </ion-page>
</template>

<script>
import {
  IonFooter,
  IonPage,
  IonFab,
  IonFabButton,
  IonContent,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  toastController,
  loadingController,
} from "@ionic/vue";

import {
  cameraReverseOutline,
  videocamOutline,
  videocamOffOutline,
  micOutline,
  micOffOutline,
  callOutline,
  arrowBackOutline,
  volumeHighOutline,
  volumeMuteOutline,
} from "ionicons/icons";

import { logOffApp } from "../../services/utils";
import { readFromDB } from "../../services/db";
import { getStrings } from "../../services/lang";
import {
  chatFriendsProfile,
  getConversationConfig,
} from "../../services/apiCall";
import { getVideoChatRoomID } from "../../services/microservice";

import Peer from "peerjs";
const io = require("socket.io-client");

/**
 * based on: https://levelup.gitconnected.com/building-a-video-chat-app-with-node-js-socket-io-webrtc-26f46b213017
 * also: https://blog.devgenius.io/how-to-create-a-video-chat-application-with-a-custom-stun-and-turn-server-8c6258542324
 */
export default {
  name: "VideoConversation",

  ionViewDidEnter() {
    console.log("VideoConversation did enter");
    this.readDBAndInit();
  },
  ionViewDidLeave() {
    console.log("VideoConversation page did leave");
  },
  ionViewWillEnter() {
    console.log("VideoConversation page will enter");
  },
  ionViewWillLeave() {
    console.log("VideoConversation page will leave");
  },
  components: {
    IonFooter,
    IonPage,
    IonFab,
    IonFabButton,
    IonContent,
    IonIcon,
    IonGrid,
    IonRow,
    IonCol,
  },
  setup() {
    return {
      getStrings,
      getConversationConfig,
    };
  },
  mounted() {
    console.log("VideoConversation mounted");
  },
  data() {
    return {
      inUserRefreshMode: false,

      myVideoStream: undefined,
      videoGrid: undefined,
      myVideo: undefined,

      otherTokenizedEntity: this.$route.params.vchatuserid,
      otheruser: {},

      peer: undefined,

      socket: undefined,

      roomID: this.$route.params.roomid ? this.$route.params.roomid : undefined,

      initialConversationType: this.$route.params.calltype
        ? this.$route.params.calltype
        : "video",

      peerID: undefined,

      backCameraOn: false,
      micOff: false,
      videocamOff: false,
      callDisconnected: false,
      speakerOff: true,

      lastAddedGrid: 0,

      icon: {
        cameraReverseOutline,
        videocamOutline,
        videocamOffOutline,
        micOutline,
        micOffOutline,
        callOutline,
        arrowBackOutline,
        volumeHighOutline,
        volumeMuteOutline,
      },

      audioSettings: {
        autoGainControl: true,
        echoCancellation: true,
        noiseSuppression: true,
      },

      videoSettings: {
        // 720 p resolution
        width: 1280,
        height: 720,
        facingMode: "user",
        echoCancellation: true,
        noiseSuppression: true,
        muted: true,
      },

      participants: [],

      serverConfig: {},

      loading: undefined,

      joinInfo: undefined,
    };
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    async openToast(msg) {
      const toast = await toastController.create({
        message: msg,
        duration: 1500,
      });
      return toast.present();
    },

    readDBAndInit() {
      console.log("[VideoConversation] - readDBAndInit");
      try {
        if (this.inUserRefreshMode) return;

        this.inUserRefreshMode = true;

        readFromDB(this.$store)()
          .then(async () => {
            this.currentUser = this.$store.getters.user;
            if (
              this.currentUser === null ||
              typeof this.currentUser === "undefined" ||
              typeof this.currentUser.user_id === "undefined"
            ) {
              this.inUserRefreshMode = false;
              this.openToast(
                this.getStrings(this.strings, "LoggingOffUnablecredentials")
              );
              this.logOff(this);
            } else {
              this.inUserRefreshMode = false;
              this.init();
            }
          })
          .catch((err) => {
            console.log("indexdb not available", err);
            this.inUserRefreshMode = false;

            this.logOff(this);
          });
      } catch (err) {
        console.log("indexdb not available", err);
        this.inUserRefreshMode = false;

        this.logOff(this);
      }
    },

    setInitialConversationType() {
      this.initialConversationType = this.$route.params.calltype
        ? this.$route.params.calltype
        : "video";

      if (
        this.initialConversationType !== "audio" ||
        this.initialConversationType !== "video"
      ) {
        this.initialConversationType = "video";
      }
    },

    async init() {
      console.log("init");
      console.log("fetchProfile", this.otheruser);

      (this.otherTokenizedEntity = this.$route.params.vchatuserid),
        (this.fetchingProfile = true);
      if (typeof this.otheruser.user_id === "undefined") {
        this.otheruser.user_id = this.otherTokenizedEntity;
      }

      this.otheruser.isGroup = false;
      this.otheruser.full_name = "...";
      this.otheruser.photo = "";
      this.otheruser.profile = [];
      this.otheruser.joining_date = "";

      let connections = this.$store.getters.chatConnections;
      let displayInfo = connections.find(
        (friend) => friend.tokenizedUser === this.otheruser.user_id
      );
      console.log("displayInfo", JSON.stringify(displayInfo));

      if (displayInfo) {
        this.otheruser.full_name = displayInfo.full_name;
        this.otheruser.photo = displayInfo.photo_thumbnail;
        this.otheruser.id = displayInfo.id;

        this.otheruser.profile = [];
        this.otheruser.last_seen = "";

        this.fetchingProfile = false;
        this.loadOtherUserDetails();
      } else {
        this.loadOtherUserDetails();
      }

      this.loading = await loadingController.create({
        message: "Connecting...",
      });
      await this.loading.present();

      this.setInitialConversationType();

      try {
        getConversationConfig()
          .then(async (res) => {
            if (res.data.status === 0) {
              this.serverConfig = res.data.result.config;
              this.serverConfig.socketServer =
                this.serverConfig.videoSocketServer;

              console.log("video server", this.serverConfig);

              await this.createOrFetchRoomID();
              this.initSocketConnection();
              this.initPeerConnection();
              this.initVideoStream();

              if (this.loading) {
                await this.loading.dismiss();
                this.loading = undefined;
              }
            } else {
              console.log("getVideoCallConfig - err1", res);
            }
          })
          .catch((err) => {
            console.log("getVideoCallConfig - err2", err);
            this.openToast("Unable to get room ID: " + err.toString());
            // TODO : notify error to user
            this.initSocketConnection();
            this.initPeerConnection();
            this.initVideoStream();

            this.loading.dismiss();
          });
      } catch (err) {
        console.log("getVideoCallConfig - err3", err);
        this.openToast("Unable to initiate call: " + err.toString());
        this.initSocketConnection();
        this.initPeerConnection();
        this.initVideoStream();

        this.loading.dismiss();
      }
    },

    async createOrFetchRoomID() {
      this.roomID = this.$route.params.roomid
        ? this.$route.params.roomid
        : undefined;

      let currentUser = this.$store.getters.user;

      if (!this.roomID) {
        let joinInfo = {
          room_id: this.roomID,
          tokenized_user: currentUser.tokenized_user,
          tokenized_entity_id: this.otherTokenizedEntity,
          entity_type: "videouser",
          user_auth: currentUser,
        };
        console.log("[video - joinInfo]", joinInfo);
        this.roomID = await getVideoChatRoomID(joinInfo);
        this.joinInfo = {
          room_id: this.roomID,
          tokenized_user: currentUser.tokenized_user,
          tokenized_entity_id: this.otherTokenizedEntity,
          entity_type: "videouser",
          user_auth: currentUser,
        };

        console.log("[new chat room]", this.roomID);

        if (typeof this.roomID === "undefined") {
          console.log("unable to creare roomID");
        }
      } else {
        console.log("[old chat room]", this.roomID);
      }
    },

    loadOtherUserDetails() {
      chatFriendsProfile({ profile_user_id: this.otheruser.user_id })
        .then((res) => {
          //console.log("userProfile", JSON.stringify(res));
          this.otheruser.profile = [];
          if (res.data.status === 0) {
            // basic profile
            this.otheruser.id = res.data.result.id;
            this.otheruser.full_name = res.data.result.full_name;
            var userProfileData = res.data.result.user_profile;
            this.otheruser.last_seen = res.data.result.last_seen;
            // photo
            var profilePhoto = userProfileData.filter(
              (x) => x.section_name === "PROFILE_PHOTO"
            );
            if (profilePhoto.length === 1) {
              profilePhoto = profilePhoto[0];
              var photo = profilePhoto.fields.filter(
                (x) => x.field_name === "profile_photo"
              );
              if (photo.length === 1) this.otheruser.photo = photo[0].value;
            }
          }

          console.log("this.otheruser.profile:", this.otheruser.profile);

          this.fetchingProfile = false;
        })
        .catch((err) => {
          console.log("userProfile - err", err);

          this.fetchingProfile = false;
        });
    },

    initSocketConnection() {
      this.socket = io(this.serverConfig.socketServer, {
        reconnectionDelayMax: 10000,
        autoConnect: true,
      });

      console.log("socket", this.socket);
    },

    initPeerConnection() {
      this.peer = new Peer(undefined, {
        path: "/peerjs",
        port: this.serverConfig.port,
        host: this.serverConfig.peerServer,
        config: this.serverConfig.turnServer,
      });
      console.log("peer connection established", this.peer);
      console.log("peer server", this.serverConfig);

      this.socket.io.on("update", (data) => console.log("socket-update", data));
      this.socket.io.on("error", (data) => console.log("socket-error", data));
      this.socket.io.on("connect_error", (err) =>
        console.log("connect_error", err)
      );
      this.socket.io.on("connect_failed", (err) =>
        console.log("connect_failed", err)
      );
      this.socket.io.on("disconnect", (err) => console.log("disconnect", err));

      // handle peer open
      this.peer.on("open", (id) => {
        console.log("[peer.open]", id);

        this.peerID = id;
        this.joinInfo = {
          room_id: this.roomID,
          tokenized_user: this.user.tokenized_user,
          tokenized_entity_id: this.otherTokenizedEntity,
          entity_type: "videouser",
          peer_user_id: id,
          user_auth: this.user,
        };
        this.socket.emit("join-room", this.joinInfo);
      });

      this.peer.on("error", (err) => {
        console.log("[peer.error]", err);
      });
    },

    async initVideoStream() {
      console.log("initVideoStream");
      this.myVideo = document.createElement("video");
      this.myVideo.className = "video-frame";
      this.myVideo.setAttribute("playsinline", "true");

      // add the video stream to the vide
      const addVideoStream = (video, stream) => {
        video.srcObject = stream;
        video.addEventListener("loadedmetadata", () => {
          console.log(
            "addVideoStream",
            this.participants.length,
            this.lastAddedGrid
          );

          video.style.width = "100%";
          video.style.height = "100%";
          video.className = "video-frame";

          let colEl = document.createElement("ion-col");
          let itmEl = document.createElement("ion-item");
          itmEl.appendChild(video);
          colEl.appendChild(itmEl);

          let videoGrid = undefined;

          if (this.lastAddedGrid === 0 || this.lastAddedGrid === 2) {
            videoGrid = document.getElementById("video-grid-1");
            this.lastAddedGrid = 1;
          } else {
            videoGrid = document.getElementById("video-grid-2");
            this.lastAddedGrid = 2;
          }

          videoGrid.appendChild(colEl);

          video.play();
        });
      };

      // conect the new user
      const connectToNewUser = (userId, stream) => {
        const call = this.peer.call(userId, stream); // call the new user with my stream
        const video = document.createElement("video");

        console.log("connecToNewUser", call);

        if (call) {
          call.on("stream", (userVideoStream) => {
            console.log("[connectToNewUser.call.stream]", userVideoStream);

            addVideoStream(video, userVideoStream);
          });
        }
      };

      let audioOnlySettings = {
        audio: this.audioSettings,
      };
      let videoOnlySettings = {
        audio: false,
        video: this.videoSettings,
      };

      let audioStream = undefined;
      let videoStream = undefined;
      let combinedStream = undefined;

      if (this.initialConversationType === "audio") {
        audioStream = await navigator.mediaDevices.getUserMedia(
          audioOnlySettings
        );
        combinedStream = new MediaStream([...audioStream.getAudioTracks()]);
      } else {
        audioStream = await navigator.mediaDevices.getUserMedia(
          audioOnlySettings
        );
        videoStream = await navigator.mediaDevices.getUserMedia(
          videoOnlySettings
        );
        combinedStream = new MediaStream([
          ...videoStream.getVideoTracks(),
          ...audioStream.getAudioTracks(),
        ]);
      }

      this.myVideoStream = combinedStream;
      addVideoStream(this.myVideo, combinedStream);
      this.participants.push(this.user); // TODO: this needs to be user with room ID and peer ID

      // callback on call from the other user
      this.peer.on("call", (call) => {
        // when i get call request from other user
        console.log("[peer.call]", call);

        call.answer(combinedStream); // answer the call with my stream

        this.participants.push(call); // TODO: this needs to be user with room ID and peer ID

        const video = document.createElement("video");
        call.on("stream", async (userVideoStream) => {
          console.log("[call.stream]", call);

          addVideoStream(video, userVideoStream);
        });
      });

      // handle user connect
      this.socket.on("user-connected", (userId) => {
        console.log("[socket.user-connected]", userId);

        connectToNewUser(userId, this.myVideoStream);
      });
    },

    async logOff(callbackObject = this) {
      await logOffApp();

      callbackObject.$store.dispatch("adduser", {});

      callbackObject.$router.replace("/login");
    },
    onCameraReverse() {
      this.backCameraOn = !this.backCameraOn;

      if (!this.backCameraOn) {
        for (let track of this.myVideoStream.getVideoTracks()) {
          console.log("applying constrain", track);
          this.videoSettings.facingMode = "user";
          track.stop();
          track.applyConstraints(this.videoSettings);
        }
      } else {
        for (let track of this.myVideoStream.getVideoTracks()) {
          console.log("applying constrain", track);
          this.videoSettings.facingMode = "environment";
          track.stop();
          track.applyConstraints(this.videoSettings);
        }
      }
    },
    onMicToggle() {
      this.micOff = !this.micOff;

      console.log("onMicToggle", this.micOff);

      if (this.micOff) {
        for (let track of this.myVideoStream.getAudioTracks()) {
          console.log("applying constrain", track);
          track.enabled = false;
        }
      } else {
        for (let track of this.myVideoStream.getAudioTracks()) {
          console.log("applying constrain", track);
          track.enabled = true;
        }
      }
    },
    onVideoToggle() {
      this.videocamOff = !this.videocamOff;

      console.log("onVideoToggle", this.videocamOff);

      if (!this.videocamOff) {
        for (let track of this.myVideoStream.getVideoTracks()) {
          console.log("applying constrain", track);
          track.applyConstraints(this.videoSettings);
          track.enabled = true;
        }
      } else {
        for (let track of this.myVideoStream.getVideoTracks()) {
          console.log("applying constrain", track);
          track.enabled = false;
        }
      }
    },
    onCallToggle() {
      this.callDisconnected = !this.callDisconnected;

      if (this.callDisconnected) {
        // TODO: send disconnect message to all peers
        this.socket.emit("hang-up", this.joinInfo);

        for (let track of this.myVideoStream.getAudioTracks()) {
          track.enabled = false;
          track.active = false;
          track.stop();
        }
        for (let track of this.myVideoStream.getVideoTracks()) {
          track.enabled = false;
          track.active = false;
          track.stop();
        }
        this.$router.replace("/conversations");
      }
    },
    onSpeakerToggle() {
      this.speakerOff = !this.speakerOff;
    },
  },
};
</script>