<template>
  <base-layout
    page-title="VerifyYourAccount"
    page-default-back-link="/"
    page-back-link="/"
    showBackButton="true"
  >
    <br />
    <form ion-padding>
      <ion-row
        class="ion-no-padding ion-no-margin left-margin-15px right-margin-15px"
      >
        <ion-col
          :size="getColSize()"
          :offset="getOffsetSize()"
          class="ion-no-padding ion-no-margin"
        >
          <ion-item no-lines lines="none" class="login-input-item">
            <ion-label
              position="stacked"
              style="--padding-top: 0px; margin-bottom: 25px"
              class="line_hight_one ion-no-padding ion-no-margin"
              >Enter E-Mail / SMS OTP</ion-label
            >
            <ion-input
              v-model="totp"
              style="--padding-top: 0px; margin-top: 0px"
              class="line_hight_one"
              placeholder="OTP"
            >
            </ion-input>
          </ion-item>
          <ion-item no-lines lines="none">
            <ion-button @click="verifyAccount()" :disabled="submitDisabled"
              >Verify Account<ion-spinner
                v-if="submitDisabled"
                slot="end"
                name="dots"
              ></ion-spinner
            ></ion-button>
            <ion-button @click="cancelVerify()" :disabled="submitDisabled"
              >Cancel<ion-spinner
                v-if="submitDisabled"
                slot="end"
                name="dots"
              ></ion-spinner
            ></ion-button>
          </ion-item>
          <ion-item v-if="userVerificationError" no-lines lines="none">
            <ion-label size="small" color="danger"
              ><small>Verification Failed. Please try again.</small></ion-label
            >
          </ion-item>
          <hr style="border-top: 1px dotted grey" />
          <ion-item no-lines lines="none">
            <ion-text size="small" color="medium">
              Not received verification email/SMS?
              <ul>
                <li>
                  <small>
                    Ensure that the email/phone given at the signup time is
                    correct
                  </small>
                </li>
                <li>
                  <small
                    >Check your email/phone filtered folders or spam
                    folder</small
                  >
                </li>
                <li>
                  <small
                    >To resend the verification email/SMS, <br /><br />
                    <ion-button
                      color="primary"
                      size="small"
                      shape="round"
                      @click="resendVerification()"
                      ><small>Resend OTP</small
                      ><ion-spinner
                        v-if="reverifyClicked"
                        slot="end"
                        name="dots"
                      ></ion-spinner></ion-button
                  ></small>
                </li>
              </ul>
            </ion-text>
          </ion-item>
          <ion-item v-if="resendVerifyMsg != ''" no-lines lines="none">
            <ion-label size="small" color="secondary"
              ><small>{{ resendVerifyMsg }}</small></ion-label
            >
          </ion-item>
        </ion-col>
      </ion-row>
    </form>
  </base-layout>
</template>

<script>
import { getColSize, getOffsetSize, logOffApp } from "../services/utils";

import {
  IonButton,
  IonItem,
  IonLabel,
  IonInput,
  IonRow,
  IonCol,
  alertController,
  IonSpinner,
  IonText,
} from "@ionic/vue";

import {
  verify,
  verifySession,
  resendVerify,
  resendVerifySession,
  registerDevice,
} from "../services/apiCall";
import { loadLang, getStrings } from "../services/lang";
import { initDB, putUser } from "../services/db";

import { useRouter } from "vue-router";

import { Plugins } from "@capacitor/core";

import { isAndroid, isIOS } from "../services/utils";

const { PushNotifications, LocalNotifications } = Plugins;

export default {
  components: {
    IonButton,
    IonItem,
    IonLabel,
    IonInput,
    IonRow,
    IonCol,
    IonSpinner,
    IonText,
  },
  ionViewWillEnter() {
    this.init();
  },
  setup() {
    const router = useRouter();

    return {
      router,
      getColSize,
      getOffsetSize,
      getStrings,
    };
  },
  data() {
    return {
      verify,
      myID: this.$route.params.me,
      totp: "",
      userVerificationError: false,
      submitDisabled: false,
      resendVerifyMsg: "",
      reverifyClicked: false,
    };
  },
  computed: {},
  methods: {
    async init() {
      try {
        // init the DB
        await initDB();
        loadLang(this.$store);
      } catch (err) {
        console.log("indexDB not available", err);
        loadLang(this.$store);
      }
    },
    verifyAccount() {
      this.submitDisabled = true;
      if (typeof this.myID === "undefined") {
        verifySession({ totp: this.totp })
          .then(async (res) => {
            this.userVerificationError = false;

            if (res.data.status === 0) {
              if (res.data.result.verified) {
                this.submitDisabled = false;
                const successAlert = await alertController.create({
                  header: "Account Verified",
                  message: "Account successfully verified, enjoy NEXT!",
                  buttons: ["OK"],
                });
                await successAlert.present();
                localStorage.setItem("defaultRoute", "/posts");
                this.router.replace("/posts");
              } else {
                this.submitDisabled = false;
                const failedAlert = await alertController.create({
                  header: "Account Verification Failed",
                  message: res.data.error.error_message,
                  buttons: ["OK"],
                });
                this.userVerificationError = true;
                await failedAlert.present();
              }
            } else {
              this.submitDisabled = false;
              const failedAlert = await alertController.create({
                header:
                  "Account Verification Failed. Error: " +
                  res.data.error.error_message,
                message: res.data.error.error_message,
                buttons: ["OK"],
              });
              this.userVerificationError = true;
              await failedAlert.present();
            }
          })
          .catch((err) => {
            this.submitDisabled = false;
            console.log("verify - err", err);
            this.userVerificationError = true;
          });
      } else {
        verify({ totp: this.totp, user_id: this.myID, auto_login: true })
          .then(async (res) => {
            this.userVerificationError = false;

            if (res.data.status === 0 && res.data.result.verified) {
              const successAlert = await alertController.create({
                header: "Account Verified",
                message: "Account successfully verified.<br/>Your password is auto generated and sent to your email.<br/>Enjoy NEXT!",
                buttons: ["OK"],
              });
              await successAlert.present();
              await this.$store.dispatch("adduser", res.data.result.auth_user);
              await putUser("currentUser", res.data.result.auth_user);
              this.registerPushNotifications(true);
            } else {
              this.submitDisabled = false;
              this.userVerificationError = true;
              const failedAlert = await alertController.create({
                header: "Account Verification Failed",
                message: res.data.error.error_message,
                buttons: ["OK"],
              });
              await failedAlert.present();
            }
          })
          .catch(async (err) => {
            this.submitDisabled = false;
            console.log("verify - err", err);
            this.userVerificationError = true;
            const failedAlert = await alertController.create({
              header: "Verification Failed",
              message: "Verification failed, try again",
              buttons: ["OK"],
            });
            await failedAlert.present();
            await this.logOff();
          });
      }
    },
    navigateNextPage(isVerified) {
      // if the user is verified direct to /posts else to /verify
      if (isVerified) {
        this.submitDisabled = false;
        localStorage.setItem("defaultRoute", "/posts");
        this.router.replace("/posts");
      } else {
        this.submitDisabled = false;
        this.router.replace("/verify");
      }
    },
    async registerPushNotifications(isVerified) {
      if (isAndroid() || isIOS()) {
        try {
          await PushNotifications.requestPermission();
          await LocalNotifications.requestPermission();
          await PushNotifications.register();
          PushNotifications.addListener("registration", (token) => {
            // save this device token to user profile
            registerDevice({
              device_id: token.value,
              device_type: isAndroid() ? "android" : isIOS() ? "ios" : "na",
            })
              .then((res) => {
                if (res.data.status === 0) {
                  this.navigateNextPage(isVerified);
                } else {
                  console.log("registerDevice - err1", res);
                }
                
              })
              .catch((err) => {
                console.log("registerDevice - err2", err);
                alert(err);
                this.navigateNextPage(isVerified);
              });
          });
          PushNotifications.addListener("registrationError", (err) => {
            console.log("notification registration failed", err);
            // alert(err.toString());
            this.navigateNextPage(isVerified);
          });
        } catch (ignored) {
          console.log("Push notification reg error", ignored);
          // alert(ignored);
          this.navigateNextPage(isVerified);
        }
      } else {
        this.navigateNextPage(isVerified);
      }
    },
    resendVerification() {
      this.reverifyClicked = true;
      var options = {};

      this.resendVerifyMsg = "";

      if (typeof this.myID !== "undefined") {
        options["user_id"] = this.myID;

        resendVerify(options)
          .then((res) => {
            if (res.data.status === 0) {
              this.resendVerifyMsg =
                "Verification email resent, please check your email.";
              this.reverifyClicked = false;
            } else {
              this.resendVerifyMsg =
                "Failed to resend verification email, try again.";
              this.reverifyClicked = false;
            }
          })
          .catch((err) => {
            this.reverifyClicked = false;
            this.resendVerifyMsg =
              "Failed to resend verification email, try again.";
            console.log("resendVerify - err", err);
          });
      } else {
        resendVerifySession(options)
          .then((res) => {
            if (res.data.status === 0) {
              this.resendVerifyMsg =
                "Verification email resent, please check your email.";
              this.reverifyClicked = false;
            } else {
              this.resendVerifyMsg =
                "Failed to resend verification email, try again.";
              this.reverifyClicked = false;
            }
          })
          .catch((err) => {
            this.reverifyClicked = false;
            this.resendVerifyMsg =
              "Failed to resend verification email, try again.";
            console.log("resendVerifySession - err", err);
          });
      }
    },
    async logOff(callbackObject = this) {
      callbackObject.logOutDisabled = true;

      await logOffApp();

      callbackObject.$store.dispatch("adduser", {});

      callbackObject.router.replace("/login");
    },
    cancelVerify() {
      this.logOff();
    }
  },
};
</script>