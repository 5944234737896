<template>
  <ion-content>
    <ion-list lines="none">
      <ion-item
        v-for="action in notificationActions"
        :key="action"
        :value="action"
        class="ion-padding-horizontal popover_item_style"
      >
        <ion-content>
          <ion-item class="popover_item_style">
            <ion-icon
              size="small"
              color="dark"
              :icon="checkmarkCircleOutline"
              v-if="action.id === 'MARK_READ'"
            ></ion-icon>
            <ion-icon
              size="small"
              color="dark"
              :icon="settingsOutline"
              v-if="action.id === 'SETTINGS'"
            ></ion-icon>
            <ion-button
              color="dark"
              fill="clear"
              style="text-transform: none"
              @click="handleAction(action, notificationsView)"
            >
              {{ action.name }}
            </ion-button>
          </ion-item>
        </ion-content>
      </ion-item>
    </ion-list>
  </ion-content>
</template>

<script>
import {
  IonContent,
  IonItem,
  IonList,
  IonButton,
  IonIcon,
  toastController,
} from "@ionic/vue";

import {
  settingsOutline,
  checkmarkCircleOutline,
} from "ionicons/icons";

import { useRouter } from "vue-router";
import { markNotificationAsRead } from "../../services/apiCall.js";

import { defineComponent } from "vue";

export default defineComponent({
  name: "UserList",
  props: ["notificationActions", "parent", "notificationsView"],
  components: {
    IonContent,
    IonItem,
    IonList,
    IonButton,
    IonIcon,
  },
  setup() {
    const router = useRouter();
    return {
      router,
    };
  },
  data() {
    return {
      settingsOutline,
      checkmarkCircleOutline,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    async openToast(msg) {
      const toast = await toastController.create({
        message: msg,
        duration: 1500,
      });
      return toast.present();
    },
    handleAction(action, notificationsView) {
      if (action.id === "MARK_READ") {
        this.markAllRead(notificationsView);
      } else if (action.id === "SETTINGS") {
        console.log('Open Notifications 1');
        this.navigateSettings();
      }
      this.parent.dismiss();
    },
    markAllRead(notificationsView) {
      markNotificationAsRead({ read_all: true })
      .then((res) => {
        if (res.data.status === 0) {
          var notifications = notificationsView.notifications;
          for (var idx=0; idx < notifications.length; idx++) {
            notifications[idx].is_read = true;
          }
          notificationsView.new_count = 0;
        }
      })
      .catch((err) => {
        console.log("markNotificationAsRead - err", err);
      });
    },
    async navigateSettings() {
      await this.router.push("/notifications/settings");
    },

  },
});
</script>
