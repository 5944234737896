
<template>
  <div>
    <ion-infinite-scroll
      position="top"
      @ionInfinite="loadConversations($event)"
      threshold="100px"
      id="infinite-scroll"
      @keypress.enter="isDesktopScreen() ? sendMessage() : ''"
      :disabled="scrollState.scrollDisabled"
    >
      <ion-infinite-scroll-content
        loading-spinner="bubbles"
        loading-text="Loading more chats..."
      >
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>
    <ion-list>
      <conversation-thread-item
        v-for="conversationThread in conversationThreadList"
        v-bind:key="conversationThread.id"
        :conversationThread="conversationThread"
      >
      </conversation-thread-item>
      <ion-item style="min-height: 50px" lines="none" no-lines id="_lastConversationItem">
        <ion-label></ion-label>
      </ion-item>
    </ion-list>
    <!-- <ion-badge color="dark" style="position: fixed; top: 60px; left: 35%"
      >5 October 2021</ion-badge
    > -->
  </div>
</template>

<script>
import {
  IonList,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  //IonBadge,
} from "@ionic/vue";
import { useRouter } from "vue-router";

import ConversationThreadItem from "./ConversationThreadItem.vue";

import { isDesktopScreen } from "../../services/utils.js";

export default {
  name: "ConversationThread",
  props: ["conversationThreadList"],

  components: {
    IonList,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    //IonBadge,
    ConversationThreadItem,
  },

  mounted() {
    this.init();
  },

  setup() {
    const router = useRouter();
    return {
      router,
      isDesktopScreen,
    };
  },

  data() {
    return {
      icon: {},

      scrollState: {
        scrollDisabled: true,
      },
    };
  },

  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      return this.$store.getters.user;
    },
  },

  methods: {
    init() {
      let conversationListEl = document.getElementById("conversation-list");

      let mutationObserver = new MutationObserver((mutation) => {
        let lastMutation = mutation[mutation.length - 1];
        let lastAddedNode =
          lastMutation.addedNodes[lastMutation.addedNodes.length - 1];
        let addedID = lastAddedNode.id;
        console.log("mutation occured", mutation, addedID);
        this.scrollMessageListToBottom();
      });

      if (conversationListEl) {
        mutationObserver.observe(conversationListEl, { childList: true });
      }
    },

    scrollMessageListToBottom() {
      document.getElementById("_conversationThreadContent").scrollToBottom(200);
    },

    loadConversations(ev) {
      console.log("loadConversations", ev);
    },
  },
};
</script>