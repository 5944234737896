<template>
  <base-layout page-title="Search" showBackButton="true">
    <ion-row class="animate-fading">
      <ion-col :size="getColSize()" :offset="getOffsetSize()">
        <ion-item class="ion-no-padding ion-no-margin" no-lines lines="none">
          <ion-searchbar
            class="searchbar-input"
            type="text"
            placeholder="Search People or Post"
            v-model="searchText"
            @keyup.enter="setTimeout(searchEverything, 500)"
            @ionChange="searchEverything"
            debounce="500"
            clearInput
          >
          </ion-searchbar>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row class="animate-fading">
      <ion-col :size="getColSize()" :offset="getOffsetSize()">
          <ion-segment
            @ionChange="segmentChanged($event)"
            value="People"
            v-model="currentTabSegment"
          >
            <ion-segment-button
              v-for="tab in tabs"
              v-bind:key="tab.value"
              :value="tab.value"
              layout="icon-end"
              class="overflow-tab"
              :disabled="searchInProgress"
            >
              <ion-label class="ion-text-wrap">{{
                getStrings(strings, tab.name)
              }}</ion-label>
            </ion-segment-button>
          </ion-segment>
      </ion-col>
    </ion-row>
    <!-- Search People -->
    <ion-row v-if="currentTabSegment === 'People'" class="animate-fading">
      <ion-col
        :size="getColSize()"
        :offset="getOffsetSize()"
        class="ion-no-padding ion-no-margin"
      >
        <ion-item class="ion-no-padding ion-no-margin" no-lines lines="none">
          <ion-label class="ion-padding-horizontal" v-if="searchInProgress">
            <b>Searching People</b>
          </ion-label>
          <ion-spinner
            v-if="searchInProgress"
            slot="end"
            name="dots"
          ></ion-spinner>
          <ion-label class="ion-padding-horizontal" v-if="!searchInProgress && searchText.length > 1 && peopleList.length === 0">
            <b>No people found containing '{{ searchText }}'</b>
          </ion-label>
        </ion-item>
        <friends-list 
          v-if="peopleList.length > 0" 
          :friends="peopleList" 
          type="full"          
          :searchString="searchText"
          :scrollState="scrollState"
          :appendUsers="appendUsers"
          :limit="userFetchLimit"
        ></friends-list>
      </ion-col>
    </ion-row>
    <span v-if="currentTabSegment === 'Posts'">
      <ion-row>
        <ion-col
          :size="getColSize()"
          :offset="getOffsetSize()"
          class="ion-no-padding ion-no-margin"
        >
          <ion-item class="ion-no-padding ion-no-margin" no-lines lines="none">
            <ion-label class="ion-padding-horizontal" v-if="searchInProgress">
              <b>Searching Posts</b>
            </ion-label>
            <ion-spinner
              v-if="searchInProgress"
              slot="end"
              name="dots"
            ></ion-spinner>
            <ion-label class="ion-padding-horizontal" v-if="!searchInProgress && searchText.length > 1 && posts.length === 0">
              <b>No posts found containing '{{ searchText }}'</b>
            </ion-label>
            <ion-label class="ion-padding-horizontal" v-if="!searchInProgress && posts.length">
              <b>Searched Posts</b>
            </ion-label>
          </ion-item>
          <posts-list
            :posts="posts"
            :fetchPostAPI="postSearch"
            :searchString="searchText"
            :scrollState="scrollState"
          ></posts-list>
        </ion-col>
      </ion-row>
    </span>
    <span v-if="currentTabSegment === 'Groups'">
      <ion-row>
        <ion-col
          :size="getColSize()"
          :offset="getOffsetSize()"
          class="ion-no-padding ion-no-margin"
        >
        <ion-item class="ion-no-padding ion-no-margin" no-lines lines="none">
          <ion-label class="ion-padding-horizontal" v-if="searchInProgress">
            <b>Searching groups</b>
          </ion-label>
          <ion-spinner
            v-if="searchInProgress"
            slot="end"
            name="dots"
          ></ion-spinner>
          <ion-label class="ion-padding-horizontal" v-if="!searchInProgress && searchText.length > 1 && groupList.length === 0">
            <b>No groups found containing '{{ searchText }}'</b>
          </ion-label>
        </ion-item>
          <group-list
            v-if="groupList.length"
            :groupListName="'Searched Groups'"
            :groups="groupList"
          >
          </group-list>
        </ion-col>
      </ion-row>
    </span>
    <span v-if="currentTabSegment === 'Pages'">
      <ion-row>
        <ion-col
          :size="getColSize()"
          :offset="getOffsetSize()"
          class="ion-no-padding ion-no-margin"
        >
          <ion-item class="ion-no-padding ion-no-margin" no-lines lines="none">
          <ion-label class="ion-padding-horizontal" v-if="searchInProgress">
            <b>Searching pages</b>
          </ion-label>
          <ion-spinner
            v-if="searchInProgress"
            slot="end"
            name="dots"
          ></ion-spinner>
          <ion-label class="ion-padding-horizontal" v-if="!searchInProgress && searchText.length > 1 && pageList.length === 0">
            <b>No pages found containing '{{ searchText }}'</b>
          </ion-label>
         </ion-item>
          <group-list
            v-if="pageList.length"
            :groupListName="'Searched Pages'"
            :groups="pageList"
          >
          </group-list>
        </ion-col>
      </ion-row>
    </span>
  </base-layout>
</template>

<script>
import {
  IonSearchbar,
  IonItem,
  IonLabel,
  IonCol,
  IonRow,
  IonSpinner,
  IonSegment,
  IonSegmentButton,
} from "@ionic/vue";

import { useRouter } from "vue-router";

import {
  getColSize,
  getOffsetSize,
  processPreview,
  logOffApp,
  defaultPostsPerFetch,
} from "../services/utils.js";

import { search, homeOutline } from "ionicons/icons";

import { getStrings } from "../services/lang";
import { userSearch, postSearch, searchGroup } from "../services/apiCall";
import FriendsList from "../components/users/FriendsList.vue";
import PostsList from "../components/posts/PostList.vue";
import { readFromDB } from "../services/db.js";
import GroupList from "../components/groups/GroupList.vue";

export default {
  name: "SearchPostPage",
  ionViewWillEnter() {
    this.readDBAndInit();
  },
  ionViewWillLeave() {
    this.searchText = "";
  },
  components: {
    IonSearchbar,
    IonItem,
    IonLabel,
    IonCol,
    IonRow,
    IonSpinner,
    IonSegment,
    IonSegmentButton,
    FriendsList,
    PostsList,
    GroupList,
  },
  watch: {
    "$route.params.searchtxt": function (stxt) {
      console.log('Search txt', stxt);
      try {
        this.searchText = this.$route.params.searchtxt
          ? this.$route.params.searchtxt
          : "";
        this.searchUsers();
      } catch (err) {
        console.log("Error searching. " + err);
      }
    },
  },
  data() {
    return {
      searchText: this.$route.params.searchtxt
        ? this.$route.params.searchtxt
        : "",
      search,
      homeOutline,
      searchInProgress: false,
      peopleList: [],
      groupList: [],
      pageList: [],
      currentTabSegment: "People",
      userFetchLimit: 5,
      scrollState: {
        scrollDisabled: false,
        scrollSkip: 0,
        isFavourite: false,
        topic_filter: [],
        post_origin_tag: "search",
      },
      tabs: [
        {
          name: "People",
          value: "People",
        },
        {
          name: "Posts",
          value: "Posts",
        },
        {
          name: "Groups",
          value: "Groups",
        },
        {
          name: "Pages",
          value: "Pages",
        },
      ],
    };
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      return this.$store.getters.user;
    },
    posts() {
      return this.$store.getters.posts.filter(
        (p) => p.post_origin_tag === "search"
      );
    },
  },
  setup() {
    const router = useRouter();
    return {
      router,
      getColSize,
      getOffsetSize,
      processPreview,
      getStrings,
      userSearch,
      postSearch,
      searchGroup,
    };
  },
  methods: {
    init() {
      this.currentTabSegment = "People";
      this.searchText = this.$route.params.searchtxt
        ? this.$route.params.searchtxt
        : "";
      if (this.searchText !== "") {
        this.searchUsers();
      }
    },
    readDBAndInit(callbackObject = this) {
      this.$store.dispatch("setposts", []);

      try {
        readFromDB(this.$store, true)()
          .then(async () => {
            if (
              typeof callbackObject.user === "undefined" ||
              typeof callbackObject.user.user_id === "undefined"
            ) {
              this.logOff(callbackObject);
            } else {
              this.init();
            }
          })
          .catch((err) => {
            console.log("indexdb not available", err);
            this.init();
          });
      } catch (err) {
        console.log("indexdb not available", err);
        this.init();
      }
    },
    searchEverything() {
      if (this.searchText.length < 2) return;

      if (this.currentTabSegment === "People") {
        this.searchUsers();
      } else if (this.currentTabSegment === "Posts") {
        this.searchPosts();
      } else if (this.currentTabSegment === "Groups") {
        this.searchGroups();
      } else if (this.currentTabSegment === "Pages") {
        this.searchGroups(true);
      }      
    },
    appendUsers(people) {
      this.peopleList = this.peopleList.concat(people);
    },
    searchUsers() {
      this.peopleList = [];
      this.searchInProgress = true;

      if (!this.searchText) {
        this.searchInProgress = false;
        return;
      }
      if (this.searchText.trim() === "") {
        this.searchInProgress = false;
        return;
      }

      userSearch({
        search_string: this.searchText.trim(),
        limit: this.userFetchLimit,
        skip: 0,
      })
        .then((res) => {
          if (res.data.status === 0) {
            this.scrollState.scrollDisabled = false;
            this.scrollState.scrollSkip = 0;
            this.peopleList = res.data.result.search_list;
          }
          this.searchInProgress = false;
        })
        .catch((err) => {
          console.log("userSearch - err", err);
          this.searchInProgress = false;
        });
    },
    searchPosts() {
      if (!this.searchText) {
        this.searchInProgress = false;
        return;
      }
      if (this.searchText.trim() === "") {
        this.searchInProgress = false;
        return;
      }

      this.searchInProgress = true;
      postSearch({ search_string: this.searchText.trim(), limit: defaultPostsPerFetch(), skip: 0 })
        .then((res) => {
          if (res.data.status === 0) {
            this.scrollState.scrollDisabled = false;
            this.scrollState.scrollSkip = 0;
            this.scrollState.post_origin_tag = "search";
            var searchedPosts = res.data.result.post_list.map((onePost) => {
              var previewData = processPreview(onePost);
              onePost["previewData"] = previewData;
              onePost["post_origin_tag"] = "search";
              return onePost;
            });
            this.$store.dispatch('setposts', searchedPosts);

            this.searchInProgress = false;
          }
        })
        .catch((err) => {
          console.log("postSearch - err", err);
          this.searchInProgress = false;
        });
    },
    searchGroups(isPage=false) {
      this.groupList = [];
      this.searchInProgress = true;

      if (!this.searchText) {
        this.searchInProgress = false;
        return;
      }
      if (this.searchText.trim() === "") {
        this.searchInProgress = false;
        return;
      }

      var cond = {
        search_string: this.searchText.trim(),
        limit: defaultPostsPerFetch(),
        skip: 0,
      }
      if (isPage) cond['only_pages'] = true;

      searchGroup(cond)
        .then((res) => {
          if (res.data.status === 0) {
            if (isPage) {
              this.pageList = res.data.result.group_list;
            } else {
              this.groupList = res.data.result.group_list;
            }
          }
          this.searchInProgress = false;
        })
        .catch((err) => {
          console.log("groupSearch - err", err);
          this.searchInProgress = false;
        });
    },
    async logOff(callbackObject = this) {
      await logOffApp();

      callbackObject.$store.dispatch("adduser", {});

      callbackObject.router.replace("/login");
    },
    segmentChanged() {
      if (this.searchText.length < 2) return;
      
      if (this.currentTabSegment === "People") {      
        this.searchUsers();
      } else if (this.currentTabSegment === "Posts") {
        this.searchPosts();
      } else if (this.currentTabSegment === "Groups") {
        this.searchGroups();
      } else if (this.currentTabSegment === "Pages") {
        this.searchGroups(true);
      }
    },
  },
  
};
</script>