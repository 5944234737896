<template>
  <span v-if="field[0].value">
  <ion-item 
    lines="none" no-lines 
    class="ion-no-padding ion-no-margin left-margin-15px right-margin-15px"
    v-for="privacy in field[0].value"
    :key="privacy.privacy_name"
    :value="privacy.privacy_name"
  >
   {{ privacy.display_text }}
   <ion-chip
      slot="end"
      size="samll"
      @click="showVisibility(privacy, $event)"
    >
      <ion-label size="samll"
        ><small>{{
          privacy.visibility ? privacy.visibility : "Only Me"
        }}</small></ion-label
      >
    </ion-chip>
  </ion-item>
  <ion-item 
    lines="none" no-lines 
    class="ion-no-padding ion-no-margin"
  >
    <ion-button
      slot="end"
      @click="savePrivacySettings(section)"
      style="text-transform: none;"
      class="right-margin-15px"
      :disabled="updatingSettings"
    >
      Save Privacy Settings
    </ion-button>
  </ion-item>
  </span>
</template>

<script>
import {
  IonItem,
  IonLabel,
  IonChip,
  IonButton,
  popoverController,
  toastController,
} from "@ionic/vue";
import { defineComponent } from "vue";

import { updateUserProfileField } from "../../services/apiCall.js";
import FieldVisibilityOptions from "./FieldVisibilityOptions.vue";

export default defineComponent({
  name: "PrivacySettingSection",
  props: ["field", "section"],
  components: {
    IonItem,
    IonLabel,
    IonChip,
    IonButton,
  },
  setup() {
    return {
    };
  },
  data() {
    return {
      localField: this.field,
      updatingSettings: false,
    };
  },
  mounted() {
  },
  methods: {
    async openToast(msg) {
      const toast = await toastController.create({
        message: msg,
        duration: 1500,
      });
      return toast.present();
    },
    async showVisibility(privacyfield, ev) {
      const popover = await popoverController.create({
        component: FieldVisibilityOptions,
        event: ev,
        translucent: true,
      });
      popover.componentProps = { field: privacyfield, parent: popover };
      return popover.present();
    },
    savePrivacySettings(section) {
      this.updatingSettings = true;
      var localSection = JSON.parse(JSON.stringify(section));
      this.localField.lastEdited = new Date();
      localSection.field = [this.localField];
      updateUserProfileField({ section_data: localSection })
        .then((res) => {
          if (res.data.status === 0) {
            this.openToast('Privacy settings are saved');
            section.is_expanded = false;
          } else {
            console.log("savePrivacySettings - err1", res);
          }
          this.updatingSettings = false;

        })
        .catch((err) => {
          console.log("savePrivacySettings - err2", err);
        });
      this.updatingSettings = false;
    },
  },
});
</script>
