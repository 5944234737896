<template>
  <ion-content>
    <ion-list>
      <ion-item @click="changeFieldVisibility(field, 'Only Me')" value="OnlyMe">
        <ion-label class="left-margin-15px">
          Only Me&nbsp;<ion-icon
            slot="icon-only"
            v-if="hasVisibility(field, 'OnlyMe')"
            :icon="checkmarkCircleOutline"
          ></ion-icon
        ></ion-label>
      </ion-item>
      <ion-item @click="changeFieldVisibility(field, 'Family')" value="Family">
        <ion-label class="left-margin-15px">
          Family&nbsp;<ion-icon
            slot="icon-only"
            size="small"
            v-if="hasVisibility(field, 'Family')"
            :icon="checkmarkCircleOutline"
          ></ion-icon></ion-label
      ></ion-item>
      <ion-item
        @click="changeFieldVisibility(field, 'Friends &amp; Family')"
        value="FriendsAndFamily"
        ><ion-label class="left-margin-15px"
          >Friends &amp; Family&nbsp;<ion-icon
            slot="icon-only"
            size="small"
            v-if="hasVisibility(field, 'FriendsAndFamily')"
            :icon="checkmarkCircleOutline"
          ></ion-icon></ion-label
      ></ion-item>
      <ion-item @click="changeFieldVisibility(field, 'Public')" value="Public"
        ><ion-label class="left-margin-15px"
          >Public&nbsp;<ion-icon
            slot="icon-only"
            size="small"
            v-if="hasVisibility(field, 'Public')"
            :icon="checkmarkCircleOutline"
          ></ion-icon></ion-label
      ></ion-item>
    </ion-list>
  </ion-content>
</template>
<script>
import { IonContent, IonList, IonItem, IonIcon, IonLabel } from "@ionic/vue";
import { defineComponent } from "vue";

import { checkmarkCircleOutline } from "ionicons/icons";

export default defineComponent({
  name: "FieldVisibilityOptions",
  props: ["field", "parent"],
  components: { IonContent, IonList, IonItem, IonIcon, IonLabel },
  data() {
    return { checkmarkCircleOutline };
  },
  methods: {
    hasVisibility(field, visibility) {
      return field.visibility === visibility;
    },
    changeFieldVisibility(field, visibility) {
      field.visibility = visibility;

      this.parent.dismiss();
    },
  },
});
</script>
