/** sync remote data with local db and optionally memcache */

import { getConversationList } from './microservice';
import { getConversationThreadList } from './microservice';

import {getConversationLocalList, getMessageLocalList } from "./conversationdb";

export async function syncUserConversationList(store = undefined) {
    // TODO:
    // 1. call the API
    // 2. update the DB
    // 3. (optionally) update the memcache 

    try {
        let res = await getConversationList();
        console.log('syncUserConversationList - res', res);

        if (res.status === 0) {
            var deliveryStatus = ['Queued', 'Sent', 'Delivered', 'Seen']
            var dbConversationList = res.result.conversation_list.map((x) => {
                return {
                    id: x.conversation_id,
                    full_name: x.other_user_name,
                    photo_thumbnail: "assets/icon/misc/avatar.svg",
                    profile_photo_available: false,
                    user_id: x.other_user_tokenized_id,
                    tokenized_user: x.other_user_tokenized_id,
                    last_seen: x.last_seen,
                    last_message: x.last_message,
                    sent_by_me: x.sent_by_me,
                    last_message_by_self: true,
                    //last_message_delivery_status: deliveryStatus[Object.keys(x.delivery_status).length - 1],
                    last_message_delivery_status: deliveryStatus[x.current_delivery_status],
                    last_message_time: x.last_message_time,
                    new_msg_count: x.new_conversation_count,
                };
            });
            
            // await setConversationLocalList(dbConversationList);

            if (store) {
                store.dispatch("setconversationlist", dbConversationList);
            }
        } else {
            if (store) {
                store.dispatch("setconversationlist", await getConversationLocalList());
            }
            console.log("Unable to get conversation list: " + res);
        }
    } catch (err) {
        console.log('syncUserConversationList - err', err);
        if (store) {
            store.dispatch("setconversationlist", await getConversationLocalList());
        }
    }
}

export async function syncUserConversationThreadList(otherTokenizedUser, store = undefined) {
    try {
        let res = await getConversationThreadList(otherTokenizedUser);

        if (res.status === 0) {
            var dbMessageList = res.result.conversation_thread_list.filter((x) => !('Delivered' in x.delivery_status)).map((x) => {
                return {
                    message_id: x.message_id,
                    message: x.content.message,
                    message_type: x.content.message_type,
                    user_id: x.other_user_tokenized_id,
                    time: x.message_time,
                    current_delivery_status: x.current_delivery_status,
                    delivery_status: x.delivery_status,
                    sent_by_me: x.sent_by_me,
                    is_previous_user_same: false,
                    is_forwarded: false,
                    message_origin: x.message_origin,
                    orignial_message_id: x.orignial_message_id,
                    preview: x.content.message_type === 'Image' ? JSON.parse(x.content.preview) : '',
                };
            });

            console.log('syncUserConversationThreadList', dbMessageList);

            // await setMessageLocalList(dbMessageList);

            if (store) {
                if (dbMessageList.length > 0 ) {
                    console.log('Before 1:', dbMessageList);
                    store.dispatch("setconversationthreadlist", dbMessageList);
                } else {
                    console.log('Before 2:', otherTokenizedUser);
                    store.dispatch("loadconversationthreadlist", otherTokenizedUser);
                }
                
            }

            return dbMessageList; // return the message list
        } else {
            if (store) {
                store.dispatch("setconversationthreadlist", await getMessageLocalList(otherTokenizedUser));
            }
            console.log("Unable to get conversation thread list: " + res);
        }
    } catch (err) {
        console.log('syncUserConversationThreadList', err);

        if (store) {
            store.dispatch("setconversationthreadlist", await getMessageLocalList(otherTokenizedUser));
        }
    }
}

export async function syncPosts() {
    // TODO:
}
