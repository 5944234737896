<template>
  <ion-grid class="ion-no-padding ion-no-margin">
    <ion-row class="row_top_border">
      <ion-col class="col_layout_nopadding">
        <div>
          <ion-button
            fill="clear"
            @click="openReactionsPopover"
            :disabled="
              !post.is_active || (post.group_id && !post.is_group_member)
            "
            expand="full"
            style="text-transform: none"
            class="button_nopadding"
          >
            <ion-icon
              slot="icon-only"
              color="medium"
              :icon="thumbsUpOutline"
              v-if="noReactionByMe(post)"
            ></ion-icon>

            <ion-icon
              slot="icon-only"
              v-for="reaction in localReactionOptions.filter((x) =>
                hasMyReaction(post, x.name)
              )"
              v-bind:key="reaction.name"
              :src="reaction.src"
            ></ion-icon>
          </ion-button>
        </div> </ion-col
      ><ion-col class="col_layout_nopadding">
        <ion-button
          fill="clear"
          color="medium"
          expand="full"
          style="text-transform: none"
          class="button_nopadding"
          :disabled="
            !post.is_active ||
            commentListClicked ||
            (post.group_id && !post.is_group_member)
          "
          @click="commentThePost(showCommentList)"
        >
          <ion-icon
            v-if="!post.comment_count || post.comment_count === 0"
            slot="icon-only"
            color="medium"
            :icon="chatboxEllipsesOutline"
          ></ion-icon>
          <ion-icon
            v-if="post.comment_count > 0"
            slot="icon-only"
            color="primary"
            :icon="chatboxEllipsesOutline"
          ></ion-icon>
          <ion-spinner v-if="commentListClicked" name="dots"></ion-spinner>
        </ion-button>
      </ion-col>
      <ion-col class="col_layout_nopadding" v-if="!post.group_id">
        <ion-button
          fill="clear"
          color="medium"
          expand="full"
          style="text-transform: none"
          class="button_nopadding"
          :disabled="user && (user.user_id === post.post_by || !post.is_active)"
          @click="shareThePost(post)"
        >
          <ion-icon
            v-if="!post.shared_by_me"
            slot="icon-only"
            color="medium"
            :icon="arrowRedoOutline"
          ></ion-icon>
          <ion-icon
            v-if="post.shared_by_me"
            slot="icon-only"
            color="primary"
            :icon="arrowRedoSharp"
          ></ion-icon>
        </ion-button>
      </ion-col>
      <ion-col class="col_layout_nopadding">
        <ion-button
          fill="clear"
          expand="full"
          @click="favouriteThePost(post)"
          :disabled="
            !post.is_active || (post.group_id && !post.is_group_member)
          "
          style="text-transform: none"
          class="button_nopadding"
        >
          <ion-icon
            slot="icon-only"
            color="medium"
            :icon="starOutline"
            v-if="!post.is_favourite"
          ></ion-icon>
          <ion-icon
            slot="icon-only"
            color="warning"
            :icon="star"
            v-if="post.is_favourite"
          ></ion-icon>
        </ion-button>
      </ion-col>
      <ion-col class="col_layout_nopadding">
        <ion-button
          fill="clear"
          expand="full"
          class="button_nopadding"
          style="text-transform: none"
          :disabled="
            user &&
            (user.user_id === post.post_by ||
              !post.is_active ||
              (post.group_id && !post.is_group_member))
          "
          @click="openFlagPopover"
        >
          <ion-icon
            v-if="post.flag_count <= 0"
            slot="icon-only"
            color="medium"
            :icon="flag"
          ></ion-icon>
          <ion-icon
            v-if="post.flag_count > 0"
            slot="icon-only"
            color="danger"
            :icon="flag"
          ></ion-icon>
          <span v-if="post.flag_count && post.post_by === user.user_id">
            <sup
              ><b>{{ post.flag_count }}</b></sup
            >
          </span>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import {
  IonButton,
  IonIcon,
  IonGrid,
  IonCol,
  IonRow,
  IonSpinner,
  popoverController,
} from "@ionic/vue";
import {
  flag,
  personAdd,
  thumbsUp,
  thumbsUpOutline,
  arrowRedoOutline,
  arrowRedoSharp,
  repeatOutline,
  star,
  starOutline,
  trashOutline,
  createOutline,
  chatboxEllipsesOutline,
} from "ionicons/icons";

import { defineComponent } from "vue";

import {
  favouritePost,
  sharePost,
  commentList,
} from "../../services/apiCall.js";
import {
  formatDate,
  isMobileScreen,
  processNestedComments,
} from "../../services/utils";
import FlagOptions from "./FlagOptions.vue";
import ReactionOptions from "./ReactionOptions.vue";

import { reactionListArray } from "../../services/utils";

export default defineComponent({
  name: "UserPostReaction",
  props: ["post", "showCommentList"],
  components: {
    IonButton,
    IonIcon,
    IonGrid,
    IonCol,
    IonRow,
    IonSpinner,
  },
  setup() {
    return {
      favouritePost,
      isMobileScreen,
      formatDate,
      sharePost,
      processNestedComments,
    };
  },
  data() {
    return {
      flag,
      personAdd,
      thumbsUp,
      thumbsUpOutline,
      arrowRedoOutline,
      arrowRedoSharp,
      repeatOutline,
      star,
      starOutline,
      trashOutline,
      createOutline,
      chatboxEllipsesOutline,
      reactionName: "Like",
      commentListClicked: false,
      localReactionOptions: reactionListArray,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    async openReactionsPopover(ev) {
      const popover = await popoverController.create({
        component: ReactionOptions,
        event: ev,
        translucent: true,
        showBackdrop: false,
        cssClass: "reaction_popover",
      });
      popover.componentProps = { post: this.post, parent: popover };
      return popover.present();
    },
    async openFlagPopover(ev) {
      const popover = await popoverController.create({
        component: FlagOptions,
        event: ev,
        translucent: true,
      });
      popover.componentProps = { post: this.post, parent: popover };
      return popover.present();
    },
    noReactionByMe(post) {
      if (post.my_reactions && post.my_reactions.length > 0) {
        return false;
      }
      return true;
    },
    hasMyReaction(post, rtype) {
      if (post.my_reactions) {
        var hasReaction =
          post.my_reactions.findIndex((x) => x.reaction_type === rtype) >= 0;
        if (hasReaction) this.reactionName = post.my_reactions[0].reaction_type;
        return hasReaction;
      } else {
        return false;
      }
    },
    commentThePost(showCommentList) {
      this.commentListClicked = true;
      var localPost = this.post;
      if (!showCommentList.show) {
        console.log("Comment:", this.post);
        commentList(this.post.post_id)
          .then(async (res) => {
            if (res.data.status === 0) {
              localPost.comments = processNestedComments(
                res.data.result.comment_list
              );
              showCommentList.show = true;
              this.commentListClicked = false;
            } else {
              console.log("post comment list err - 1", res);
              showCommentList.show = false;
              this.commentListClicked = false;
            }
          })
          .catch(async (err) => {
            console.log("post comment list err - 2", err);
            showCommentList.show = false;
            this.commentListClicked = false;
          });
      } else {
        showCommentList.show = false;
        this.commentListClicked = false;
      }
    },
    shareThePost(post) {
      post.share_count =
        typeof post.share_count !== "undefined" ? post.share_count + 1 : 1;
      sharePost({ post_id: post.post_id })
        .then((res) => {
          if (res.data.status == 0) {
            post.share_count = res.data.result.share_count;
            post.shared_by_me = !post.shared_by_me;
          }
        })
        .catch((err) => {
          console.log("share post - err", err);
        });
    },
    favouriteThePost(post) {
      post.is_favourite = !post.is_favourite;
      favouritePost({ post_id: post.post_id })
        .then((res) => {
          if (res.data.status == 0) {
            post.is_favourite = res.data.result.is_favourite;
          }
        })
        .catch((err) => {
          console.log("favouritePost", err);
        });
    },
  },
});
</script>
