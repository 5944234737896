<template>
  <ion-button
    @click="uploadOptions"
    slot="end"
    fill="clear"
    size="medium"
    :class="
      isIOS()
        ? 'ion-no-margin ion-no-padding tenpxleft'
        : 'ion-no-margin ion-no-padding photo_button_padding tenpxleft'
    "
    :disabled="photoPost.is_photo_post"
  >
    <ion-icon slot="icon-only" :icon="icon.imageOutline"></ion-icon>
  </ion-button>
</template>

<script>
import { imageOutline } from "ionicons/icons";

import { IonButton, IonIcon, popoverController } from "@ionic/vue";

import { defineComponent } from "vue";

import { isIOS } from "../../services/utils";

import MediaUploadOptions from "./MediaUploadOptions.vue";

export default defineComponent({
  name: "MediaUploadButton",
  props: ["post", "photoPost"],
  components: {
    IonButton,
    IonIcon,
  },
  data() {
    return {
      icon: {
        imageOutline,
      },
      popoverController,
    };
  },
  setup() {
    return { isIOS };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    async uploadOptions(ev) {
      const popover = await popoverController.create({
        component: MediaUploadOptions,
        event: ev,
        translucent: true,
        cssClass: "userlist_popover",
      });

      popover.componentProps = {
        post: this.post,
        parent: popover,
        photoPost: this.photoPost,
      };
      return popover.present();
    },
  },
});
</script>
