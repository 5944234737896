<template>
  <base-layout page-title="SignUp" showBackButton="true">
    <template v-slot:actions-end>
      <language-selection></language-selection>
    </template>
    <form @submit.prevent="handleSignup">
      <ion-grid class="grid_layout_nopadding">
        <ion-row
          class="
            ion-padding-horizontal ion-no-margin ion-no-padding
            inline-padding-0px
          "
        >
          <ion-col
            :size="getColSize()"
            :offset="getOffsetSize()"
            class="inline-padding-0px ion-padding-horizontal ion-no-margin"
          >
            <ion-card
              class="ion-padding-horizontal ion-no-margin ion-no-padding"
              no-lines
              lines="none"
            >
              <ion-label color="primary" ion-no-margin ion-no-padding>
                <br/><br/>
                {{ getStrings(strings, "RegisterYourInterest") }}
                <br/>
              </ion-label>
              <span v-if="currentSignupStep===1">
                <ion-item no-lines lines="none" class="signup-item-style">
                  <ion-label position="stacked" style="padding-bottom: 20px;"
                    >{{ getStrings(strings, "ReferralCode") }}<br/>
                    <small
                      >(Please check your email or SMS for referral code)</small><br/>
                    </ion-label
                  ><br/>
                  <ion-input
                    type="text"
                    v-model="form.referral_code"
                    id="referral_code"
                    placeholder="Referral Code"
                    required
                  ></ion-input>
                </ion-item>
                <ion-item no-lines lines="none" class="signup-item-style">
                  <ion-label size="large" position="stacked" style="padding-bottom: 20px;">
                    {{ getStrings(strings, "BirthDate")
                  }}</ion-label>
                  <ion-datetime
                    v-model="form.birth_date"
                    id="birth_date"
                    displayFormat="DD MMMM YYYY"
                    pickerFormat="DD MMMM YYYY"
                    placeholder="Birthdate"
                    required
                  >
                  </ion-datetime>
                </ion-item>
                <ion-button
                  shape="round"
                  expand="block"
                  color="primary"
                  size="normal"
                  @click="validateReferralAndAge()"
                >
                  Next
                </ion-button>
              </span>
              <span v-if="currentSignupStep===2">
                <ion-item no-lines lines="none" class="signup-item-style">
                  <ion-label position="stacked" style="padding-bottom: 20px;">{{
                    getStrings(strings, "FullName")
                  }}</ion-label>
                  <ion-input
                    ref="name"
                    v-model="form.name"
                    id="name"
                    placeholder="Name"
                    required
                  ></ion-input>
                </ion-item>

                <ion-item no-lines lines="none" class="signup-item-style">
                  <ion-label position="stacked" style="padding-bottom: 20px;">
                  {{ getStrings(strings, "Email") }}</ion-label>
                  <ion-input
                    v-model="form.username"
                    id="username"
                    type="email"
                    placeholder="Email"
                    :readonly="setUsernameDisabled"
                    required
                  ></ion-input>
                </ion-item>

                <ion-item no-lines lines="none" class="signup-item-style">
                  <ion-label size="large" position="stacked" style="padding-bottom: 20px;">
                    {{ getStrings(strings, "Phone") }}
                  </ion-label>
                  <ion-select
                    class="ion-no-padding ion-no-margin"
                    v-model="form.country_code"
                    required
                  >
                    <ion-select-option
                      v-for="country in countryNames"
                      v-bind:key="country.tel_code"
                      :value="country.tel_code"
                      class="ion-padding-horizontal ion-no-margin"
                      no-lines
                      lines="none"
                      placeholder="Conuntry Code"
                      >{{ '+' + country.tel_code + ' ' + country.name }}</ion-select-option>
                  </ion-select>
                  <ion-input
                    v-model="form.phone"
                    id="phone"
                    ref="phone"
                    type="tel"
                    placeholder="Phone Number"
                    :readonly="setPhoneDisabled"
                    required
                  ></ion-input>
                </ion-item>

                <password-field v-if="currentSignupStep===5"
                  :passwordSetting="form.passwordSetting"
                ></password-field>

                <ion-item no-lines lines="none" class="signup-item-style">
                  <ion-label position="stacked" style="padding-bottom: 20px;">{{ getStrings(strings, "Gender") }}</ion-label>
                  <ion-select
                    class="ion-no-padding ion-no-margin"
                    multiple="false"
                    interface="popover"
                    v-model="form.gender"
                    placeholder="Select Gender"
                    no-lines
                    lines="none"
                  >
                    <ion-select-option
                      class="ion-padding-horizontal ion-no-margin"
                      value="Male"
                      >{{ getStrings(strings, "Male") }}</ion-select-option
                    >
                    <ion-select-option
                      class="ion-padding-horizontal ion-no-margin"
                      value="Female"
                      >{{ getStrings(strings, "Female") }}</ion-select-option
                    >
                    <ion-select-option
                      class="ion-padding-horizontal ion-no-margin"
                      value="Undisclosed"
                      >{{ getStrings(strings, "Other") }}</ion-select-option
                    >
                  </ion-select>
                </ion-item>
                <!-- <ion-item no-lines lines="none" class="signup-item-style">
                  <ion-label size="large">{{
                    getStrings(strings, "Country")
                  }}</ion-label>
                  <ion-select
                    class="ion-padding-horizontal ion-no-margin"
                    v-model="form.country"
                    required
                  >
                    <ion-select-option
                      v-for="country in countryNames"
                      v-bind:key="country.code"
                      :value="country.name"
                      class="ion-padding-horizontal ion-no-margin"
                      no-lines
                      lines="none"
                      >{{ country.name }}</ion-select-option
                    >
                  </ion-select>
                </ion-item> -->
                <ion-item no-lines lines="none">
                  <small>
                    <div>
                      <br /><br />
                      <span
                        :innerHTML="
                          preFormat('By clicking signup you are agreeing to the ')
                        "
                      ></span>
                      <a
                        style="cursor: pointer; color: #005ca8"
                        href="https://www.nextapp.co.in/docs/terms_of_service.html"
                        >Terms and Conditions.
                      </a>
                      <br /><br />
                    </div>
                  </small>
                </ion-item>
                <ion-button
                  type="submit"
                  shape="round"
                  expand="block"
                  color="primary"
                  size="normal"
                  :disabled="submitDisabled"
                >
                  {{ getStrings(strings, "SignUp") }}
                  <ion-icon slot="end" :icon="personAdd"></ion-icon>
                  <ion-spinner
                    v-if="submitDisabled"
                    slot="end"
                    name="dots"
                  ></ion-spinner>
                </ion-button>
              </span>
              <br />
              <span v-if="currentSignupStep===1">
                <ion-item no-lines lines="none">
                  <span slot="end"
                    >{{ getStrings(strings, "AlreadyHaveAccount") }}</span
                  >
                </ion-item>
                <ion-item no-lines lines="none">
                  <ion-button
                    type="button"
                    @click="this.$router.replace('/')"
                    shape="round"
                    color="success"
                    size="small"
                    slot="end"
                  >
                    {{ getStrings(strings, "SignIn") }}
                    <ion-icon slot="end" :icon="logIn"></ion-icon>
                  </ion-button>
                </ion-item>
                <br/>
              </span>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </form>
  </base-layout>
</template>

<script>
import {
  IonCard,
  IonItem,
  IonLabel,
  IonButton,
  IonInput,
  alertController,
  IonIcon,
  IonGrid,
  IonCol,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonDatetime,
  //IonText,
} from "@ionic/vue";
import { logIn, personAdd } from "ionicons/icons";
import { useRouter } from "vue-router";
import { signup, checkReferralCode } from "../services/apiCall";
import {
  getColSize,
  getOffsetSize,
  isMinAge,
  preFormat,
  isValidEmail,
} from "../services/utils";
import BaseLayout from "../components/base/BaseLayout.vue";
import LanguageSelection from "../components/settings/LanguageSelection.vue";
import PasswordField from "../components/settings/PasswordField.vue";
import { topicList } from "../services/apiCall";
import { countryList } from "../services/countries.js";
import { loadLang, getStrings } from "../services/lang";
import { initDB } from "../services/db";

export default {
  name: "Signup",
  components: {
    IonCard,
    IonItem,
    IonLabel,
    IonButton,
    IonInput,
    IonIcon,
    IonGrid,
    IonCol,
    IonRow,
    BaseLayout,
    LanguageSelection,
    IonSelect,
    IonSelectOption,
    IonSpinner,
    IonDatetime,
    //IonText,
    PasswordField,
  },
  ionViewWillEnter() {
    this.init();
  },

  setup() {
    const router = useRouter();
    return {
      router,
      logIn,
      personAdd,
      getColSize,
      getOffsetSize,
      countryList,
      getStrings,
    };
  },
  data() {
    return {
      form: {
        username: this.$route.params.email ? this.$route.params.email : "",
        name: "",
        passwordSetting: {
          name: "Password",
          password: "",
          is_valid: false,
        },
        gender: "",
        birth_date: "",
        phone: "",
        country: "India",
        country_code: '91',
        referral_code: this.$route.params.rcode ? this.$route.params.rcode : "",
        topic: [],
      },
      signup,
      checkReferralCode,
      errors: [],
      countryNames: this.countryList(),
      submitDisabled: false,
      isTermsAccepted: false,
      currentSignupStep: 1,
      setUsernameDisabled: false,
      setPhoneDisabled: false,
      setCountryCodeDisabled: false,
      preFormat,
    };
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    topics() {
      return this.$store.getters.topicList;
    },
  },
  methods: {
    async init() {
      try {
        // init the DB
        await initDB();
        loadLang(this.$store);

        topicList()
          .then((res) => {
            if (res.data.status === 0) {
              this.$store.dispatch("settopiclist", res.data.result.topic_list);
            }
          })
          .catch((err) => {
            console.log("topicList - err", err);
          });
      } catch (err) {
        console.log("indexDB not available", err);
        loadLang(this.$store);
      }
    },

    async showError(errorMsg) {
      (
        await alertController.create({
          header: "Signup Failed",
          message: "<small>" + errorMsg + "</small>",
          buttons: ["OK"],
        })
      ).present();
    },

    isAgreeChecked(event) {
      this.isTermsAccepted = !this.isTermsAccepted;
      console.log("isAgreeChecked", event.target.checked, this.isTermsAccepted);
    },

    validateForm() {
      this.errors = [];

      if (!this.form.name) {
        this.errors.push("Name is empty");
      }
      if (!this.form.username) {
        this.errors.push("Email is empty");
      } 

      if (!isValidEmail(this.form.username)) {
        this.errors.push("Invalid Email format provided");
      }

      if (!this.form.phone) {
        this.errors.push("Phone is empty");
      }
      if (!(/^\d+$/.test(this.form.phone))) {
        this.errors.push("Invalid Phone number provided");
      }
      if (this.form.country_code === "91") {
        if (this.form.phone.length !== 10) {
          this.errors.push("Invalid Phone number format provided");
        }
      }
      if (!this.form.gender) {
        this.errors.push("Gender is empty");
      }
      if (!this.form.country) {
        this.errors.push("Country is empty");
      }

      // if (!this.form.passwordSetting.password) {
      //   this.errors.push("Password is empty");
      // }

      return this.errors.length === 0;
    },

    async handleSignup() {
      if (this.submitDisabled) return;
      this.submitDisabled = true;

      // take country name by tel_code
      var userCountry = this.countryNames.filter((y) => y.tel_code === this.form.country_code);
      if (userCountry.length === 0) {
        this.submitDisabled = false;
        this.showError("Country telephone code not selected");
        return;
      }

      this.form.country = userCountry[0].name;
      if (this.validateForm()) {
        this.signup({
          full_name: this.form.name,
          user_email: this.form.username,
          //password: this.form.passwordSetting.password,
          country_code: this.form.country_code,
          phone: this.form.phone,
          birth_date: this.form.birth_date,
          gender: this.form.gender,
          country: this.form.country,
          referral_code: this.form.referral_code,
          topic: this.form.topic,
        })
          .then(async (res) => {
            if (res.data.status === 0) {
              this.submitDisabled = false;
              this.router.replace("/verify/" + res.data.result.tokenized_user);
            } else {
              this.submitDisabled = false;
              this.showError(res.data.error.error_message);
            }
          })
          .catch(async (err) => {
            this.submitDisabled = false;
            console.log("signin - err", err);
            this.showError(err);
          });
      } else {
        this.submitDisabled = false;
        var errorMsg = this.errors.join("<br/>* ");
        errorMsg = "Please correct the following error(s):<br/>* " + errorMsg;
        this.showError(errorMsg);
      }
    },
    validateReferralAndAge() {
      this.nextButtonClicked = true;

      if (!this.form.referral_code) {
        this.showError("Referral code is empty");
        return;
      }
       if (!this.form.birth_date) {
        this.showError("Birthdate is empty");
        return;
      }

      if (!isMinAge(this.form.birth_date)) {
        this.showError("You need to be minimum 13 years to signup.");
        return;
      }

      checkReferralCode({ referral_code: this.form.referral_code })
      .then(async (res) => {
        if (res.data.status === 0) {
          var referral = res.data.result.referral;
          if (referral.email && referral.email.length > 1) {
            this.form.username = referral.email;
            this.setUsernameDisabled = true;
          }
          if (referral.phone && referral.phone.length > 1) {
            this.form.phone = referral.phone;
            this.setPhoneDisabled = true;
          }
          if (referral.country_code && referral.country_code.length > 1) {
            this.form.country_code = referral.country_code;
            this.setCountryCodeDisabled = true;
          }
          this.currentSignupStep = 2;
        } else {
          this.showError(res.data.error.error_message);
        }
        this.nextButtonClicked = false;
      })
      .catch(async (err) => {
        console.log('checkReferral error: ', err);
        this.nextButtonClicked = false;
        this.showError("Error while signup, please try after some time.");
      });
      this.nextButtonClicked = false;
    },
  },
};
</script>