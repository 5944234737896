<template>
  <post-list-item
    v-for="post in posts"
    :key="post.id"
    :post="post"
  ></post-list-item>

  <ion-infinite-scroll
    @ionInfinite="loadMorePosts($event)"
    threshold="100px"
    id="infinite-scroll"
    :disabled="scrollState.scrollDisabled"
  >
    <ion-infinite-scroll-content
      loading-spinner="bubbles"
      loading-text="Loading more posts..."
    >
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</template>

<script>
import { IonInfiniteScroll, IonInfiniteScrollContent } from "@ionic/vue";
import {
  defaultPostsPerFetch,
  defaultMaxPosts,
  processPreview,
} from "../../services/utils";

import PostListItem from "./PostListItem.vue";
import { add } from "ionicons/icons";

import { setPosts } from "../../services/db.js";

export default {
  props: [
    "posts",
    "fetchPostAPI",
    "showPostsFrom",
    "searchString",
    "scrollState",
  ],
  components: {
    PostListItem,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
  },
  data() {
    return {
      add,
      limit: defaultPostsPerFetch(),
    };
  },
  setup() {
    return {
      processPreview,
    };
  },
  methods: {
    loadMorePosts(ev) {
      const sState = this.scrollState;

      // console.log(
      //   "loadMorePosts",
      //   ev,
      //   this.posts,
      //   this.fetchPostAPI,
      //   this.showPostsFrom,
      //   this.searchString
      // );

      sState.scrollSkip = sState.scrollSkip + this.limit;
      var cond = {
        filtered_by: this.showPostsFrom,
        search_string: this.searchString,
        topic_filter: sState.topic_filter,
        limit: this.limit,
        skip: sState.scrollSkip,
        is_favourite: sState.isFavourite,
      };
      if (sState.user_id) {
        cond = {
          filtered_by: "Me",
          limit: this.limit,
          skip: sState.scrollSkip,
          is_favourite: sState.isFavourite,
          user_id: sState.user_id,
        };
      }
      if (sState.group_id) {
        cond["filtered_by"] = "Group";
        cond["group_id"] = sState.group_id;
      }
      this.fetchPostAPI(cond)
        .then(async (res) => {
          // console.log("fetchPostsAPI", res);

          if (res.data.status == 0 && res.data.result.post_list.length != 0) {
            var processedPosts = res.data.result.post_list.map((onePost) => {
              try {
                var previewData = processPreview(onePost);
                onePost["previewData"] = previewData;
                onePost["post_origin_tag"] = sState.post_origin_tag;
                return onePost;
              } catch (ignored) {
                console.log("listPost - err [ parse error ]", ignored, onePost);
                return onePost;
              }
            });

            this.$store.dispatch("appendposts", processedPosts);
            await setPosts(processedPosts);

            // console.log("Loaded data");
            ev.target.complete();

            // App logic to determine if all data is loaded
            // and disable the infinite scroll
            if (
              this.posts.length == defaultMaxPosts() ||
              res.data.result.post_list.length == 0
            ) {
              sState.scrollDisabled = true;
            }
          } else {
            ev.target.complete();
          }
        })
        .catch((err) => {
          console.log("fetchPostsAPI - err", err);
        });
    },
  },
};
</script>