
<template>
  <ion-item
    class="ion-no-padding ion-no-margin ion-text-wrap"
    lines="none"
    :id="'_threadItem_' + conversationThread.id"
    :color="conversationThread.is_selected ? 'medium' : ''"
  >
    <!-- text only item -->
    <ion-text
      v-if="conversationThread.message_type === 'Text'"
      :class="conversationThread.sent_by_me ? getSenderClass() : getReceivedClass()"
      :slot="conversationThread.sent_by_me ? 'end' : 'start'"
      style="max-inline-size: 90%;"
    >
      <ion-label style="margin-bottom: 5px;" v-if="conversationThread.is_group" :color="getPeopleColor(conversationThread.id)" >Sender Name</ion-label>
      <ion-label color="medium" style="text-align: left; line-height: 1.0;" v-if="conversationThread.message_origin==='Forward'">
        <ion-icon
          size="small"
          color="medium"
          style="display: inline-block; vertical-align: middle;"
          :icon="icon.arrowRedoSharp">
        </ion-icon>
        <small>&nbsp;Forwarded message</small>
      </ion-label>
      <ion-card class="ion-no-padding ion-no-margin" style="border-radius: 0px; border-left: 4px solid #EE82EE; height: 50px;" v-if="conversationThread.message_origin==='Reply'">
        Reply to message
      </ion-card>
      {{ this.conversationThread.message }}
      <ion-label style="text-align: right; line-height: 0.8;"> <small>{{formatDateHHMM(conversationThread.time)}}&nbsp;</small>
        <ion-icon
          size="small"
          :color="Object.keys(conversationThread.delivery_status).length===3?'success':''"
          style="display: inline-block; vertical-align: middle;"
          :icon="getSendStatusIcon()"
          v-if="conversationThread.sent_by_me"
        >
        </ion-icon>
      </ion-label>
    </ion-text>

    <!-- image item -->
    <div
      v-if="conversationThread.message_type === 'Image'"
      :class="
        conversationThread.sent_by_me ? getSenderClass() : getReceivedClass()
      "
      :slot="conversationThread.sent_by_me ? 'end' : 'start'"
      style="max-inline-size: 90%;"
    >
      <ion-label style="margin-bottom: 5px;" v-if="conversationThread.is_group" :color="getPeopleColor(conversationThread.id)" >Sender Name</ion-label>
      <ion-label color="medium" style="text-align: left; line-height: 1.0;" v-if="conversationThread.message_origin==='Forward'">
        <ion-icon
          size="small"
          color="medium"
          style="display: inline-block; vertical-align: middle;"
          :icon="icon.arrowRedoSharp">
        </ion-icon>
        <small>&nbsp;Forwarded message</small>
      </ion-label>
      <ion-card class="ion-no-padding ion-no-margin" style="border-radius: 0px; border-left: 4px solid #EE82EE; height: 50px;" v-if="conversationThread.message_origin==='Reply'">
        Reply to message
      </ion-card>
      <ion-img :src="conversationThread.preview.images[0]"></ion-img>
      <ion-icon
          size="large"
          style="position: absolute; top: 50%; right: 50%;"
          :icon="icon.arrowUpCircleOutline"
          v-if="(uploadFailed || !imageAppropriate)&& !showUploadSpinner"
      >
      </ion-icon>
      <ion-label color="danger" v-if="!imageAppropriate">Error: Inappropriate Image</ion-label>
      <ion-spinner v-if="showUploadSpinner" style="position: absolute; top: 50%; right: 50%;" color="primary" name="dots"></ion-spinner>
      <ion-label class="ion-text-wrap" style="margin-top: 10px;"> {{ this.conversationThread.message }}</ion-label>
      <ion-label style="text-align: right; line-height: 0.8;"> <small>{{formatDateHHMM(conversationThread.time)}}&nbsp;</small>
        <ion-icon
          size="small"
          :color="Object.keys(conversationThread.delivery_status).length===3?'success':''"
          style="display: inline-block; vertical-align: middle;"
          :icon="getSendStatusIcon()"
          v-if="conversationThread.sent_by_me"
        >
        </ion-icon>
      </ion-label>
    </div>

    <!-- link item -->
    <div
      v-if="conversationThread.message_type === 'Link'"
      :class="conversationThread.sent_by_me ? getSenderClass() : getReceivedClass()"
      :slot="conversationThread.sent_by_me ? 'end' : 'start'"
      style="max-inline-size: 90%;"
    >
      <ion-label style="margin-bottom: 5px;" v-if="conversationThread.is_group" :color="getPeopleColor(conversationThread.id)" >Sender Name</ion-label>
      <ion-label color="medium" style="text-align: left; line-height: 1.0; margin-bottom: 5px;" v-if="conversationThread.message_origin==='Forward'">
        <ion-icon
          size="small"
          color="medium"
          style="display: inline-block; vertical-align: middle;"
          :icon="icon.arrowRedoSharp"
        >
        </ion-icon>
        <small>&nbsp;Forwarded message</small>
      </ion-label>
      <ion-card class="ion-no-padding ion-no-margin" style="border-radius: 0px; border-left: 4px solid #EE82EE; height: 50px;" v-if="conversationThread.message_origin==='Reply'">
        Reply to message
      </ion-card>
      <a
        :href="conversationThread.preview.url"
        target="_nextChatLink"
        style="text-decoration: none;"
      >
        <ion-img
          :src="conversationThread.preview.images[0]"
        ></ion-img>
        {{ this.conversationThread.preview.siteName }}
        <br />
        {{ this.conversationThread.preview.title }}
      </a>
      <br />
      <ion-label style="margin-top: 10px;"> {{ this.conversationThread.message }}</ion-label>
      <ion-label style="text-align: right; line-height: 0.8;"> <small>{{formatDateHHMM(conversationThread.time)}}&nbsp;</small>
        <ion-icon
          size="small"
          :color="Object.keys(conversationThread.delivery_status).length===3?'success':''"
          style="display: inline-block; vertical-align: middle;"
          :icon="getSendStatusIcon()"
          v-if="conversationThread.sent_by_me"
        >
        </ion-icon>
      </ion-label>
    </div>
  </ion-item>
</template>

<script>
import { IonItem, IonText, IonImg, IonLabel, IonIcon, IonSpinner, IonCard, popoverController } from "@ionic/vue";
import { useRouter } from "vue-router";
import { checkmarkDoneOutline, checkmarkOutline, syncOutline, arrowRedoSharp, arrowUpCircleOutline } from "ionicons/icons";
import { Plugins } from "@capacitor/core";

const { Haptics } = Plugins;
import axios from "axios";

import { scaleImage, formatDateHHMM } from "../../services/utils.js";
import { uploadImage, presignedURL, nsfwCheck, deleteImage, getAPIKey } from "../../services/apiCall";
import Hammer from "hammerjs";
import md5 from "md5";

import ConversationReactionOptions from "./ConversationReactionOptions.vue";

export default {
  name: "ConversationThreadItem",
  props: ["conversationThread"],

  components: { IonItem, IonText, IonImg, IonLabel, IonIcon, IonSpinner, IonCard },
  setup() {
    const router = useRouter();
    return {
      router,
      scaleImage,      
      presignedURL,
      uploadImage,
      nsfwCheck,
      deleteImage,
      getAPIKey,
      formatDateHHMM,
    };
  },
  data() {
    return {
      showUploadSpinner: false,
      uploadFailed: false,
      imageAppropriate: true,
      icon: {
        checkmarkDoneOutline,
        checkmarkOutline,
        syncOutline,
        arrowRedoSharp,
        arrowUpCircleOutline,
      },
    };
  },
  mounted() {
    this.init();
  },
  unmounted() {},
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
  },
  methods: {
    init() {
      // register gesture
      let theItemEl = document.getElementById(
        "_threadItem_" + this.conversationThread.id
      );
      //console.log("ConvesationThredItem.init()", theItemEl);

      let manager = new Hammer.Manager(theItemEl);
      let longPress = new Hammer.Press({
        time: 500,
      });

      manager.add(longPress);

      var conversation = this.conversationThread;

      if (conversation.new_message) {
        this.sendMessage();
      }

      manager.on("press", async (ev) => {
        console.log("press", ev);

        try {
          Haptics.vibrate();
        } catch (ignored) {
          ignored;
        }

        conversation.is_selected = true;

        const popover = await popoverController.create({
          component: ConversationReactionOptions,
          event: ev,
        });

        popover.componentProps = {
          conversationThread: this.conversationThread,
          dismissCallback: this.dismissCallback,
          parent: popover,
        };

        popover.onDidDismiss().then((ev) => {
          ev;
          conversation.is_selected = false;
        });
        return popover.present();
      });
    },

    dismissCallback() {
      var conversation = this.conversationThread;
      conversation.is_selected = false;
    },

    getSenderClass() {
      return (
        "box_conversation box_conversation_user ion-no-padding ion-no-margin ion-text-wrap " +
        (this.conversationThread.is_previous_user_same
          ? "box_conversation_less_margin"
          : "sent_conversation")
      );
    },

    getReceivedClass() {
      return (
        "box_conversation box_conversation_other ion-no-padding ion-no-margin ion-text-wrap " +
        (this.conversationThread.is_previous_user_same
          ? "box_conversation_less_margin"
          : "received_conversation")
      );
    },
    
    getSendStatusIcon() {
      var deliveryStatus = ['Sent', 'Delivered', 'Seen']
      var delStatus = deliveryStatus[Object.keys(this.conversationThread.delivery_status).length - 1];
      if (delStatus === 'Sent') return this.icon.checkmarkOutline;
      else if (delStatus === 'Delivered') return this.icon.checkmarkDoneOutline;
      else if (delStatus === 'Seen') return this.icon.checkmarkDoneOutline;
      else return this.icon.syncOutline;
    },
    
    getPeopleColor(userId) {
      var allColors = ['secondary', 'tertiary', 'success', 'warning', 'danger'];
      return allColors[userId.length%5];
    },

    async sendMessage() {
      this.showUploadSpinner = true;
      this.scaleAndUploadImage();
      
      // call api to send message
      const user = this.$store.getters.user;
      let sendMessageURI = 'conversation' + "/" + getAPIKey() + "/" + user.user_id + "/" +
        user.auth.token + "/" + user.auth.session;
      console.log("sendMessageURI:", sendMessageURI);

      let res = (await axios.get(sendMessageURI)).data;

      console.log('Send msg res:', res);

      if (res.status === 0) {
        console.log('Res:', res.result);
      } else {
        console.log("Unable to send message : " + res);
      }

      this.showUploadSpinner = false;
    },

    async scaleAndUploadImage() {
      if (!this.conversationThread.preview.attached_image) return;

      const user = this.$store.getters.user;
      var attachedImage = this.conversationThread.preview.attached_image;
      let imageFormat = attachedImage.image_file.type.split("/")[1];
      const fileName = user.user_id + "_" + user.auth.token + "_" + user.auth.session + "_" + 
        md5(attachedImage.image_file.name) + Date.now() + "." +  imageFormat;

      this.scaleImage(attachedImage.image_file, fileName).then((fileObject) => {
        presignedURL({ name: fileName })
          .then((res) => {
            console.log('presignedURL: ', res);
            if (res.data.status === 0) {
              uploadImage(res.data.result.put_url, fileObject)
                .then(() => {
                  nsfwCheck({ url: res.data.result.get_url })
                    .then((res3) => {
                      console.log('nsfwCheck: ', res3);
                      if (res3.data.status === 0) {
                        this.imageAppropriate = res3.data.result.is_ok;
                        if (!this.imageAppropriate) {
                          deleteImage({ name: fileName })
                            .then(() => {
                              // file delete from
                            })
                            .catch((err) => {
                              console.log("deleteImage - err", err);
                            });
                        }
                      }
                    })
                    .catch((err3) => {
                      console.log("nsfwCheck - err", err3);
                      this.uploadFailed = true;
                    });
                })
                .catch((err2) => {
                  console.log("uploadImage - err", err2);
                  this.uploadFailed = true;
                });
            }
          })
          .catch((err2) => {
            console.log("uploadImage - err", err2);
            this.uploadFailed = true;
          });
      });

      this.showUploadSpinner = false;
    }
  },
};
</script>