<template>
  <ion-page>
    <ion-header>
      <ion-toolbar mode="md" class="next_bar_color">
        <ion-title>{{pageTitle}}</ion-title>
        <ion-buttons
          slot="start"
          size="small"
          style="padding: 0px"
          class="ion-no-padding ion-no-margin"
        >
          <ion-back-button
            size="small"
            slot="icon-only"
            style="padding: 0px"
            class="ion-no-padding ion-no-margin"
          ></ion-back-button>
        </ion-buttons>
        <ion-buttons :collapse="true" slot="end">
          <slot name="actions-end"></slot>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <slot />
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonContent,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
} from "@ionic/vue";

import {
  ellipsisHorizontal
} from "ionicons/icons";

export default {
  props: ["pageTitle"],
  setup() {
    return {};
  },
  data() {
    return {
      icon: {
        ellipsisHorizontal,
      },
    };
  },
  components: {
    IonPage,
    IonHeader,
    IonContent,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
  },
  methods: {},
};
</script>