<template>
  <ion-content>
    <ion-button
      class="reaction_popover_button"
      fill="clear"
      size="small"
      v-for="reaction in localReactionOptions"
      v-bind:key="reaction.name"
      @click="reactConversation(conversationThread, reaction.name)"
    >
      <ion-icon
        slot="icon-only"
        :src="reaction.src"
        :isActive="hasMyReaction(conversationThread, reaction.name)"
      ></ion-icon>
    </ion-button>
    <hr />
    <ion-list>
      <ion-item @click="onReply">
        <ion-label>Reply</ion-label>
        <ion-icon :icon="icon.arrowUndoOutline"></ion-icon>
      </ion-item>
      <ion-item @click="onForward">
        <ion-label>Forward</ion-label>
        <ion-icon :icon="icon.arrowRedoOutline"></ion-icon>
      </ion-item>
      <ion-item @click="onCopy">
        <ion-label>Copy</ion-label>
        <ion-icon :icon="icon.copyOutline"></ion-icon>
      </ion-item>
      <ion-item @click="onDelete">
        <ion-label>Delete</ion-label>
        <ion-icon :icon="icon.trashOutline"></ion-icon>
      </ion-item>
    </ion-list>
  </ion-content>
</template>
<script>
import {
  IonButton,
  IonIcon,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
} from "@ionic/vue";
import { defineComponent } from "vue";

import {
  copyOutline,
  trashOutline,
  arrowUndoOutline,
  arrowRedoOutline,
} from "ionicons/icons";

import { reactionListArray } from "../../services/utils";

export default defineComponent({
  name: "ConversationReactionOptions",
  props: ["conversationThread", "parent", "dismissCallback"],
  components: {
    IonButton,
    IonIcon,
    IonContent,
    IonList,
    IonItem,
    IonLabel,
  },
  data() {
    return {
      localReactionOptions: reactionListArray,
      icon: {
        copyOutline,
        trashOutline,
        arrowUndoOutline,
        arrowRedoOutline,
      },
    };
  },
  methods: {
    hasMyReaction(conversationThread, rtype) {
      console.log("hasMyReaction", conversationThread, rtype);
      // TODO:
      return false;
    },
    dismissMe() {
      if (this.parent) this.parent.dismiss();
      if (this.dismissCallback) this.dismissCallback();
    },
    reactConversation(conversationThread, rtype) {
      console.log("reactConversation", conversationThread, rtype);

      this.dismissMe();
    },
    onCopy() {
      this.dismissMe();
    },
    onDelete() {
      this.dismissMe();
    },
    onForward() {
      this.dismissMe();
    },
    onReply() {
      this.dismissMe();
    },
  },
});
</script>