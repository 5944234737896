<template>
  <base-layout page-title="Feedback" showBackButton="true">
    <br/>
    <ion-row class="ion-no-padding ion-no-pargin">
      <ion-col
        :size="getColSize()"
        :offset="getOffsetSize()"
        class="ion-no-padding ion-no-pargin"
      >
        <ion-item no-lines lines="none">
          <ion-text class="left-margin-15px right-margin-15px">
            {{ getStrings(strings, 'PleaseSendFeedback') }}
          </ion-text>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row class="ion-no-padding ion-no-pargin">
      <ion-col
        :size="getColSize()"
        :offset="getOffsetSize()"
        class="ion-no-padding ion-no-pargin"
      >
        <form @submit.prevent="sendFeedbackHandler">
          <ion-item no-lines lines="none" class="signup-item-style">
            <ion-textarea
              auto-grow="true"
              autocomplete="on"
              autocapitalize="on"
              spellcheck="true"
              inputmode="text"
              rows="1"
              v-model="form.feedbackDescription"
              id="feedbackDescription"
              required
              no-padding
              no margin
              placeholder="Write your feedback"
              class="right-margin-15px left-margin-15px"
            ></ion-textarea>
          </ion-item>
          <ion-item no-lines lines="none">
            <ion-button
              size="medium"
              slot="end"
              :disabled="sendFeedbackClicked || form.feedbackDescription.length < 1"
              type="submit"
              class="right-margin-15px"
            >
              {{ getStrings(strings, 'SendFeedback') }}
            </ion-button>
            <ion-spinner v-if="sendFeedbackClicked" name="dots"></ion-spinner>
          </ion-item>
        </form>
      </ion-col>
    </ion-row>
    <ion-row class="ion-no-padding ion-no-pargin" v-if="feedbackSubmitted">
      <ion-col
        :size="getColSize()"
        :offset="getOffsetSize()"
        class="ion-no-padding ion-no-pargin"
      >
        <ion-item no-lines lines="none">
          <ion-label color="success" style="text-align: center;" class="left-margin-15px right-margin-15px">
            {{ getStrings(strings, 'ThanksForFeedback') }}
            <br/>
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
  </base-layout>
</template>

<script>
import {
  IonButton,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonTextarea,
  IonSpinner,
  IonText,
} from "@ionic/vue";

import { homeOutline, settingsOutline, logOutOutline, pencilOutline } from "ionicons/icons";
import { useRouter } from "vue-router";
import { isMobileScreen, getColSize, getOffsetSize, logOffApp } from "../services/utils";
import { readFromDB } from "../services/db";
import { createFeedback } from "../services/apiCall";
import { getStrings } from "../services/lang";

export default {
  name: "FeedbackPage",
  ionViewWillEnter() {
    this.init();
  },
  components: {
    IonButton,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonTextarea,
    IonSpinner,
    IonText,
  },
  setup() {
    const router = useRouter();
    return {
      router,
      getColSize,
      getOffsetSize,
      isMobileScreen,
      createFeedback,
      getStrings,
    };
  },
  data() {
    return {
      homeOutline,
      settingsOutline,
      logOutOutline,
      pencilOutline,
      logOutDisabled: false,
      sendFeedbackClicked: false,
      feedbackSubmitted: false,
      form: {
        feedbackDescription: "",
        feedbackRating: "Excellent",
      },
    };
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    init() {
      console.log("USER:", this.user);
      this.readDB();
    },
    readDB(callbackObject = this) {
      try {
        readFromDB(this.$store, true)()
          .then(async () => {
            // read from DB done
            if (
              typeof callbackObject.user === "undefined" ||
              typeof callbackObject.user.user_id === "undefined"
            ) {
              this.logOff(callbackObject);
            }
            console.log("current user:", callbackObject.currentUser);
          })
          .catch((err) => {
            console.log("indexdb not available", err);
          });
      } catch (err) {
        console.log("indexdb not available", err);
      }
    },
    async logOff(callbackObject = this) {
      callbackObject.logOutDisabled = true;

      await logOffApp();

      callbackObject.$store.dispatch("adduser", {});

      callbackObject.router.replace("/login");
    },

    sendFeedbackHandler() {
      console.log('Send feedback', this.form);
      this.sendFeedbackClicked = true;
      createFeedback({ feedback_text: this.form.feedbackDescription, app_rating: this.form.feedbackRating })
        .then((res) => {
          console.log("sendFeedbackHandler", res);
          if (res.data.status === 0) {
            console.log('Feedback submitted');
            this.feedbackSubmitted = true;
            this.form.feedbackDescription = "";
          }
          this.sendFeedbackClicked = false;
        })
        .catch((err) => {
          console.log("sendFeedbackHandler - err", err);
          this.sendFeedbackClicked = false;
        });
    }

  },
};
</script>