<template>
  <ion-item
    size="small"
    :class="isIOS() ? 'reaction_count_item_ios' : 'reaction_count_item'"
    v-if="
      post.reaction_count > 0 || post.comment_count > 0 || post.share_count > 0
    "
    lines="none"
    no-lines
    class="ion-no-padding ion-no-margin"
  >
    <span slot="start" class="ion-no-padding ion-no-margin">
      <reaction-by-button
        v-if="post.reaction_count > 0"
        :postId="post.post_id"
        :reactionCount="post.reaction_count"
        :disabled="post.group_id && !post.is_group_member"
        class="left-margin-10px"
      ></reaction-by-button>
    </span>
    <span
      slot="end"
      v-if="post.comment_count > 0 || post.share_count > 0"
      class="ion-no-padding ion-no-margin"
    >
      <ion-button
        size="small"
        slot="end"
        fill="clear"
        color="primary"
        class="button_nopadding"
        style="text-transform: none"
        @click="onCommentListButton()"
        :disabled="commentListClicked || (post.group_id && !post.is_group_member)"
        v-if="post.comment_count > 0"
      >
        <span v-if="!showCommentList.show">
          {{ post.comment_count }}
          {{ post.comment_count === 1 ? getStrings(strings, "Comment") : getStrings(strings, "Comments") }}
        </span>
        <span v-if="setCommentShow()"> {{ getStrings(strings, "HideComments") }} </span>
        <ion-spinner v-if="commentListClicked" name="dots"></ion-spinner>
      </ion-button>
      <shared-by-button
        :postId="post.post_id"
        :shareCount="post.share_count"
      ></shared-by-button>
    </span>
  </ion-item>
  <ion-item class="user_reaction_item" lines="none" no-lines>
    <user-post-reaction :post="post" :showCommentList="showCommentList">
    </user-post-reaction>
  </ion-item>
  <ion-item
    class="comment_box_item"
    lines="none"
    no-lines
    v-if="post.is_active && showCommentList.show"
  >
    <post-comment-box
      :post="post"
      :newComment="newComment"
    >
    </post-comment-box>
  </ion-item>
  <span v-if="showCommentList.show" lines="none" no-lines>
    <post-comment-list
      :post="post"
      :comments="post.comments"
      :showCommentBox="false"
      v-if="showCommentList.show"
    ></post-comment-list>
  </span>
  <ion-item
    lines="none"
    no-lines
    class="ion-padding-horizontal ion-no-margin"
    v-if="newComment.added && !showCommentList.show"
  >
    <ion-label color="success"
      ><small
        >Comment added, click comments button to see comments</small
      ></ion-label
    >
  </ion-item>
</template>

<script>
import { IonButton, IonItem, IonLabel, IonSpinner } from "@ionic/vue";
import {
  flag,
  personAdd,
  thumbsUp,
  thumbsUpSharp,
  thumbsUpOutline,
  arrowRedoOutline,
  arrowRedoSharp,
  repeatOutline,
  heart,
  happy,
  sad,
  star,
  starOutline,
  trashOutline,
  createOutline,
} from "ionicons/icons";

import { defineComponent } from "vue";

import {
  favouritePost,
  sharePost,
  commentList,
} from "../../services/apiCall.js";
import { isIOS, processNestedComments } from "../../services/utils.js";
import { formatDate, isMobileScreen } from "../../services/utils";
import ReactionByButton from "../activity/ReactionByButton.vue";
import SharedByButton from "../activity/SharedByButton.vue";
import UserPostReaction from "./UserPostReaction.vue";
import PostCommentBox from "./PostCommentBox.vue";
import PostCommentList from "./PostCommentList.vue";
import { getStrings } from "../../services/lang";

export default defineComponent({
  name: "PostFooter",
  props: ["postId", "post", "showComments"],
  components: {
    IonButton,
    IonItem,
    IonLabel,
    IonSpinner,
    ReactionByButton,
    SharedByButton,
    UserPostReaction,
    PostCommentBox,
    PostCommentList,
  },
  setup() {
    return {
      sharePost,
      favouritePost,
      isMobileScreen,
      formatDate,
      isIOS,
      processNestedComments,
      getStrings,
    };
  },
  data() {
    // console.log("Post:", this.post);
    return {
      flag,
      personAdd,
      thumbsUp,
      thumbsUpSharp,
      thumbsUpOutline,
      arrowRedoOutline,
      arrowRedoSharp,
      repeatOutline,
      heart,
      happy,
      sad,
      star,
      starOutline,
      trashOutline,
      createOutline,
      commentAdded: false,
      newComment: { added: false },
      isCommentListVisible: false,
      showCommentList: {
        show: this.showComments ? this.showComments : false,
      },
      commentListClicked: false,
    };
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    setCommentShow() {
      if (this.commentListClicked) return false;
      
      var commentsAvailable = this.post.comments
        ? this.post.comments.length > 0
        : false;

      if (this.showCommentList.show) {
        if (commentsAvailable) {
          return true;
        } else {
          this.showCommentList.show = false;
        }
      }

      return false;
    },
    onCommentListButton() {
      this.commentListClicked = true;

      this.newComment.added = false;
      this.showCommentList.show = !this.showCommentList.show;
      var localPost = this.post;

      if (this.showCommentList.show) {
        commentList(this.post.post_id)
          .then(async (res) => {
            if (res.data.status === 0) {
              localPost.comments = processNestedComments(res.data.result.comment_list);
              console.log('comment - replies', localPost.comments);
              this.commentListClicked = false;
            } else {
              console.log("post comment list err - 1", res);
              this.showCommentList.show = false;
              this.commentListClicked = false;
            }
          })
          .catch(async (err) => {
            console.log("post comment list err - 2", err);
            this.showCommentList.show = false;
            this.commentListClicked = false;
          });
      } else {
        this.commentListClicked = false;
      }
    },
  },
});
</script>
