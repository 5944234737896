<template>
  <ion-item v-if="isNameFieldPresent" class="ion-padding-horizontal ion-no-margin">
    <ion-label size="small">Your Name will appear as:</ion-label>
    <ion-text size="large"><b>{{ namePreview() }}</b></ion-text>
  </ion-item>
  <ion-item v-if="fields.length < 1" color="warning" class="ion-padding-horizontal ion-no-margin">
    <ion-label size="small">Under Development</ion-label>
  </ion-item>
  <generic-profile-field
    v-for="field in fields"
    :key="field.field_name"
    :field="processField(field)"
    :section="section"
  >
  </generic-profile-field>
</template>

<script>
import { IonItem, IonLabel, IonText } from "@ionic/vue";

import GenericProfileField from "./GenericProfileField.vue";

export default {
  name: "GenericProfileSection",
  props: ["fields", "section"],
  components: {
    IonItem,
    IonLabel,
    IonText,
    GenericProfileField,
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  data() {
    return {
      isNameFieldPresent: this.hasNameField(),
    };
  },
  setup() {},
  methods: {
    hasNameField() {
      var nameFields = this.fields.filter((x) =>
        x.field_name.endsWith("_name")
      );
      return nameFields.length >= 4;
    },
    namePreview() {
      var initials = this.fields.filter((x) => x.field_name == "initials_name");

      var firstName = this.fields.filter((x) => x.field_name == "first_name");

      var middleName = this.fields.filter((x) => x.field_name == "middle_name");

      var lastName = this.fields.filter((x) => x.field_name == "last_name");

      // TODO: handle visibility 

      return (
        (initials.length == 1 ? initials[0].value ? initials[0].value + " " : "" : "") +
        (firstName.length == 1 ? firstName[0].value ? firstName[0].value + " " : "" : "") +
        (middleName.length == 1 ? middleName[0].value ? middleName[0].value + " " : "" : "") +
        (lastName.length == 1 ? lastName[0].value ? lastName[0].value + " " : "" : "")
      );
    },
    processField(field) {
      if (field.type === "json" && field.ui_type === "multirow") {
        if (typeof(field.default) === 'string') {
          field.default = JSON.parse(field.default);
          field.value = JSON.parse(JSON.stringify(field.default));
        }
        return field;
      } else {
        return field;
      }
    }
  },
};
</script>