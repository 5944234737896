<template>
  <selection-chat-base-layout
    page-title="Forward to ..."
    page-default-back-link="/chat"
    showBackButton="true"
    @menu-cancel="onMenuCancelClicked"
  >
    <template v-slot:actions-end>
      <ion-searchbar
        v-if="showInlineSearch"
        v-model="inlineSearchText"
        placeholder="Search Users"
        class="ion-text-left search_box"
        autofocus="true"
        inputmode="search"
        animated="true"
        clearIcon
        enterkeyhint
        type="text"
        @ionChange="searchUsersAndPost"
      >
      </ion-searchbar>
      <ion-button
        @click="
          isMobileScreen()
            ? this.$router.push('/chat/search')
            : (showInlineSearch = !showInlineSearch)
        "
        size="getToolButtonSize()"
        class="button_nopadding"
      >
        <ion-icon
          v-if="!showInlineSearch"
          src="assets/icon/misc/search.svg"
        ></ion-icon>
        <ion-icon
          slot="icon-only"
          v-if="showInlineSearch"
          size="getToolButtonSize()"
          :icon="icon.closeCircle"
        ></ion-icon>
      </ion-button>
    </template>
    <selection-chat-user-list
      :tokenizedUser="user.user_id"
      :isSelfUser="true"
      :userFFList="userFFList"
      :isForwarding="true"
      :forwardData="postForwarding"
      ref="mUserList"
    >
    </selection-chat-user-list>

    <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button @click="sendMessage">
        <ion-icon :icon="icon.sendOutline"></ion-icon
      ></ion-fab-button>
    </ion-fab>
    <ion-loading :is-open="fetchingFriendList"> </ion-loading>
  </selection-chat-base-layout>
</template>

<script>
import {
  IonLoading,
  IonSearchbar,
  IonIcon,
  IonButton,
  IonFab,
  IonFabButton,
  modalController,
  toastController,
} from "@ionic/vue";
import { closeCircle, search, sendOutline } from "ionicons/icons";
import {
  getColSize,
  getOffsetSize,
  isMobileScreen,
  defaultPostsPerFetch,
} from "../../services/utils";

import { useRouter } from "vue-router";
import { getUser } from "../../services/db";

import { forwardChat, forwardGroupChat } from "../services/chatApiCall";
import { friendsInChat, chatUserSearch } from "../../services/apiCall";
import SelectionChatUserList from "./SelectionChatUserList.vue";
import SelectionChatBaseLayout from "./SelectionChatBaseLayout.vue";

export default {
  props: ["postForForwarding", "isForwardingPost", "wsConnection", "curUser"],
  components: {
    IonIcon,
    IonButton,
    IonLoading,
    IonSearchbar,
    IonFab,
    IonFabButton,
    SelectionChatUserList,
    SelectionChatBaseLayout,
  },
  ionViewWillLeave() {
    //console.log("SelectionChatUsers page will leave");
    if (this.friendsRefreshCallback !== null)
      clearInterval(this.friendsRefreshCallback);
  },

  beforeUnmount(){
    //console.log("SelectionChatUsers page will unmount");
    if (this.friendsRefreshCallback !== null)
      clearInterval(this.friendsRefreshCallback);
  },

  setup() {
    //console.log("Selection - ChatUsers page setup");
    return {
      getColSize,
      getOffsetSize,
      isMobileScreen,
    };
  },
  data() {
    const router = useRouter();
    return {
      router,
      icon: { closeCircle, search, sendOutline },

      userFFList: [],
      fetchingFriendList: false,

      friendsRefreshCallback: null,

      showInlineSearch: false,
      inlineSearchText: "",

      modalController,
      postForwarding: this.postForForwarding,
      isForwarding: this.isForwardingPost,
      wsConn: this.wsConnection,
    };
  },
  computed: {
    theFriendsList() {
      return this.$store.getters.friends;
    },
    user() {
      return this.$store.getters.user;
    },
  },

  ionViewDidLeave() {
    console.log("Selection - window did leave");
  },
  ionViewWillEnter() {
    console.log("Selection - window will enter");
  },

  async mounted() {
    this.currentUser = await getUser("currentUser");
    this.loadFriends();

    this.init();

    console.log("Selection - window mounted", this.currentUser);
    console.log("Selection - post :", JSON.stringify(this.postForForwarding));
  },

  async ionViewDidEnter() {
    console.log("Selection - Chat users page did enter!");
    /*this.currentUser = await getUser("currentUser");
    this.loadFriends();

    this.init();*/
  },

  methods: {
    addFriend(friendData) {
      this.$store.dispatch("addfriend", friendData);
    },
    init() {
      //console.log("Selection Chat User --- init ");
      this.friendsRefreshCallback = setInterval(this.updateFriends, 30000);
    },

    searchUsersAndPost() {
      this.friendList = [];
      this.fetchingFriendList = true;

      if (!this.inlineSearchText) {
        this.fetchingFriendList = false;
        return;
      }
      if (this.inlineSearchText.trim() === "") {
        this.fetchingFriendList = false;
        return;
      }

      //console.log("Search User and Post called ");
      chatUserSearch({
        search_string: this.inlineSearchText.trim(),
        limit: defaultPostsPerFetch(),
        skip: 0,
      })
        .then((res) => {
          if (res.data.status === 0) {
            this.userFFList = res.data.result.search_list;

            this.userFFList.forEach((obj) => {
              obj["isSelected"] = false;
            });
          }
          this.fetchingFriendList = false;
        })
        .catch((err) => {
          console.log("userSearch - err", err);
          this.fetchingFriendList = false;
        });
    },

    loadFriends() {
      //console.log("Selection Chat User --- load firends");
      this.fetchingFriendList = true;
      let friendList = this.$store.getters.chatConnections;
      if (friendList.length > 0) {
        this.userFFList = friendList;
        this.fetchingFriendList = false;
        this.updateFriends();
      } else {
        friendsInChat({ tokenized_user: this.tokenizedUser })
          .then((res) => {
            if (res.data.status === 0) {
              //console.log(JSON.stringify(res.data.result.friend_list));
              this.userFFList = res.data.result.friend_list;
              this.userFFList.forEach((obj) => {
                this.$store.dispatch("addChatConnection", obj);
              });
            }
            this.fetchingFriendList = false;
          })
          .catch((err) => {
            console.log("friend list - err", err);
            this.fetchingFriendList = false;
            this.logOff();
          });
      }
      console.log("Selection Chat User --- done load firends");
    },

    updateFriends() {
      friendsInChat({ tokenized_user: this.tokenizedUser })
        .then((res) => {
          if (res.data.status === 0) {
            const newList = res.data.result.friend_list;
            //console.log(JSON.stringify(newList));
            if (this.userFFList.length === newList.length) {
              for (var i = 0; i < this.userFFList.length; i++) {
                this.$store.dispatch("addChatConnection", this.userFFList[i]);

                if (this.userFFList[i].is_group) {
                  var result1 = newList.find((obj) => {
                    return (
                      obj.tokenized_group === this.userFFList[i].tokenized_group
                    );
                  });

                  if (result1) {
                    this.userFFList[i].recent_text = result1.recent_text;
                    this.userFFList[i].new_chat_count = result1.new_chat_count;
                    this.userFFList[i].createdAt = "";
                    this.userFFList[i].createdAt = result1.createdAt;
                  }
                } else {
                  var result = newList.find((obj) => {
                    return (
                      obj.tokenizedUser === this.userFFList[i].tokenizedUser
                    );
                  });

                  if (result) {
                    this.userFFList[i].recent_text = result.recent_text;
                    this.userFFList[i].new_chat_count = result.new_chat_count;
                    this.userFFList[i].createdAt = "";
                    this.userFFList[i].createdAt = result.createdAt;
                  }
                }
              }
            } else {
              this.userFFList = newList;
            }

            //console.log(JSON.stringify(this.userFFList));
          }
        })
        .catch((err) => {
          console.log("friend list - err", err);
          this.logOff();
        });
    },

    async sendMessage() {
      if (this.isForwarding) {
        if (this.postForForwarding) {
          var selUsers = this.$refs.mUserList.getSelectedUsersList();
          console.log("Send Message to : ", JSON.stringify(selUsers));

          if (selUsers.length > 0) {
            console.log("MSSG:" + JSON.stringify(this.postForForwarding));

            var postData = {
              msgtype: this.postForwarding.content.msgtype,
              message: this.postForwarding.content.data,
            };

            if ("text" != this.postForwarding.content.msgtype) {
              postData["media_path"] = this.postForwarding.content.media_path;
            }

            var replyTo = 0;

            var forwardid = 0;
            if (this.postForwarding.forward_id)
              forwardid = this.postForwarding.forward_id;

            const currentUser = this.currentUser;
            selUsers.forEach((obj) => {           
              var str_is_group = "Group";

              if (this.postForForwarding.to_user_id) str_is_group = "User";

              var forwardContents = {
                from: str_is_group,
                fwdID: forwardid,
                chatid: this.postForwarding.id,
              };

              if (obj.is_group) {
                forwardGroupChat(
                  this.wsConn,
                  currentUser,
                  obj.token,
                  postData,
                  replyTo,
                  forwardContents
                );
                console.log("Forwarded to Group");
              } else {
                forwardChat(
                  this.wsConn,
                  currentUser,
                  obj.token,
                  postData,
                  replyTo,
                  forwardContents
                );
                console.log("Forwarded to User");
              }
            });
          }
        }
      }

      var toastString = "Message sent...";
      this.openToast(toastString);

      await this.modalController.dismiss();
    },

    async logOff() {
      this.$store.dispatch("adduser", {});
      this.router.replace("/login");

      if (this.friendsRefreshCallback !== null)
        clearInterval(this.friendsRefreshCallback);
    },

    inLineSearch() {
      console.log("inLineSearch", this.inlineSearchText);
      this.router.push("/chat/search/" + this.inlineSearchText);
    },

    async onMenuCancelClicked() {
      await this.modalController.dismiss();
    },

    async openToast(msg) {
      const toast = await toastController.create({
        message: msg,
        duration: 1500,
      });
      return toast.present();
    },
  },
};
</script>