<template>
  <base-layout page-title="Search" showBackButton="true">
    <ion-row class="animate-fading">
      <ion-col :size="getColSize()" :offset="getOffsetSize()" class="ion-no-padding ion-no-margin">
        <ion-item class="ion-no-padding ion-no-margin" no-lines lines="none">
          <ion-searchbar
            class="searchbar-input"
            type="text"
            placeholder="Search People"
            v-model="searchText"
            @keyup.enter="setTimeout(searchUsersAndPost, 500)"
            @ionChange="searchUsersAndPost"
            debounce="500"
            clearInput
          >
          </ion-searchbar>

          <ion-spinner
            v-if="userSearchInProgress || postSearchInProgress"
            slot="end"
            name="dots"
          ></ion-spinner>
        </ion-item>
      </ion-col>
    </ion-row>

    <ion-row class="animate-fading">
      <ion-col :size="getColSize()" :offset="getOffsetSize()">
        <ion-item class="ion-no-padding ion-no-margin">
          <ion-label class="ion-padding-horizontal"
            ><b>People ({{ friendList.length }})</b></ion-label
          >
          <ion-spinner
            v-if="userSearchInProgress"
            slot="end"
            name="dots"
          ></ion-spinner>
        </ion-item>
      </ion-col>
    </ion-row>

    <ion-row v-if="friendList.length > 0" class="animate-fading">
      <ion-col>
        <chat-user-list
          :tokenizedUser="user.user_id"
          :isSelfUser="true"
          :userFFList="friendList"
        >
        </chat-user-list>
      </ion-col>
    </ion-row>
  </base-layout>
</template>

<script>
import {
  IonSearchbar,
  IonItem,
  IonLabel,
  IonCol,
  IonRow,
  IonSpinner,
} from "@ionic/vue";

import { useRouter } from "vue-router";

import {
  getColSize,
  getOffsetSize,
  processPreview,
  logOffApp,
  defaultPostsPerFetch,
} from "../../services/utils.js";

import { search, homeOutline } from "ionicons/icons";

import { chatUserSearch, postSearch } from "../../services/apiCall";
import ChatUserList from "../components/ChatUserList.vue";
import { readFromDB } from "../../services/db.js";

export default {
  name: "ChatSearchUserPage",
  ionViewWillEnter() {
    this.readDBAndInit();
  },
  ionViewWillLeave() {
    this.searchText = "";
  },
  components: {
    IonSearchbar,
    IonItem,
    IonLabel,
    IonCol,
    IonRow,
    ChatUserList,
    IonSpinner,
  },
  watch: {
    "$route.params.searchtxt": function (stxt) {
      console.log("Search txt", stxt);
      try {
        this.searchText = this.$route.params.searchtxt
          ? this.$route.params.searchtxt
          : "";
        this.searchUsersAndPost();
      } catch (err) {
        console.log("Error searching. " + err);
      }
    },
  },
  data() {
    return {
      searchText: this.$route.params.searchtxt
        ? this.$route.params.searchtxt
        : "",
      search,
      homeOutline,
      userSearchInProgress: false,
      postSearchInProgress: false,
      friendList: [],
      posts: [],
      postSearch,
      scrollState: {
        scrollDisabled: false,
        scrollSkip: 0,
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    init() {
      this.searchText = this.$route.params.searchtxt
        ? this.$route.params.searchtxt
        : "";
      if (this.searchText !== "") {
        this.searchUsersAndPost();
      }
    },
    readDBAndInit(callbackObject = this) {
      this.$store.dispatch("setposts", []);

      try {
        readFromDB(this.$store, true)()
          .then(async () => {
            // read from DB done
            // console.log("read from db, user", callbackObject.user);

            if (
              typeof callbackObject.user === "undefined" ||
              typeof callbackObject.user.user_id === "undefined"
            ) {
              this.logOff(callbackObject);
            } else {
              this.init();
            }
          })
          .catch((err) => {
            console.log("indexdb not available", err);
            this.init();
          });
      } catch (err) {
        console.log("indexdb not available", err);
        this.init();
      }
    },
    searchUsersAndPost() {
      this.posts = [];
      this.friendList = [];
      this.userSearchInProgress = true;

      if (!this.searchText) {
        this.userSearchInProgress = false;
        this.postSearchInProgress = false;
        return;
      }
      if (this.searchText.trim() === "") {
        this.userSearchInProgress = false;
        this.postSearchInProgress = false;
        return;
      }

      chatUserSearch({
        search_string: this.searchText.trim(),
        limit: defaultPostsPerFetch(),
        skip: 0,
      })
        .then((res) => {
          if (res.data.status === 0) {
            this.friendList = res.data.result.search_list;
          }
          this.userSearchInProgress = false;
        })
        .catch((err) => {
          console.log("userSearch - err", err);
          this.userSearchInProgress = false;
        });
    },
    async logOff(callbackObject = this) {
      await logOffApp();

      callbackObject.$store.dispatch("adduser", {});

      callbackObject.router.replace("/login");
    },
  },
  setup() {
    const router = useRouter();
    return {
      router,
      getColSize,
      getOffsetSize,
      processPreview,
    };
  },
};
</script>