<template>
  <ion-content>
    <ion-button
      class="reaction_popover_button"
      fill="clear"
      size="medium"
      v-for="reaction in localReactionOptions" v-bind:key="reaction.name"
      @click="reactThePost(post, reaction.name)"
    >
      <ion-icon
        slot="icon-only"
        :src="reaction.src"
        :isActive="hasMyReaction(post, reaction.name)"
      ></ion-icon>
    </ion-button>
  </ion-content>
</template>
<script>
import { IonButton, IonIcon, IonContent } from "@ionic/vue";
import { defineComponent } from "vue";

import { reactPost } from "../../services/apiCall.js";
import { reactionListArray } from "../../services/utils";

export default defineComponent({
  name: "ReactionOptions",
  props: ["post", "parent"],
  components: {
    IonButton,
    IonIcon,
    IonContent,
  },
  data() {
    return {
      reactPost,
      localReactionOptions: reactionListArray,
    };
  },
  methods: {
    hasMyReaction(post, rtype) {
      if (post.my_reactions) {
        var hasReaction =
          post.my_reactions.findIndex((x) => x.reaction_type === rtype) >= 0;
        return hasReaction;
      } else {
        return false;
      }
    },
    reactThePost(post, rtype) {
      post.reaction_count =
        typeof post.reaction_count !== "undefined"
          ? post.reaction_count + 1
          : 1;
      reactPost({ post_id: post.post_id, reaction_type: rtype })
        .then((res) => {
          this.parent.dismiss();
          if (res.data.status == 0) {
            post.reaction_count = res.data.result.reaction_count;
            if (!post.my_reactions) post.my_reactions = [];
            var prevReact = post.my_reactions.findIndex(
              (x) => x.reaction_type === rtype
            );
            if (prevReact < 0) {
              if (post.my_reactions.length > 0) {
                post.my_reactions = [];
              }
              post.my_reactions.push({ reaction_type: rtype });
            } else {
              post.my_reactions.splice(prevReact, 1);
            }
          }
        })
        .catch((err) => {
          this.parent.dismiss();
          console.log("react post - err", err);
        });
    },
  },
});
</script>