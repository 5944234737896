<template>
  <ion-content>
    <ion-list>
      <ion-item>
        <ion-button
          size="medium"
          color="dark"
          fill="clear"
          @click="takePhoto"
          style="text-transform: none"
        >
          Camera
          <ion-icon :icon="icon.cameraOutline" slot="start"></ion-icon>
        </ion-button>
      </ion-item>
      <ion-item>
        <ion-button
          size="medium"
          color="dark"
          fill="clear"
          @click="showUploadPhotos"
          style="text-transform: none"
        >
          Photo
          <ion-icon :icon="icon.imagesOutline" slot="start"></ion-icon>
        </ion-button>
      </ion-item>
      <ion-item>
        <ion-button
          size="medium"
          color="dark"
          fill="clear"
          @click="showUploadVideo"
          style="text-transform: none"
        >
          Video
          <ion-icon :icon="icon.videocamOutline" slot="start"></ion-icon>
        </ion-button>
      </ion-item>
    </ion-list>
    <input
      type="file"
      id="video_file"
      name="video_file"
      accept="video/mp4"
      style="display: none"
      @change="uploadVideo"
      hidden
    />
    <input
      type="file"
      id="image_files"
      name="image_files"
      accept="image/png, image/jpeg"
      style="display: none"
      @change="uploadPhotos"
      hidden
      multiple
    />
  </ion-content>
</template>

<script>
import {
  IonContent,
  IonItem,
  IonList,
  IonButton,
  IonIcon,
  modalController,
  alertController,
  loadingController,
} from "@ionic/vue";

import { defineComponent } from "vue";

import { imagesOutline, cameraOutline, videocamOutline } from "ionicons/icons";

import { Plugins, CameraResultType, CameraSource } from "@capacitor/core";

import Jimp from "jimp";

import md5 from "md5";

const { Camera } = Plugins;

import ApplyFilterModal from "../media/ApplyFilterModal.vue";

import { imagePostSize, maxUploadSize, scaleImage } from "../../services/utils";

import {
  presignedURL,
  uploadImage,
  nsfwCheck,
  deleteImage,
} from "../../services/apiCall";

export default defineComponent({
  name: "MediaUploadOptions",
  props: ["post", "photoPost", "parent"],
  components: {
    IonContent,
    IonItem,
    IonList,
    IonButton,
    IonIcon,
  },
  setup() {
    return {
      presignedURL,
      uploadImage,
      imagePostSize,
      maxUploadSize,
      scaleImage,
    };
  },
  data() {
    return {
      imageFileEle: document.getElementById("image_files"),
      videoFileEle: document.getElementById("video_file"),
      icon: {
        cameraOutline,
        imagesOutline,
        videocamOutline,
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    async showAlert(title, msg) {
      const anAlert = await alertController.create({
        header: title,
        message: msg,
        buttons: ["OK"],
      });
      await anAlert.present();
    },
    async uploadMediaFile(
      fileName,
      fileBlob,
      sizeInBytes,
      loader,
      isVideo = false
    ) {
      if (sizeInBytes > maxUploadSize()) {
        this.showAlert(
          "Media upload error",
          "Maximum upload size exeeded. Max: " +
            maxUploadSize() / 1024 / 1024 +
            "mb."
        );
        loader.dismiss();
        return;
      }

      // convert to file object
      const fileObject = new File([fileBlob], fileName);

      var photoPost = this.photoPost;

      // start the upload process
      presignedURL({ name: fileName })
        .then((res) => {
          if (res.data.status === 0) {
            uploadImage(res.data.result.put_url, fileObject)
              .then((res2) => {
                console.log("uploadImage", res2);
                if (isVideo || photoPost.format === "gif") {
                  // cant do an online checks for video and gifs at the moment
                  // this.enteredDescription = res.data.result.get_url;
                  photoPost.is_photo_post = !isVideo;
                  photoPost.is_video_post = isVideo;
                  photoPost.photo_url = res.data.result.get_url;

                  loader.dismiss();
                } else {
                  nsfwCheck({ url: res.data.result.get_url })
                    .then((res3) => {
                      if (res3.data.status === 0) {
                        photoPost.is_content_scanned = true;
                        photoPost.is_content_appropriate =
                          res3.data.result.is_ok;

                        if (!photoPost.is_content_appropriate) {
                          // inappropriate content, show a message and initiate a delete.

                          this.showAlert(
                            "Inappropriate Content Detected",
                            "Our system has detected Inappropriate Content and the same will be deleted. " +
                              "If you feel this is an error, report this to report@nextapp.co.in"
                          );

                          deleteImage({
                            name: this.photoPost.photo_object_name,
                          })
                            .then((res) => {
                              loader.dismiss();
                              if (res.data.status === 0) {
                                photoPost.is_photo_post = false;
                                photoPost.is_video_post = false;
                                photoPost.photo_url = "";
                                photoPost.photo_object_name = "";
                                photoPost.format = "";
                                photoPost.is_content_scanned = false;
                                photoPost.is_content_appropriate = false;

                                if (this.cancelCallback) this.cancelCallback();
                              } else {
                                this.showAlert(
                                  "Image delete error",
                                  "Unable to delete image, please try again"
                                );
                              }

                              this.deletePhotoClicked = false;
                            })
                            .catch((err) => {
                              console.log("deletePhoto - err", err);
                              this.showAlert(
                                "Image delete error",
                                "Unable to delete image, please try again"
                              );

                              loader.dismiss();
                            });
                        } else {
                          // we are ok with the content
                          photoPost.is_photo_post = !isVideo;
                          photoPost.is_video_post = isVideo;
                          photoPost.photo_url = res.data.result.get_url;
                          loader.dismiss();
                        }
                      } else {
                        photoPost.is_content_scanned = false;
                        photoPost.is_content_appropriate = false;

                        loader.dismiss();
                      }
                    })
                    .catch((err3) => {
                      console.log("nsfwCheck - err", err3);
                      photoPost.is_content_scanned = false;
                      photoPost.is_content_appropriate = false;
                      loader.dismiss();
                    });
                }
              })
              .catch((err2) => {
                console.log("uploadImage - err", err2);
                this.showAlert(
                  "Media upload error",
                  "We were unable to upload your media, please try again"
                );
                loader.dismiss();
              });
          }
        })
        .catch((err) => {
          console.log("presignedURL - err", err);
          this.showAlert(
            "Media upload error",
            "We were unable to upload your media, please try again"
          );
          loader.dismiss();
        });
    },
    async takePhoto() {
      this.parent.dismiss();

      const loading = await loadingController.create({
        message: "Processing image...",
      });

      try {
        const imgSize = imagePostSize();

        const photo = await Camera.getPhoto({
          resultType: CameraResultType.Uri,
          source: CameraSource.Camera,
          width: imgSize,
          preserveAspectRatio: true,
          correctOrientation: true,
        });

        const filterCallback = async (filteredImg) => {
          const filterLoading = await loadingController.create({
            message: "Uploading image...",
          });
          await filterLoading.present();

          // form a unique file name
          const user = this.$store.getters.user;
          const fileName =
            user.user_id +
            "_" +
            user.auth.token +
            "_" +
            user.auth.session +
            "_" +
            Date.now() +
            "." +
            photo.format;

          var photoPost = this.photoPost;
          photoPost.photo_object_name = fileName;
          photoPost.format = photo.format;

          // create a image blob
          const imgBlob = await (await fetch(filteredImg)).blob();

          // get the blob size
          const sizeInBytes = imgBlob.size;

          this.uploadMediaFile(fileName, imgBlob, sizeInBytes, filterLoading);
        };

        await loading.present();
        // rescale to 500px
        let imageObj = await Jimp.read(photo.webPath);

        if (photo.format === "png" || photo.format === "jpg") {
          if (imageObj.getWidth() > imgSize) {
            let ratio = imgSize / imageObj.getWidth();
            this.takenImageUrl = await imageObj
              .scale(ratio)
              .getBase64Async("image/" + photo.format);
          } else {
            this.takenImageUrl = await imageObj.getBase64Async("image/png");
          }

          // apply filter - dialog
          const modal = await modalController.create({
            component: ApplyFilterModal,
            mode: "md",
          });
          modal.componentProps = {
            imgSrc: this.takenImageUrl,
            parent: modal,
            filter_callback: filterCallback,
          };

          loading.dismiss();
          return modal.present();
        } else {
          loading.dismiss();
          filterCallback(photo.webPath);
        }
      } catch (err) {
        console.log("photoTaking - err", err);

        if (err.toString().indexOf("User cancelled photos app") < 0) {
          this.showAlert(
            "Image upload error",
            "We were unable to upload your image, please try again. Error is: " +
              err.toString()
          );
        }
        loading.dismiss();
      }
    },
    async showUploadPhotos() {
      this.parent.dismiss();
      this.imageFileEle = document.getElementById("image_files");
      this.imageFileEle.click();
    },

    // async scaleImage(imgFile, fileName) {
    //   const imgSize = imagePostSize();

    //   try {
    //     // console.log("Image File: ", imgFile);

    //     let imageObj = await Jimp.read(await imgFile.arrayBuffer());

    //     if (imageObj.getWidth() > imgSize) {
    //       let ratio = imgSize / imageObj.getWidth();
    //       // console.log("Image File scaling: ", ratio);
    //       let scaledObject = new Buffer(await imageObj.scale(ratio).quality(80).getBufferAsync(Jimp.AUTO));
    //       // console.log("Scaled object", scaledObject);
    //       return new File([scaledObject], fileName);
    //     } else {
    //       return new File([imgFile], fileName);
    //     }
    //   } catch (ignored) {
    //     ignored;
    //     console.log(ignored);
    //     return new File([imgFile], fileName);
    //   }
    // },

    async uploadPhotos() {
      const user = this.$store.getters.user;
      var photoPost = this.photoPost;

      var filesFromDevice = this.imageFileEle.files;
      if (filesFromDevice.length > 10) {
        this.showAlert(
          "Media upload error",
          "Maximum 10 images can be uploaded"
        );
        return;
      }
      var imageFiles = [];
      photoPost.is_photo_album = true;
      for (var idx = 0; idx < filesFromDevice.length; idx++) {
        imageFiles.push(filesFromDevice[idx]);
        photoPost["photos"].push({
          photo_url: filesFromDevice[idx],
          photo_object_name: filesFromDevice[idx].name,
          format: filesFromDevice[idx].type.split("/")[1],
          is_photo_post: false,
          is_video_post: false,
          sale_specifics: { upi: "", amount: "", note: "" },
          upload_completed: false,
          upload_error: false,
          error_message: "",
        });
        ``;
      }

      var imageUploadTasks = imageFiles.map((imgFile) => {
        return new Promise((resolve) => {
          let photoFormat = imgFile.type.split("/")[1];
          const fileName =
            user.user_id +
            "_" +
            user.auth.token +
            "_" +
            user.auth.session +
            "_" +
            md5(imgFile.name) +
            Date.now() +
            "." +
            photoFormat;

          //var photoRec = photoPost.photos.filter((y) => y.photo_object_name === imgFile.name);
          var filterIndex = photoPost.photos.findIndex(
            (x) => x.photo_object_name === imgFile.name
          );

          var photoRec = photoPost.photos[filterIndex];
          photoRec["photo_object_name"] = fileName;
          photoRec["format"] = photoFormat;
          photoRec["sale_specifics"] = { upi: "", amount: "", note: "" };

          if (imgFile.size > maxUploadSize()) {
            photoRec["upload_error"] = true;
            photoRec["upload_completed"] = true;
            photoRec["error_message"] =
              "Can not upload due to large file size. Max size upto " +
              maxUploadSize() / 1024 / 1024 +
              " mb";
            return resolve(photoRec);
          }
          // convert to file object
          this.scaleImage(imgFile, fileName).then((fileObject) => {
            // console.log('Got scaled object: ', fileObject);
            presignedURL({ name: fileName })
              .then((res) => {
                if (res.data.status === 0) {
                  uploadImage(res.data.result.put_url, fileObject)
                    .then(() => {
                      nsfwCheck({ url: res.data.result.get_url })
                        .then((res3) => {
                          if (res3.data.status === 0) {
                            photoPost.is_content_scanned = true;
                            photoPost.is_content_appropriate =
                              res3.data.result.is_ok;

                            if (!photoPost.is_content_appropriate) {
                              photoRec["upload_error"] = true;
                              photoRec["upload_completed"] = true;
                              photoRec["error_message"] =
                                "System has detected Inappropriate Content and the same will be deleted.";
                              deleteImage({
                                name: photoRec.photo_object_name,
                              })
                                .then(() => {
                                  // file delete from
                                })
                                .catch((err) => {
                                  console.log("deletePhoto - err", err);
                                  return resolve(photoRec);
                                });
                              return resolve(photoRec);
                            } else {
                              // we are ok with the content
                              photoRec["is_photo_post"] = false;
                              photoRec["is_video_post"] = false;
                              photoRec["upload_completed"] = true;
                              photoRec["photo_url"] = res.data.result.get_url;
                              return resolve(photoRec);
                            }
                          } else {
                            photoPost.is_content_scanned = false;
                            photoPost.is_content_appropriate = false;
                          }
                        })
                        .catch((err3) => {
                          console.log("nsfwCheck - err", err3);
                          photoRec["upload_error"] = true;
                          photoRec["upload_completed"] = true;
                          photoRec["error_message"] = "System image check failed. Please try again.";
                          
                          return resolve(photoRec);
                        });
                    })
                    .catch((err2) => {
                      console.log("uploadImage - err", err2);
                      photoRec["upload_error"] = true;
                      photoRec["upload_completed"] = true;
                      photoRec["error_message"] = "Upload error";
                      return resolve(photoRec);
                    });
                }
              })
              .catch((err2) => {
                console.log("uploadImage - err", err2);
                photoRec["upload_error"] = true;
                photoRec["upload_completed"] = true;
                photoRec["error_message"] = "Upload error";
                return resolve(photoRec);
              });
            // photoPost.photos[index] = photoRec;
          });
        });
      });

      Promise.all(imageUploadTasks)
        .then((photoRes) => {
          if (photoRes.length === 0) {
            console.log("Nothing to uploads");
          }
          photoPost.is_photo_album = true;
        })
        .catch((err) => {
          console.log("All file upload tasks Err " + err);
        });

      return;
    },
    async showUploadVideo() {
      this.parent.dismiss();

      this.videoFileEle = document.getElementById("video_file");
      this.videoFileEle.click();
    },
    async uploadVideo() {
      if (this.videoFileEle.files.length === 1) {
        let videoFile = this.videoFileEle.files[0];
        let sizeInBytes = videoFile.size;

        const loading = await loadingController.create({
          message: "Uploading video...",
        });

        loading.present();

        const user = this.$store.getters.user;
        const fileName =
          user.user_id +
          "_" +
          user.auth.token +
          "_" +
          user.auth.session +
          "_" +
          Date.now() +
          ".mp4";

        var photoPost = this.photoPost;
        photoPost.photo_object_name = fileName;

        this.uploadMediaFile(fileName, videoFile, sizeInBytes, loading, true);
      }
    },
  },
});
</script>
