<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script>
import {
  IonApp,
  IonRouterOutlet,
  loadingController,
  toastController,
} from "@ionic/vue";
import { useRouter } from "vue-router";
import { defineComponent } from "vue";

import { Plugins } from "@capacitor/core";

// For disabling back button
// import { useBackButton } from "@ionic/vue";

const { PushNotifications, LocalNotifications, App, StatusBar } = Plugins;

import { isAndroid, isIOS, isPWA } from "./services/utils";
import { markNotificationAsRead } from "./services/apiCall";
import { addMessageToDB } from "./services/conversationdb";

export default defineComponent({
  name: "App",
  mounted() {
    this.init();
  },
  ionViewDidEnter() {
    console.log("App did enter");
    this.inForeground = true;

    // clear all delivered notifications, TODO: is this correct behaviour?
    PushNotifications.removeAllDeliveredNotifications();
  },
  ionViewDidLeave() {
    console.log("App did leave");
    this.inForeground = false;
  },
  ionViewWillEnter() {
    console.log("App will enter");
  },
  ionViewWillLeave() {
    console.log("App will leave");
  },
  components: {
    IonApp,
    IonRouterOutlet,
  },
  setup() {
    // for disabling back button
    // useBackButton(-1, () => {
    //   // DO nothing.
    // });

    return {
      loadingController,
      isAndroid,
      isIOS,
    };
  },
  data() {
    const router = useRouter();

    return {
      router,
      inForeground: true,
    };
  },
  methods: {
    async openToast(msg) {
      const toast = await toastController.create({
        message: msg,
        duration: 5000,
      });
      return toast.present();
    },
    async init() {
      try {
        // hide the status bar
        if ((isAndroid() || isIOS()) && !isPWA()) await StatusBar.hide();
        
        // clear all delivered notifications, TODO: is this correct behaviour?
        PushNotifications.removeAllDeliveredNotifications();

        if ((isAndroid() || isIOS()) && !isPWA()) {
          // add app state listener change
          App.addListener("appStateChange", (state) => {
            if ((isAndroid() || isIOS()) && !isPWA()) {
              try {
                if (state.isActive()) {
                  // clear all delivered notifications, TODO: is this correct behaviour?
                  PushNotifications.removeAllDeliveredNotifications();
                }
              } catch (ignored) {
                ignored;
              }
            }
          });

          // add listener for TestShare, ImageShare and VideoShare events
          window.addEventListener("TextShare", async (shareData) => {
            // alert("TextShare: " + shareData.detail.data);
            const loading = await loadingController.create({
              message: "Starting share...",
            });
            await loading.present();
            await this.router.replace(
              "/shareselect/text/" + window.btoa(shareData.detail.data)
            );
            loading.dismiss();
          });
          window.addEventListener("ImageShare", async (shareData) => {
            // alert("ImageShare: " + shareData.detail.data);
            const loading = await loadingController.create({
              message: "Starting share...",
            });
            await loading.present();
            await this.router.replace(
              "/shareselect/image/" + window.btoa(shareData.detail.data)
            );
            loading.dismiss();
          });
          window.addEventListener("VideoShare", async (shareData) => {
            // alert("ImageShare: " + shareData.detail.data);
            const loading = await loadingController.create({
              message: "Starting share...",
            });
            await loading.present();
            await this.router.replace(
              "/shareselect/video/" + window.btoa(shareData.detail.data)
            );
            loading.dismiss();
          });

          await PushNotifications.requestPermission();
          await LocalNotifications.requestPermission();

          // listen to push notifications
          PushNotifications.addListener(
            "pushNotificationReceived",
            async (notification) => {
              console.log("notification received", notification);
              if (this.inForeground) {
                this.openToast(notification.data.message);
              }
              // update localdb and mem cash for new message
              let metadata = JSON.parse(notification.data.metadata);
              if (metadata.type === "conversation") {
                this.$store.dispatch("updateconversation", metadata);
                await addMessageToDB(metadata.tokenized_user, metadata);
                this.$store.dispatch("updateconversationliststatus", {
                  tokenized_user: metadata.tokenized_user,
                  updateData: { last_message: metadata.content, last_message_time: metadata.message_time },
                });
              }
            }
          );
          PushNotifications.addListener(
            "pushNotificationActionPerformed",
            (actionPerformed) => {
              console.log("notification action performed", actionPerformed);
              let metadata = JSON.parse(
                actionPerformed.notification.data.metadata
              );

              if (typeof metadata !== "undefined") {
                if (metadata.type === "user") {
                  this.router.push("/user/profile/" + metadata.tokenized_user);
                } else if (metadata.type === "post") {
                  this.router.push("/posts/" + metadata.id);
                } else if (metadata.type === "group") {
                  this.router.push(
                    "/groups/" + metadata.tokenized_group + "/show"
                  );
                } else if (metadata.type === "chat") {
                  this.router.push("/chat/" + metadata.user_token);
                } else if (metadata.type === "groupchat") {
                  this.router.push("/groupchat/" + metadata.user_token);
                } else if (metadata.type === "conversation") {
                  this.router.push("/conversations/" + metadata.user_token);
                } else if (metadata.type === "audiocall") {
                  this.router.push("/conversations/audio/" + metadata.user_token + "/" + metadata.room_id);
                } else if (metadata.type === "videocall") {
                  this.router.push("/conversations/video/" + metadata.user_token + "/" + metadata.room_id);
                }

                // mark the notification as read
                if (metadata.notification_id !== -1) {
                  markNotificationAsRead({
                    notification_id: [metadata.notification_id],
                  })
                    .then((res) => {
                      console.log("notify read", res);
                    })
                    .catch((err) => {
                      console.log("notify read err", err);
                    });
                }

                // clear all delivered notifications, TODO: is this correct behaviour?
                PushNotifications.removeAllDeliveredNotifications();
              }
            }
          );
        }
      } catch (e) {
        console.log("Push notification / Intent listener not registered");
        // alert("Intent listeners not registered. " + e);
      }
    },
  },
});
</script>