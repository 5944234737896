<template>
  <ion-item no-lines lines="none">
    <ion-icon
      size="large"
      :icon="icons.personCircleOutline"
      slot="start"
      v-if="!currentUserProfile.photo"
    ></ion-icon>
    <ion-avatar
      size="large"
      style="min-width: 200px; min-height: 200px"
      slot="start"
      v-if="currentUserProfile.photo"
    >
      <img id="profilePhoto" :src="currentUserProfile.photo" />
      <ion-spinner v-if="uploadingPhoto" slot="end" name="dots"></ion-spinner>
    </ion-avatar>
    <ion-button slot="end" color="medium" size="small" @click="takePhoto">
      <ion-icon :icon="icons.cameraOutline" size="small"></ion-icon>
      <sup
        ><small
          ><ion-icon
            :icon="icons.cloudUploadOutline"
            size="small"
          ></ion-icon></small
      ></sup>
      <ion-spinner v-if="uploadingPhoto" slot="end" name="dots"></ion-spinner>
    </ion-button>
  </ion-item>
</template>

<script>
import {
  IonItem,
  IonIcon,
  IonAvatar,
  IonButton,
  IonSpinner,
  modalController,
} from "@ionic/vue";
import { defineComponent } from "vue";

import { Plugins, CameraResultType, CameraSource } from "@capacitor/core";

import ApplyFilterModal from "../media/ApplyFilterModal.vue";

import {
  cameraOutline,
  cloudUploadOutline,
  personCircleOutline,
} from "ionicons/icons";

import Jimp from "jimp";

const { Camera } = Plugins;

import { updateUserPhoto, fetchUserProfilePhoto } from "../../services/apiCall";
import { profileImageSize } from "../../services/utils";

export default defineComponent({
  name: "ProfilePhotoSection",
  components: { IonItem, IonIcon, IonAvatar, IonButton, IonSpinner },
  data() {
    return {
      icons: {
        cameraOutline,
        cloudUploadOutline,
        personCircleOutline,
      },
      currentUserProfile: {
        photo: "",
        photo_thumbnail: "",
      },
      uploadingPhoto: false,
    };
  },
  mounted() {
    console.log("ProfilePhotoSection page mounted");
    this.init();
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  setup() {
    return {};
  },
  methods: {
    async init() {
      console.log("init", this.currentUserProfile);

      fetchUserProfilePhoto()
        .then((res) => {
          if (res.data.status === 0) {
            this.currentUserProfile.photo = res.data.result.profile_photo.photo;
            this.currentUserProfile.photo_thumbnail =
              res.data.result.profile_photo.photo_thumbnail;
          } else {
            console.log("fetchUserProfilePhoto - err", res);
          }
        })
        .catch((err) => {
          console.log("fetchUserProfilePhoto - err", err);
        });
    },
    async takePhoto() {
      try {
        this.uploadingPhoto = true;

        const imgSize = profileImageSize();

        // take the photo
        const photo = await Camera.getPhoto({
          resultType: CameraResultType.Uri,
          source: CameraSource.Prompt,
          width: imgSize,
          preserveAspectRatio: true,
          correctOrientation: true,
        });

        // rescale to 300px
        let imageObj = await Jimp.read(photo.webPath);
        let ratio = imgSize / imageObj.getWidth();
        let scaledImgObj = await imageObj.scale(ratio);

        let imgB64 = await scaledImgObj.getBase64Async("image/png");
        this.currentUserProfile.photo = imgB64;

        this.uploadingPhoto = false;

        // filter call back
        var filterCallback = (filteredImg) => {
          this.uploadingPhoto = true;

          this.currentUserProfile.photo = filteredImg;
          this.currentUserProfile.photo_thumbnail = filteredImg;

          updateUserPhoto({ photo: this.currentUserProfile.photo })
            .then((res) => {
              console.log("updateUserPhoto", res);
              this.$store.dispatch(
                "setcurrentprofilephoto",
                this.currentUserProfile
              );
              this.uploadingPhoto = false;
            })
            .catch((err) => {
              console.log("updateUserPhoto - err", err);
              this.uploadingPhoto = false;
            });
        };

        // apply filter - dialog
        const modal = await modalController.create({
          component: ApplyFilterModal,
          mode: "md",
        });
        modal.componentProps = {
          imgSrc: this.currentUserProfile.photo,
          parent: modal,
          filter_callback: filterCallback,
        };

        return modal.present();
      } catch (e) {
        this.uploadingPhoto = false;
        console.log("takePhoto - err", e);
      }
    },
  },
});
</script>
