export function countryList() {
  var countries = [
    { name: 'India', code: 'IN', 'tel_code': '91' },
    { name: 'United States', code: 'US', 'tel_code': '1' },
    { name: 'United Kingdom', code: 'GB', 'tel_code': '44' },
    { name: 'Australia', code: 'AU', 'tel_code': '61' },
    { name: 'Afghanistan', code: 'AF', 'tel_code': '93' },
    { name: 'Åland Islands', code: 'AX', 'tel_code': '35818' },
    { name: 'Albania', code: 'AL', 'tel_code': '355' },
    { name: 'Algeria', code: 'DZ', 'tel_code': '213' },
    { name: 'American Samoa', code: 'AS', 'tel_code': '1-684' },
    { name: 'AndorrA', code: 'AD', 'tel_code': '376' },
    { name: 'Angola', code: 'AO', 'tel_code': '913' },
    { name: 'Anguilla', code: 'AI', 'tel_code': '1-264' },
    { name: 'Antarctica', code: 'AQ', 'tel_code': '672' },
    { name: 'Antigua and Barbuda', code: 'AG', 'tel_code': '1-268' },
    { name: 'Argentina', code: 'AR', 'tel_code': '54' },
    { name: 'Armenia', code: 'AM', 'tel_code': '374' },
    { name: 'Aruba', code: 'AW', 'tel_code': '297' },
    { name: 'Austria', code: 'AT', 'tel_code': '43' },
    { name: 'Azerbaijan', code: 'AZ', 'tel_code': '994' },
    { name: 'Bahamas', code: 'BS', 'tel_code': '1-242' },
    { name: 'Bahrain', code: 'BH', 'tel_code': '973' },
    { name: 'Bangladesh', code: 'BD', 'tel_code': '880' },
    { name: 'Barbados', code: 'BB', 'tel_code': '1-246' },
    { name: 'Belarus', code: 'BY', 'tel_code': '375' },
    { name: 'Belgium', code: 'BE', 'tel_code': '32' },
    { name: 'Belize', code: 'BZ', 'tel_code': '501' },
    { name: 'Benin', code: 'BJ', 'tel_code': '229' },
    { name: 'Bermuda', code: 'BM', 'tel_code': '1-441' },
    { name: 'Bhutan', code: 'BT', 'tel_code': '975' },
    { name: 'Bolivia', code: 'BO', 'tel_code': '591' },
    { name: 'Bosnia and Herzegovina', code: 'BA', 'tel_code': '387' },
    { name: 'Botswana', code: 'BW', 'tel_code': '267' },
    { name: 'Bouvet Island', code: 'BV', 'tel_code': '000' },
    { name: 'Brazil', code: 'BR', 'tel_code': '55' },
    { name: 'British Indian Ocean Territory', code: 'IO', 'tel_code': '000' },
    { name: 'Brunei Darussalam', code: 'BN', 'tel_code': '673' },
    { name: 'Bulgaria', code: 'BG', 'tel_code': '359' },
    { name: 'Burkina Faso', code: 'BF', 'tel_code': '226' },
    { name: 'Burundi', code: 'BI', 'tel_code': '257' },
    { name: 'Cambodia', code: 'KH', 'tel_code': '855' },
    { name: 'Cameroon', code: 'CM', 'tel_code': '237' },
    { name: 'Canada', code: 'CA', 'tel_code': '1' },
    { name: 'Cape Verde', code: 'CV', 'tel_code': '238' },
    { name: 'Cayman Islands', code: 'KY', 'tel_code': '1-345' },
    { name: 'Central African Republic', code: 'CF', 'tel_code': '236' },
    { name: 'Chad', code: 'TD', 'tel_code': '235' },
    { name: 'Chile', code: 'CL', 'tel_code': '56' },
    { name: 'China', code: 'CN', 'tel_code': '86' },
    { name: 'Christmas Island', code: 'CX', 'tel_code': '000' },
    { name: 'Cocos (Keeling) Islands', code: 'CC', 'tel_code': '000' },
    { name: 'Colombia', code: 'CO', 'tel_code': '57' },
    { name: 'Comoros', code: 'KM', 'tel_code': '269' },
    { name: 'Congo', code: 'CG', 'tel_code': '242' },
    { name: 'Congo, The Democratic Republic of the', code: 'CD', 'tel_code': '243' },
    { name: 'Cook Islands', code: 'CK', 'tel_code': '682' },
    { name: 'Costa Rica', code: 'CR', 'tel_code': '506' },
    { name: 'Cote D\'Ivoire', code: 'CI', 'tel_code': '225' },
    { name: 'Croatia', code: 'HR', 'tel_code': '385' },
    { name: 'Cuba', code: 'CU', 'tel_code': '53' },
    { name: 'Cyprus', code: 'CY', 'tel_code': '357' },
    { name: 'Czech Republic', code: 'CZ', 'tel_code': '420' },
    { name: 'Denmark', code: 'DK', 'tel_code': '45' },
    { name: 'Djibouti', code: 'DJ', 'tel_code': '253' },
    { name: 'Dominica', code: 'DM', 'tel_code': '1-767' },
    { name: 'Dominican Republic', code: 'DO', 'tel_code': '1-809' },
    { name: 'Ecuador', code: 'EC', 'tel_code': '593' },
    { name: 'Egypt', code: 'EG', 'tel_code': '20' },
    { name: 'El Salvador', code: 'SV', 'tel_code': '503' },
    { name: 'Equatorial Guinea', code: 'GQ', 'tel_code': '240' },
    { name: 'Eritrea', code: 'ER', 'tel_code': '291' },
    { name: 'Estonia', code: 'EE', 'tel_code': '372' },
    { name: 'Ethiopia', code: 'ET', 'tel_code': '251' },
    { name: 'Falkland Islands (Malvinas)', code: 'FK', 'tel_code': '500' },
    { name: 'Faroe Islands', code: 'FO', 'tel_code': '298' },
    { name: 'Fiji', code: 'FJ', 'tel_code': '679' },
    { name: 'Finland', code: 'FI', 'tel_code': '358' },
    { name: 'France', code: 'FR', 'tel_code': '33' },
    { name: 'French Guiana', code: 'GF', 'tel_code': '594' },
    { name: 'French Polynesia', code: 'PF', 'tel_code': '689' },
    { name: 'French Southern Territories', code: 'TF', 'tel_code': '000' },
    { name: 'Gabon', code: 'GA', 'tel_code': '241' },
    { name: 'Gambia', code: 'GM', 'tel_code': '220' },
    { name: 'Georgia', code: 'GE', 'tel_code': '995' },
    { name: 'Germany', code: 'DE', 'tel_code': '49' },
    { name: 'Ghana', code: 'GH', 'tel_code': '233' },
    { name: 'Gibraltar', code: 'GI', 'tel_code': '350' },
    { name: 'Greece', code: 'GR', 'tel_code': '30' },
    { name: 'Greenland', code: 'GL', 'tel_code': '299' },
    { name: 'Grenada', code: 'GD', 'tel_code': '1-473' },
    { name: 'Guadeloupe', code: 'GP', 'tel_code': '590' },
    { name: 'Guam', code: 'GU', 'tel_code': '1-671' },
    { name: 'Guatemala', code: 'GT', 'tel_code': '502' },
    { name: 'Guernsey', code: 'GG', 'tel_code': '000' },
    { name: 'Guinea', code: 'GN', 'tel_code': '224' },
    { name: 'Guinea-Bissau', code: 'GW', 'tel_code': '245' },
    { name: 'Guyana', code: 'GY', 'tel_code': '592' },
    { name: 'Haiti', code: 'HT', 'tel_code': '509' },
    { name: 'Heard Island and Mcdonald Islands', code: 'HM', 'tel_code': '000' },
    { name: 'Holy See (Vatican City State)', code: 'VA', 'tel_code': '000' },
    { name: 'Honduras', code: 'HN', 'tel_code': '504' },
    { name: 'Hong Kong', code: 'HK', 'tel_code': '852' },
    { name: 'Hungary', code: 'HU', 'tel_code': '36' },
    { name: 'Iceland', code: 'IS', 'tel_code': '354' },
    { name: 'Indonesia', code: 'ID', 'tel_code': '62' },
    { name: 'Iran, Islamic Republic Of', code: 'IR', 'tel_code': '98' },
    { name: 'Iraq', code: 'IQ', 'tel_code': '964' },
    { name: 'Ireland', code: 'IE', 'tel_code': '353' },
    { name: 'Isle of Man', code: 'IM', 'tel_code': '000' },
    { name: 'Israel', code: 'IL', 'tel_code': '972' },
    { name: 'Italy', code: 'IT', 'tel_code': '39' },
    { name: 'Jamaica', code: 'JM', 'tel_code': '1-876' },
    { name: 'Japan', code: 'JP', 'tel_code': '81' },
    { name: 'Jersey', code: 'JE', 'tel_code': '000' },
    { name: 'Jordan', code: 'JO', 'tel_code': '962' },
    { name: 'Kazakhstan', code: 'KZ', 'tel_code': '7' },
    { name: 'Kenya', code: 'KE', 'tel_code': '254' },
    { name: 'Kiribati', code: 'KI', 'tel_code': '686' },
    { name: 'North Korea', code: 'KP', 'tel_code': '850' },
    { name: 'South Korea', code: 'KR', 'tel_code': '82' },
    { name: 'Kuwait', code: 'KW', 'tel_code': '965' },
    { name: 'Kyrgyzstan', code: 'KG', 'tel_code': '996' },
    { name: 'Laos', code: 'LA', 'tel_code': '856' },
    { name: 'Latvia', code: 'LV', 'tel_code': '371' },
    { name: 'Lebanon', code: 'LB', 'tel_code': '961' },
    { name: 'Lesotho', code: 'LS', 'tel_code': '266' },
    { name: 'Liberia', code: 'LR', 'tel_code': '231' },
    { name: 'Libyan Arab Jamahiriya', code: 'LY', 'tel_code': '218' },
    { name: 'Liechtenstein', code: 'LI', 'tel_code': '423' },
    { name: 'Lithuania', code: 'LT', 'tel_code': '370' },
    { name: 'Luxembourg', code: 'LU', 'tel_code': '352' },
    { name: 'Macao', code: 'MO', 'tel_code': '853' },
    { name: 'Macedonia', code: 'MK', 'tel_code': '389' },
    { name: 'Madagascar', code: 'MG', 'tel_code': '261' },
    { name: 'Malawi', code: 'MW', 'tel_code': '265' },
    { name: 'Malaysia', code: 'MY', 'tel_code': '60' },
    { name: 'Maldives', code: 'MV', 'tel_code': '960' },
    { name: 'Mali', code: 'ML', 'tel_code': '223' },
    { name: 'Malta', code: 'MT', 'tel_code': '356' },
    { name: 'Marshall Islands', code: 'MH', 'tel_code': '692' },
    { name: 'Martinique', code: 'MQ', 'tel_code': '000' },
    { name: 'Mauritania', code: 'MR', 'tel_code': '222' },
    { name: 'Mauritius', code: 'MU', 'tel_code': '230' },
    { name: 'Mayotte', code: 'YT', 'tel_code': '262' },
    { name: 'Mexico', code: 'MX', 'tel_code': '52' },
    { name: 'Micronesia', code: 'FM', 'tel_code': '691' },
    { name: 'Moldova', code: 'MD', 'tel_code': '373' },
    { name: 'Monaco', code: 'MC', 'tel_code': '377' },
    { name: 'Mongolia', code: 'MN', 'tel_code': '976' },
    { name: 'Montserrat', code: 'MS', 'tel_code': '1-664' },
    { name: 'Morocco', code: 'MA', 'tel_code': '212' },
    { name: 'Mozambique', code: 'MZ', 'tel_code': '258' },
    { name: 'Myanmar', code: 'MM', 'tel_code': '95' },
    { name: 'Namibia', code: 'NA', 'tel_code': '000' },
    { name: 'Nauru', code: 'NR', 'tel_code': '000' },
    { name: 'Nepal', code: 'NP', 'tel_code': '000' },
    { name: 'Netherlands', code: 'NL', 'tel_code': '000' },
    { name: 'Netherlands Antilles', code: 'AN', 'tel_code': '000' },
    { name: 'New Caledonia', code: 'NC', 'tel_code': '000' },
    { name: 'New Zealand', code: 'NZ', 'tel_code': '000' },
    { name: 'Nicaragua', code: 'NI', 'tel_code': '000' },
    { name: 'Niger', code: 'NE', 'tel_code': '000' },
    { name: 'Nigeria', code: 'NG', 'tel_code': '000' },
    { name: 'Niue', code: 'NU', 'tel_code': '000' },
    { name: 'Norfolk Island', code: 'NF', 'tel_code': '000' },
    { name: 'Northern Mariana Islands', code: 'MP', 'tel_code': '000' },
    { name: 'Norway', code: 'NO', 'tel_code': '000' },
    { name: 'Oman', code: 'OM', 'tel_code': '000' },
    { name: 'Pakistan', code: 'PK', 'tel_code': '92' },
    { name: 'Palau', code: 'PW', 'tel_code': '000' },
    { name: 'Palestinian Territory, Occupied', code: 'PS', 'tel_code': '000' },
    { name: 'Panama', code: 'PA', 'tel_code': '000' },
    { name: 'Papua New Guinea', code: 'PG', 'tel_code': '000' },
    { name: 'Paraguay', code: 'PY', 'tel_code': '000' },
    { name: 'Peru', code: 'PE', 'tel_code': '51' },
    { name: 'Philippines', code: 'PH', 'tel_code': '000' },
    { name: 'Pitcairn', code: 'PN', 'tel_code': '000' },
    { name: 'Poland', code: 'PL', 'tel_code': '000' },
    { name: 'Portugal', code: 'PT', 'tel_code': '000' },
    { name: 'Puerto Rico', code: 'PR', 'tel_code': '000' },
    { name: 'Qatar', code: 'QA', 'tel_code': '000' },
    { name: 'Reunion', code: 'RE', 'tel_code': '000' },
    { name: 'Romania', code: 'RO', 'tel_code': '000' },
    { name: 'Russian Federation', code: 'RU', 'tel_code': '000' },
    { name: 'RWANDA', code: 'RW', 'tel_code': '000' },
    { name: 'Saint Helena', code: 'SH', 'tel_code': '000' },
    { name: 'Saint Kitts and Nevis', code: 'KN', 'tel_code': '000' },
    { name: 'Saint Lucia', code: 'LC', 'tel_code': '000' },
    { name: 'Saint Pierre and Miquelon', code: 'PM', 'tel_code': '000' },
    { name: 'Saint Vincent and the Grenadines', code: 'VC', 'tel_code': '000' },
    { name: 'Samoa', code: 'WS', 'tel_code': '000' },
    { name: 'San Marino', code: 'SM', 'tel_code': '000' },
    { name: 'Sao Tome and Principe', code: 'ST', 'tel_code': '000' },
    { name: 'Saudi Arabia', code: 'SA', 'tel_code': '000' },
    { name: 'Senegal', code: 'SN', 'tel_code': '000' },
    { name: 'Serbia and Montenegro', code: 'CS', 'tel_code': '000' },
    { name: 'Seychelles', code: 'SC', 'tel_code': '000' },
    { name: 'Sierra Leone', code: 'SL', 'tel_code': '000' },
    { name: 'Singapore', code: 'SG', 'tel_code': '000' },
    { name: 'Slovakia', code: 'SK', 'tel_code': '000' },
    { name: 'Slovenia', code: 'SI', 'tel_code': '000' },
    { name: 'Solomon Islands', code: 'SB', 'tel_code': '000' },
    { name: 'Somalia', code: 'SO', 'tel_code': '000' },
    { name: 'South Africa', code: 'ZA', 'tel_code': '000' },
    { name: 'South Georgia and the South Sandwich Islands', code: 'GS', 'tel_code': '000' },
    { name: 'Spain', code: 'ES', 'tel_code': '000' },
    { name: 'Sri Lanka', code: 'LK', 'tel_code': '000' },
    { name: 'Sudan', code: 'SD', 'tel_code': '000' },
    { name: 'Suriname', code: 'SR', 'tel_code': '000' },
    { name: 'Svalbard and Jan Mayen', code: 'SJ', 'tel_code': '000' },
    { name: 'Swaziland', code: 'SZ', 'tel_code': '000' },
    { name: 'Sweden', code: 'SE', 'tel_code': '000' },
    { name: 'Switzerland', code: 'CH', 'tel_code': '000' },
    { name: 'Syrian Arab Republic', code: 'SY', 'tel_code': '000' },
    { name: 'Taiwan, Province of China', code: 'TW', 'tel_code': '000' },
    { name: 'Tajikistan', code: 'TJ', 'tel_code': '000' },
    { name: 'Tanzania, United Republic of', code: 'TZ', 'tel_code': '000' },
    { name: 'Thailand', code: 'TH', 'tel_code': '000' },
    { name: 'Timor-Leste', code: 'TL', 'tel_code': '000' },
    { name: 'Togo', code: 'TG', 'tel_code': '000' },
    { name: 'Tokelau', code: 'TK', 'tel_code': '000' },
    { name: 'Tonga', code: 'TO', 'tel_code': '000' },
    { name: 'Trinidad and Tobago', code: 'TT', 'tel_code': '000' },
    { name: 'Tunisia', code: 'TN', 'tel_code': '000' },
    { name: 'Turkey', code: 'TR', 'tel_code': '000' },
    { name: 'Turkmenistan', code: 'TM', 'tel_code': '000' },
    { name: 'Turks and Caicos Islands', code: 'TC', 'tel_code': '000' },
    { name: 'Tuvalu', code: 'TV', 'tel_code': '000' },
    { name: 'Uganda', code: 'UG', 'tel_code': '000' },
    { name: 'Ukraine', code: 'UA', 'tel_code': '000' },
    { name: 'United Arab Emirates', code: 'AE', 'tel_code': '000' },
    { name: 'United States Minor Outlying Islands', code: 'UM', 'tel_code': '000' },
    { name: 'Uruguay', code: 'UY', 'tel_code': '000' },
    { name: 'Uzbekistan', code: 'UZ', 'tel_code': '000' },
    { name: 'Vanuatu', code: 'VU', 'tel_code': '000' },
    { name: 'Venezuela', code: 'VE', 'tel_code': '000' },
    { name: 'Viet Nam', code: 'VN', 'tel_code': '000' },
    { name: 'Virgin Islands, British', code: 'VG', 'tel_code': '000' },
    { name: 'Virgin Islands, U.S.', code: 'VI', 'tel_code': '000' },
    { name: 'Wallis and Futuna', code: 'WF', 'tel_code': '000' },
    { name: 'Western Sahara', code: 'EH', 'tel_code': '000' },
    { name: 'Yemen', code: 'YE', 'tel_code': '000' },
    { name: 'Zambia', code: 'ZM', 'tel_code': '000' },
    { name: 'Zimbabwe', code: 'ZW' }
  ];

  return countries;
}