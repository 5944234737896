<template>
  <ion-page>
    <ion-header>
      <ion-toolbar
        mode="md"
        class="next_bar_color ion-no-padding ion-no-margin"
      >
        <ion-buttons
          slot="start"
          size="small"
          style="padding: 0px"
          class="ion-no-padding ion-no-margin"
        >
          <ion-back-button
            size="small"
            slot="icon-only"
            style="padding: 0px"
            class="ion-no-padding ion-no-margin"
          ></ion-back-button>
        </ion-buttons>
        <ion-item class="next_bar_color ion-no-padding ion-no-margin">
          <slot name="profile-photo-slot"></slot>
        </ion-item>
        <ion-buttons
          slot="end"
          size="small"
          style="padding: 0px"
          class="ion-no-padding ion-no-margin"
        >
          <ion-button shape="round" size="small" @click="videoCall">
            <ion-icon :icon="icon.videocamOutline"></ion-icon>
          </ion-button>
          <ion-button shape="round" size="small" @click="audioCall">
            <ion-icon :icon="icon.callOutline"></ion-icon>
          </ion-button>
          <ion-button shape="round" size="small">
            <ion-icon :icon="icon.ellipsisHorizontal"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content id="_conversationThreadContent" :scroll-events="true">
      <slot />
    </ion-content>
    <ion-footer>
      <conversation-box @send-message="sendMessage"></conversation-box>
    </ion-footer>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonContent,
  IonToolbar,
  IonFooter,
  IonItem,
  IonIcon,
  IonButton,
  IonButtons,
  IonBackButton,
} from "@ionic/vue";

import {
  videocamOutline,
  callOutline,
  ellipsisHorizontal,
} from "ionicons/icons";

import ConversationBox from "../ConversationBox.vue";

export default {
  props: ["pageTitle", "initiateVideoCall", "initiateAudioCall"],
  emits: ["send-message"],

  setup() {
    return {};
  },
  data() {
    return {
      icon: {
        videocamOutline,
        callOutline,
        ellipsisHorizontal,
      },
    };
  },
  components: {
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    IonFooter,
    IonIcon,
    IonItem,
    IonButton,
    IonButtons,
    IonBackButton,
    ConversationBox,
  },
  methods: {
    sendMessage(messagePayload) {
      this.$emit("send-message", messagePayload);
    },
    videoCall() {
      if (this.initiateVideoCall) this.initiateVideoCall();
    },
    audioCall() {
      if (this.initiateAudioCall) this.initiateAudioCall();
    },
  },
};
</script>