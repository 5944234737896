<template>
  <ion-page ion-no-padding ion-no-margin>
    <ion-header ion-no-padding ion-no-margin>
      <ion-toolbar
        mode="md"
        color="primary"
        class="ion-no-padding ion-no-margin"
        v-if="!isChatItemSelected"
      >
        <ion-item color="primary">
          <ion-buttons
            slot="start"
            size="small"
            style="padding: 0px"
            class="ion-no-padding ion-no-margin"
          >
            <ion-button
              size="small"
              fill="clear"
              @click="selectionCancelClicked()"
            >
              <ion-icon
                slot="icon-only"
                :icon="icon.arrowBackOutline"
              ></ion-icon>
            </ion-button>
          </ion-buttons>

          <ion-buttons :collapse="true" slot="end">
          <slot name="actions-end"></slot>
        </ion-buttons>
        </ion-item>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <slot />
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonItem,
  IonContent,
  IonButton,
  IonButtons,
  IonIcon,
  toastController,
} from "@ionic/vue";

import {
  videocamOutline,
  callOutline,
  ellipsisVertical,
  copyOutline,
  trashOutline,
  arrowUndoOutline,
  arrowRedoOutline,
  arrowBackOutline,
} from "ionicons/icons";

import {
  appVersion,
  appName,
  isMobileScreen,
  formatChatTime,
} from "../../services/utils.js";

export default {
  emits: [
    "menu-cancel",
    "menu-reply",
    "menu-delete",
    "menu-copy",
    "menu-forward",
  ],
  props: [
    "pageTitle",
    "showBackButton",
    "pageDefaultBackLink",
    "isChatItemSelected",
  ],
  setup() {
    return { appVersion, appName, isMobileScreen, formatChatTime };
  },
  data() {
    return {
      icon: {
        videocamOutline,
        callOutline,
        ellipsisVertical,
        copyOutline,
        trashOutline,
        arrowUndoOutline,
        arrowRedoOutline,
        arrowBackOutline,
      },
    };
  },
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonItem,
    IonContent,
    IonButton,
    IonButtons,
    IonIcon,
  },
  methods: {
    capitalizeFirstLetter(string) {
      var str = string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
      return str;
    },

    async openVideo() {
      const toast = await toastController.create({
        message: "Coming soon....",
        duration: 1500,
      });
      return toast.present();
    },
    async openCall() {
      const toast = await toastController.create({
        message: "Coming soon....",
        duration: 1500,
      });
      console.log("ChatItem staus :".this.is);
      return toast.present();
    },

    isUserOnline(recentTime) {
      //console.log(recentTime);
      var lastSeen = new Date(recentTime).getTime();
      var currentTime = new Date().getTime();
      var diffTime = currentTime - lastSeen;

      if (diffTime < 120 * 1000) return true;
      return false;
    },

    selectionCancelClicked() {
      this.$emit("menu-cancel");
    },
    selectionReplyClicked() {
      this.$emit("menu-reply");
    },
    selectionForwardClicked() {
      this.$emit("menu-forward");
    },
    selectionCopyClicked() {
      this.$emit("menu-copy");
    },
    selectionDeleteClicked() {
      this.$emit("menu-delete");
    },
  },
};
</script>