<template>
  <!-- friends list -->
  <ion-item v-if="showFriends">
    <ion-button
      fill="clear"
      v-if="friendList.length > 0"
      @click="showFriendListClickHandler"
    >
      Friends ({{ friendList.length }})
      <ion-icon
        v-if="!showFriendList"
        :icon="icons.caretForwardOutline"
        slot="end"
      ></ion-icon>
      <ion-icon
        v-if="showFriendList"
        :icon="icons.caretDownOutline"
        slot="end"
      ></ion-icon>
    </ion-button>
    <ion-label v-if="friendList.length <= 0" color="medium"
      ><small>No Friends Yet</small></ion-label
    >
  </ion-item>
  <ion-item v-if="showFriendList && showFriends">
    <friends-list :friends="friendList" type="full"></friends-list>
  </ion-item>

  <!-- friend request list -->
  <ion-item v-if="showFriends">
    <ion-button
      fill="clear"
      v-if="friendRequestList.length > 0"
      @click="showFriendRequestListClickHandler"
    >
      Friend Requests ({{ friendRequestList.length }})
      <ion-icon
        v-if="!showFriendRequestList"
        :icon="icons.caretForwardOutline"
        slot="end"
      ></ion-icon>
      <ion-icon
        v-if="showFriendRequestList"
        :icon="icons.caretDownOutline"
        slot="end"
      ></ion-icon>
    </ion-button>
    <ion-label v-if="friendRequestList.length <= 0" color="medium"
      ><small>No New Friend Requests</small></ion-label
    >
  </ion-item>
  <ion-item v-if="showFriendRequestList && showFriends">
    <friends-list :friends="friendRequestList" type="full"></friends-list>
  </ion-item>

  <!-- following list -->
  <ion-item v-if="showFriends">
    <ion-button
      fill="clear"
      v-if="followingList.length > 0"
      @click="showFollowingListClickHandler"
    >
      Following ({{ followingList.length }})
      <ion-icon
        v-if="!showFollowingList"
        :icon="icons.caretForwardOutline"
        slot="end"
      ></ion-icon>
      <ion-icon
        v-if="showFollowingList"
        :icon="icons.caretDownOutline"
        slot="end"
      ></ion-icon>
    </ion-button>
    <ion-label v-if="followingList.length <= 0" color="medium"
      ><small>Not yet following anyone</small></ion-label
    >
  </ion-item>
  <ion-item v-if="showFollowingList && showFriends">
    <friends-list :friends="followingList" type="full"></friends-list>
  </ion-item>

  <!-- follower list -->
  <ion-item v-if="showFriends">
    <ion-button
      fill="clear"
      v-if="followerList.length > 0"
      @click="showFollowerListClickHandler"
    >
      Followers ({{ followerList.length }})
      <ion-icon
        v-if="!showFollowerList"
        :icon="icons.caretForwardOutline"
        slot="end"
      ></ion-icon>
      <ion-icon
        v-if="showFollowerList"
        :icon="icons.caretDownOutline"
        slot="end"
      ></ion-icon>
    </ion-button>
    <ion-label v-if="followerList.length <= 0" color="medium"
      ><small>No followers yet</small></ion-label
    >
  </ion-item>
  <ion-item v-if="showFollowerList && showFriends">
    <friends-list :friends="followerList" type="full"></friends-list>
  </ion-item>
</template>

<script>
import {
  IonButton,
  IonItem,
  IonLabel,
  IonIcon,
} from "@ionic/vue";

import { caretForwardOutline, caretDownOutline } from "ionicons/icons";

import {
  friends,
  followers,
  following,
  friendRequestsList,
} from "../../services/apiCall";

import { setFriends } from "../../services/db.js";

import FriendsList from "../users/FriendsList.vue";

export default {
  name: "FriendsAndFamilySection",
  components: {
    IonButton,
    IonItem,
    IonLabel,
    IonIcon,
    FriendsList,
  },
  mounted() {
    console.log("FriendsAndFamilySection page mounted");
    this.init();
  },
  computed: {
    friendList() {
      return this.$store.getters.friends;
    },
    friendRequestList() {
      return this.$store.getters.friendRequestList;
    },
    followerList() {
      return this.$store.getters.followers;
    },
    followingList() {
      return this.$store.getters.following;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  data() {
    return {
      icons: {
        caretForwardOutline,
        caretDownOutline,
      },
      showFriends: true,
      showFriendList: false,
      showFollowerList: false,
      showFollowingList: false,
      showFriendRequestList: false,
    };
  },
  methods: {
    init() {
      
      friends()
        .then(async (res) => {
          this.$store.dispatch("setfriends", res.data.result.friend_list);
          await setFriends(res.data.result.friend_list);
        })
        .catch((err) => {
          console.log("friends-err", err);
          // TODO: handle errors
        });

      friendRequestsList()
        .then(async (res) => {
          this.$store.dispatch(
            "setfriendrequest",
            res.data.result.request_list
          );
          // TODO: maintain different list
          await setFriends(res.data.result.request_list);
        })
        .catch((err) => {
          console.log("friend request -err", err);
          // TODO: handle errors
        });

      followers()
        .then(async (res) => {
          this.$store.dispatch("setfollowers", res.data.result.follower_list);
          // TODO: maintain different list
          await setFriends(res.data.result.follower_list);
        })
        .catch((err) => {
          console.log("followers-err", err);
          // TODO: handle errors
        });

      following()
        .then(async (res) => {
          this.$store.dispatch("setfollowings", res.data.result.following_list);
          // TODO: maintain different list
          await setFriends(res.data.result.following_list);
        })
        .catch((err) => {
          console.log("following-err", err);
          // TODO: handle errors
        });
    },

    showFriendListClickHandler() {
      this.showFriendList = !this.showFriendList;
    },

    showFollowerListClickHandler() {
      this.showFollowerList = !this.showFollowerList;
    },

    showFollowingListClickHandler() {
      this.showFollowingList = !this.showFollowingList;
    },

    showFriendRequestListClickHandler() {
      this.showFriendRequestList = !this.showFriendRequestList;
    },
  },
  setup() {  
    return {};
  },
};
</script>