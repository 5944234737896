
import { openDB } from 'idb';
// const CryptoJS = require('crypto-js');

//const APP_KEY = '2b1cb114de2ee3d5696179930ae19ea0';

let _conversations = [], _messages = {};

let _isIndexDBAvailable = true;

let _conversationDB = undefined;

let _hasConversationObjectStoreOpened = false;

initConversationDB();

export async function openConversationObjectStore() {
    var tx = (await _conversationDB).transaction((await _conversationDB).objectStoreNames, "readwrite");

    _conversations = tx.objectStore('conversations');
    _messages = tx.objectStore('messages');

    _hasConversationObjectStoreOpened = true;
}

export async function clearConversationObjectStore() {
    if (!_isIndexDBAvailable) return;

    // clear the object stores 
    await openConversationObjectStore();

    // TODO: how dow we clear this?
    _conversations.clear();
    _messages.clear();
}

export async function closeConversationDB() {
    if (!_isIndexDBAvailable) return;
    (await _conversationDB).close();
}

export async function initConversationDB() {
    try {
        var t1 = Date.now();
        if (_conversationDB) {
            await closeConversationDB();
        }

        _conversationDB = openDB("susamvad_conversations", 1, {
            upgrade(db, oldVersion, newVersion, transaction) {
                console.log("upgrade db", db, oldVersion, newVersion, transaction);

                _conversations = db.createObjectStore('conversations');
                _messages = db.createObjectStore('messages');
            },
            blocked() {
                // …
            },
            blocking() {
                // …
            },
            terminated() {
                // …
            },
        });

        await _conversationDB;

        // await clearObjectStore();
        if (!_hasConversationObjectStoreOpened) {
            await openConversationObjectStore();
        }

        _isIndexDBAvailable = true;
        console.log('ConversationDB Time:', Date.now() - t1);
        return;
    } catch (err) {
        console.log('initDB - indexdb not available', err);
        _isIndexDBAvailable = false;
    }
}

export async function getConversations(key) {
    if (!_isIndexDBAvailable) {
        return _conversations[key];
    }
    return (await _conversationDB).get('conversations', key);
}

export async function putConversation(key, value) {
    if (!_isIndexDBAvailable) {
        _conversations[key] = value;
        return;
    }
    return (await _conversationDB).put('conversations', value, key);
}

export async function deleteConversation(key) {
    if (!_isIndexDBAvailable) {
        delete _conversations[key];
        return;
    }

    return (await _conversationDB).delete('conversations', key);
}

export async function getConversationLocalList() {
    if (!_isIndexDBAvailable) return Object.values(_conversations);
    var conversations = await (await _conversationDB).getAllKeys('conversations');
    var conversationList = [];
    for (var conKey in conversations) {
        conversationList.push(await getConversations(conversations[conKey]));
    }
    return conversationList;
}

export async function setConversationLocalList(conversationList) {
    if (!_isIndexDBAvailable) {
        _conversations = conversationList;
        return;
    }

    try {
        for (let conversation in conversationList) {
            let theConversation = conversationList[conversation];
            await putConversation(theConversation.id, theConversation);
        }

    } catch (err) {
        console.log("indexdb not available", err);
    }
}

export async function updateConversationLocalList(userKey, updateData) {
    var updateKeys = Object.keys(updateData);

    if (!_isIndexDBAvailable) {
        if (userKey in _conversations) {
            let theConversation = _conversations[userKey];

            for (let i=0; i < updateKeys.length; i++) {
                if (updateKeys[i] === 'delivery_status' && updateKeys[i] in theConversation) {
                    theConversation.delivery_status[updateKeys[i]] = updateData[updateKeys[i]];
                } else {
                    theConversation[updateKeys[i]] = updateData[updateKeys[i]];
                }
            }
        } else {
            _conversations[userKey] = updateData;
        }
        return;
    }

    try {
        let convList = getConversationLocalList();
        console.log('convList', convList);
        let theConversation = (await convList).filter((x) => x.tokenized_user === userKey);
        if (theConversation.length === 1) {
            theConversation = theConversation[0];
        }
        for (let i=0; i < updateKeys.length; i++) {
            if (updateKeys[i] === 'delivery_status' && updateKeys[i] in theConversation) {
                theConversation.delivery_status[updateKeys[i]] = updateData[updateKeys[i]];
            } else {
                theConversation[updateKeys[i]] = updateData[updateKeys[i]];
            }
        }
        await putConversation(userKey, theConversation);
    } catch (err) {
        console.log("indexdb not available", err);
    }
}

export async function getMessages(key) {
    if (!_isIndexDBAvailable) {
        return _messages[key];
    }
    return (await _conversationDB).get('messages', key);
}

export async function putMessage(key, value) {
    if (!_isIndexDBAvailable) {
        if (key in _messages) {
            _messages[key][value.message_id] = value;
        } else {
            _messages[key] = {};
            _messages[key][value.message_id] = value;
        }
        return;
    }

    let messages = await getMessages(key);
    if (typeof(messages) === 'undefined') {
        let firstMsg = {};
        firstMsg[value.message_id] = value;
        return (await _conversationDB).put('messages', firstMsg, key);
    }

    if (messages[value.message_id] && messages[value.message_id].length > 0) {
        messages[value.message_id].push(...value);
    } else {
        messages[value.message_id] = value;
    }

    console.log("Put msg:", messages);

    return (await _conversationDB).put('messages', messages, key);
}

export async function deleteMessage(key, messageID) {
    if (!_isIndexDBAvailable) {
        let msgIdx = _messages[key].findIndex((x) => x.message_id === messageID);
        if (msgIdx >= 0) {
            delete _messages[key][msgIdx];
        }
    }

    let messages = await getMessages(key);
    if (messageID in messages) {
        delete messages[messageID];
    } else {
        console.log('did Found message');
    }

    return (await _conversationDB).put('messages', messages, key);
}

export async function setMessageLocalList(messageList) {
    // convert list to dictionary
    let dbMessages = {};
    messageList.map((x) => {
        dbMessages[x.message_id] = x;
    });

    if (!_isIndexDBAvailable) {
        _messages = dbMessages;
        return;
    }
    try {
        for (let message in dbMessages) {
            await putMessage(dbMessages[message].user_id, dbMessages[message]);
        }
    } catch (err) {
        console.log("indexdb no available", err);
    }
}

export async function getMessageLocalList(userkey) {
    if (!_isIndexDBAvailable) return Object.values(_messages);
    let msgList = await getMessages(userkey);
    if (typeof(msgList) === 'undefined') {
        msgList = [];
    }
    let messageList = [];
    for(let messageID in msgList) {
        messageList.push(msgList[messageID]);
    }
    msgList = messageList.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
    return msgList;
}

export async function updateDBMessageId(userkey, messageId, newMessageId) {
    if (!_isIndexDBAvailable) return;
    let msgList = await getMessages(userkey);
    console.log('updateDBMessageId', userkey, messageId, newMessageId, msgList);

    try {
        // copy old message to new and delete new message
        msgList[newMessageId] = msgList[messageId];
        msgList[newMessageId].message_id = newMessageId;

        await putMessage(userkey, msgList[newMessageId]);
        await deleteMessage(userkey, messageId);
    } catch(ignored) {
        ignored;
    } 
}

export async function updateDBMessageStatus(userkey, messageID, newMessageStatus) {
    if (!_isIndexDBAvailable) {
        let msgIdx = _messages[userkey].findIndex((x) => x.message_id === messageID);
        if (msgIdx >= 0) {
            _messages[userkey][msgIdx].delivery_status = newMessageStatus;
        }
    }

    try {
        let msgList = await getMessages(userkey);
        console.log('updateDBMessageStatus', userkey, messageID, msgList, newMessageStatus);

        // change delivery status
        var newStatusKeys = Object.keys(newMessageStatus);

        if ('delivery_status' in msgList[messageID]) {
            console.log('Updating message status:', newStatusKeys, msgList[messageID]);
            msgList[messageID].delivery_status[newStatusKeys[0]] = newMessageStatus[newStatusKeys[0]];
            console.log('After Updating message status:', msgList[messageID]);
        } else {
            msgList[messageID].delivery_status = newMessageStatus;
        }  
        
        await putMessage(userkey, msgList[messageID]);

        // update last message and time for conversation list
        updateConversationLocalList(userkey, {'delivery_status': newMessageStatus });

    } catch(ignored) {
        ignored;
    } 
}

export async function addMessageToDB(userkey, message) {
    await putMessage(userkey, message);
}
