<template>
  <span no-lines lines="none" slot="start" size="small" v-if="!post.edit_mode" class="ion-no-padding ion-no-margin">
    <ion-item v-if="post.shared_by.length > 0" no-lines lines="none" class="ion-padding-horizontal ion-no-margin share_by_text">
      <ion-button
        fill="clear"
        color="primary"
        class="button_nopadding"
        size="small"
      >
        <ion-icon
          slot="end"
          color="primary"
          :icon="arrowRedoSharp"
          size="small"
          v-if="post.shared_by.length > 0"
        >
        </ion-icon>
      </ion-button>

      <ion-text class="ion-padding-horizontal ion-no-margin" size="small" color="primary" v-if="post.shared_by.length > 1"
        ><small>
          {{ getStrings(strings, "SharedBy") }} <b>{{ post.shared_by[0].full_name }}</b> and
          <b>{{ post.shared_by.length - 1 }}</b> {{ getStrings(strings, "Other") }} </small
        ></ion-text
      >
      <ion-text class="ion-padding-horizontal ion-no-margin" size="small" color="primary" v-if="post.shared_by.length == 1"
        ><small>
          {{ getStrings(strings, "SharedBy") }} <b>{{ post.shared_by[0].full_name }}</b>
        </small>
      </ion-text>
      <more-actions-button v-if="post.is_active"
        :post="post"
      ></more-actions-button>
    </ion-item>
    <ion-item no-lines lines="none" class="ion-padding-horizontal ion-no-margin">
      <ion-avatar
        size="small"
        slot="start"
        v-if="!post.profile_photo_available"
        @click="this.$router.push('/user/profile/' + post.tokenized_user);"
        style="background-color: #7762FF; cursor: pointer; align-items: center;"
      >
        <ion-label style="color:black; margin-top: 7px;" class="ion-text-center"><b>{{ make2CharName(post.user_fullname) }}</b></ion-label>
      </ion-avatar>
      <ion-avatar
        size="small"
        slot="start"
        v-if="post.profile_photo_available"
        @click="this.$router.push('/user/profile/' + post.tokenized_user);"
        style="cursor: pointer;"
      >
        <ion-img @ionError="flagProfilePhotoNotAvailable(post)" size="small" :src="post.user_profile_photo"></ion-img> 
      </ion-avatar>
      <ion-text color="dark" v-if="isMobileScreen()" @click="this.$router.push('/user/profile/' + post.tokenized_user);">
        <span class="br">
          <b style="cursor: pointer;">{{ post.user_fullname }}&nbsp;</b> 
          <ion-icon v-if="post.user_verified_level === 'Full'" color="primary" :icon="checkmarkCircleSharp"></ion-icon>
          <ion-icon v-if="post.user_verified_level === 'Partial'" color="warning" :icon="checkmarkCircleSharp"></ion-icon>
          <small class="br" style="color: #808080" v-if="!post.is_edited">{{ formatDate(post.createdAt, strings) }} </small>
          <small class="br" style="color: #808080" v-if="post.is_edited">{{ formatDate(post.createdAt, strings) }} (edited)</small>
        </span>
      </ion-text>
      <ion-text color="dark" v-if="!isMobileScreen()" @click="this.$router.push('/user/profile/' + post.tokenized_user);">
        <span class="br">
          <b style="cursor: pointer;">{{ post.user_fullname }}&nbsp;</b>
          <ion-icon v-if="post.user_verified_level === 'Full'" color="primary" :icon="checkmarkCircleSharp"></ion-icon>
          <ion-icon v-if="post.user_verified_level === 'Partial'" color="warning" :icon="checkmarkCircleSharp"></ion-icon>
          <small class="br" style="color: #808080" v-if="!post.is_edited">{{ formatDate(post.createdAt, strings) }}</small>
          <small class="br" style="color: #808080" v-if="post.is_edited">{{ formatDate(post.createdAt, strings) }} (edited)</small>
        </span>
      </ion-text>
      <more-actions-button v-if="post.shared_by.length === 0"
        :post="post"
      ></more-actions-button>
    </ion-item>
  </span>
  <create-post-form
    v-if="post.edit_mode"
    class="animate-fading"
    :restrictSelection="2"
    :inEditMode="post.edit_mode"
    :post="post"
    :topics="createPostList"
    :editCancelAction="cancelEdit"
    :visibilityMode="post.visibility"
  ></create-post-form>
</template>

<script>
import {
  IonItem,
  IonText,
  IonButton,
  IonIcon,
  IonAvatar,
  IonImg,
  IonLabel,
} from "@ionic/vue";
import {
  flag,
  personAdd,
  thumbsUp,
  thumbsUpSharp,
  thumbsUpOutline,
  arrowRedoOutline,
  arrowRedoSharp,
  repeatOutline,
  heart,
  happy,
  sad,
  star,
  starOutline,
  trashOutline,
  createOutline,
  personCircleOutline,
  shieldCheckmark,
  checkmarkCircleSharp,
} from "ionicons/icons";

import { defineComponent } from "vue";

import {
  formatDate,
  isMobileScreen,
  isDesktopScreen,
  isTabletScreen,
  make2CharName,
} from "../../services/utils";
import CreatePostForm from "./CreatePostForm.vue";
import MoreActionsButton from "../activity/MoreActionsButton.vue"
import { getStrings } from "../../services/lang";

export default defineComponent({
  name: "PostHeader",
  props: ["post", "editedPost"],
  components: {
    IonItem,
    IonText,
    IonButton,
    IonIcon,
    IonAvatar,
    IonImg,
    IonLabel,
    CreatePostForm,
    MoreActionsButton,
  },
  setup() {
    return {
      formatDate,
      isMobileScreen,
      isDesktopScreen,
      isTabletScreen,
      make2CharName,
      getStrings,
    };
  },
  data() {
    return {
      flag,
      personAdd,
      thumbsUp,
      thumbsUpSharp,
      thumbsUpOutline,
      arrowRedoOutline,
      arrowRedoSharp,
      repeatOutline,
      heart,
      happy,
      sad,
      star,
      starOutline,
      trashOutline,
      createOutline,
      personCircleOutline,
      shieldCheckmark,
      checkmarkCircleSharp,
      currentUser: {},
      createPostList: this.postTopicList(this.post),
    };
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    cancelEdit(post) {
      post.edit_mode = false;
    },
    postTopicList(post) {
      var selectedTopics = post.topics;
      var topicList = JSON.parse(JSON.stringify(this.$store.getters.topicList));

      if (typeof selectedTopics !== "undefined") {
        topicList = topicList.map((x) => {
          var isSlectedTopic =
            selectedTopics.filter((y) => {
              return y.topic_name == x.topic_name;
            }).length > 0;
          x.isSelected = isSlectedTopic;
          return x;
        });
      }
      return topicList;
    },
    flagProfilePhotoNotAvailable(post) {
      post.profile_photo_available = false;
    }
  },

});
</script>
