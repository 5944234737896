/** handle ui languages */

import {
    getLangSettings,
    getLangList,
} from "./apiCall";

/** default strings in english */
const DEFAULT_STRINGS = {
    "version": "0.1",
    "language": "English",
    "app_name": "NEXT",

    "Post": "Post",
    "Posts": "Posts",
    "Public": "Public",
    "Family": "Family",
    "UnFamily": "Un-family",
    "Friend": "Friend",
    "Friends": "Friends",
    "UnFriend": "Un-friend",
    "Group": "Group",
    "Groups": "Groups",
    "Profile": "Profile",
    "Settings": "Settings",
    "SignOut": "Sign Out",
    "Logout": "Logout",
    "EditSettings": "Edit Settings",
    "UserProfile": "User Profile",
    "EditProfile": "Edit Profile",
    "Menu": "Menu",
    "Conversations": "Conversations",
    "Chats": "Chats",
    "AddAPost": "Add a post",
    "Search": "Search",
    "Delete": "Delete",
    "Accept": "Accept",
    "Reject": "Reject",
    "Decline": "Decline",
    "Remove": "Remove",
    "Invite": "Invite",
    "Cancel": "Cancel",
    "About": "About",
    "Contacts": "Contacts",
    "Draft": "Draft",
    "Drafts": "Drafts",
    "SaveDraft": "Save Draft",
    "Load": "Load",
    "Tags": "Tags",
    "AddTagsForVisibility": "Add tags for visibility",
    "UpdatePost": "Update Post",
    "EditingADraft": "Editing a draft",
    "MarkSold": "Mark Sold",

    "Like": "Like",
    "Likes": "Likes",
    "Reaction": "Reaction",
    "Reactions": "Reactions",
    "Comment": "Comment",
    "Comments": "Comments",
    "Share": "Share",
    "Report": "Report",
    "All": "All",
    "FilterTopics": "Filter Topics",
    "ClearTopics": "Clear Topics",
    "UnFollow": "Un-follow",
    "Follow": "Follow",
    "Followers": "Followers",
    "HidePost": "Hide Post",
    "UnHidePost": "Un-hide Post",
    "NotificationOn": "Notification On",
    "NotificationOff": "Notification Off",
    "Photo": "Photo",
    "Video": "Video",
    "Abusive": "Abusive",
    "Explicit": "Explicit",
    "Communal": "Communal",
    "Racial": "Racial",
    "Fake": "Fake",
    "SharedBy": "SharedBy",
    "Replies": "Replies",
    "HideReplies": "Hide Replies",
    "Hide": "Hide",
    "Show": "Show",
    "Flag": "Flag",
    "Edit": "Edit",
    "Editing": "Editing",
    "Update": "Update",
    "HideComments": "Hide Comments",
    "Notification": "Notification",
    "Notifications": "Notifications",
    "MarkAllRead": "Mark All Read",
    "MarkRead": "Mark Read",
    "NotificationSettings": "Notification Settings",
    "Other": "Other",
    "NothingToShowHere": "Nothing to show here",
    "PullToRefresh": "Pull to refresh",
    "LoadingPost": "Loading post",
    "ShowOnlyFavourites": "Show Only Favourites",
    "ShowAllPosts": "Show All Posts",
    "UpdatePhoto": "Update Photo",
    "FriendRequests": "Friend Requests",
    "FamilyRequests": "Family Requests",
    "DeleteFamilyRequest": "Delete Family Request",
    "DeleteFriendRequest": "Delete Friend Request",
    "MutualFriends": "Mutual Friends",
    "DeleteForMe": "Delete for me",
    "DeleteForEveryone" : "Delete for everyone",
    "GroupInfo" : "Group info",


    "UserName": "Email",
    "Password": "Password",
    "SignIn": "Sign In",
    "NotRegistered": "Not Registered?",
    "SignUp": "Sign Up",
    "Name": "Full Name",
    "AlreadyHaveAccount": "Already have an Account?",
    "ForgotPassword": "Forgot Password",
    "CreateNewAccount": "Create New Account",
    "ChangePassword": "Change Password",
    "ResetPassword": "Reset Password",
    "VerifyYourAccount": "Verify Your Account",

    "LoginPage_Title": "Sign In",

    "Feedback": "Feedback",
    "SendFeedback": "Send Feedback",
    "Description": "Description",
    "PleaseSendFeedback": "Please send us your feedback",
    "ThanksForFeedback" : "Thanks for sharing your Feedback",
    "WriteFeedback": "Write Feedback",
    "WriteYourFeedback": "Write Your Feedback",

    "CreateGroup": "Create Group",
    "Admin": "Admin",
    "Administered": "Administered",
    "Member": "Member",
    "Members": "Members",
    "Request": "Request",
    "GroupJoinRequests": "Group Join Requests",
    "Private": "Private",
    "Invites": "Invited",
    "YouAreMemberOfThisGroup": "You are member of this group",
    "YouAreAdminOfThisGroup": "You are admin of this group",
    "NotMemberOfAnyGroup": "Not a member of any group",
    "MakeAdmin": "Make Admin",
    "UpdateGroup": "Update Group",
    "IsGroupSearchable": "Is Group Searchable",
    "Invitation": "Invitation",
    "Open": "Open",
    "MemberJoiningMode": "Member Joining Mode",
    "ClosedGroup": "Closed",
    "GroupVisibility": "Group Visibility",
    "GroupDescription": "Group Description",
    "GroupName": "Group Name",
    "DescribeyourGroup": "Describe your Group",
    "UploadGroupProfilePhoto": "Upload group profile photo",
    "Next": "Next",
    "Done": "Done",
    "InviteMembers": "Invite Members",
    "JoinGroup": "Join Group",
    "JoinGroupRequestSent": "Join group request sent",
    "MemberByInviteOnly": "Member by Invite only",
    "AccpetToJoinGroup": "Accpet to Join Group",
    "JoinGroupToPost": "Join Group To Post",
    "LeaveGroup": "Leave Group",
    "EditGroup": "Edit Group",
    "ManageGroup": "Manage Group",

    "January": "January",
    "February": "February",
    "March": "March",
    "April": "April",
    "May": "May",
    "June": "June",
    "July": "July",
    "August": "August",
    "September": "September",
    "October": "October",
    "November": "November",
    "December": "December",

    "FriendRequestSent": "Friend request sent",
    "FriendRequestAlreadySent": "Friend request already sent",
    "YouHaveUnFriendTheUser": "You have un-friend the user",
    "YouAreFollowingNow": "You are following now",
    "YouHaveUnFollowedTheUser": "You have un-followed the user",
    "YouWillNotReceivePostNotification": "You will not receive any notifications for this post",
    "YouWillNotSeePost": "You will not see this post in future",
    "YouWillReceivePostNotifications": "You will start receiving notifications for this post",
    "YouWillSeePost": "You will see this post now",
    "GroupCreated": "GroupCreated",
    "UnableToJoinRequestTryAgain.": "Unable to make join request, Please try again",
    "UnableAcceptInviteTryAgain": "Unable to make accept invite, Please try again",
    "UnableRejectInviteTryAgain": "Unable to reject invite, Please try again",
    "UnableSendInviteTryAgain": "Unable to send invite, please try again",
    "NewGroupPostsNotificationsMuted": "New posts notifications from this group are muted",
    "NewGroupPostsNotificationsUnMuted": "You will receive new posts notifications from this group",
    "GroupDetailsUpdated": "Group details updated",
    "GroupUpdateErrorTryAgain": "Group update error. Please try again",
    "MemberRemovedFromGroup": "Member removed from group",
    "MemberAdminNow": "Member is admin now",
    "UnableToGeneratePreview": "Unable to generate preview",
    "FriendRequestAccepted": "Friend request accepted",
    "FriendRequestRejected": "Friend request rejected",
    "FriendRequestDeleted": "Friend request deleted",
    "FamilyRequestAccepted": "Family request accepted",
    "FamilyRequestRejected": "Family request rejected",
    "FamilyRequestDeleted": "Family request deleted",
    "GroupDeletedOrDeactivated": "Group is deleted or deactivated",
    "LoggingOffUnablecredentials": "Logging off. Unable to read user credentials",
    "LoggingOffUserInvalidSession": "Logging off. User session not valid",
    "EnsureConnectedToInternet": "Ensure you are connected to the Internet.",
    "BrowserNotSupportVideo": "Your browser does not support the video tag.",

    "FullName": "Full Name",
    "Email": "Email",
    "Phone": "Phone",
    "Gender": "Gender",
    "Male": "Male",
    "Female": "Female",
    "BirthDate": "Birth Date",
    "Country": "Country",
    "ReferralCode": "Referral Code",
    "RegisterYourInterest": "Register Your Interest",
    "Minimum8Characters": "Minimum 8 Characters",
    "Minimum1UpperCaseCharacter": "Minimum 1 upper case character (A,B,..,Z)",
    "Minimum1SpecialCharacter": "Minimum 1 special character (#,$,%,@,!,^,*,~)",
    "Minimum1NumericCharacter": "Minimum 1 numeric character (0,1,2,..,9)"
};

/** load translation files from server */
export function loadLang(store, langCode = "default") {
    getLangList()
        .then(async (res) => {
            console.log("getLangList", res);
            if (res.data.status === 0) {
                store.dispatch("setlanglist", res.data.result.language_list);
            }
        })
        .catch((err) => {
            console.log("getLangList", err);
        });

    getLangSettings({ language_code: langCode })
        .then(async (res) => {
            console.log("getLangSettings", res);

            if (res.data.status == 0) {
                var langJSON = res.data.result.language_json;
                langJSON.language_code = langCode;
                store.dispatch("addstrings", langJSON);
                store.dispatch("setcurrentlang", langCode);
            }
        })
        .catch((err) => {
            console.log("getLangSettings - err", err);
        });
}

/** get a string */
export function getStrings(strings, key) {
    try {
        var langStr = strings[key] ? strings[key] : DEFAULT_STRINGS[key];
        if (!langStr) return key;
        return langStr;
    } catch (ignored) {
        return DEFAULT_STRINGS[key] ? DEFAULT_STRINGS[key] : key;
    }
}
