<template>
  <base-layout
    page-title="ResetPassword"
    page-default-back-link="/"
    page-back-link="/"
    showBackButton="true"
  >
    <template v-slot:actions-end>
      <ion-button @click="this.$router.replace('/')">
        <ion-icon slot="icon-only" :icon="logInOutline"></ion-icon>
      </ion-button>
    </template>

    <form ion-padding v-if="!isResetMode">
      <ion-row>
        <ion-col :size="getColSize()" :offset="getOffsetSize()">
          <ion-item>
            <ion-label position="floating">Enter your registered email</ion-label>
            <ion-input
              type="email"
              v-model="registeredEmail"
              autocomplete="email"
            ></ion-input>
          </ion-item>
          <ion-item>
            <ion-button
              @click="requestPasswordReset()"
              :disabled="submitDisabled"
              shape="round"
              >Send Reset E-mail<ion-spinner
                v-if="submitDisabled"
                slot="end"
                name="dots"
              ></ion-spinner
            ></ion-button>
          </ion-item>
          <ion-item v-if="resetEmailSent">
            <ion-label color="medium" size="small" class="ion-text-wrap"
              ><small
                >If your email and phone number is correct, you will receive password
                reset email and sms. Follow the instructions received in your email to
                reset password.</small
              ></ion-label
            >
          </ion-item>
        </ion-col>
      </ion-row>
    </form>

    <form ion-padding v-if="isResetMode">
      <ion-row>
        <ion-col :size="getColSize()" :offset="getOffsetSize()">
          <password-field
            :passwordSetting="newPasswordSetting"
            :verifyCallback="verifyPasswordRules"
          ></password-field>
          <ion-item>
            <ion-label position="floating">Retype Password</ion-label>
            <ion-input
              type="password"
              v-model="retypePassword"
              autocomplete="new-password"
              @ionChange="verifyPasswordRules"
            ></ion-input>
          </ion-item>
          <ion-item v-if="passwordMismatch">
            <ion-label size="small"
              ><small>New and re-typed passwords do not match</small></ion-label
            >
          </ion-item>
          <ion-item>
            <ion-label position="floating"
              >Enter Verification Code</ion-label
            >
            <ion-input v-model="totp"></ion-input>
          </ion-item>
          <ion-item>
            <ion-button
              @click="resetThePassword()"
              :disabled="submitDisabled"
              shape="round"
              >Change Password<ion-spinner
                v-if="submitDisabled"
                slot="end"
                name="dots"
              ></ion-spinner
            ></ion-button>
          </ion-item>

          <hr style="border-top: 1px dotted grey" />
          <ion-item v-if="resendVerifyMsg != ''">
            <ion-label size="small" color="secondary"
              ><small>{{ resendVerifyMsg }}</small></ion-label
            >
          </ion-item>
          <ion-item>
            <ion-text size="small" color="medium">
              Misplaced or lost the authenticator code?
              <ul>
                <li>
                  <small
                    >Ensure that the email given at the signup time is
                    correct</small
                  >
                </li>
                <li>
                  <small
                    >Check your email filtered folders or spam folder</small
                  >
                </li>
                <li>
                  <small
                    >You will need to setup your Authenticator app with the code
                    you receive in the email. To resend the authenticator code
                    via email,
                    <ion-button
                      color="medium"
                      size="small"
                      shape="round"
                      @click="resendVerification()"
                      ><small>click here</small
                      ><ion-spinner
                        v-if="reverifyClicked"
                        slot="end"
                        name="dots"
                      ></ion-spinner></ion-button
                  ></small>
                </li>
              </ul>
            </ion-text>
          </ion-item>
        </ion-col>
      </ion-row>
    </form>
  </base-layout>
</template>

<script>
import { getColSize, getOffsetSize } from "../services/utils";

import {
  IonButton,
  IonItem,
  IonLabel,
  IonIcon,
  IonInput,
  IonRow,
  IonCol,
  IonText,
  alertController,
  IonSpinner,
} from "@ionic/vue";

import {
  checkmarkDoneOutline,
  closeOutline,
  logInOutline,
} from "ionicons/icons";

import {
  resetPasswordRequest,
  resetPassword,
  resendVerify,
} from "../services/apiCall";
import { useRouter } from "vue-router";

import PasswordField from "../components/settings/PasswordField.vue";

export default {
  components: {
    IonButton,
    IonItem,
    IonLabel,
    IonIcon,
    IonInput,
    IonRow,
    IonCol,
    IonText,
    IonSpinner,
    PasswordField,
  },
  setup() {
    const router = useRouter();

    return {
      router,
      getColSize,
      getOffsetSize,
    };
  },
  data() {
    return {
      myID: this.$route.params.me,
      resetCode: this.$route.params.code,
      resetPasswordRequest,
      resetPassword,
      checkmarkDoneOutline,
      closeOutline,
      logInOutline,
      submitDisabled: false,
      reverifyClicked: false,
      registeredEmail: "",
      resendVerifyMsg: "",
      newPasswordSetting: {
        name: "New Password",
        password: "",
      },
      retypePassword: "",
      totp: "",
      passwordRuleError: true,
      passwordRules: {
        minEightChars: false,
        minOneSpecialChar: false,
        minOneUpperCase: false,
        minOneNumeric: false,
      },
      passwordMismatch: false,
      resetEmailSent: false,
    };
  },
  computed: {
    isResetMode() {
      const flag1 = typeof this.myID !== "undefined" && this.myID !== "";
      const flag2 =
        typeof this.resetCode !== "undefined" && this.resetCode !== "";
      return flag1 && flag2;
    },
  },
  methods: {
    verifyPasswordRules() {
      this.passwordMismatch =
        this.newPasswordSetting.password !== this.retypePassword;
    },
    async showError(title, msg) {
      const failedAlert = await alertController.create({
        header: title,
        message: msg,
        buttons: ["OK"],
      });
      await failedAlert.present();
    },
    resetThePassword() {
      resetPassword({
        user_id: this.myID,
        reset_code: this.resetCode,
        new_password: this.newPasswordSetting.password,
        totp: this.totp,
      })
        .then(async (res) => {
          if (res.data.status === 0) {
            const successAlert = await alertController.create({
              header: "Password Changed",
              message: "New password set successfully. You can now login.",
              buttons: ["OK"],
            });
            await successAlert.present();
            this.router.replace("/login");
          } else {
            this.showError(
              "Password Change Error",
              "Password Change Failed. Message from Server: " +
                res.data.error.error_message
            );
          }
        })
        .catch(async (err) => {
          console.log("changePassword - err", err);
          this.showError(
            "Password Changed",
            "Password Change Failed. Error is: " + err
          );
        });
    },
    requestPasswordReset() {
      this.submitDisabled = true;
      resetPasswordRequest({ email: this.registeredEmail })
        .then((res) => {
          console.log("resetPasswordRequest", res);
          this.submitDisabled = false;
          this.resetEmailSent = true;
        })
        .catch((err) => {
          console.log("resetPasswordRequest - err", err);
          this.submitDisabled = false;
          this.resetEmailSent = true;
        });
    },
    resendVerification() {
      this.reverifyClicked = true;
      var options = {};

      this.resendVerifyMsg = "";

      if (typeof this.myID !== "undefined") options["user_id"] = this.myID;

      resendVerify(options)
        .then((res) => {
          console.log("resendVerify", res);
          if (res.data.status === 0) {
            this.resendVerifyMsg =
              "Email with authenticator code resent, please check your email.";
            this.reverifyClicked = false;
          } else {
            this.resendVerifyMsg = "Failed to send email, try again.";
            this.reverifyClicked = false;
          }
        })
        .catch((err) => {
          this.reverifyClicked = false;
          this.resendVerifyMsg = "Failed to send email, try again.";
          console.log("resendVerify - err", err);
        });
    },
  },
};
</script>