<template>
  <ion-content>
    <ion-list>
      <ion-item class="ion-padding-horizontal ion-no-margin" v-for="fopt in flagOptions" v-bind:key="fopt.value" @click="flagTheComment(comment, fopt.value)" :value="fopt.value"
        ><ion-label
          >{{ getStrings(strings, fopt.display_text) }}&nbsp;<ion-icon
            slot="icon-only"
            v-if="hasMyFlag(comment, fopt.value)"
            :icon="checkmarkCircleOutline"
          ></ion-icon></ion-label
      ></ion-item>
    </ion-list>
  </ion-content>
</template>
<script>
import { IonContent, IonList, IonItem, IonLabel, IonIcon } from "@ionic/vue";
import { defineComponent } from "vue";

import { checkmarkCircleOutline } from "ionicons/icons";

import { flagComment, flagCommentOptions } from "../../services/apiCall.js";
import { getStrings } from "../../services/lang";


export default defineComponent({
  name: "CommentFlagOptions",
  props: ["comment", "parent"],
  components: { IonContent, IonList, IonItem, IonLabel, IonIcon },
  mounted() {
    console.log("CommentFlagOptions page mounted");
    this.init();
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
  },
  setup() {
    return {
      getStrings,
    };
  },
  data() {
    return { 
      flagOptions: [],
      checkmarkCircleOutline 
    };
  },
  methods: {
    init() {
      flagCommentOptions().then((res) => {
        console.log('flagCommentOptions', res);
        if (res.data.status === 0) {
          this.flagOptions = res.data.result.flag_options;
        }
      }).catch((err) => {
        console.log('flagCommentOptions - err', err);
      })
    },
    hasMyFlag(comment, theReason) {
      // console.log("hasMyFlag", comment.my_flags);
      if (comment.my_flags) {
        var hasFlag =
          comment.my_flags.findIndex((x) => x.flag_type === theReason) >= 0;
        // console.log("hasMyFlag - return", theReason, hasFlag);
        return hasFlag;
      } else {
        // console.log("hasMyFlag - return(no def)", theReason, false);
        return false;
      }
    },
    flagTheComment(comment, theReason) {
      // console.log("flagTheComment", comment);

      comment.flag_count =
        typeof comment.flag_count !== "undefined" ? comment.flag_count + 1 : 1;

      flagComment({
        comment_id: comment.id,
        flag_type: theReason,
        reason: theReason,
      })
        .then((res) => {
          // console.log("flag comment", res);
          if (res.data.status == 0) {
            comment.flag_count = res.data.result.flag_count;

            if (!comment.my_flags) comment.my_flags = [];
            var prevReact = comment.my_flags.findIndex(
              (x) => x.flag_type === theReason
            );
            if (prevReact < 0) {
              comment.my_flags.push({ flag_type: theReason });
            } else {
              comment.my_flags.splice(prevReact, 1);
            }
          }
          this.parent.dismiss();
        })
        .catch((err) => {
          console.log("flag comment - err", err);
          this.parent.dismiss();
        });
    },
  },
});
</script>
