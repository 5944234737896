<template>
  <ion-card class="ion-no-padding ion-no-margin">
    <ion-card-content
      no-padding
      no-margin
      class="ion-no-padding ion-no-margin"
      :class="isIOS() ? 'boxiOS' : 'box'"
    >
      <ion-grid class="ion-no-margin">
        <!-- photo post preview -->
        <ion-row
          class="ion-no-margin ion-no-padding"
          style="border-radius: 10px; padding: 6px"
          v-if="photoPost.is_photo_post"
          color="medium"
        >
          <ion-col class="ion-no-margin ion-no-padding" :size="12">
            <ion-button
              color="light"
              size="small"
              shape="round"
              class="button_image_overlap"
              @click="deletePhoto"
              :disabled="deletePhotoClicked"
            >
              <ion-icon
                slot="icon-only"
                color="dark"
                :icon="icon.closeCircle"
              ></ion-icon>
            </ion-button>
            <ion-spinner
              v-show="deletePhotoClicked"
              class="button_image_overlap"
              name="dots"
            ></ion-spinner>
            <ion-item
              no-lines
              lines="none"
              class="ion-no-padding ion-no-margin"
            >
              <ion-img :src="photoPost.photo_url"></ion-img>
            </ion-item>
          </ion-col>
        </ion-row>

        <!-- video post preview -->
        <ion-row
          class="ion-no-margin ion-no-padding"
          style="border-radius: 10px; padding: 6px"
          v-if="photoPost.is_video_post"
          color="medium"
        >
          <ion-col class="ion-no-margin ion-no-padding" :size="12">
            <ion-button
              color="light"
              size="small"
              shape="round"
              class="button_image_overlap"
              @click="deletePhoto"
              :disabled="deletePhotoClicked"
            >
              <ion-icon
                slot="icon-only"
                color="dark"
                :icon="icon.closeCircle"
              ></ion-icon>
            </ion-button>
            <ion-spinner
              v-show="deletePhotoClicked"
              class="button_image_overlap"
              name="dots"
            ></ion-spinner>
            <ion-item
              no-lines
              lines="none"
              class="ion-no-padding ion-no-margin"
            >
              <video class="video_player" controls autoplay muted>
                <source :src="photoPost.photo_url" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </ion-item>
          </ion-col>
        </ion-row>

        <!-- audio post preview -->
        <ion-row
          class="ion-no-margin ion-no-padding"
          style="border-radius: 10px; padding: 6px"
          v-if="photoPost.is_audio_post"
          color="red"
        >
          <ion-col class="ion-no-margin ion-no-padding" :size="11">
            <ion-spinner
              v-if="deletePhotoClicked"
              class="button_image_overlap"
              name="dots"
            ></ion-spinner>
            <ion-item
              no-lines
              lines="none"
              class="ion-no-padding ion-no-margin"
            >
              <audio controls>
                <source :src="photoPost.photo_url" type="audio/ogg" />
                Your browser does not support the audio element.
              </audio>
            </ion-item>
          </ion-col>
          <ion-col class="ion-no-margin ion-no-padding" :size="1">
            <ion-button
              color="light"
              size="small"
              shape="round"
              @click="deletePhoto"
              :disabled="deletePhotoClicked"
            >
              <ion-icon
                slot="icon-only"
                color="dark"
                :icon="icon.closeCircle"
              ></ion-icon>
            </ion-button>
          </ion-col>
        </ion-row>
        <!--<section class="sound-clips"></section> -->

        <!-- link preview -->
        <ion-row
          v-if="
            isLinkPreviewAvailable &&
            !photoPost.is_video_post &&
            !photoPost.is_photo_post
          "
        >
          <ion-col class="ion-no-margin ion-no-padding" :size="12">
            <ion-item
              no-lines
              lines="none"
              class="
                ion-no-padding
                ion-no-margin
                ion-align-self-center
                ion-justify-content-center
                ion-text-wrap
                word-wrap:
                break-word;
                word-break;
              "
            >
              <a
                target="_nextExternalLink"
                style="text-decoration: none"
                :href="previewData.url"
              >
                <ion-card
                  class="
                    ion-padding-vertical-photo
                    ion-justify-content-center
                    ion-no-margin
                    ion-align-self-center
                    ion-text-wrap
                    full_width
                  "
                >
                  <span style="width: 100%" class="animate-fading">
                    <ion-img
                      width="100%"
                      alt=".  .(No Image Available).  ."
                      :src="previewData.images[0]"
                      class="animate-fading"
                    ></ion-img>
                  </span>

                  <ion-card-header
                    class="
                      ion-padding-vertical-photo
                      ion-padding-horizontal
                      ion-no-margin
                    "
                  >
                    <ion-card-subtitle class="ion-no-margin">
                      <a target="_nextExternalLink" :href="previewData.url"
                        >{{ previewData.siteName }}
                      </a></ion-card-subtitle
                    ><ion-card-title class="ion-no-margin">
                      {{ previewData.title }}
                    </ion-card-title></ion-card-header
                  ><ion-card-content
                    class="
                      ion-padding-horizontal ion-no-margin
                      card_content_custom
                    "
                  >
                    <div class="ion-no-margin">
                      {{ previewData.description }}
                    </div>
                  </ion-card-content></ion-card
                >
              </a>
            </ion-item>
          </ion-col>
        </ion-row>
        <!--END link preview -->
        <ion-row
          class="ion-no-margin ion-no-padding reply_box"
          style="border-radius: 10px; padding: 6px"
          v-show="isReplying"
        >
          <ion-col class="ion-no-margin ion-no-padding">
            <ion-item
              class="ion-no-margin reply_box"
              lines="none"
              style="border-radius: 10px; background-color: #ff4444"
            >
              <ion-text
                style="padding: 6px"
                class="reply_box"
                :innerHTML="getReplyWindowContent()"
              >
              </ion-text>
            </ion-item>
          </ion-col>
          <ion-col size="auto">
            <ion-button
              fill="clear"
              color="medium"
              @click="onCloseReplyClicked"
            >
              <ion-icon
                align="center"
                slot="icon-only"
                :icon="icon.closeOutline"
                style="padding: 0px"
              ></ion-icon>
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row class="ion-no-margin ion-no-padding">
          <ion-col class="ion-no-margin ion-no-padding">
            <ion-item class="ion-no-padding" no-lines lines="none">
              <ion-textarea
                @keyup.enter="sendMessage"
                autoGrow="true"
                rows="1"
                autocomplete="on"
                autocapitalize="on"
                spellcheck="true"
                size="small"
                class="ion-no-margin ion-no-padding boxInner tenpxright"
                v-model="chatData.message"
                @ionChange="detectActions"
                id="taInputBox"
              ></ion-textarea>
            </ion-item>
          </ion-col>
          <ion-col size="auto" class="ion-no-padding ion-no-margin">
            <ion-item
              class="ion-no-padding ion-no-margin"
              no-lines
              lines="none"
            >
              <chat-media-upload-button :post="chatPost" :photoPost="photoPost">
              </chat-media-upload-button>
            </ion-item>
          </ion-col>

          <ion-col size="auto" class="ion-no-padding ion-no-margin">
            <ion-item
              class="ion-no-padding ion-no-margin"
              no-lines
              lines="none"
            >
              <ion-button
                fill="clear"
                color="medium"
                :class="isIOS() ? ' ' : ' photo_button_padding'"
                @click="recordAudioMessage"
              >
                <ion-icon
                  slot="icon-only"
                  :icon="icon.micOutline"
                  :color="isRecording ? 'primary' : ''"
                ></ion-icon>
              </ion-button>
            </ion-item>
          </ion-col>

          <ion-col size="auto" class="ion-no-padding ion-no-margin">
            <ion-item
              class="ion-no-padding ion-no-margin"
              no-lines
              lines="none"
            >
              <ion-button
                :disabled="!isWSAvailable"
                fill="clear"
                color="medium"
                :class="isIOS() ? ' ' : ' photo_button_padding'"
                @click="sendMessage"
              >
                <ion-icon slot="icon-only" :icon="icon.sendOutline"></ion-icon>
              </ion-button>
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>
  </ion-card>
</template>

<script>
import {
  IonImg,
  IonCol,
  IonRow,
  IonItem,
  IonGrid,
  IonText,
  IonIcon,
  IonCard,
  IonButton,
  IonSpinner,
  IonTextarea,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  loadingController,
  toastController,
} from "@ionic/vue";

import {
  deleteImage,
  generateLinkPreview,
  presignedURL,
  uploadImage,
} from "../../services/apiCall";
import {
  isIOS,
  isAndroid,
  extracURLs,
  b64toBlob,
  maxUploadSize,
} from "../../services/utils";

import { Plugins } from "@capacitor/core";

import {
  micOutline,
  sendOutline,
  attachOutline,
  closeOutline,
  closeCircle,
} from "ionicons/icons";
import ChatMediaUploadButton from "./media/ChatMediaUploadButton.vue";

const { Filesystem } = Plugins;

export default {
  name: "ChatBox",
  props: [
    "isReplying",
    "replyContent",
    "isWSAvailable",
    "paramData",
    "paramDataType",
  ],
  emits: ["send-chat", "close-reply-box"],
  watch: {
    paramData: function (theData) {
      console.log("paramData", theData);
      this.chatData.message = window.atob(theData);
      this.decodeParamData();
    },
    paramDataType: function (theDataType) {
      if (theDataType === "image") {
        // handle image type
        this.photoPost.is_photo_post = true;
      } else if (theDataType === "video") {
        // handle video type
        this.photoPost.is_video_post = true;
      } else if (theDataType === "audio") {
        // handle audio type
        this.photoPost.is_audio_post = true;
      }
      this.decodeParamData();
    },
  },
  components: {
    IonImg,
    IonCol,
    IonRow,
    IonItem,
    IonGrid,
    IonIcon,
    IonCard,
    IonText,
    IonButton,
    IonSpinner,
    IonTextarea,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    ChatMediaUploadButton,
  },
  mounted() {
    console.log("mounted - paramData", this.paramData, this.paramDataType);
    if (typeof this.paramData !== "undefined") {
      this.chatData.message = window.atob(this.paramData);
      if (typeof this.paramDataType !== "undefined") {
        if (this.paramDataType === "image") {
          // handle image type
          this.photoPost.is_photo_post = true;
        } else if (this.paramDataType === "video") {
          // handle video type
          this.photoPost.is_video_post = true;
        } else if (this.paramDataType === "audio") {
          this.photoPost.is_audio_post = true;
        }

        this.decodeParamData();
      }
    }

    //this.initAudioControl();
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    currentProfilePhoto() {
      return this.$store.getters.currentProfilePhoto;
    },
  },
  data() {
    return {
      icon: {
        micOutline,
        sendOutline,
        attachOutline,
        closeOutline,
        closeCircle,
      },

      chatData: {
        message: "",
      },

      deletePhotoClicked: false,
      photoPost: {
        is_photo_post: false,
        is_video_post: false,
        is_audio_post: false,
        photo_url: "",
        photo_object_name: "",
      },

      chatPost: {},
      previewData: {},
      isLinkPreviewAvailable: false,

      mediaRecorder: null,
      audio_chunks: [],
      isRecording: false,
      isClipAvailable: false,
    };
  },
  setup() {
    return {
      isIOS,
      isAndroid,
      extracURLs,
    };
  },
  methods: {
    decodeParamData() {
      if (this.paramDataType === "text") {
        this.chatData.message = window.atob(this.paramData);
      } else if (this.paramDataType === "image") {
        let imageURI = window.atob(this.paramData);

        this.prepareImageShare(imageURI);
      } else if (this.paramDataType === "video") {
        let imageURI = window.atob(this.paramData);

        this.prepareVideoShare(imageURI);
      } else {
        this.chatData.message = window.atob(this.paramData);
      }
    },
    async prepareImageShare(imageURI) {
      if (this.prepareImageShareCalled) return;
      this.prepareImageShareCalled = true;

      const loading = await loadingController.create({
        message: "Making image preview...",
      });
      await loading.present();

      try {
        // form a unique file name
        const user = this.$store.getters.user;
        const fileName =
          user.user_id +
          "_" +
          user.auth.token +
          "_" +
          user.auth.session +
          "_" +
          Date.now() +
          ".jpg";

        this.photoPost.photo_object_name = fileName;

        const imgData = await Filesystem.readFile({
          path: imageURI,
        });

        // create a image blob
        const imageObject = new File([b64toBlob(imgData.data)], fileName);

        const sizeInBytes = imageObject.size;

        this.uploadMediaFile(
          fileName,
          imageObject,
          sizeInBytes,
          loading,
          false,
          true
        );
      } catch (gerr) {
        loading.dismiss();
        this.showAlert(
          "Image upload error",
          "We were unable to upload your image, please try again. Error is: " +
            gerr.toString()
        );
      }
    },
    async prepareVideoShare(imageURI) {
      if (this.prepareImageShareCalled) return;
      this.prepareImageShareCalled = true;

      const loading = await loadingController.create({
        message: "Making video preview...",
      });
      await loading.present();

      try {
        // form a unique file name
        const user = this.$store.getters.user;
        const fileName =
          user.user_id +
          "_" +
          user.auth.token +
          "_" +
          user.auth.session +
          "_" +
          Date.now() +
          ".mp4";

        this.photoPost.photo_object_name = fileName;

        const imgData = await Filesystem.readFile({
          path: imageURI,
        });

        // create a image blob
        const imageObject = new File([b64toBlob(imgData.data)], fileName);

        const sizeInBytes = imageObject.size;

        this.uploadMediaFile(
          fileName,
          imageObject,
          sizeInBytes,
          loading,
          true,
          false
        );
      } catch (gerr) {
        loading.dismiss();
        this.showAlert(
          "Video upload error",
          "We were unable to upload your video, please try again"
        );
      }
    },
    async uploadMediaFile(
      fileName,
      fileBlob,
      sizeInBytes,
      loader,
      isVideo = false,
      isImage = false
    ) {
      if (sizeInBytes > maxUploadSize()) {
        this.showAlert(
          "Media upload error",
          "Maximum upload size exeeded. Max: " +
            maxUploadSize() / 1024 / 1024 +
            "mb."
        );
        loader.dismiss();
        return;
      }

      // convert to file object
      const fileObject = new File([fileBlob], fileName);

      var photoPost = this.photoPost;

      // start the upload process
      presignedURL({ name: fileName })
        .then((res) => {
          console.log("presignedURL", res);
          if (res.data.status === 0) {
            uploadImage(res.data.result.put_url, fileObject)
              .then((res2) => {
                console.log("uploadImage", res2);
                photoPost.is_photo_post = isImage;
                photoPost.is_video_post = isVideo;
                photoPost.is_audio_post = false;
                if (!isImage && !isVideo) photoPost.is_audio_post = true;

                photoPost.photo_url = res.data.result.get_url;
                photoPost.photo_object_name = fileName;

                this.chatData.message = "";

                this.prepareImageShareCompleted = true;

                loader.dismiss();
              })
              .catch((err2) => {
                console.log("uploadImage - err", err2);
                this.showAlert(
                  "Media upload error",
                  "We were unable to upload your media, please try again"
                );
                loader.dismiss();
              });
          }
        })
        .catch((err) => {
          console.log("presignedURL - err", err);
          this.showAlert(
            "Media upload error",
            "We were unable to upload your media, please try again"
          );
          loader.dismiss();
        });
    },

    initAudioControl() {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        //console.log("getUserMedia supported.");
        let self = this;
        navigator.mediaDevices
          .getUserMedia(
            // constraints - only audio needed for this app
            {
              audio: true,
            }
          )

          // Success callback
          .then(function (stream) {
            self.mediaRecorder = new MediaRecorder(stream);

            self.mediaRecorder.ondataavailable = function (e) {
              self.audio_chunks.push(e.data);
            };

            //self.mediaRecorder.onstop = async function (e) {
            self.mediaRecorder.onstop = async function () {
              const user = self.$store.getters.user;
              const clipName =
                user.user_id +
                "_" +
                user.auth.token +
                "_" +
                user.auth.session +
                "_" +
                Date.now() +
                ".ogg";

              //console.log("recorder stopped - on stop called: " +clipName +JSON.stringify(e));

              const clipContainer = document.createElement("article");
              const audio = document.createElement("audio");
              const deleteButton = document.createElement("button");

              clipContainer.classList.add("clip");
              audio.setAttribute("controls", "");
              deleteButton.innerHTML = "Delete";
              //clipLabel.innerHTML = clipName;

              clipContainer.appendChild(audio);
              //clipContainer.appendChild(clipLabel);
              clipContainer.appendChild(deleteButton);

              //const soundClips = document.querySelector(".sound-clips");
              //soundClips.appendChild(clipContainer);

              const blob = new Blob(self.audio_chunks, {
                type: "audio/ogg; codecs=opus",
              });
              self.audio_chunks = [];
              const audioURL = window.URL.createObjectURL(blob);
              audio.src = audioURL;

              const audioObject = new File([blob], clipName);
              const sizeInBytes = audioObject.size;

              const loading = await loadingController.create({
                message: "Making audio preview...",
              });
              await loading.present();
              self.uploadMediaFile(
                clipName,
                audioObject,
                sizeInBytes,
                loading,
                false,
                false
              );
              //console.log("File :" + clipName + "   -- Size:" + sizeInBytes);

              deleteButton.onclick = function (e) {
                let evtTgt = e.target;
                evtTgt.parentNode.parentNode.removeChild(evtTgt.parentNode);
              };
            };

            self.startRecording();
          })

          // Error callback
          .catch(function (err) {
            console.log("The following getUserMedia error occurred: " + err);
          });
      } else {
        console.log("getUserMedia not supported on your browser!");
      }
    },

    async recordAudioMessage() {
      if (null == this.mediaRecorder) {
        this.initAudioControl();
        //console.log("Done Initialise");
      } else {
        if (this.isRecording) {
          this.stopRecording();
          //console.log("Stopped Recording");
        } else {
          this.startRecording();
          //console.log("recording started");
        }
      }
    },

    playMediaRecorded() {
      if (null != this.mediaRecorder) {
        this.mediaRecorder.stream();
      }
    },

    startRecording() {
      if (null !== this.mediaRecorder) {
        this.mediaRecorder.start();
        //console.log(this.mediaRecorder.state);
        //console.log("recorder started");

        this.isRecording = true;
      } else {
        console.log("Media Recorder not available");
      }
    },

    stopRecording() {
      if (null !== this.mediaRecorder) {
        this.mediaRecorder.stop();
        //console.log(this.mediaRecorder.state);
        //console.log("recorder stopped");
        this.isClipAvailable = true;
        this.isRecording = false;
      }
    },

    async sendMessage() {
      if (!this.isWSAvailable) return; // to avoid enter press for send - without socket

      var str = this.sanitize(this.chatData.message);
      //console.log("FROM CHAT BOX : ", JSON.stringify(this.chatData));
      var post_type = "text";
      if (true === this.photoPost.is_photo_post) {
        post_type = "photo_post";
      } else if (true === this.photoPost.is_video_post) {
        post_type = "video_post";
      } else if (true === this.photoPost.is_audio_post) {
        post_type = "audio_post";
      }

      if ("text" === post_type) {
        if (str.length == 0) {
          return;
        }
      }

      var replyId = 0;
      if (this.isReplying) {
        if (undefined != this.replyContent) {
          replyId = this.replyContent.id;
        }
      }

      let message = {
        from: "_self_",
        type: post_type,
        message: str,
        timeStamp: new Date(),
        replyto: replyId,
      };

      if (
        "photo_post" === post_type ||
        "video_post" === post_type ||
        "audio_post" === post_type
      ) {
        const ulr = this.photoPost.photo_url.replaceAll("&", "%26");
        message["media_path"] = ulr;
      }

      this.$emit("send-chat", message);

      this.chatData.message = "";
      this.clearMediaPostData();

      this.clearLinkPreviewPostData();

      this.setTextBoxFocus();
    },

    async showLinkPreview() {
      //console.log("showLinkPreview", this.chatData.message);
      let urlList = extracURLs(this.chatData.message);

      if (urlList.length > 0) {
        if (this.previewData.url === urlList[0]) {
          return; // the same URL
        }

        generateLinkPreview({ url: urlList[0] })
          .then((res) => {
            //console.log("preview generated", res);
            if (res.data.status === 0) {
              this.isLinkPreviewAvailable = true;
              this.previewData = res.data.result.link_preview;
            } else {
              this.isLinkPreviewAvailable = false;
            }
          })
          .catch((err) => {
            console.log("preview err", err);
            this.openToast(
              this.getStrings(this.strings, "UnableToGeneratePreview")
            );
            this.isLinkPreviewAvailable = false;
          });
      } else {
        //console.log("not a URL Link");
        this.clearLinkPreviewPostData();
      }
    },

    //async detectActions(ev) {
    async detectActions() {
      this.showLinkPreview();
    },

    sanitize(str) {
      var str1 = str.trim();
      str1 = str1.replaceAll('"', '\\"');
      str1 = str1.replaceAll("\n", "\\n");
      str1 = str1.replaceAll("\r", "\\r");
      str1 = encodeURIComponent(str1);

      return str1;
    },

    onCloseReplyClicked() {
      this.$emit("close-reply-box");
    },

    getReplyWindowContent() {
      if (undefined !== this.replyContent) {
        if (undefined !== this.replyContent.content) {
          if (undefined != this.replyContent.content.sender_name) {
            const sender = this.replyContent.content.sender_name;
            if ("text" === this.replyContent.content.msgtype) {
              const output =
                '<span style="font-size: .8em; color:darkblue;">' +
                sender +
                "</span></br>" +
                this.replyContent.content.data +
                "";

              return output;
            } else {
              const output =
                '<span style="font-size: .8em; color:darkblue;">' +
                sender +
                "</span></br>" +
                this.replyContent.content.msgtype +
                "";

              return output;
            }
          } else {
            if ("text" === this.replyContent.content.msgtype) {
              return "Reply to :" + this.replyContent.content.data;
            }

            return (
              "Reply to " + this.replyContent.content.msgtype + " content."
            );
          }
        }
      }

      return "Replying to message..";
    },

    async deletePhoto() {
      this.deletePhotoClicked = true;
      //console.log("deletePhoto-call",JSON.stringify(this.photoPost),this.photoPost.photo_object_name);
      deleteImage({ name: this.photoPost.photo_object_name })
        .then((res) => {
          console.log("deletePhoto", res);

          if (res.data.status === 0) {
            this.clearMediaPostData();
          } else {
            this.showAlert(
              "Image delete error",
              "Unable to delete image, please try again"
            );
          }

          this.deletePhotoClicked = false;
        })
        .catch((err) => {
          console.log("deletePhoto - err", err);
          this.showAlert(
            "Image delete error",
            "Unable to delete image, please try again"
          );

          this.deletePhotoClicked = false;
        });
    },

    clearMediaPostData() {
      this.photoPost.is_photo_post = false;
      this.photoPost.is_video_post = false;
      this.photoPost.is_audio_post = false;
      this.photoPost.photo_url = "";
      this.photoPost.photo_object_name = "";
    },

    clearLinkPreviewPostData() {
      this.previewData = {};
      this.isLinkPreviewAvailable = false;
    },

    setTextBoxFocus() {
      const thisObj = document.getElementById("taInputBox");
      if (thisObj) thisObj.setFocus();
    },

    async openToast(msg) {
      const toast = await toastController.create({
        message: msg,
        duration: 5000,
      });
      return toast.present();
    },
  },
};
</script>