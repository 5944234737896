
<template>
  <ion-list class="ion-no-padding ion-no-margin" style="padding-top: 10px;"> 
      <conversation-list-item v-for="conversation in conversationList" v-bind:key="conversation.id" :conversation="conversation">
      </conversation-list-item>
  </ion-list>
</template>

<script>
import { IonList } from "@ionic/vue";
import { useRouter } from "vue-router";

import ConversationListItem from "./ConversationListItem.vue"

export default {
  name: "ConversationList",
  
  props: ["conversationList"],

  components: {
    IonList,
    ConversationListItem,
  },

  setup() {
    const router = useRouter();
    return {
      router,
    };
  },
  
  data() {
    return {
      icon: {},
    };
  },
  
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  
  methods: {
    init() {},
  },
};
</script>