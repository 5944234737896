<template>
  <ion-item
    lines="none"
    no-lines
    no-padding
    no-margin
    class="ion-padding-horizontal ion-no-margin"
  >
    <ion-label
      ><small>{{ localField.display_text }}</small></ion-label
    >
    <ion-chip
      size="samll"
      @click="showVisibility"
      :disabled="!localField.inEditMode"
    >
      <ion-label size="samll"
        ><small>{{
          localField.visibility ? localField.visibility : "Only Me"
        }}</small></ion-label
      >
    </ion-chip>
    <ion-button
      slot="end"
      fill="clear"
      size="small"
      v-if="!localField.inEditMode"
      @click="editField"
      shape="round"
    >
      Edit
    </ion-button>
    <ion-button
      slot="end"
      fill="clear"
      color="success"
      size="small"
      v-if="localField.inEditMode"
      @click="saveField"
      shape="round"
    >
      Save
    </ion-button>
  </ion-item>

  <ion-item
    v-if="(field.type === 'text' || field.type === 'number') && !field.ui_type"
    lines="none"
    class="ion-padding-horizontal ion-no-margin"
  >
    <ion-input
      :placeholder="field.field_description"
      v-model="localField.value"
      :type="field.type"
      :disabled="!field.inEditMode"
    ></ion-input>
  </ion-item>

  <ion-item
    v-if="field.type === 'text' && field.ui_type === 'gender'"
    lines="none"
    no-lines
    class="ion-padding-horizontal ion-no-margin"
  >
    <ion-select
      :placeholder="field.field_description"
      multiple="false"
      interface="popover"
      v-model="localField.value"
      value="Undisclosed"
      :disabled="!field.inEditMode"
    >
      <ion-select-option
        class="ion-padding-horizontal ion-no-margin"
        value="Male"
        >Male</ion-select-option
      >
      <ion-select-option
        class="ion-padding-horizontal ion-no-margin"
        value="Female"
        >Female</ion-select-option
      >
      <ion-select-option
        class="ion-padding-horizontal ion-no-margin"
        value="Undisclosed"
        >Undisclosed</ion-select-option
      >
    </ion-select>
  </ion-item>

  <ion-item
    v-if="field.type === 'text' && field.ui_type === 'birthyear'"
    lines="none"
    no-lines
    class="ion-padding-horizontal ion-no-margin"
  >
    <ion-label size="large" position="floating">{{
      field.field_description
    }}</ion-label>
    <ion-datetime
      :disabled="!field.inEditMode"
      display-format="YYYY"
      v-model="localField.value"
    ></ion-datetime>
  </ion-item>

  <ion-item
    v-if="field.type === 'text' && field.ui_type === 'birthdate'"
    lines="none"
    no-lines
    class="ion-padding-horizontal ion-no-margin"
  >
    <ion-label size="large" position="floating">{{
      field.field_description
    }}</ion-label>
    <ion-datetime
      :disabled="!field.inEditMode"
      displayFormat="DD MMMM"
      pickerFormat="DD MMMM"
      v-model="localField.value"
    ></ion-datetime>
  </ion-item>

  <ion-item
    v-if="field.type === 'text' && field.ui_type === 'country_list'"
    lines="none"
    class="ion-padding-horizontal ion-no-margin"
  >
    <ion-select
      :placeholder="field.field_description"
      v-model="localField.value"
      :disabled="!field.inEditMode"
    >
      <ion-select-option
        v-for="country in countryNames"
        v-bind:key="country.code"
        :value="country.name"
        >{{ country.name }}</ion-select-option
      >
    </ion-select>
  </ion-item>

  <ion-item
    v-if="field.type === 'text' && field.ui_type === 'relationship_status_list'"
    lines="none"
    no-lines
    class="ion-padding-horizontal ion-no-margin"
  >
    <ion-select
      :placeholder="field.field_description"
      multiple="false"
      interface="popover"
      v-model="localField.value"
      value="Undisclosed"
      :disabled="!field.inEditMode"
    >
      <ion-select-option
        class="ion-padding-horizontal ion-no-margin"
        value="Single"
        >Single</ion-select-option
      >
      <ion-select-option
        class="ion-padding-horizontal ion-no-margin"
        value="Engaged"
        >Engaged</ion-select-option
      >
      <ion-select-option
        class="ion-padding-horizontal ion-no-margin"
        value="Married"
        >Married</ion-select-option
      >
      <ion-select-option
        class="ion-padding-horizontal ion-no-margin"
        value="Separated"
        >Separated</ion-select-option
      >
      <ion-select-option
        class="ion-padding-horizontal ion-no-margin"
        value="Divorced"
        >Divorced</ion-select-option
      >
      <ion-select-option
        class="ion-padding-horizontal ion-no-margin"
        value="Undisclosed"
        >Undisclosed</ion-select-option
      >
    </ion-select>
  </ion-item>

  <ion-item
    v-if="field.type === 'text' && field.ui_type === 'blood_group_list'"
    lines="none"
    no-lines
    class="ion-padding-horizontal ion-no-margin"
  >
    <ion-select
      :placeholder="field.field_description"
      multiple="false"
      interface="popover"
      v-model="localField.value"
      value="Unknown"
      :disabled="!field.inEditMode"
      lines="none"
      no-lines
      class="ion-padding-horizontal ion-no-margin"
    >
      <ion-select-option
        class="ion-padding-horizontal ion-no-margin"
        value="apositive"
        >A +ve</ion-select-option
      >
      <ion-select-option
        class="ion-padding-horizontal ion-no-margin"
        value="bpositive"
        >B +ve</ion-select-option
      >
      <ion-select-option
        class="ion-padding-horizontal ion-no-margin"
        value="abpositive"
        >AB +ve</ion-select-option
      >
      <ion-select-option
        class="ion-padding-horizontal ion-no-margin"
        value="opositive"
        >O +ve</ion-select-option
      >
      <ion-select-option
        class="ion-padding-horizontal ion-no-margin"
        value="apositive"
        >A -ve</ion-select-option
      >
      <ion-select-option
        class="ion-padding-horizontal ion-no-margin"
        value="bpositive"
        >B -ve</ion-select-option
      >
      <ion-select-option
        class="ion-padding-horizontal ion-no-margin"
        value="abpositive"
        >AB -ve</ion-select-option
      >
      <ion-select-option
        class="ion-padding-horizontal ion-no-margin"
        value="opositive"
        >O -ve</ion-select-option
      >
      <ion-select-option
        class="ion-padding-horizontal ion-no-margin"
        value="unknown"
        >Unknown</ion-select-option
      >
    </ion-select>
  </ion-item>

  <ion-item
    v-if="field.type === 'json' && field.ui_type === 'multirow'"
    lines="none"
    no-lines
    class="ion-no-padding ion-no-margin"
  >
    <table class="profile-table" style="width: 100%">
      <tr class="profile-table">
        <th
          class="profile-table"
          v-for="header in field.default.header"
          v-bind:key="header.name"
        >
          {{ header.name }}
        </th>
      </tr>
      <tr
        v-for="(row, rowIdx) in field.value.rows"
        v-bind:key="rowIdx"
        class="profile-table"
      >
        <td
          v-for="(col, colIdx) in row"
          v-bind:key="colIdx"
          class="profile-table"
        >
          <ion-item class="ion-no-padding ion-no-margin">
            <ion-input
              v-if="field.default.header[colIdx].type === 'text'"
              :disabled="!field.inEditMode"
              v-model="localField.value.rows[rowIdx][colIdx]"
            >
            </ion-input>
            <ion-datetime
              v-if="field.default.header[colIdx].type === 'year'"
              :disabled="!field.inEditMode"
              display-format="YYYY"
              v-model="localField.value.rows[rowIdx][colIdx]"
            ></ion-datetime>
          </ion-item>
        </td>
      </tr>
    </table>
  </ion-item>

  <ion-item
    v-if="field.type === 'json' && field.ui_type === 'interest_list'"
    lines="none"
    no-lines
    class="ion-padding-horizontal ion-no-margin"
  >
    <ion-label size="large" position="floating">{{
      field.field_description
    }}</ion-label>
    <ion-select
      multiple="true"
      v-model="localField.value"
      :disabled="!field.inEditMode"
    >
      <ion-select-option
        v-for="topic in topicList"
        v-bind:key="topic.topic_name"
        :value="topic.topic_name"
        >{{ topic.topic_name }}</ion-select-option
      >
    </ion-select>
  </ion-item>

  <ion-item
    v-if="field.type === 'text' && field.ui_type === 'language_select'"
    lines="none"
    no-lines
    class="ion-padding-horizontal ion-no-margin"
    :disabled="!field.inEditMode"
  >
    <language-selection :changeLangCallback="langugeSelectionChanged" :callbackRef="field" ></language-selection>
  </ion-item>
</template>

<script>
import {
  IonButton,
  IonItem,
  IonInput,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonChip,
  IonDatetime,
  popoverController,
} from "@ionic/vue";

import FieldVisibilityOptions from "./FieldVisibilityOptions.vue";
import { updateUserProfileField, topicList } from "../../services/apiCall.js";
import { countryList } from "../../services/countries.js";

import LanguageSelection from "./LanguageSelection.vue";

export default {
  name: "GenericProfileField",
  props: ["field", "section"],
  components: {
    IonButton,
    IonItem,
    IonInput,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonDatetime,
    IonChip,
    LanguageSelection,
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  mounted() {
    this.getTopicList();
  },
  data() {
    return {
      localField: this.field,
      countryNames: this.countryList(),
      topicList: {},
    };
  },
  setup() {
    return {
      updateUserProfileField,
      countryList,
    };
  },
  methods: {
    async showVisibility(ev) {
      const popover = await popoverController.create({
        component: FieldVisibilityOptions,
        event: ev,
        translucent: true,
      });
      popover.componentProps = { field: this.localField, parent: popover };
      return popover.present();
    },
    saveField() {
      this.localField.inEditMode = false;
      var localSection = JSON.parse(JSON.stringify(this.section));
      this.localField.lastEdited = new Date();
      localSection.fields = [this.localField];
      updateUserProfileField({ section_data: localSection })
        .then((res) => {
          console.log("updateUserProfileField", res);
        })
        .catch((err) => {
          console.log("updateUserProfileField", err);
        });
    },
    editField() {
      this.localField.inEditMode = true;
    },
    getTopicList() {
      topicList()
        .then((res) => {
          if (res.data.status === 0) {
            this.topicList = res.data.result.topic_list;
          }
        })
        .catch((err) => {
          console.log("topicList - err", err);
        });
    },
    langugeSelectionChanged(field, value) {
      field.value = value;
    },
  },
};
</script>