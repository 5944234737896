<template>
  <ion-item lines="none" :color="chatItem.isSelected ? 'medium' : ''">
    <ion-text :slot="isSentByMe(chatItem) ? 'end' : 'start'">
      <div
        class="box_chat box_other received"
        :id="`ci_${chatItem.id}`"
        v-show="!isSentByMe(chatItem) && isDisplayRequired(chatItem)"
      >
        <div v-show="isDeleted(chatItem)">
          <span
            class="
              item-text-wrap
              ion-text-wrap
              dont-break-out
              line_hight_one_point_four
            "
            v-show="chatItem.content.data !== undefined"
            ><i>(Message deleted)</i><br
          /></span>
        </div>
        <div v-show="!isDeleted(chatItem)">
          <ion-item
            v-show="isSenderNameAvailable(chatItem)"
            class="box_other_sender"
            lines="none"
          >
            {{ getFormattedUserName(chatItem.content.sender_name) }}
          </ion-item>

          <div
            align="right"
            v-show="ifForwarded(chatItem)"
            class="box_chat_forwarded_other"
            lines="none"
          >
            <ion-icon
              size="small"
              slot="icon-only"
              :icon="icon.arrowRedoOutline"
              style="
            vertical-align: middle;
            display: inline-block;appendReceivedChat
            margin-left: 5px;
          "
            ></ion-icon>
            <ion-text style="vertical-align: middle; display: inline-block">
              &nbsp;Forwarded
            </ion-text>
          </div>

          <ion-item
            v-show="chatItem.reply_to !== 0"
            style="
              min-width: 40px;
              min-height: 40px;
              cursor: pointer;
              border-radius: 10px;
            "
            class="box_other_reply"
            lines="none"
          >
            <span
              class="
                box_other_reply
                item-text-wrap
                ion-text-wrap
                dont-break-out
                line_hight_one_point_four
              "
              style="margin: 6px"
              :innerHTML="getReplyPreview(chatItem)"
            >
            </span>
          </ion-item>

          <div
            v-if="isMediaDisplayRequired(chatItem)"
            :innerHTML="getMediaDisplayHTML(chatItem)"
            @click="toggleBackdrop(chatItem)"
          ></div>
          <div v-if="isLinkPreviewAvailable">
            <ion-col class="ion-no-margin ion-no-padding" :size="12">
              <ion-item
                no-lines
                lines="none"
                class="
                  ion-no-padding
                  ion-no-margin
                  ion-align-self-center
                  ion-justify-content-center
                  ion-text-wrap
                  word-wrap:
                  break-word;
                  word-break;
                "
              >
                <a
                  target="_nextExternalLink"
                  style="text-decoration: none"
                  :href="previewData.url"
                >
                  <ion-card
                    class="
                      ion-padding-vertical-photo
                      ion-justify-content-center
                      ion-no-margin
                      ion-align-self-center
                      ion-text-wrap
                      full_width
                    "
                  >
                    <span style="width: 100%" class="animate-fading">
                      <ion-img
                        width="100%"
                        alt=".  .(No Image Available).  ."
                        :src="previewData.images[0]"
                        class="animate-fading"
                      ></ion-img>
                    </span>

                    <ion-card-header
                      class="
                        ion-padding-vertical-photo
                        ion-padding-horizontal
                        ion-no-margin
                      "
                    >
                      <ion-card-subtitle class="ion-no-margin">
                        <a target="_nextExternalLink" :href="previewData.url"
                          >{{ previewData.siteName }}
                        </a></ion-card-subtitle
                      ><ion-card-title class="ion-no-margin">
                        {{ previewData.title }}
                      </ion-card-title></ion-card-header
                    ><ion-card-content
                      class="
                        ion-padding-horizontal ion-no-margin
                        card_content_custom
                      "
                    >
                      <div class="ion-no-margin">
                        {{ previewData.description }}
                      </div>
                    </ion-card-content></ion-card
                  >
                </a>
              </ion-item>
            </ion-col>
          </div>
          <span
            class="
              item-text-wrap
              ion-text-wrap
              dont-break-out
              line_hight_one_point_four
            "
            v-show="chatItem.content.data !== undefined"
            >{{ chatItem.content.data }}<br
          /></span>
          <ion-text color="dark">
            <small>{{ displayChatTime }}</small>
          </ion-text>
        </div>
      </div>

      <div
        class="box_chat box_user sent"
        :id="`ci_${chatItem.id}`"
        v-show="isSentByMe(chatItem) && isDisplayRequired(chatItem)"
      >
        <div v-show="isDeleted(chatItem)">
          <span v-if="chatItem.content.data"
            ><i>(Message deleted)</i><br
          /></span>
        </div>
        <div v-show="!isDeleted(chatItem)">
          <div
            align="right"
            v-show="ifForwarded(chatItem)"
            class="box_chat_forwarded"
            lines="none"
          >
            <ion-icon
              size="small"
              slot="icon-only"
              :icon="icon.arrowRedoOutline"
              style="
            vertical-align: middle;
            display: inline-block;appendReceivedChat
            margin-left: 5px;
          "
            ></ion-icon>
            <ion-text style="vertical-align: middle; display: inline-block">
              &nbsp;Forwarded
            </ion-text>
          </div>

          <ion-item
            v-show="chatItem.reply_to !== 0"
            style="
              min-width: 40px;
              min-height: 40px;
              cursor: pointer;
              border-radius: 10px;
            "
            class="box_user_reply"
            lines="none"
          >
            <span
              class="
                box_user_reply
                item-text-wrap
                ion-text-wrap
                dont-break-out
                line_hight_one_point_four
              "
              style="margin: 6px"
              :innerHTML="getReplyPreview(chatItem)"
            >
            </span>
          </ion-item>

          <div
            v-show="isMediaDisplayRequired(chatItem)"
            :innerHTML="getMediaDisplayHTML(chatItem)"
            @click="toggleBackdrop(chatItem)"
          ></div>

          <div v-if="isLinkPreviewAvailable">
            <ion-col class="ion-no-margin ion-no-padding" :size="12">
              <ion-item
                no-lines
                lines="none"
                class="
                  ion-no-padding
                  ion-no-margin
                  ion-align-self-center
                  ion-justify-content-center
                  ion-text-wrap
                  word-wrap:
                  break-word;
                  word-break;
                "
              >
                <a
                  target="_nextExternalLink"
                  style="text-decoration: none"
                  :href="previewData.url"
                >
                  <ion-card
                    class="
                      ion-padding-vertical-photo
                      ion-justify-content-center
                      ion-no-margin
                      ion-align-self-center
                      ion-text-wrap
                      full_width
                    "
                  >
                    <span style="width: 100%" class="animate-fading">
                      <ion-img
                        width="100%"
                        alt=".  .(No Image Available).  ."
                        :src="previewData.images[0]"
                        class="animate-fading"
                      ></ion-img>
                    </span>

                    <ion-card-header
                      class="
                        ion-padding-vertical-photo
                        ion-padding-horizontal
                        ion-no-margin
                      "
                    >
                      <ion-card-subtitle class="ion-no-margin">
                        <a target="_nextExternalLink" :href="previewData.url"
                          >{{ previewData.siteName }}
                        </a></ion-card-subtitle
                      ><ion-card-title class="ion-no-margin">
                        {{ previewData.title }}
                      </ion-card-title></ion-card-header
                    ><ion-card-content
                      class="
                        ion-padding-horizontal ion-no-margin
                        card_content_custom
                      "
                    >
                      <div class="ion-no-margin">
                        {{ previewData.description }}
                      </div>
                    </ion-card-content></ion-card
                  >
                </a>
              </ion-item>
            </ion-col>
          </div>

          <span v-show="chatItem.content.data !== undefined"
            >{{ chatItem.content.data }}<br
          /></span>

          <div align="right">
            <ion-text style="vertical-align: middle; display: inline-block">
              <small>{{ displayChatTime }}</small>
            </ion-text>

            <ion-icon
              v-show="chatItem.status === 'new'"
              size="small"
              slot="icon-only"
              :icon="icon.checkmark"
              style="
            vertical-align: middle;
            display: inline-block;appendReceivedChat
            margin-left: 5px;
          "
            ></ion-icon>

            <ion-icon
              v-show="chatItem.status === 'sent'"
              size="small"
              slot="icon-only"
              src="assets/icon/misc/checkmark-done-outline.svg"
              style="
                vertical-align: middle;
                display: inline-block;
                margin-left: 5px;
              "
            ></ion-icon>

            <ion-icon
              v-show="chatItem.status === 'read'"
              size="small"
              slot="icon-only"
              src="assets/icon/misc/checkmark-done-outline.svg"
              style="
                vertical-align: middle;
                display: inline-block;
                margin-left: 5px;
                color: DodgerBlue;
              "
            ></ion-icon>
          </div>
        </div>
      </div>
    </ion-text>
  </ion-item>
</template>

<script>
import {
  IonItem,
  IonIcon,
  IonText,
  createGesture,
  modalController,
  IonImg,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
} from "@ionic/vue";

import {
  preFormat,
  getColSize,
  getOffsetSize,
  isMobileScreen,
  formatChatTime,
  extracURLs,
} from "../../services/utils";

import {
  personCircle,
  callOutline,
  checkmark,
  checkmarkDone,
  arrowRedoOutline,
} from "ionicons/icons";

import {
  fetchGroupChat,
  fetchChat,
  generateLinkPreview,
} from "../../services/apiCall";
import ChatPostImageModal from "./media/ChatPostImageModal.vue";

import { Plugins } from "@capacitor/core";
const { Haptics } = Plugins;

export default {
  name: "ChatItemView",
  emits: ["reply-chat", "forward-chat", "message-read", "chat-item-selected"],
  props: ["chatItem", "curUser", "friendToken"],

  ionViewDidEnter() {
    // console.log(" Item did enter", this.chatItem.id);
  },
  ionViewDidLeave() {
    // console.log(" Item did leave");
  },
  ionViewWillEnter() {
    //c onsole.log(" Item will enter",this.chatItem.id);
  },
  ionViewWillLeave() {
    // console.log(" Item will leave - clearing Intervals");
    if (this.updateTimeCallback !== null)
      clearInterval(this.updateTimeCallback);
  },
  beforeUnmount() {
    if (this.updateTimeCallback !== null)
      clearInterval(this.updateTimeCallback);
  },
  unmounted() {
    //c onsole.log(" Item unmounted  -- ", this.chatItem.id);
  },
  mounted() {
    // console.log(" Item mounted", this.chatItem.id);
    this.init();
  },
  activated() {
    // console.log("Item activated", this.chatItem.id);
  },
  components: {
    IonItem,
    IonIcon,
    IonText,
    IonImg,
    IonCol,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
  },
  setup() {
    return {
      isMobileScreen,
      getColSize,
      getOffsetSize,
      formatChatTime,
      extracURLs,
      generateLinkPreview,
    };
  },
  data() {
    return {
      icon: {
        personCircle,
        callOutline,
        checkmark,
        checkmarkDone,
        arrowRedoOutline,
      },

      reply_data: [],

      timeMouseDown: 0,
      timeMouseUp: 0,

      displayChatTime: "",

      previewData: {},
      isLinkPreviewAvailable: false,

      updateTimeCallback: null,

      longPressActive: false,
    };
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      return this.$store.getters.user;
    },
  },

  methods: {
    init() {
      if (!this.isDisplayRequired(this.chatItem)) return;

      this.updateChatStatus();

      this.displayChatTime = formatChatTime(this.chatItem.createdAt);

      const idtext = "ci_" + this.chatItem.id;
      this.addGestures(idtext);

      if (undefined !== this.chatItem.reply_info) {
        this.reply_data = this.chatItem.reply_info;
        //console.log("Reply information is already present");
      }

      if (this.chatItem.reply_to !== 0) {
        this.fetchReplyData(this.chatItem.reply_to);
      }

      this.showLinkPreview();

      this.updateTimeCallback = setInterval(this.updateChatTime, 10000);
    },

    async showLinkPreview() {
      // console.log("showLinkPreview", this.enteredDescription);
      let urlList = extracURLs(this.chatItem.content.data);

      if (urlList.length > 0) {
        if (this.previewData.url === urlList[0]) {
          return; // the same URL
        }

        generateLinkPreview({ url: urlList[0] })
          .then((res) => {
            //console.log("preview generated", res);
            if (res.data.status === 0) {
              this.isLinkPreviewAvailable = true;
              this.previewData = res.data.result.link_preview;
            } else {
              this.isLinkPreviewAvailable = false;
            }
          })
          .catch((err) => {
            console.log("preview err", err);
            this.openToast(
              this.getStrings(this.strings, "UnableToGeneratePreview")
            );
            this.isLinkPreviewAvailable = false;
          });
      } else {
        //console.log("not a URL Link");
        this.clearLinkPreviewPostData();
      }
    },

    updateChatStatus() {
      //console.log("UPDATe STATUS CALLED for : ", this.chatItem.id+ "["+this.chatItem.status+"]");
      if (this.curUser.user_id != this.chatItem.from_user_id) {
        if (this.chatItem.status !== "read") {
          this.$emit("message-read", this.chatItem);
        }
      }
    },

    onLongPresessSelected() {
      if (!this.chatItem.deleted) {
        this.$emit("chat-item-selected", this.chatItem);

        Haptics.vibrate();
        //console.log("On Item Selected - emited signal");
      }
    },

    fetchReplyData(curChatID) {
      //TODO : check reply data is already available or not
      if (undefined !== this.chatItem.reply_info) {
        if (undefined !== this.chatItem.reply_info.reply_content) {
          return;
        }
      }

      if (this.chatItem.group_id) {
        fetchGroupChat({ message_id: curChatID })
          .then((res) => {
            if (res.data.status === 0) {
              let chatResult = res.data.result.chat_message;
              let reply_info = {
                reply_id: curChatID,
                reply_content: chatResult.content,
              };

              //console.log("Fetch Reply[",curChatID,"]:",JSON.stringify(chatResult));
              this.reply_data = reply_info;
            }
          })
          .catch((err) => {
            console.log("Chat message - err", err);
          });
      } else {
        fetchChat({ message_id: curChatID })
          .then((res) => {
            if (res.data.status === 0) {
              let chatResult = res.data.result.chat_message;
              let reply_info = {
                reply_id: curChatID,
                reply_content: chatResult.content,
              };

              //console.log("Fetch Reply[",curChatID,"]:",JSON.stringify(chatResult));
              this.reply_data = reply_info;
            }
          })
          .catch((err) => {
            console.log("Chat message - err", err);
          });
      }
    },

    addGestures(elementid) {
      const thisPage = document.getElementById(elementid);
      //console.log("Adding geesture to : ", thisPage);
      let startSpan = 0;
      let endSpan = 0;

      const slideTab = (direction) => {
        console.log("slideTab", direction);

        if (direction === "right") {
          console.log("moved to right", JSON.stringify(this.chatItem));
          this.$emit("reply-chat", this.chatItem);
        } else if (direction === "left") {
          console.log("moved to left", JSON.stringify(this.chatItem));
          this.$emit("reply-chat", this.chatItem);
        }
      };

      const gesture = createGesture({
        el: thisPage,
        gestureName: "swipe",
        onStart: (detail) => {
          if (detail.type === "pan") {
            startSpan = detail.currentX;
            endSpan = 0;
          }
        },
        onEnd: (detail) => {
          console.log(JSON.stringify(detail));
          if (detail.type === "pan") {
            endSpan = detail.currentX;

            if (startSpan - endSpan > 15) {
              //console.log("Left swipe");
              slideTab("left");
            }
            if (endSpan - startSpan > 15) {
              //console.log("Right swipe");
              slideTab("right");
            }
          }
        },
      });

      gesture.enable(true);

      const longPress = createGesture(
        {
          el: thisPage,
          threshold: 0,
          gestureName: "long-press",
          onStart: (ev) => {
            this.longPressActive = true;
            this.timeMouseDown = ev.currentTime;
            // this.increasePower();
            // console.log(" START :", JSON.stringify(ev));
          },
          onEnd: (ev) => {
            this.longPressActive = false;
            this.timeMouseUp = ev.currentTime;

            // console.log(" END :", JSON.stringify(ev));
            // console.log(this.timeMouseUp+" - "+this.timeMouseDown+" = "+(this.timeMouseUp - this.timeMouseDown));
            if (this.timeMouseUp - this.timeMouseDown > 1000) {
              console.log("Long press detected");
              this.onLongPresessSelected();
            }
          },
        },
        true
      ); // Passing true will run the gesture callback inside of NgZone!

      // https://ionicframework.com/blog/building-interactive-ionic-apps-with-gestures-and-animations/
      longPress.enable(true);
    },

    async toggleBackdrop(chatItem) {
      try {
        if (chatItem.content.msgtype === "video_post") return; // not for video posts at the moment

        var imgSrc = chatItem.content.media_path;
        // console.log("imagepreview", imgSrc);

        const modal = await modalController.create({
          component: ChatPostImageModal,
          mode: "md",
        });
        modal.componentProps = {
          title: "Media Display",
          imgSrc: imgSrc,
          parent: modal,
        };
        return modal.present();
      } catch (err) {
        console.log("preview err", err);
      }
    },

    isMediaDisplayRequired(chtItem) {
      if (chtItem.content) {
        if ("text" !== chtItem.content.msgtype) {
          return true;
        }
      }

      return false;
    },

    getMediaDisplayHTML(chtItem) {
      var urlRegex = /(https?:\/\/[^\s]+)/g;
      if (chtItem.content.media_path) {
        if (chtItem.content.msgtype === "photo_post") {
          var text = chtItem.content.media_path;
          return preFormat(
            text.replace(urlRegex, function (url) {
              return '<img src="' + url + '" /> ';
            })
          );
        } else if (chtItem.content.msgtype === "video_post") {
          var text1 = chtItem.content.media_path;
          return preFormat(
            text1.replace(urlRegex, function (url) {
              return (
                '<video class="video_player" controls autoplay muted>' +
                '<source src="' +
                url +
                '" type="video/mp4" />' +
                "Your browser does not support the video tag." +
                "</video>"
              );
            })
          );
        } else if (chtItem.content.msgtype === "audio_post") {
          var text2 = chtItem.content.media_path;
          return preFormat(
            text2.replace(urlRegex, function (url) {
              return (
                '<audio controls style="display:block; width:250px;">' +
                '<source src="' +
                url +
                '" type="audio/ogg" />' +
                "Your browser does not support the audio element." +
                "</audio>"
              );
            })
          );
        }
      }

      return "";
    },

    getReplyPreview() {
      if (undefined !== this.reply_data) {
        if (undefined != this.reply_data.reply_content) {
          if (undefined != this.reply_data.reply_content.sender_name) {
            const sender = this.reply_data.reply_content.sender_name;
            if ("text" === this.reply_data.reply_content.msgtype) {
              const output =
                '<span style="font-size: .8em; color:darkblue;">' +
                sender +
                "</span></br>" +
                this.reply_data.reply_content.data +
                "";

              return output;
            } else {
              const output =
                '<span style="font-size: .8em; color:darkblue;">' +
                sender +
                "</span></br>" +
                this.reply_data.reply_content.msgtype +
                "";

              return output;
            }
          } else {
            if (undefined != this.reply_data.reply_content.data)
              return this.reply_data.reply_content.data;
          }
        }
      }

      return "reply content not available";
    },

    getChatTime(timeval) {
      if (timeval == 0) return "..";
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      var dateObj = new Date(timeval);
      var hours = dateObj.getHours();
      var minutesstr =
        dateObj.getMinutes() < 10
          ? "0" + dateObj.getMinutes()
          : dateObj.getMinutes();
      var hoursstr = hours < 10 ? "0" + hours : hours;

      var newDateFormat =
        dateObj.getDate() +
        " " +
        months[dateObj.getMonth()] +
        ", " +
        hoursstr +
        ":" +
        minutesstr;
      return newDateFormat;
    },

    clearLinkPreviewPostData() {
      this.previewData = {};
      this.isLinkPreviewAvailable = false;
    },

    isSentByMe(curChatItem) {
      if (curChatItem.group_id) {
        return curChatItem.from_user == this.curUser.user_id ? true : false;
      } else {
        return curChatItem.from_user_id == this.curUser.user_id ? true : false;
      }
    },

    isDeleted(curChatItem) {
      if (curChatItem.group_id) {
        if (curChatItem.deleted) return true;

        const curChatStatus = curChatItem.chat_status;
        if (curChatStatus) {
          let deleted = false;
          curChatStatus.forEach((objSts) => {
            if (objSts.user_id === this.curUser.user_id) {
              if ("deleted" === objSts.status) deleted = true;
            }
          });

          return deleted;
        }

        return false;
      } else {
        if (curChatItem.deleted) return curChatItem.deleted;

        const curStatus = curChatItem.status;
        if (this.curUser.user_id === curChatItem.from_user_id) {
          if (curStatus === "sender_purged") return true;
        } else {
          if (curStatus === "receiver_purged") return true;
        }
      }
    },

    isDisplayRequired(curChatItem) {
      //If msg is deleted and more time lag then do not show
      const HIDE_DELETED_CHAT_AFTER_MINUTES = 10;

      if (curChatItem.group_id) {
        let deleted = false;
        if (curChatItem.deleted) deleted = true;

        const curChatStatus = curChatItem.chat_status;
        if (curChatStatus) {
          curChatStatus.forEach((objSts) => {
            if (objSts.user_id === this.curUser.user_id) {
              if ("deleted" === objSts.status) deleted = true;
            }
          });
        }
        if (deleted) {
          const lastSeen = new Date(curChatItem.updatedAt).getTime();
          const currentTime = new Date().getTime();
          const diffMinutes = (currentTime - lastSeen) / (60 * 1000);
          if (diffMinutes > HIDE_DELETED_CHAT_AFTER_MINUTES) {
            return false;
          }
        }

        return true;
      } else {
        if (curChatItem.deleted) {
          const lastSeen = new Date(curChatItem.updatedAt).getTime();
          const currentTime = new Date().getTime();
          const diffMinutes = (currentTime - lastSeen) / (60 * 1000);
          if (diffMinutes > HIDE_DELETED_CHAT_AFTER_MINUTES) {
            return false;
          }
        } else return true;
      }

      return true;
    },

    isSenderNameAvailable(curChatItem) {
      if (curChatItem.group_id) {
        if (curChatItem.content.sender_name) {
          return true;
        } else return false;
      } else {
        return false;
      }
    },

    ifForwarded(chatItem) {
      if (chatItem.forward_id) {
        if (chatItem.forward_id > 0) return true;
      }
      return false;
    },

    getFormattedUserName(usrName) {
      if(! usrName ) return "";
      
      if (usrName.length > 18) usrName = usrName.substring(0, 18) + "...";

      return usrName;
    },

    updateChatTime() {
      this.displayChatTime = formatChatTime(this.chatItem.createdAt);

      const lastSeen = new Date(this.chatItem.createdAt).getTime();
      const currentTime = new Date().getTime();
      const diffTime = currentTime - lastSeen;

      const diffMinutes = diffTime / (60 * 1000);
      if (diffMinutes > 60) {
        // Not required to update so frequently
        if (this.updateTimeCallback !== null)
          clearInterval(this.updateTimeCallback);
      }
    },
  }, //END - Methods
};
</script>