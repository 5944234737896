<template>
  <ion-item no-lines lines="none"  class="signup-item-style">
    <ion-item v-if="passwordRuleError" no-lines lines="none" s>
      <ion-text>
        <ul style="margin-bottom: 0px;">
          <li>
            <small v-if="passwordRules.minEightChars"
              ><ion-icon
                slot="start"
                color="success"
                :icon="checkmarkDoneOutline"
              ></ion-icon
              >&nbsp;{{ getStrings(strings, "Minimum8Characters") }}</small
            >
            <small v-if="!passwordRules.minEightChars"
              ><ion-icon
                slot="start"
                color="danger"
                :icon="closeOutline"
              ></ion-icon
              >&nbsp;{{ getStrings(strings, "Minimum8Characters") }}</small
            >
          </li>
          <li>
            <small v-if="passwordRules.minOneAlphabet"
              ><ion-icon
                slot="start"
                color="success"
                :icon="checkmarkDoneOutline"
              ></ion-icon
              >&nbsp;{{ getStrings(strings, "Minimum1Char") }}</small
            >
            <small v-if="!passwordRules.minOneAlphabet"
              ><ion-icon
                slot="start"
                color="danger"
                :icon="closeOutline"
              ></ion-icon
              >&nbsp;{{ getStrings(strings, "Minimum1Char") }}</small
            >
          </li>
          <li>
            <small v-if="passwordRules.minOneNumeric"
              ><ion-icon
                slot="start"
                color="success"
                :icon="checkmarkDoneOutline"
              ></ion-icon
              >&nbsp;{{ getStrings(strings, "Minimum1NumericCharacter") }}</small
            >
            <small v-if="!passwordRules.minOneNumeric"
              ><ion-icon
                slot="start"
                color="danger"
                :icon="closeOutline"
              ></ion-icon
              >&nbsp;{{ getStrings(strings, "Minimum1NumericCharacter") }}</small
            >
          </li>
        </ul>
      </ion-text>
    </ion-item>
    <ion-label position="floating">{{ getStrings(strings, localPasswordSetting.name) }}</ion-label>
    <ion-input
      type="password"
      v-model="localPasswordSetting.password"
      autocomplete="new-password"
      @ionChange="verifyPasswordRules"
    ></ion-input>  
  </ion-item>
</template>

<script>
import {
  IonItem,
  IonText,
  IonIcon,
  IonLabel,
  IonInput,
} from "@ionic/vue";
import { defineComponent } from "vue";

import { checkPasswordRules } from "../../services/utils";
import { getStrings } from "../../services/lang";

import {
  checkmarkDoneOutline,
  closeOutline,
  homeOutline,
} from "ionicons/icons";

export default defineComponent({
  name: "PasswordField",
  props: ["passwordSetting", "verifyCallback"],
  components: { IonItem, IonText, IonIcon, IonLabel, IonInput },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
  },
  setup() {
    return {
      getStrings,
    };
  },
  data() {
    return {
      checkmarkDoneOutline,
      closeOutline,
      homeOutline,
      passwordRuleError: true,
      passwordRules: {
        minEightChars: false,
        minOneAlphabet: false,
        minOneNumeric: false,
      },
      localPasswordSetting: this.passwordSetting
    };
  },
  methods: {
    verifyPasswordRules() {
      // console.log('verifyPasswordRules', this.passwordSetting.password);
      var passRules = checkPasswordRules(this.passwordSetting.password);

      this.passwordRules.minEightChars = passRules.minEightChars;
      this.passwordRules.minOneAlphabet = passRules.minOneAlphabet;
      this.passwordRules.minOneNumeric = passRules.minOneNumeric;

      this.passwordRuleError = !(
        this.passwordRules.minEightChars &&
        this.passwordRules.minOneAlphabet &&
        this.passwordRules.minOneNumeric
      );

      this.localPasswordSetting.is_valid = !this.passwordRuleError;

      if (typeof this.verifyCallback !== "undefined") {
        this.verifyCallback();
      }
    },
  },
});
</script>
