<template>
  <ion-content fullscreen="true">
    <ion-slides :options="slideOpts" style="height: 100%" v-if="imgSrc.length > 0">
      <ion-slide style="height: 100%">
        <div class="swiper-zoom-container" style="height: 100%">
          <img :src="imgSrc" />
        </div>
      </ion-slide>
    </ion-slides>
    <ion-slides :options="slideOpts" ref="photoSlide" style="height: 100%" v-if="albumImages && albumImages.length > 0" @ionSlideDidChange="onSlideChanged">
      <ion-slide style="height: 100%" v-for="img in albumImages" :key="img">
        <div class="swiper-zoom-container" style="height: 100%">
          <img :src="img" />
        </div>
        <ion-button 
          shape="round"
          fill="clear"
          v-if="!hidePrevBtn && albumImages.length > 1"
          @click="prevSlide()"
          style="position: absolute; left: 0; margin-left: 10px;"
          class="ion-no-padding ion-no-margin"
        >
          <ion-icon slot="icon-only" style="background-color: rgba(0,0,0,0.2); color: white;" size="large" :icon="chevronBackOutline"></ion-icon>
        </ion-button>
        <ion-button
          shape="round"
          fill="clear"
          v-if="!hideNextBtn && albumImages.length > 1"
          @click="nextSlide()"
          style="position: absolute; right: 0; margin-right: 10px;"
          class="ion-no-padding ion-no-margin"
        >
          <ion-icon slot="icon-only" style="background-color: rgba(0,0,0,0.2); color: white;" size="large" :icon="chevronForwardOutline"></ion-icon>
        </ion-button>
      </ion-slide>
    </ion-slides>
    <ion-button
      shape="round"
      color="light"
      @click="close()"
      class="over-slider image_close_button"
    >
      <ion-icon
        color="dark"
        slot="icon-only"
        size="large"
        :icon="closeOutline"
      ></ion-icon>
    </ion-button>
  </ion-content>
</template>

<script>
import { closeOutline, saveOutline, chevronForwardOutline, chevronBackOutline } from "ionicons/icons";
import {
  IonContent,
  IonButton,
  IonSlide,
  IonSlides,
  IonIcon,
} from "@ionic/vue";

import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "PostImageModal",
  props: ["title", "imgSrc", "parent", "albumImages", "currentPhotoIndex"],
  data() {
    return {
      closeOutline,
      saveOutline,
      chevronForwardOutline,
      chevronBackOutline,
    };
  },
  components: {
    IonContent,
    IonButton,
    IonSlide,
    IonSlides,
    IonIcon,
  },
  mounted() {
    if (this.currentPhotoIndex >= 0 && this.currentPhotoIndex < this.albumImages.length) {
      this.slideTo(this.currentPhotoIndex, this.albumImages.length);
    }
  },
  setup() {
    const photoSlide = ref(null);
    const hidePrevBtn = ref(true);
    const hideNextBtn = ref(false);

    const slideOpts = {
      zoom: true,
      initialSlide: 0,
      speed: 400,
      autoHeight: true,
      centeredSlides: true,
      followFinger: false,
      longSwipes: false,
    };

    const slideTo = async (index, totalSlides) => {
      const pslide = await photoSlide?.value?.$el.getSwiper();
      if (index > 0) hidePrevBtn.value = false;
      if (index === totalSlides - 1) hideNextBtn.value = true;
      await pslide.slideTo(index, 0, false);
    }

    const nextSlide = async () => {
      const pslide = await photoSlide?.value?.$el.getSwiper();
      await pslide.slideNext();
    }

    const prevSlide = async () => {
      const pslide = await photoSlide?.value?.$el.getSwiper();
      await pslide.slidePrev();
    }

    const onSlideChanged = async () => {
      const pslide = await photoSlide?.value?.$el.getSwiper();
      hidePrevBtn.value = pslide.activeIndex === 0;
      hideNextBtn.value = pslide.activeIndex === (pslide.slides.length - 1);
    }

    return { slideOpts, photoSlide, nextSlide, prevSlide, onSlideChanged, hidePrevBtn, hideNextBtn, slideTo };
  },
  methods: {
    close() {
      if (typeof this.parent !== "undefined") this.parent.dismiss();
    },
  },
});
</script>
