/** wrapper for all api calls */

import axios from "axios";

import { getUser } from "./db";

const BASE_URL = 'https://susamvad-api.vlifesciences.com';

const API_KEY = 'beaad044301e7e1f6b4b9ede94e975e5';

async function getStdHeader() {
    // console.log('getStdHeader - user');

    return {
        "api_key": API_KEY,
    };
}

async function getHeader() {
    var currentUser = await getUser('currentUser');

    // console.log('getHeader - user', currentUser);

    return {
        "api_key": API_KEY,
        "user_id": currentUser.user_id,
        "auth_token": currentUser.auth.token,
        "session_id": currentUser.auth.session
    };
}

export function getAPIKey() {
    return API_KEY;
}

export function profilePictureURL(query) {
    return BASE_URL + "/api/v1/user/profile-photo-url/" + query.user_id + "/" + query.auth_token + "/" + query.session_id + "/" + query.tokenized_user;
}

export async function deployedAppVersion() {
    var theHeaders = await getStdHeader();
    return axios.post(BASE_URL + '/api/v1/settings/app/version', {}, { headers: theHeaders });
}

export async function profileStructure() {
    var theHeaders = await getStdHeader();
    return axios.post(BASE_URL + '/api/v1/settings/app/profile-structure', {}, { headers: theHeaders });
}

export async function getProfilePicture(tokenizedUser="") {
    var currentUser = await getUser('currentUser');
    var profileURL = profilePictureURL({
        "user_id": currentUser.user_id,
        "auth_token": currentUser.auth.token,
        "session_id": currentUser.auth.session,
        "tokenized_user": tokenizedUser === "" ? currentUser.tokenized_user : tokenizedUser,
    });
    return profileURL;
}

export async function login(cred) {
    var theHeaders = await getStdHeader();
    return axios.post(BASE_URL + '/api/v1/user/login', cred, { headers: theHeaders });
}

export async function logoff() {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/user/logoff', {}, { headers: theHeaders });
}

export async function signup(user) {
    var theHeaders = await getStdHeader();
    return axios.post(BASE_URL + '/api/v1/user/create', user, { headers: theHeaders });
}

export async function verify(cred) {
    var theHeaders = await getStdHeader();
    return axios.post(BASE_URL + '/api/v1/user/verify', cred, { headers: theHeaders });
}

export async function resendVerify(cred) {
    var theHeaders = await getStdHeader();
    return axios.post(BASE_URL + '/api/v1/user/resend-verify', cred, { headers: theHeaders });
}

export async function getLangSettings(options = {}) {
    var theHeaders = await getStdHeader();
    return axios.post(BASE_URL + '/api/v1/settings/app/lang', options, { headers: theHeaders });
}

export async function getLangList(options = {}) {
    var theHeaders = await getStdHeader();
    return axios.post(BASE_URL + '/api/v1/settings/app/list-lang', options, { headers: theHeaders });
}

export async function getPreferredLang(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/user/preferred-language', options, { headers: theHeaders });
}

export async function verifySession(cred) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/user/verify', cred, { headers: theHeaders });
}

export async function registerDevice(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/user/register-device', options, { headers: theHeaders });
}

export async function resendVerifySession(cred) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/user/resend-verify', cred, { headers: theHeaders });
}

export async function createPost(post) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/post/create', post, { headers: theHeaders });
}

export async function updatePost(post) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/post/update', post, { headers: theHeaders });
}

export async function deletePost(post) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/post/delete', post, { headers: theHeaders });
}

export async function markPostSold(post) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/post/commerce/mark-sold', post, { headers: theHeaders });
}

export async function listPosts(options = { filtered_by: 'All' }) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/post/list', options, { headers: theHeaders });
}

export async function sharePost(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/post/share', options, { headers: theHeaders });
}

export async function sharedBy(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/post/share-by-list', options, { headers: theHeaders });
}

export async function reactPost(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/post/reaction', options, { headers: theHeaders });
}

export async function reactedBy(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/post/reaction-list', options, { headers: theHeaders });
}

export async function addPostUserSettings(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/post/user-setting', options, { headers: theHeaders });
}

export async function deletePostUserSettings(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/post/delete-user-setting', options, { headers: theHeaders });
}

export async function getPostUserSettings(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/post/get-user-settings', options, { headers: theHeaders });
}

export async function commentReactedBy(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/comment/reaction-list', options, { headers: theHeaders });
}

export async function favouritePost(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/post/favourite', options, { headers: theHeaders });
}

export async function flagPost(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/post/flag', options, { headers: theHeaders });
}

export async function flagPostOptions(options={}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/post/flag-options', options, { headers: theHeaders });
}

export async function fetchPost(postId) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/post/fetch', { post_id: postId }, { headers: theHeaders });
}

export async function fetchPostContent(postId) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/post/fetch-content', { post_id: postId }, { headers: theHeaders });
}

export async function savePostDraft(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/post/draft/save', options, { headers: theHeaders });
}

export async function deletePostDraft(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/post/draft/delete', options, { headers: theHeaders });
}

export async function listPostDrafts(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/post/draft/list', options, { headers: theHeaders });
}

export async function fetchPostDraft(draftId) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/post/draft/fetch', { draft_id: draftId }, { headers: theHeaders });
}

export async function commentList(postId) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/comment/list', { post_id: postId }, { headers: theHeaders });
}

export async function postComment(postId, comment, replyToComment=-1) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/post/comment', { post_id: postId, comment_content: comment, reply_to_comment: replyToComment }, { headers: theHeaders });
}

export async function reactComment(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/comment/reaction', options, { headers: theHeaders });
}

export async function commentedBy(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/comment/commented-by-list', options, { headers: theHeaders });
}

export async function deleteComment(postId, commentId) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/comment/delete', { post_id: postId, comment_id: commentId }, { headers: theHeaders });
}

export async function updateComment(postId, commentId, comment) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/comment/update', { post_id: postId, comment_id: commentId, comment_content: comment }, { headers: theHeaders });
}

export async function flagComment(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/comment/flag', options, { headers: theHeaders });
}

export async function flagCommentOptions(options={}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/comment/flag-options', options, { headers: theHeaders });
}

export async function friends(options={}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/friend/list', options, { headers: theHeaders });
}

export async function friendRequest(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/friend/request', options, { headers: theHeaders });
}

export async function friendRequestsList(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/friend/request-list', options, { headers: theHeaders });
}

export async function acceptRequest(notification_type, notificationMetadata) {
    var options = {};
    var theHeaders = await getHeader();
    if (notification_type === 'FRIEND_REQUEST_RECEIVED') {
        options['friend_request_id'] = notificationMetadata.request_id;
        options['request_response'] = 1;
        return axios.post(BASE_URL + '/api/v1/friend/respond-request', options, { headers: theHeaders });

    } else if (notification_type === 'FAMILY_REQUEST_RECEIVED') {
        options['family_request_id'] = notificationMetadata.request_id;
        options['request_response'] = 1;
        return axios.post(BASE_URL + '/api/v1/family/respond-request', options, { headers: theHeaders });

    } else if (notification_type === 'GROUP_MEMBER_INVITE' || notification_type === 'PAGE_MEMBER_INVITE') {
        options['group_invite_id'] = notificationMetadata.invite_id;
        return axios.post(BASE_URL + '/api/v1/group/accept-invite', options, { headers: theHeaders });

    } else {
        return;
    }
}

export async function declineRequest(notification_type, notificationMetadata) {
    var theHeaders = await getHeader();
    var options = {};
    if (notification_type === 'FRIEND_REQUEST_RECEIVED') {
        options['friend_request_id'] = notificationMetadata.request_id;
        options['request_response'] = 0;
        return axios.post(BASE_URL + '/api/v1/friend/respond-request', options, { headers: theHeaders });

    } else if (notification_type === 'FAMILY_REQUEST_RECEIVED') {
        options['family_request_id'] = notificationMetadata.request_id;
        options['request_response'] = 0;
        return axios.post(BASE_URL + '/api/v1/family/respond-request', options, { headers: theHeaders });

    } else if (notification_type === 'GROUP_MEMBER_INVITE' || notification_type === 'PAGE_MEMBER_INVITE') {
        options['group_invite_id'] = notificationMetadata.invite_id;
        return axios.post(BASE_URL + '/api/v1/group/ignore-invite', options, { headers: theHeaders });

    } else {
        return;
    }
}

export async function getAllConnection(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/user/mutual-connections', options, { headers: theHeaders });
}

export async function acceptFriendRequest(options = {}) {
    var theHeaders = await getHeader();
    options['request_response'] = 1;
    return axios.post(BASE_URL + '/api/v1/friend/respond-request', options, { headers: theHeaders });
}

export async function declineFriendRequest(options = {}) {
    var theHeaders = await getHeader();
    options['request_response'] = 0;
    return axios.post(BASE_URL + '/api/v1/friend/respond-request', options, { headers: theHeaders });
}

export async function listFamily(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/family/list', options, { headers: theHeaders });
}

export async function acceptFamilyRequest(options = {}) {
    var theHeaders = await getHeader();
    options['request_response'] = 1;
    return axios.post(BASE_URL + '/api/v1/family/respond-request', options, { headers: theHeaders });
}

export async function declineFamilyRequest(options = {}) {
    var theHeaders = await getHeader();
    options['request_response'] = 0;
    return axios.post(BASE_URL + '/api/v1/family/respond-request', options, { headers: theHeaders });
}

export async function familyRequest(options = {}) {
    var theHeaders = await getHeader();
    options['request_response'] = 0;
    return axios.post(BASE_URL + '/api/v1/family/request', options, { headers: theHeaders });
}

export async function familyRequestsList(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/family/request-list', options, { headers: theHeaders });
}

export async function unFriend(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/friend/un-friend', options, { headers: theHeaders });
}

export async function removeFamilyRelation(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/family/remove-relation', options, { headers: theHeaders });
}

export async function deleteFriendRequest(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/friend/delete-request', options, { headers: theHeaders });
}

export async function deleteFamilyRequest(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/family/delete-request', options, { headers: theHeaders });
}

export async function followUser(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/follow/create', options, { headers: theHeaders });
}

export async function followers(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/follow/follower-list', options, { headers: theHeaders });
}

export async function following(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/follow/following-list', options, { headers: theHeaders });
}

export async function unFollow(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/follow/un-follow', options, { headers: theHeaders });
}

export async function userSearch(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/user/search', options, { headers: theHeaders });
}

export async function postSearch(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/post/search', options, { headers: theHeaders });
}

export async function fetchUserProfile(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/user/fetch-profile', options, { headers: theHeaders });
}

export async function fetchDetailedUserProfile(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/detailed-profile/fetch', options, { headers: theHeaders });
}

export async function fetchUserProfilePhoto(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/user/fetch-profile-photo', options, { headers: theHeaders });
}

export async function userProfile(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/user/profile', options, { headers: theHeaders });
}

export async function updateUserProfileField(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/detailed-profile/update', options, { headers: theHeaders });
}

export async function updateUserPhoto(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/user/profile-photo', options, { headers: theHeaders });
}

export async function topicList(options = {}) {
    var theHeaders = await getStdHeader();
    return axios.post(BASE_URL + '/api/v1/topic/list', options, { headers: theHeaders });
}

export async function referUser(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/user/referral', options, { headers: theHeaders });
}

export async function checkReferralCode(options = {}) {
    var theHeaders = await getStdHeader();
    return axios.post(BASE_URL + '/api/v1/user/check-referral', options, { headers: theHeaders });
}

export async function getNotifications(options = {}) {
    var theHeaders = await getHeader();
    // console.log('getNotifications', theHeaders);
    return axios.post(BASE_URL + '/api/v1/user/notification', options, { headers: theHeaders });
}

export async function markNotificationAsRead(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/user/read-notification', options, { headers: theHeaders });
}

export async function changePassword(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/user/password/change', options, { headers: theHeaders });
}

export async function resetPasswordRequest(options = {}) {
    var theHeaders = await getStdHeader();
    return axios.post(BASE_URL + '/api/v1/user/password/reset-request', options, { headers: theHeaders });
}

export async function resetPassword(options = {}) {
    var theHeaders = await getStdHeader();
    return axios.post(BASE_URL + '/api/v1/user/password/reset', options, { headers: theHeaders });
}

export async function generateLinkPreview(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/link/preview', options, { headers: theHeaders });
}

export async function presignedURL(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/post/image/presigned-url', options, { headers: theHeaders });
}

export async function deleteImage(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/post/image/delete', options, { headers: theHeaders });
}

export async function uploadImage(url, file) {
    var theHeaders = await getHeader();
    return axios.put(url, file, { headers: theHeaders });
}

export async function nsfwCheck(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/post/image/nsfw-check', options, { headers: theHeaders });
}

export async function listNotificationModes(options={}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/notification-settings/modes', options, { headers: theHeaders });
}

export async function listNotificationEvents(options={}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/notification-settings/events', options, { headers: theHeaders });
}

export async function updateNotificationSettings(options={}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/notification-settings/update', options, { headers: theHeaders });
}

export async function downloadImage(url) {
    var theHeaders = await getHeader();
    return axios.get(url, { headers: theHeaders });
} 

export async function renewImageURL(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/post/image/renew-url', options, { headers: theHeaders });
}

export async function replyList(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/comment/reply-list', options, { headers: theHeaders });
}

export async function createGroup(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/group/create', options, { headers: theHeaders });
}

export async function updateGroup(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/group/update', options, { headers: theHeaders });
}

export async function listGroups(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/group/list', options, { headers: theHeaders });
}

export async function fetchGroup(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/group/fetch', options, { headers: theHeaders });
}

export async function groupMemberList(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/group/member-list', options, { headers: theHeaders });
}

export async function inviteRequestList(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/group/invite-list', options, { headers: theHeaders });
}

export async function joinRequestList(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/group/join-request-list', options, { headers: theHeaders });
}

export async function acceptGroupInvite(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/group/accept-invite', options, { headers: theHeaders });
}

export async function ignoreGroupInvite(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/group/ignore-invite', options, { headers: theHeaders });
}

export async function groupProfilePhoto(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/group/fetch-profile-photo', options, { headers: theHeaders });
}

export async function updateGroupProfilePhoto(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/group/profile-photo', options, { headers: theHeaders });
}

export async function groupMemberProfilePhoto(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/group/member-profile-photo', options, { headers: theHeaders });
}

export async function updateGroupMemberProfilePhoto(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/group/fetch-member-profile-photo', options, { headers: theHeaders });
}

export async function fetchGroupMember(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/group/fetch-member', options, { headers: theHeaders });
}

export async function inviteGroupUser(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/group/invite-user', options, { headers: theHeaders });
}

export async function removeGroupUser(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/group/remove-user', options, { headers: theHeaders });
}

export async function removeGroupAdmin(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/group/remove-admin', options, { headers: theHeaders });
}

export async function makeGroupAdmin(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/group/make-admin', options, { headers: theHeaders });
}

export async function searchGroup(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/group/search', options, { headers: theHeaders });
}

export async function requestJoinGroup(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/group/request-join', options, { headers: theHeaders });
}

export async function joinGroupRequestList(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/group/join-request-list', options, { headers: theHeaders });
}

export async function acceptGroupJoin(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/group/accept-join', options, { headers: theHeaders });
}

export async function rejectGroupJoin(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/group/reject-join', options, { headers: theHeaders });
}

export async function deactivateGroup(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/group/deactivate', options, { headers: theHeaders });
}

export async function leaveGroup(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/group/leave-group', options, { headers: theHeaders });
}

export async function muteGroup(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/group/toggle-mute', options, { headers: theHeaders });
}

// ---------------------- Chat Api Calls -----------------
export async function fetchNewChats(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/chat/get-new-messages', options, { headers: theHeaders });
}

export async function friendsInChat(options={}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/chat/friend-list', options, { headers: theHeaders });
}

export async function fetchChat(options={}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/chat/get-message', options, { headers: theHeaders });
}

export async function fetchGroupChat(options={}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/chat/get-group-message', options, { headers: theHeaders });
}

export async function fetchChatsAfterTime(options={}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/chat/get-chats-after-time', options, { headers: theHeaders });
}

export async function fetchGroupChatsAfterTime(options={}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/chat/get-group-chats-after-time', options, { headers: theHeaders });
}

export async function fetchChatInRange(options={}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/chat/get-messages-range', options, { headers: theHeaders });
}

export async function fetchGroupChatInRange(options={}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/chat/get-group-chat-range', options, { headers: theHeaders });
}

export async function updateChatStatus(options={}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/chat/update-chat-status', options, { headers: theHeaders });
}

export async function updateGroupChatStatus(options={}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/chat/update-group-chat-status', options, { headers: theHeaders });
}

export async function deleteChat(options={}){
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/chat/delete-chat', options, { headers: theHeaders });
}

export async function deleteChatForMe(options={}){   
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/chat/delete-chat-for-me', options, { headers: theHeaders });
}

export async function deleteGroupChat(options={}){
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/chat/delete-group-chat', options, { headers: theHeaders });
}                                 

export async function chatFriendsProfile(options={}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/chat/chat-friend-profile', options, { headers: theHeaders });
}

export async function chatUserSearch(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/chat/chat-user-search', options, { headers: theHeaders });
}

export async function getUserEncryptionKeys(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/chat/get-user-key', options, { headers: theHeaders });
}

export async function createFeedback(options={}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/feedback/create', options, { headers: theHeaders });
}

export async function showFeedbacks(options={}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/feedback/list', options, { headers: theHeaders });
}

export async function getUserVerifiedStatus(options={}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/user/fetch-verified-status', options, { headers: theHeaders });
}

export async function sendOTP(options={}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/user/send-otp', options, { headers: theHeaders });
}

export async function verifyOTP(options={}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/user/verify-otp', options, { headers: theHeaders });
}

export async function blockUser(options={}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/user/block', options, { headers: theHeaders });
}

export async function unblockUser(options={}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/user/unblock', options, { headers: theHeaders });
}

export async function listBlockUsers(options={}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/user/block-list', options, { headers: theHeaders });
}

export async function getConversationConfig(options={}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/conversation/get-config', options, { headers: theHeaders });
}

export async function notifyCall(options={}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/chat/video/notify-call', options, { headers: theHeaders });
}
