<template>
  <ion-content>
    <ion-list>
      <ion-item class="ion-padding-horizontal ion-no-margin" v-for="fopt in flagOptions" v-bind:key="fopt.value" @click="flagThePost(post, fopt.value)" :value="fopt.value"
        ><ion-label
          >{{ getStrings(strings, fopt.display_text) }}&nbsp;<ion-icon
            slot="icon-only"
            v-if="hasMyFlag(post, fopt.value)"
            :icon="checkmarkCircleOutline"
          ></ion-icon></ion-label
      ></ion-item>
    </ion-list>
  </ion-content>
</template>
<script>
import { IonContent, IonList, IonItem, IonIcon, IonLabel } from "@ionic/vue";
import { defineComponent } from "vue";

import { checkmarkCircleOutline } from "ionicons/icons";

import { flagPost, flagPostOptions } from "../../services/apiCall.js";
import { getStrings } from "../../services/lang";

export default defineComponent({
  name: "FlagOptions",
  props: ["post", "parent"],
  components: { IonContent, IonList, IonItem, IonIcon, IonLabel },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
  },
  setup() {
    return {
      getStrings,
    };
  },
  data() {
    return { 
      flagOptions: [],
      checkmarkCircleOutline 
    };
  },
  mounted() {
    console.log("FlagOptions page mounted");
    this.init();
  },
  methods: {
    init() {
      flagPostOptions().then((res) => {
        console.log('flagPostOptions', res);
        if (res.data.status === 0) {
          this.flagOptions = res.data.result.flag_options;
        }
      }).catch((err) => {
        console.log('flagPostOptions - err', err);
      })
    },
    hasMyFlag(post, theReason) {
      if (post.my_flags) {
        var hasFlag =
          post.my_flags.findIndex((x) => x.flag_type === theReason) >= 0;
        return hasFlag;
      } else {
        return false;
      }
    },
    flagThePost(post, theReason) {
      post.flag_count =
        typeof post.flag_count !== "undefined" ? post.flag_count + 1 : 1;

      flagPost({
        post_id: post.post_id,
        flag_type: theReason,
        reason: theReason,
      })
        .then((res) => {
          if (res.data.status == 0) {
            post.flag_count = res.data.result.flag_count;
            if (!post.my_flags) post.my_flags = [];
            var prevReact = post.my_flags.findIndex(
              (x) => x.flag_type === theReason
            );
            if (prevReact < 0) {
              post.my_flags.push({ flag_type: theReason });
            } else {
              post.my_flags.splice(prevReact, 1);
            }
          }
          this.parent.dismiss();
        })
        .catch((err) => {
          console.log("flag post - err", err);
          this.parent.dismiss();
        });
    },
  },
});
</script>
