<template>
  <div class="scrollmenu">
    <ion-chip
      v-for="topic in topics"
      v-bind:key="topic.id"
      :outline="topic.isSelected"
      :color="topic.icon_color"
      size="small"
      @click="toggleTopic(topic)"
    >
      <ion-label size="small" class="ion-text-center"
        ><small>{{ topic.topic_name }}</small></ion-label
      >
      <ion-icon
        size="small"
        v-if="topic.isSelected"
        :icon="checkmarkCircleOutline"
      ></ion-icon>
    </ion-chip>
  </div>

  <ion-item v-if="showRestrict">
    <ion-label color="warning" size="small"
      ><small>Select at most {{ restrictSelection }} tags</small></ion-label
    >
  </ion-item>
</template>
<script>
import { defineComponent } from "vue";
import { IonChip, IonLabel, IonIcon, IonItem } from "@ionic/vue";
import { closeCircle, checkmarkCircleOutline } from "ionicons/icons";

export default defineComponent({
  name: "Topics",
  props: ["topics", "restrictSelection", "callbackObjects"],
  components: {
    IonChip,
    IonLabel,
    IonIcon,
    IonItem,
  },
  data() {
    return {
      closeCircle,
      checkmarkCircleOutline,
      showRestrict: false,
      slideOptions: {
        navigation: true,
        initalSlide: 1,
        slidesPerView: "auto",
        grabCursor: true,
        freeMode: true,
        observeSlideChildren: true,
        setWrapperSize: true,
        centeredSlides: true,
        centeredSlidesBounds: true,
        // Responsive breakpoints
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 3,
            spaceBetween: 1,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 3,
            spaceBetween: 1,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 4,
            spaceBetween: 1,
          },
          // when window width is > 720px
          720: {
            slidesPerView: 5,
            spaceBetween: 1,
          },
          // when window width is > 1080px
          1080: {
            slidesPerView: 6,
            spaceBetween: 1,
          },
        },
      },
    };
  },
  methods: {
    toggleTopic(topic) {
      this.showRestrict = false;
      var currentlySelected = this.topics.filter((x) => x.isSelected);
      var isChangingCurrentlySelected =
        currentlySelected.findIndex((x) => x.id === topic.id) >= 0;
      if (isChangingCurrentlySelected) {
        topic.isSelected = !topic.isSelected;
      } else if (this.restrictSelection) {
        // console.log('toggleTopic', currentlySelected.length, this.restrictSelection);
        if (currentlySelected.length < this.restrictSelection) {
          topic.isSelected = !topic.isSelected;
        } else {
          this.showRestrict = true;
        }
      } else {
        topic.isSelected = !topic.isSelected;
      }
      // console.log("toggleTopic", topic);
      if (this.callbackObjects) {
        this.callbackObjects();
      }
    },
  },
});
</script>