<template>
  <ion-card class="ion-no-padding ion-no-margin">
    <ion-card-content
      no-padding
      no-margin
      class="ion-no-padding ion-no-margin"
      :class="isIOS() ? 'boxiOS' : 'box'"
    >
      <form @submit.prevent="submitForm" no-margin>
        <ion-grid class="grid_layout_nopadding zero_bottom_padding">
          <ion-row
            no-margin
            no-padding
            class="row_layout_nopadding zero_bottom_padding"
          >
            <ion-col
              class="
                ion-no-padding ion-no-margin
                zero_bottom_padding
                inline-padding-5px
              "
            >
              <!-- edit mode control -->
              <ion-item
                v-if="localEditMode"
                no-lines
                lines="none"
                no-margin
                no-padding
                class="ion-no-padding ion-no-margin"
              >
                <ion-button
                  size="normal"
                  fill="clear"
                  slot="end"
                  color="danger"
                  @click="cancelEdit()"
                >
                  <ion-icon size="small" :icon="closeCircleOutline"></ion-icon>
                </ion-button>
              </ion-item>

              <ion-row
                class="
                  row_layout_nopadding
                  ion-no-padding ion-no-margin
                  zero_bottom_padding zero_top_padding
                "
              >
                <ion-col :size="12" class="ion-no-padding ion-no-margin">
                  <ion-row
                    class="ion-no-padding ion-no-margin"
                    v-if="
                      (!localEditMode && inDraftLoadMode) ||
                      (localEditMode && !inDraftLoadMode && hasContent())
                    "
                  >
                    <ion-item
                      class="ion-no-padding ion-no-margin"
                      no-lines
                      lines="none"
                    >
                      <ion-text
                        v-if="!localEditMode && inDraftLoadMode"
                        size="small"
                        position="floating"
                        >{{ getStrings(strings, "EditingADraft") }} ({{
                          formatDate(referenceDraft.createdAt, strings)
                        }})</ion-text
                      >
                      <ion-text
                        v-if="localEditMode && !inDraftLoadMode && hasContent()"
                        size="small"
                        position="floating"
                        >{{ getStrings(strings, "UpdatePost") }}</ion-text
                      >
                    </ion-item>
                  </ion-row>

                  <ion-row class="ion-no-padding ion-no-margin">
                    <ion-col size="2" class="ion-no-padding ion-no-margin">
                      <ion-item
                        class="ion-no-padding ion-no-margin"
                        no-lines
                        lines="none"
                      >
                        <ion-avatar
                          size="small"
                          slot="start"
                          style="
                            min-width: 40px;
                            min-height: 40px;
                            cursor: pointer;
                          "
                          :class="
                            isIOS()
                              ? 'left-margin-5px avatar_button_padding'
                              : 'left-margin-5px'
                          "
                          @click="navigateUserProfile()"
                          v-if="currentProfilePhoto.photo_thumbnail && !localEditMode"
                        >
                          <ion-img
                            size="small"
                            class="animate-fading"
                            :src="currentProfilePhoto.photo_thumbnail"
                          ></ion-img>
                        </ion-avatar>

                        <ion-avatar
                          size="small"
                          slot="start"
                          v-if="typeof(currentProfilePhoto.is_photo_available) !== 'undefined' && !currentProfilePhoto.is_photo_available && !currentProfilePhoto.photo"
                          @click="navigateUserProfile()"
                          style="
                            min-width: 40px;
                            min-height: 40px;
                            background-color: #7762ff;
                            cursor: pointer;
                            align-items: center;
                          "
                          :class="
                            isIOS()
                              ? 'left-margin-5px avatar_button_padding'
                              : 'left-margin-5px'
                          "
                        >
                          <ion-label
                            style="color: black; margin-top: 7px"
                            class="ion-text-center"
                            ><b>{{
                              make2CharName(user.full_name)
                            }}</b></ion-label
                          >
                        </ion-avatar>
                      </ion-item>
                    </ion-col>
                    <ion-col size="8" class="ion-no-padding ion-no-margin">
                      <ion-item
                        class="ion-no-padding ion-no-margin"
                        no-lines
                        lines="none"
                      >
                        <ion-textarea
                          auto-grow="true"
                          autocomplete="on"
                          autocapitalize="on"
                          spellcheck="true"
                          rows="1"
                          class="ion-no-margin ion-padding-vertical-post-text"
                          :placeholder="placeHolderMessage"
                          v-model="enteredDescription"
                          name="enteredDescription"
                          @ionChange="detectActions"
                          debounce="500"
                        ></ion-textarea>
                      </ion-item>
                    </ion-col>
                    <ion-col size="2" class="ion-no-padding ion-no-margin">
                      <ion-item
                        class="ion-no-padding ion-no-margin"
                        no-lines
                        lines="none"
                      >
                        <media-upload-button
                          :post="post"
                          :photoPost="photoPost"
                        >
                        </media-upload-button>
                      </ion-item>
                    </ion-col>
                  </ion-row>
                </ion-col>

                <!-- image post preview -->
                <ion-col
                  class="ion-no-margin ion-no-padding"
                  :size="12"
                  v-if="photoPost.is_photo_post && !photoPost.is_photo_album"
                >
                  <ion-button
                    color="light"
                    size="small"
                    shape="round"
                    class="button_image_overlap"
                    @click="deletePhoto"
                    :disabled="deletePhotoClicked"
                  >
                    <ion-icon
                      slot="icon-only"
                      color="dark"
                      :icon="closeCircle"
                    ></ion-icon>
                  </ion-button>
                  <ion-spinner
                    v-if="deletePhotoClicked"
                    class="button_image_overlap"
                    name="dots"
                  ></ion-spinner>
                  <ion-item
                    no-lines
                    lines="none"
                    class="ion-no-padding ion-no-margin"
                  >
                    <ion-img :src="photoPost.photo_url"></ion-img>
                  </ion-item>
                  <ion-item
                    no-lines
                    lines="none"
                    class="ion-vertical-padding ion-no-margin"
                  >
                    <ion-toggle
                      color="primary"
                      size="small"
                      v-model="photoPost.is_for_sale"
                      @ionChange="forSaleToggled"
                    ></ion-toggle>
                    <ion-label>&nbsp;List for Direct Sale</ion-label>
                  </ion-item>
                  <ion-row>
                    <ion-col size="7">
                      <ion-item
                        v-if="photoPost.is_for_sale"
                        no-lines
                        lines="none"
                        class="ion-vertical-padding ion-no-margin"
                      >
                        <ion-input
                          placeholder="Your UPI ID"
                          v-model="photoPost.sale_specifics.upi"
                        ></ion-input>
                      </ion-item>
                      <ion-item
                        v-if="photoPost.is_for_sale"
                        no-lines
                        lines="none"
                        class="ion-vertical-padding ion-no-margin"
                      >
                        <ion-input
                          placeholder="Amount"
                          v-model="photoPost.sale_specifics.amount"
                        ></ion-input>
                      </ion-item>
                      <ion-item
                        v-if="photoPost.is_for_sale"
                        no-lines
                        lines="none"
                        class="ion-vertical-padding ion-no-margin"
                      >
                        <ion-input
                          placeholder="Note"
                          v-model="photoPost.sale_specifics.note"
                        ></ion-input>
                      </ion-item>
                    </ion-col>
                    <ion-col size="5">
                      <qrcode-vue
                        v-if="photoPost.is_for_sale"
                        class="left-margin-15px"
                        :value="getPaymentCode(photoPost)"
                      ></qrcode-vue>
                    </ion-col>
                  </ion-row>
                </ion-col>

                <!-- photo album image post preview -->
                <ion-col
                  class="ion-no-margin ion-no-padding"
                  :size="12"
                  v-if="photoPost.is_photo_album"
                >
                  <ion-row>
                    <ion-col size="4" :key="photo" v-for="photo in photoPost.photos">
                      <ion-button
                        color="light"
                        size="small"
                        shape="round"
                        class="button_image_overlap"
                        @click="deleteMultiplePhoto(photo)"
                        :disabled="deletePhotoClicked"
                      >
                        <ion-icon
                          slot="icon-only"
                          color="dark"
                          :icon="closeCircle"
                        ></ion-icon>
                      </ion-button>
                      
                      <ion-item
                        no-lines
                        lines="none"
                        class="ion-no-padding ion-no-margin"
                      >
                        <ion-img :src="photo.photo_url"></ion-img>
                        <ion-label v-if="photo.upload_error" color="danger" class="ion-text-wrap"> <small>{{ photo.error_message }} </small></ion-label>
                        <ion-label v-if="!photo.upload_completed" color="warning" class="ion-text-wrap"> <small> Uploading image </small></ion-label>
                      </ion-item>
                      <ion-spinner
                        v-if="deletePhotoClicked || !photo.upload_completed"
                         name="dots"
                      ></ion-spinner>
                    </ion-col>
                  </ion-row>
                </ion-col>

                <!-- video post preview -->
                <ion-col
                  class="ion-no-margin ion-no-padding"
                  :size="12"
                  v-if="photoPost.is_video_post"
                >
                  <ion-button
                    color="light"
                    size="small"
                    shape="round"
                    class="button_image_overlap"
                    @click="deletePhoto"
                    :disabled="deletePhotoClicked"
                  >
                    <ion-icon
                      slot="icon-only"
                      color="dark"
                      :icon="closeCircle"
                    ></ion-icon>
                  </ion-button>
                  <ion-spinner
                    v-if="deletePhotoClicked"
                    class="button_image_overlap"
                    name="dots"
                  ></ion-spinner>
                  <ion-item
                    no-lines
                    lines="none"
                    class="ion-no-padding ion-no-margin"
                  >
                    <video class="video_player" controls autoplay muted>
                      <source :src="photoPost.photo_url" type="video/mp4" />
                      {{ getStrings(strings, "BrowserNotSupportVideo") }}
                    </video>
                  </ion-item>
                </ion-col>

                <!-- link preview -->
                <ion-col
                  class="ion-no-margin ion-no-padding"
                  :size="12"
                  v-if="
                    isLinkPreviewAvailable &&
                    !photoPost.is_video_post &&
                    !photoPost.is_photo_post
                  "
                >
                  <ion-item
                    no-lines
                    lines="none"
                    class="
                      ion-no-padding
                      ion-no-margin
                      ion-align-self-center
                      ion-justify-content-center
                      ion-text-wrap
                      word-wrap:
                      break-word;
                      word-break;
                    "
                  >
                    <a
                      target="_nextExternalLink"
                      style="text-decoration: none"
                      :href="previewData.url"
                    >
                      <ion-card
                        class="
                          ion-padding-vertical-photo
                          ion-justify-content-center
                          ion-no-margin
                          ion-align-self-center
                          ion-text-wrap
                          full_width
                        "
                      >
                        <span style="width: 100%" class="animate-fading">
                          <ion-img
                            width="100%"
                            alt=".  .(No Image Available).  ."
                            :src="previewData.images[0]"
                            class="animate-fading"
                          ></ion-img>
                        </span>

                        <ion-card-header
                          class="
                            ion-padding-vertical-photo
                            ion-padding-horizontal
                            ion-no-margin
                          "
                        >
                          <ion-card-subtitle class="ion-no-margin">
                            <a
                              target="_nextExternalLink"
                              :href="previewData.url"
                              >{{ previewData.siteName }}
                            </a></ion-card-subtitle
                          ><ion-card-title class="ion-no-margin">
                            {{ previewData.title }}
                          </ion-card-title></ion-card-header
                        ><ion-card-content
                          class="
                            ion-padding-horizontal ion-no-margin
                            card_content_custom
                          "
                        >
                          <div class="ion-no-margin">
                            {{ previewData.description }}
                          </div>
                        </ion-card-content></ion-card
                      >
                    </a>
                  </ion-item>
                </ion-col>

                <ion-col
                  size="7"
                  class="ion-text-left ion-horizontal-padding ion-no-margin"
                  v-if="hasContent()"
                >
                  <ion-item class="ion-text-left" no-lines lines="none">
                    <ion-label
                      v-if="!areTopicsSelected()"
                      shape="round"
                      fill="clear"
                      color="dark"
                      class="ion-text-wrap"
                      ><span v-if="isMobileScreen()">{{
                        getStrings(strings, "AddTagsForVisibility")
                      }}</span>
                      <span v-if="!isMobileScreen()">{{
                        getStrings(strings, "AddTagsForVisibility")
                      }}</span></ion-label
                    >
                    <ion-label
                      v-if="areTopicsSelected()"
                      shape="round"
                      fill="clear"
                      color="dark"
                      class="ion-text-wrap"
                      ><small
                        ><b>{{ getStrings(strings, "Tags") }}</b
                        >: {{ getSelectedTopics() }}</small
                      ></ion-label
                    >
                  </ion-item>
                </ion-col>
                <ion-col :size="12" v-if="isMobileScreen() && hasContent()">
                  <topics
                    :topics="topics"
                    :restrictSelection="restrictSelection"
                  ></topics>
                </ion-col>
                <ion-col
                  :size="isMobileScreen() ? 12 : 5"
                  v-if="hasContent()"
                  class="ion-text-end"
                >
                  <ion-button
                    size="small"
                    color="success"
                    shape="round"
                    @click="saveDraft"
                    v-if="!localEditMode"
                    :disabled="!hasContent() || disablePost || isUploading()"
                  >
                    {{ getStrings(strings, "SaveDraft")
                    }}<ion-spinner v-if="disablePost" name="dots"></ion-spinner>
                  </ion-button>
                  <ion-button
                    size="small"
                    color="primary"
                    shape="round"
                    @click="cancelCreatePost"
                    v-if="!localEditMode"
                    :disabled="!hasContent() || disablePost || isUploading()"
                  >
                    {{ getStrings(strings, "Cancel")
                    }}<ion-spinner v-if="disablePost" name="dots"></ion-spinner>
                  </ion-button>
                  <ion-button
                    v-if="!localEditMode"
                    :disabled="!hasContent() || disablePost || isUploading()"
                    type="submit"
                    shape="round"
                    fill="solid"
                    color="primary"
                    size="small"
                    ><b>{{ getStrings(strings, "Post") }}</b
                    ><ion-spinner
                      v-if="disablePost"
                      slot="end"
                      name="dots"
                    ></ion-spinner
                  ></ion-button>
                  <ion-button
                    v-if="localEditMode"
                    :disabled="!hasContent() || disablePost"
                    type="submit"
                    shape="round"
                    fill="solid"
                    color="success"
                    size="small"
                    >{{ getStrings(strings, "Update")
                    }}<ion-spinner
                      v-if="disablePost"
                      slot="end"
                      name="dots"
                    ></ion-spinner
                  ></ion-button>
                </ion-col>
              </ion-row>
              <topics
                v-if="(isDesktopScreen() || isTabletScreen()) && hasContent()"
                :topics="topics"
                :restrictSelection="restrictSelection"
              ></topics>
              <ion-item
                no-lines
                lines="none"
                no-margin
                no-padding
                v-if="!localEditMode && postDrafts.length > 0"
              >
                <!-- drafts only in regular post mode, not in inline edit mode -->
                <ion-button
                  size="small"
                  shape="round"
                  color="primary"
                  slot="end"
                  @click="viewDrafts"
                >
                  {{ getStrings(strings, "Drafts") }} ({{ postDrafts.length }})
                </ion-button>
              </ion-item>
            </ion-col>
          </ion-row>
        </ion-grid>
      </form>
    </ion-card-content>
  </ion-card>
</template>

<script>
import {
  IonItem,
  IonLabel,
  IonTextarea,
  IonButton,
  IonIcon,
  IonRow,
  IonGrid,
  IonCol,
  IonSpinner,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonImg,
  IonText,
  IonToggle,
  IonInput,
  IonAvatar,
  alertController,
  popoverController,
  toastController,
} from "@ionic/vue";
import {
  camera,
  eye,
  pricetags,
  trash,
  saveOutline,
  closeCircleOutline,
  closeCircle,
  imageOutline,
} from "ionicons/icons";
import {
  createPost,
  updatePost,
  savePostDraft,
  deletePostDraft,
  deleteImage,
  generateLinkPreview,
} from "../../services/apiCall";
import {
  formatDate,
  isMobileScreen,
  isDesktopScreen,
  isTabletScreen,
  isIOS,
  processPreview,
  maxUploadSize,
  extracURLs,
  make2CharName,
} from "../../services/utils";
import Topics from "./Topics.vue";
import PostDraftList from "./PostDraftList.vue";
import MentionsList from "./MentionsList.vue";
import { getStrings } from "../../services/lang";
import { useRouter } from "vue-router";

import QrcodeVue from "qrcode.vue";

import CryptoJS from "crypto-js";

import MediaUploadButton from "../media/MediaUploadButton.vue";

export default {
  emits: ["save-post"],
  props: [
    "topics",
    "restrictSelection",
    "inEditMode",
    "post",
    "editCancelAction",
    "postPlaceHolderMessage",
    "visibilityMode",
    "group",
    "externalContent",
    "cancelCallback",
    "enableMentions",
    "mentionsSuggessionsCallback",
  ],
  components: {
    IonItem,
    IonLabel,
    IonTextarea,
    IonButton,
    IonIcon,
    IonRow,
    IonCol,
    IonGrid,
    Topics,
    IonSpinner,
    IonCard,
    IonCardContent,
    IonImg,
    IonText,
    IonAvatar,
    IonInput,
    IonToggle,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    MediaUploadButton,
    QrcodeVue,
  },
  mounted() {
    this.init();
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      return this.$store.getters.user;
    },
    topicList() {
      return this.$store.getters.topicList;
    },
    postDrafts() {
      return this.$store.getters.postDrafts;
    },
    currentProfilePhoto() {
      return this.$store.getters.currentProfilePhoto;
    },
  },
  data() {
    return {
      popoverController,
      enteredDescription: this.post
        ? this.post.description
        : this.externalContent
        ? this.externalContent
        : "",
      enteredVisibility: this.visibilityMode ? this.visibilityMode : "Friend",
      camera,
      eye,
      pricetags,
      trash,
      saveOutline,
      closeCircleOutline,
      closeCircle,
      imageOutline,
      disablePost: false,
      localEditMode: this.inEditMode,
      inDraftLoadMode: false,
      referenceDraft: {},
      photoPost: {
        is_photo_post: false,
        is_photo_album: false,
        is_video_post: false,
        photo_url: "",
        photo_object_name: "",
        foramt: "",
        is_content_scanned: false,
        is_content_appropriate: false,
        is_for_sale: false,
        sale_specifics: {
          upi: "",
          amount: "",
          note: "",
        },
        photos: [],
      },
      isLinkPreviewAvailable: false,
      inMentionSelectionMode: false,
      previewData: {},
      deletePhotoClicked: false,
      currentUserProfile: {
        photo: "",
        photo_thumbnail: "",
      },
      placeHolderMessage: this.postPlaceHolderMessage
        ? this.postPlaceHolderMessage
        : "Ignite conversations",
    };
  },
  setup() {
    const router = useRouter();
    return {
      isIOS,
      processPreview,
      maxUploadSize,
      extracURLs,
      generateLinkPreview,
      getStrings,
      make2CharName,
      createPost,
      updatePost,
      savePostDraft,
      deletePostDraft,
      formatDate,
      isMobileScreen,
      isDesktopScreen,
      isTabletScreen,
      router,
    };
  },
  methods: {
    async init() {
      if (this.inEditMode && this.post.preview_type === "Media") {
        var previewObj = JSON.parse(this.post.preview);
        var imgURL = previewObj["images"][0];
        if (previewObj.is_photo_album === true) {
          this.photoPost.is_photo_album = true;
          this.photoPost.is_photo_post = false;
          this.photoPost.is_video_post = false;

          for (var idx=0; idx<previewObj["images"].length; idx++) {
            let photoRec = {};
            photoRec['photo_object_name'] = previewObj.image_object_name_list[idx];
            var imageType = previewObj.image_object_name_list[idx].split(".");
            photoRec['format'] = imageType[imageType.length-1];
            photoRec['sale_specifics'] = {'upi': "", 'amount': "", 'note': ""};
            photoRec['photo_url'] = previewObj.images[idx];
            photoRec['is_photo_post'] = false;
            photoRec['upload_completed'] = true;
            photoRec['upload_error'] = false;
            photoRec['error_message'] = "";
            this.photoPost.photos.push(photoRec);
          }

        } else if (previewObj.image_object_name.endsWith(".mp4")) {
          this.photoPost.is_photo_album = false;
          this.photoPost.is_photo_post = false;
          this.photoPost.is_video_post = true;
        } else {
          this.photoPost.is_photo_album = false;
          this.photoPost.is_photo_post = true;
          this.photoPost.is_video_post = false;
        }
        this.photoPost.photo_url = imgURL;
        this.photoPost.photo_object_name = previewObj.image_object_name;

        this.photoPost.format = previewObj.format;

        this.photoPost.is_content_scanned = previewObj.is_content_scanned;
        this.photoPost.is_content_appropriate =
          previewObj.is_content_appropriate;

        if (this.photoPost.is_photo_post) {
          if (previewObj.sale_specifics && previewObj.sale_specifics !== "") {
            try {
              let saleSpecifics = JSON.parse(
                CryptoJS.AES.decrypt(
                  previewObj.sale_specifics,
                  this.post.tokenized_user
                ).toString(CryptoJS.enc.Utf8)
              );

              this.photoPost.is_for_sale = true;
              this.photoPost.sale_specifics.upi = saleSpecifics.upi;
              this.photoPost.sale_specifics.amount = saleSpecifics.amount;
              this.photoPost.sale_specifics.note = saleSpecifics.note;
            } catch (ignored) {
              console.log("photo post err", ignored);
            }
          }
        }

        this.enteredDescription = previewObj.userContent;
      }
    },
    hasContent() {
      return (
        this.enteredDescription.length > 0 ||
        this.photoPost.is_photo_post ||
        this.photoPost.is_video_post ||
        this.photoPost.is_photo_album
      );
    },
    isUploading() {
      for (var i=0; i<this.photoPost.photos.length; i++) {
        if (!this.photoPost.photos[i].upload_completed) {
          return true;
        }
      }
      return false;
    },
    async openToast(msg) {
      const toast = await toastController.create({
        message: msg,
        duration: 1500,
      });
      return toast.present();
    },
    async showAlert(title, msg) {
      const anAlert = await alertController.create({
        header: title,
        message: msg,
        buttons: ["OK"],
      });
      await anAlert.present();
    },
    correctPhotoPostContent() {
      var correctedContent = this.enteredDescription;
      if (this.photoPost.is_photo_post || this.photoPost.is_video_post || this.photoPost.is_photo_album) {
        if (!correctedContent.endsWith("\n")) {
          correctedContent += "\n";
        }
        if (this.photoPost.is_photo_album) {
          var allurls = this.photoPost.photos.map((x) => x.photo_url);
          correctedContent += allurls.join(',');
        } else {
          correctedContent += this.photoPost.photo_url;
        }
        
      }
      return correctedContent;
    },
    async deletePhoto() {
      this.deletePhotoClicked = true;
      deleteImage({ name: this.photoPost.photo_object_name })
        .then((res) => {
          if (res.data.status === 0) {
            this.photoPost.is_photo_post = false;
            this.photoPost.is_video_post = false;
            this.photoPost.photo_url = "";
            this.photoPost.photo_object_name = "";
            this.photoPost.format = "";
            this.photoPost.is_content_scanned = false;
            this.photoPost.is_content_appropriate = false;
            this.photoPost.is_for_sale = false;
            this.photoPost.sale_specifics.upi = "";
            this.photoPost.sale_specifics.amount = "";
            this.photoPost.sale_specifics.note = "";

            if (this.cancelCallback) this.cancelCallback();
          } else {
            this.showAlert(
              "Image delete error",
              "Unable to delete image, please try again"
            );
          }

          this.deletePhotoClicked = false;
        })
        .catch((err) => {
          console.log("deletePhoto - err", err);
          this.showAlert(
            "Image delete error",
            "Unable to delete image, please try again"
          );

          this.deletePhotoClicked = false;
        });
    },
    async deleteMultiplePhoto(photo) {
      this.deletePhotoClicked = true;
      deleteImage({ name: photo.photo_object_name })
        .then((res) => {
          if (res.data.status === 0) {
            photo.is_photo_post = false;
            photo.is_video_post = false;
            photo.photo_url = "";
            photo.photo_object_name = "";
            photo.format = "";
            photo.is_content_scanned = false;
            photo.is_content_appropriate = false;
            photo.is_for_sale = false;
            photo.sale_specifics.upi = "";
            photo.sale_specifics.amount = "";
            photo.sale_specifics.note = "";

            this.photoPost.photos = this.photoPost.photos.filter((y) => y.photo_object_name !== photo.photo_object_name );
            if (this.photoPost.photos.length === 0) this.photoPost.is_photo_album = false;

            if (this.cancelCallback) this.cancelCallback();
          } else {
            this.showAlert(
              "Image delete error",
              "Unable to delete image, please try again"
            );
          }

          this.deletePhotoClicked = false;
        })
        .catch((err) => {
          console.log("deletePhoto - err", err);
          this.showAlert(
            "Image delete error",
            "Unable to delete image, please try again"
          );

          this.deletePhotoClicked = false;
        });
    },
    deleteReferencePostDraft(postData) {
      if (this.inDraftLoadMode) {
        // delete this draft as this has been published
        this.deletePostDraft({ draft_id: this.referenceDraft.id })
          .then((res) => {
            if (res.data.status === 0) {
              if (postData)
                this.$emit("save-post", this.addPreviewData(postData));
              else
                this.$store.dispatch("deletepostdraft", {
                  id: this.referenceDraft.id,
                });
            } else {
              if (postData)
                this.$emit("save-post", this.addPreviewData(postData));
            }

            this.referenceDraft = {};
            this.inDraftLoadMode = false;
          })
          .catch((err) => {
            console.log("deletePostDraft - err", err);

            if (postData)
              this.$emit("save-post", this.addPreviewData(postData));

            this.referenceDraft = {};
            this.inDraftLoadMode = false;
          });
      } else {
        if (postData) this.$emit("save-post", this.addPreviewData(postData));
      }
    },
    addPreviewData(onePost) {
      var previewData = processPreview(onePost);
      onePost["previewData"] = previewData;
      return onePost;
    },
    areTopicsSelected() {
      return this.topics.filter((x) => x.isSelected).length > 0;
    },
    getSelectedTopics() {
      return this.topics
        .filter((x) => x.isSelected)
        .map((x) => x.topic_name)
        .join(", ");
    },
    submitForm() {
      this.disablePost = true;

      var selectedTopics = this.topics
        .filter((x) => x.isSelected)
        .map((x) => {
          var tpk = {};
          tpk["id"] = x.id;
          tpk["topic_name"] = x.topic_name;
          tpk["icon_color"] = x.icon_color;
          return tpk;
        });

      if (this.inEditMode) {
        var updateOptions = {
          post_id: this.post.id,
          post_content: this.correctPhotoPostContent(),
          selected_topics: selectedTopics,
          visibility: this.enteredVisibility,
        };

        if (this.photoPost.is_photo_post || this.photoPost.is_video_post) {
          updateOptions["object_name"] = this.photoPost.photo_object_name;

          if (this.photoPost.is_photo_post && this.photoPost.is_for_sale) {
            updateOptions["sale_specifics"] = CryptoJS.AES.encrypt(
              JSON.stringify(this.photoPost.sale_specifics),
              this.user.tokenized_user
            ).toString();
          }
        } else if (this.photoPost.is_photo_album) {
          updateOptions["object_name_list"] = this.photoPost.photos.map((x) => x.photo_object_name);
        }

        this.updatePost(updateOptions)
          .then(async (res) => {
            if (res.data.status === 0) {
              // first save locally
              const postData = {
                description: this.enteredDescription,
                visibility: this.enteredVisibility,
                user_fullname: this.post.user_fullname,
                createdAt: this.post.createdAt,
                id: this.post.id,
                post_id: this.post.post_id,
                topics: selectedTopics,
                post_by: this.user.user_id,
                preview: res.data.result.preview,
                preview_type: res.data.result.preview_type,
                is_active: true,
                post_origin_tag: this.enteredVisibility,
                tokenized_user: this.user.tokenized_user,
              };
              this.$store.dispatch("addpost", this.addPreviewData(postData));

              this.enteredDescription = "";
              this.disablePost = false;
              this.topics.map((x) => (x.isSelected = false));

              this.cancelEdit();
            } else {
              this.disablePost = false;
              await this.showAlert(
                "Post Update Error",
                "Unable to update post. Error is: " +
                  res.data.error.error_message
              );
            }
          })
          .catch(async (err) => {
            console.log("post err", err);
            this.disablePost = false;
            await this.showAlert(
              "Post Update Error",
              "Unable to update post, try again."
            );
          });
      } else {
        
        if (this.photoPost.photos.length > 0) {
          this.photoPost.photos = this.photoPost.photos.filter((y) => y.upload_error === false);
        }

        var options = {
          post_content: this.correctPhotoPostContent(),
          selected_topics: selectedTopics,
          visibility: this.enteredVisibility,
        };

        if (this.photoPost.is_photo_post || this.photoPost.is_video_post) {
          options["object_name"] = this.photoPost.photo_object_name;

          if (this.photoPost.is_photo_post && this.photoPost.is_for_sale) {
            options["sale_specifics"] = CryptoJS.AES.encrypt(
              JSON.stringify(this.photoPost.sale_specifics),
              this.user.tokenized_user
            ).toString();
          }
        } else if (this.photoPost.is_photo_album) {
          if (options.post_content.length < 2 && this.photoPost.photos.length === 0) {
            this.showAlert("Post Error", "Can not create empty post");
            this.disablePost = false;
            this.photoPost.is_photo_album = false;
            return;
          }
          options["object_name_list"] = this.photoPost.photos.map((x) => x.photo_object_name);
        }

        if (this.group) {
          options["group_id"] = this.group.group_id;
        }

        // then sync to the remote
        this.createPost(options)
          .then(async (res) => {
            if (res.data.status === 0) {
              // first save locally
              const postData = {
                description: this.enteredDescription,
                visibility: this.enteredVisibility,
                user_fullname: "me",
                createdAt: new Date().toISOString(),
                id: res.data.result.id,
                post_id: res.data.result.post_id,
                topics: selectedTopics,
                post_by: this.user.user_id,
                preview: res.data.result.preview,
                preview_type: res.data.result.preview_type,
                is_active: true,
                post_origin_tag: this.enteredVisibility,
                tokenized_user: this.user.tokenized_user,
              };
              this.enteredDescription = "";
              this.disablePost = false;
              this.topics.map((x) => (x.isSelected = false));

              this.photoPost.is_photo_post = false;
              this.photoPost.is_video_post = false;
              this.photoPost.is_photo_album = false;
              this.photoPost.photos = [];
              this.photoPost.photo_url = "";
              this.photoPost.photo_object_name = "";
              this.photoPost.format = "";
              this.photoPost.is_content_scanned = false;
              this.photoPost.is_content_appropriate = false;
              this.photoPost.is_for_sale = false;
              this.photoPost.sale_specifics.upi = "";
              this.photoPost.sale_specifics.amount = "";
              this.photoPost.sale_specifics.note = "";

              // delete reference post draft, if in draft edit mode
              this.deleteReferencePostDraft(postData);
            } else {
              this.disablePost = false;
              await this.showAlert(
                "Post Error",
                "Unable to create post, try again. Error is: " +
                  res.data.error.error_message
              );
            }
          })
          .catch(async (err) => {
            console.log("post err", err);
            this.disablePost = false;
            await this.showAlert(
              "Post Error",
              "Unable to create post, try again. Error is: " + err.toString()
            );
          });
      }
    },
    wordLength() {
      return this.enteredDescription.length == 0
        ? 0
        : this.enteredDescription.split(/\s+/).length;
    },
    cancelEdit() {
      this.localEditMode = false;
      this.photoPost.photos = [];
      this.photoPost.is_photo_album = false;

      if (typeof this.editCancelAction !== "undefined") {
        this.editCancelAction(this.post);
      }
    },
    saveDraft() {
      this.disablePost = true;

      var selectedTopics = this.topics
        .filter((x) => x.isSelected)
        .map((x) => {
          var tpk = {};
          tpk["id"] = x.id;
          tpk["topic_name"] = x.topic_name;
          tpk["icon_color"] = x.icon_color;
          return tpk;
        });

      var options = {
        draft_content: this.correctPhotoPostContent(),
        selected_topics: selectedTopics,
        visibility: this.enteredVisibility,
      };

      if (this.photoPost.is_photo_post || this.photoPost.is_video_post) {
        options["object_name"] = this.photoPost.photo_object_name;
      }

      this.savePostDraft(options)
        .then(async (res) => {
          if (res.data.status === 0) {
            // first save locally
            const postData = {
              description: this.enteredDescription,
              visibility: this.enteredVisibility,
              user_fullname: "me",
              createdAt: new Date().toISOString(),
              id: res.data.result.post_draft_id,
              post_draft_id: res.data.result.post_draft_id,
              topics: selectedTopics,
              post_by: this.user.user_id,
            };
            this.$store.dispatch("addpostdraft", postData);

            this.enteredDescription = "";
            this.disablePost = false;
            this.topics.map((x) => (x.isSelected = false));

            this.photoPost.is_photo_post = false;
            this.photoPost.photo_url = "";
            this.photoPost.photo_object_name = "";
            this.photoPost.foramt = "";
            this.photoPost.is_content_scanned = false;
            this.photoPost.is_content_appropriate = false;
            this.photoPost.is_for_sale = false;
            this.photoPost.sale_specifics.upi = "";
            this.photoPost.sale_specifics.amount = "";
            this.photoPost.sale_specifics.note = "";

            // delete reference post draft, if in draft edit mode
            this.deleteReferencePostDraft();
          } else {
            this.disablePost = false;
            await this.showAlert(
              "Post Draft Error",
              "Unable to save post draft, try again."
            );
          }
        })
        .catch(async (err) => {
          console.log("post draft err", err);
          this.disablePost = false;
          await this.showAlert(
            "Post Draft Error",
            "Unable to create post draft, try again."
          );
        });
    },
    loadDraft(postDraft) {
      if (postDraft.preview_type === "Media") {
        var previewObj = JSON.parse(postDraft.preview);
        var imgURL = previewObj["images"][0];
        if (previewObj.image_object_name.endsWith(".mp4")) {
          this.photoPost.is_photo_post = false;
          this.photoPost.is_video_post = true;
        } else {
          this.photoPost.is_photo_post = true;
          this.photoPost.is_video_post = false;
        }
        this.photoPost.photo_url = imgURL;
        this.photoPost.photo_object_name = previewObj.image_object_name;

        this.photoPost.foramt = previewObj.format;

        this.photoPost.is_content_scanned = previewObj.is_content_scanned;
        this.photoPost.is_content_appropriate =
          previewObj.is_content_appropriate;

        this.enteredDescription = previewObj.userContent;
      } else {
        this.enteredDescription = postDraft.post_draft_content;
      }

      this.enteredVisibility = postDraft.visibility;
      this.inDraftLoadMode = true;
      this.referenceDraft = postDraft;
      var selectedTopics = this.postTopicList(postDraft);
      this.topics.map((x) => {
        var selTop = selectedTopics.filter(
          (y) => y.topic_name === x.topic_name
        );
        if (selTop.length === 1) {
          x.isSelected = selTop[0].isSelected;
        }
      });
    },
    postTopicList(postDraft) {
      var selectedTopics = postDraft.topics;
      var topicList = JSON.parse(
        JSON.stringify(this.$store.getters.topicList)
      ).map((x) => {
        var isSlectedTopic =
          selectedTopics.filter((y) => {
            return y.topic_name == x.topic_name;
          }).length > 0;
        x.isSelected = isSlectedTopic;
        return x;
      });

      return topicList;
    },
    async viewDrafts(ev) {
      const popover = await popoverController.create({
        component: PostDraftList,
        event: ev,
        translucent: true,
      });
      popover.componentProps = {
        postDrafts: this.postDrafts,
        loadDraftCallback: this.loadDraft,
        parent: popover,
      };
      return popover.present();
    },
    async updateDescription(newDescription, newPhotoPost) {
      if (newDescription && !newPhotoPost) {
        this.enteredDescription = newDescription;
        this.showLinkPreview();
      }
      if (newPhotoPost) {
        this.photoPost.is_photo_post = newPhotoPost.is_photo_post;
        this.photoPost.is_video_post = newPhotoPost.is_video_post;
        this.photoPost.photo_url = newPhotoPost.photo_url;
        this.photoPost.photo_object_name = newPhotoPost.photo_object_name;

        this.enteredDescription = this.enteredDescription.replace(
          this.photoPost.photo_url,
          ""
        );
      }
    },
    async updateVisibility(newVisibility) {
      if (newVisibility) {
        this.enteredVisibility = newVisibility;
      }
    },
    async showLinkPreview() {
      let urlList = extracURLs(this.enteredDescription);

      if (urlList.length > 0) {
        if (this.previewData.url === urlList[0]) {
          return; // the same URL
        }

        generateLinkPreview({ url: urlList[0] })
          .then((res) => {
            if (res.data.status === 0) {
              this.isLinkPreviewAvailable = true;
              this.previewData = res.data.result.link_preview;
            } else {
              this.isLinkPreviewAvailable = false;
            }
          })
          .catch((err) => {
            console.log("preview err", err);
            this.openToast(
              this.getStrings(this.strings, "UnableToGeneratePreview")
            );
            this.isLinkPreviewAvailable = false;
          });
      } else {
        this.isLinkPreviewAvailable = false;
      }
    },
    personSelectedCallback(person) {
      let mentionText =
        '<a href="/user/profile/' +
        person.tokenized_user +
        '">@' +
        person.full_name +
        "</a>";
      let atIndex = this.enteredDescription.lastIndexOf("@");
      this.enteredDescription =
        this.enteredDescription.substr(0, atIndex) + mentionText;
      this.inMentionSelectionMode = false;
    },
    async detectActions(ev) {
      if (
        this.enteredDescription.endsWith("@") ||
        this.inMentionSelectionMode
      ) {
        this.inMentionSelectionMode = true;
        let atIndex = this.enteredDescription.lastIndexOf("@");
        let searchText = this.enteredDescription.substr(
          atIndex + 1,
          this.enteredDescription.length
        );
        if (this.enableMentions && this.mentionsSuggessionsCallback) {
          // call mentions suggestions and show the list
          var peopleList = this.mentionsSuggessionsCallback(searchText);

          const popover = await popoverController.create({
            component: MentionsList,
            event: ev,
            translucent: true,
          });
          popover.componentProps = {
            peopleList: peopleList,
            parent: popover,
            personSelectedCallback: this.personSelectedCallback,
          };

          return popover.present();
        }
      } else {
        this.inMentionSelectionMode = false;
        this.showLinkPreview();
      }
    },
    async navigateUserProfile() {
      await this.router.push("/user/profile/" + this.user.tokenized_user);
    },
    getPaymentCode(photoPost) {
      if (!photoPost.sale_specifics) return "";
      try {
        let payURI =
          "upi://pay?pa=" +
          photoPost.sale_specifics.upi +
          "&pn=" +
          this.user.fullname +
          "&tn=" +
          photoPost.sale_specifics.note +
          "&am=" +
          photoPost.sale_specifics.amount;
        return payURI;
      } catch (ignored) {
        console.log("getPaymentCode - err", ignored);
        return "";
      }
    },
    forSaleToggled() {
      try {
        this.topics.filter((x) => x.topic_name === "For Sale")[0].isSelected =
          this.photoPost.is_for_sale;
      } catch (ignored) {
        console.log("forSaleToggled - err", ignored);
      }
    },
    async cancelCreatePost() {
      //delete uploaded images if any
      this.disablePost = true;
      for (var idx=0; idx < this.photoPost.photos.length; idx++) {
        deleteImage({ name: this.photoPost.photos[idx].photo_object_name })
        .then(() => {
        })
      }
      this.enteredDescription = "";
      this.photoPost.photos = [];
      this.photoPost.is_photo_post = false;
      this.photoPost.is_video_post = false;
      this.photoPost.is_photo_album = false;
      this.photoPost.photo_url = "";
      this.photoPost.photo_object_name = "";
      this.photoPost.format = "";
      this.photoPost.is_content_scanned = false;
      this.photoPost.is_content_appropriate = false;
      this.photoPost.is_for_sale = false;
      this.photoPost.sale_specifics.upi = "";
      this.photoPost.sale_specifics.amount = "";
      this.photoPost.sale_specifics.note = "";

      this.disablePost = false;

    }
  },
};
</script>