
<template>
  <ion-page>
    <ion-content fullscreen="true">
      <!-- <ion-row class="animate-fading">
        <ion-col class="ion-text-center ion-justify-content-center">
          <div class="text-center">
            <h1 style="text-align: center">NEXT</h1>
          </div>
        </ion-col>
      </ion-row> -->
    </ion-content>
  </ion-page>
</template>

<script>
import {
  //IonRow,
  //IonCol,
  IonPage,
  IonContent,
} from "@ionic/vue";

import { Plugins } from "@capacitor/core";
const { SplashScreen } = Plugins;

import { useRouter } from "vue-router";

export default {
  name: "LoadingPage",
  
  ionViewWillEnter() {
    this.init();
  },
  
  components: {
    //IonRow,
    //IonCol,
    IonPage,
    IonContent,
  },
  setup() {
    const router = useRouter();
    return {
      router,
    };
  },
  data() {
    return {
    };
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    async init() {
      var loadUI = async () => {
        let loadingPage = localStorage.getItem("defaultRoute");
        console.log('loading', loadingPage);
        if (typeof(loadingPage) === 'undefined' || loadingPage === '' || loadingPage === null) {
          await this.router.replace("/login");
          SplashScreen.hide();
        } else {
          await this.router.replace(loadingPage);
          SplashScreen.hide();
        }
      };

      // load the UI
      await loadUI();
    },
  },
};
</script>